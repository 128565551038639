/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { DocumentsFilter, UploadFile } from '../../types';

export interface DocumentsState {
  count: number;
  entities: Record<string, UploadFile>;
  isLoading: boolean,
};

const initialState: DocumentsState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchDocuments = createAsyncThunk(
  'getDocuments/fetchDocuments',
  async (filter?: DocumentsFilter) => {
    const response = await getPostRequest<UploadFile[] | null>('v2/document/all', filter);
    return response.data;
  },
);

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    cleanDocuments: (state) => {
      state.count = 0;
      state.entities = {};
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.entities = _.keyBy(payload.data, 'id');
          state.count = payload.count || payload.data?.length || 0;
        }
      })
      .addCase(fetchDocuments.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanDocuments } = documentsSlice.actions;

export default documentsSlice.reducer;
