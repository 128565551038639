/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterMomentJs from '@mui/lab/AdapterMoment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

interface DateRangeDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (startDate?: Date | null | undefined, endDate?: Date | null | undefined) => void;
  maxStartDate?: Date | undefined;
  maxEndDate?: Date | undefined;
  minStartDate?: Date | undefined;
  minEndDate?: Date | undefined;
}

const DateRangeDialog = (props: DateRangeDialogProps) => {
  const { open, handleClose, handleSubmit,
    maxEndDate, maxStartDate, minEndDate,
    minStartDate } = props;

  const specialPropsStartDate = {
    minDate: minStartDate,
    maxDate: maxStartDate,
  }
  const specialPropsEndDate = {
    minDate: minEndDate,
    maxDate: maxEndDate,
  }

  const [startDate, setstartDate] = useState<Date | null | undefined>(null);
  const [endDate, setendDate] = useState<Date | null | undefined>(null);

  const handleStartDateChange = (date: Date | null | undefined) => {
    setstartDate(date);
  };

  const handleEndDateChange = (date: Date | null | undefined) => {
    setendDate(date);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Selección de Fechas</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMomentJs}>
            <Grid alignItems="center" container justifyContent="center"
              style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid item xs={5}>
                <DatePicker
                  label="Fecha de Inicio"
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  value={startDate}
                  {...specialPropsStartDate}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <DatePicker
                  label="Fecha Final"

                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  value={endDate}
                  {...specialPropsEndDate}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancelar</Button>
          <Button onClick={() => {
            handleSubmit(startDate, endDate);
            handleClose();
          }} variant="contained">Aplicar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DateRangeDialog.defaultProps = {
  maxStartDate: undefined,
  maxEndDate: undefined,
  minStartDate: undefined,
  minEndDate: undefined,
}

export default DateRangeDialog
