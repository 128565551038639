import { FC } from 'react';

import {
  AttachFile,
  Image,
  PictureAsPdf,
} from '@mui/icons-material';
// import { SvgIcon } from '@mui/material'

// import FileSVG from '../assets/static/icons8-document-50.svg';
import { UploadFile } from '../types';

interface Props {
  file: UploadFile,
};

const FileIcon: FC<Props> = ({ file }): JSX.Element => {
  if (file === null) {
    return <AttachFile />
  }

  const extension = file.name.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
      return <AttachFile color='success' />

    case 'apng':
    case 'avif':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'jfif':
    case 'pjpeg':
    case 'pjp':
    case 'png':
    case 'svg':
    case 'webp':
      return <Image color='success' />;

    case 'pdf':
      return <PictureAsPdf color='success' />;

    default:
      // return <FileSVG />
      // return <SvgIcon component={FileSVG} inheritViewBox  />
      return <AttachFile />;
  }
};

export default FileIcon;
