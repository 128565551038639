/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDeleteRequest, getGetRequest, getPostRequest, getPutRequest } from 'helpers';
import _, { Dictionary } from 'lodash';

import { BankAccountDraft, BankAccountDraftFilter, BankAccountDraftUpdate, ServerType } from '../../types';

export interface BankAccountDrafsState {
  count: number;
  entities: Dictionary<BankAccountDraft>;
  isLoading: boolean;
};

const initialState: BankAccountDrafsState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchBankAccountDrafts = createAsyncThunk(
  'getBankAccountDrafts/fetchBankAccountDrafts',
  async (filter: BankAccountDraftFilter): Promise<BankAccountDraft[]> => {
    const { page, limit, clientId } = filter;

    const { status, data } = await getGetRequest(
      `bank-account-drafts/page/${page}/limit/${limit}`,
      { server: ServerType.Node },
      { clientId });
    return status === 200 ? data : [];
  },
);

export const addBankAccountDraft = createAsyncThunk(
  'postBankAccountDraft/addBankAccountDraft',
  async (draft: BankAccountDraft): Promise<BankAccountDraft | null> => {
    const { status } = await getPostRequest('bank-account-drafts', draft, { server: ServerType.Node });
    return status === 201 ? draft : null;
  },
);

export const editBankAccountDraft = createAsyncThunk(
  'putBankAccountDraft/editBankAccountDraft',
  async (draft: BankAccountDraftUpdate): Promise<BankAccountDraftUpdate | null> => {
    const { status } = await getPutRequest('bank-account-drafts', draft, { server: ServerType.Node });

    return status === 200 ? draft : null;
  }
);

export const deleteBankAccountDraft = createAsyncThunk(
  'deleteBankAccountDraft/deleteBankAccountDraft',
  async (id: string): Promise<string | null> => {
    const { status } = await getDeleteRequest(`bank-account-drafts/${id}`, { server: ServerType.Node });

    return status === 200 ? id : null;
  }
);

export const deleteManyBankAccountDraft = createAsyncThunk(
  'deleteBankAccountDraft/deleteManyBankAccountDraft',
  async (ids: string[]): Promise<string[]> => {
    const promises = await Promise.all(ids.map((id) => getDeleteRequest(`bank-account-drafts/${id}`, { server: ServerType.Node })));
    const deletedIds: string[] = [];

    promises.forEach(({ status }, index) => {
      if (status === 200) {
        deletedIds.push(ids[index]);
      }
    });

    return deletedIds;
  }
);

export const bankAccountDrafsSlice = createSlice({
  name: 'bankAccountDrafts',
  initialState,
  reducers: {
    cleanBankAccountDrafts: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccountDrafts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBankAccountDrafts.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchBankAccountDrafts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(addBankAccountDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBankAccountDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.count += 1;
          state.entities[payload.id] = payload;
        }
      })
      .addCase(addBankAccountDraft.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(editBankAccountDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editBankAccountDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.entities[payload.id] = {
            ...state.entities[payload.id],
            ...payload,
          };
        }
      })
      .addCase(editBankAccountDraft.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteBankAccountDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBankAccountDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          delete state.entities[payload];
        }
      })
      .addCase(deleteBankAccountDraft.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteManyBankAccountDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteManyBankAccountDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          payload.forEach((id) => {
            delete state.entities[id];
          });
        }
      })
      .addCase(deleteManyBankAccountDraft.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanBankAccountDrafts } = bankAccountDrafsSlice.actions;

export default bankAccountDrafsSlice.reducer;
