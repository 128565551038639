import { Column } from '../../../types';

const columns: Column[] = [
  {
    title: 'Clave',
    field: 'key',
    isEnabled: false,
    pathValue: 'employee.key',
  },
  {
    title: 'Nombre',
    field: 'name',
    isEnabled: false,
    pathValue: 'employee.name',
  },
  {
    title: 'Apellido paterno',
    field: 'firstLastName',
    isEnabled: false,
    pathValue: 'employee.firstLastName',
  },
  {
    title: 'Apellido materno',
    field: 'secondLastName',
    isEnabled: false,
    pathValue: 'employee.secondLastName',
  },
  {
    title: 'Total a pagar',
    field: 'totalToDisperse',
    type: 'money',
    isEnabled: false,
    pathValue: 'totalToDisperse',
  },
  {
    title: 'Total percepciones',
    field: 'totalPerceptions',
    type: 'money',
    isEnabled: false,
    pathValue: 'amount',
  },
  // {
  //   title: 'Total deducciones',
  //   field: 'deductionsTotal',
  //   type: 'money',
  //   isEnabled: false,
  //   hidden: true,
  //   pathValue: 'deductionsTotal',
  // },
  {
    title: 'Comisión',
    field: 'fee',
    type: 'money',
    isEnabled: false,
    pathValue: 'commission',
  },
  {
    title: 'Método de pago',
    field: 'paymentMethod',
    pathValue: 'paymentMethod.name',
  },
  {
    title: 'Bancos',
    field: 'bank',
    pathValue: 'employeeBankAccount.bank',
  },
  {
    title: 'Cuenta',
    field: 'account',
    pathValue: 'employeeBankAccount.accountNumber',
  },
];

export default columns;
