import { Helmet } from 'react-helmet-async';

import _ from 'lodash';

import routesList from '../Routes/routesList';
import { Route } from '../types';

interface IHelmetRendererProps {
  path: string;
}

const HelmetRenderer = (props: IHelmetRendererProps) => {
  const { path } = props;
  const routes = _.keyBy(routesList, 'path');

  const view = routes[path] ? routes[path] : {
    title: 'Clientes | Levita [0x1]',
  } as Route;

  return <Helmet>
    <title>{view?.title}</title>
    <meta content="Levita Clientes" name={view?.description} />
  </Helmet>;
};

export default HelmetRenderer;
