export const SUAEntry = `
  query SUAEntry($employeeIDs: [ID]) {
    suaEntry(employeeIDs: $employeeIDs)
  }
`;

export const SUAReentry = `
query SUAReentry($inputs: [ReentryMovementInput]) {
  suaReentry(inputs: $inputs)
}
`;

export const EmployeeEmail = `
query EmployeeEmail($id: ID!) {
  employee(id: $id) {
    email
  }
}
`;
