export const sfprepayrolls = `
  query sfprepayrolls($payrollPeriod: String) {
    sfprepayrolls(payrollPeriod: $payrollPeriod) {
      count
      data {
        alimony
        awards
        bonus
        calculoFiscal
        createdAt
        diningRoom
        disabilityDays
        doubleOvertime
        employee {
          id
          name
          firstLastName
          secondLastName
          key
          bankAccounts {
            id
            CLABE
            accountName
            accountNumber
            bank
            cardDefault
            description
          }
          paymentMethod {
            id
            name
          }
          payrollBranch {
            id
            society {
              id
              stationIssuers
              name
            }
          }
        }
        employeeBankAccount {
          id
          bank
          accountNumber
          CLABE
        }
        extraTime
        faults
        holidayWorked
        id
        netSalary
        pantryVouchers
        paymentMethod {
          id
          name
        }
        payrollPeriod {
          id
          fullName
          name
        }
        ptu
        restWorked
        savingFund
        sundayBonus
        sundaysWorked
        tripleOvertime
        updatedAt
        vacation
        vacationBonus
        workedDays
      }
    }
  }
`