import moment from 'moment-timezone';

import { findOption, formatCurrency } from '../../helpers';
import { Cell, UploadFile, Value } from '../../types';

const DefaultValueRenderer = (cell: Cell, row: number): Value => {
  const { type, isRequired, label, value, rowIndex, options } = cell;
  if (row === 0 || rowIndex === -1) {
    return `${value}${isRequired ? '*' : ''}`;
  }
  if (type === 'date' && value !== '' && value !== null) {
    const dateValue = typeof value === 'string' ? new Date(value) : value as Date;
    return moment(dateValue).format('DD-MM-YYYY');
  }
  if (type === 'money') {
    return formatCurrency(value as number);
  }
  if (type === 'select') {
    if (label) return label;
    if (value) return findOption(`${value}`, options).label;
  }
  if (type === 'boolean') {
    return value ? 'Si' : 'No';
  }
  if (type === 'check') {
    return value;
  }
  if (type === 'file') {
    const file = value as UploadFile;
    return file?.publicURL && '';
  }
  if (type === 'button') return cell.label;
  return value;
};

export default DefaultValueRenderer;
