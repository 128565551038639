export const payrollPeriod = `
query payrollPeriod($id: String!) {
  payrollPeriod(id: $id) {
    id
    administrativePeriod
    name
    client{
      id
    }
    frequency {
      name
      workDays
    }
    payrollSchema
    payrollType {
      id
      name
    }
    start
    end
    payrollStatus
    dispersionStatus
    dispersionInformation {
      totalAmount
      programmingDate
      date
    }
    billingInformation {
      totalInvoiceAmount
      stampingDate
      programmingDate
      programmed
      billingDate
    }
    employeeStampStatus
    type
    stampingErrors {
      type
      errorCode
      error
      employeeName
      employeeId
    }
  }
}
`