export const extraordinaryPayrollAddMany = `
mutation extraordinaryPayrollAddMany(
  $payrollPeriod: String!
  $prepayrolls: [InputExtraordinaryPayroll!]!
) {
  extraordinaryPayrollAddMany(
    payrollPeriod: $payrollPeriod
    payroll: $prepayrolls
  ) {
    amount
    employee {
      id
      name
      firstLastName
      secondLastName
    }
    id
    paymentMethod {
      id
      name
    }
    payrollPeriod {
      id
      name
    }
  }
}
`;
