/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest, getPutRequest } from '../../helpers';
import { ClientPendingTask, ClientPendingTaskFilters, ClientPendingTaskInput } from '../../types';

export interface ClientPendingTasksState {
  count: number;
  entities: Record<string, ClientPendingTask>;
  isLoading: boolean;
  selected: string;
}

const initialState: ClientPendingTasksState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: '',
};

export const createClientPendingTask = createAsyncThunk(
  'clientPendingTasks/create',
  async (clientPendingTask: ClientPendingTaskInput, { rejectWithValue }) => {
    const response = await getPostRequest<ClientPendingTask>('v2/clientpendingtask/add', clientPendingTask);
    if (response.status !== 200) {
      return rejectWithValue(response.data);
    }

    const { data: DataResponse } = response;
    const { data: document } = DataResponse;

    return document;
  }
);

export const updateClientPendingTask = createAsyncThunk(
  'clientPendingTasks/update',
  async (clientPendingTask: ClientPendingTask, { rejectWithValue }) => {
    const response = await getPutRequest('v2/clientpendingtask/edit', clientPendingTask);
    if (response.status !== 200) {
      return rejectWithValue(response.data);
    }

    const { data: DataResponse } = response;
    const { data: document } = DataResponse;

    return document;
  }
);

export const deleteClientPendingTask = createAsyncThunk(
  'getClientPendingTasks/fetchClientPendingTasks',
  async (filters?: ClientPendingTaskFilters) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/clientpendingtask/delete/many',
      filters
    );
    const { data } = dataResponse;

    if (data) {
      return data
    }

    throw new Error('Error deleting client pending tasks');

  }
);

export const promiseDeleteClientPendingTask =
  async (filters?: ClientPendingTaskFilters) => new Promise(async (resolve, reject) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/clientpendingtask/delete/many',
      filters
    );
    const { data } = dataResponse;

    if (data) {
      resolve(data)
    }

    reject(dataResponse);
  })

export const fetchClientPendingTasks = createAsyncThunk(
  'getClientPendingTasks/fetchClientPendingTasks',
  async (filters?: ClientPendingTaskFilters) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/clientpendingtask/all',
      filters
    );
    const { data } = dataResponse;
    return data || [];
  }
);

export const promisedCalculateClientPendingTasksByPeriod = async (periodId: string) => {
  const { data: dataResponse } = await getPostRequest(
    'v2/clientpendingtask/calculate',
    {
      all: false,
      byClient: false,
      byPayrollPeriod: true,
      clientId: null,
      payrollPeriodId: periodId,
    },
  );
  const { data } = dataResponse;
  return data || [];
}

export const promisedCalculateClientPendingTasksByClient = async (clientId: string) => {
  const { data: dataResponse } = await getPostRequest(
    'v2/clientpendingtask/calculate',
    {
      all: false,
      byClient: true,
      byPayrollPeriod: false,
      clientId,
      payrollPeriodId: null,
    },
  );
  const { data } = dataResponse;
  return data || [];
}

export const fetchClientPendingTasksNoRedux = async (filters?: ClientPendingTaskFilters) => new Promise<ClientPendingTask[]>((resolve, reject) => {
  getPostRequest(
    'v2/clientpendingtask/all',
    filters
  ).then(({ data: dataResponse }) => {
    const { data } = dataResponse;
    resolve(data || []);
  }).catch((error) => {
    // console.log('[ERR][fetchClientPendingTasksNoRedux]', error);
    reject(error);
  })

})

export const pendingTasksSlice = createSlice({
  name: 'clientPendingTask',
  initialState,
  reducers: {
    cleanClientPendingTasks: (state) => {
      state.count = 0;
      state.entities = {};
      state.selected = '';
    },
    setSelectedClientPendingTask: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientPendingTasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchClientPendingTasks.fulfilled,
        (state, action: PayloadAction<ClientPendingTask[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = _.keyBy(payload, 'id');
          if (
            state.selected !== '' &&
            state.entities[state.selected] === undefined
          ) {
            state.selected = '';
          }
        }
      )
      .addCase(fetchClientPendingTasks.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(createClientPendingTask.fulfilled, (state, action) => {
      const { payload } = action;
      state.entities[payload.id] = payload;
    });

    builder.addCase(updateClientPendingTask.fulfilled, (state, action) => {
      const { payload } = action;
      state.entities[payload.id] = payload;
    });
  },
});

export const { cleanClientPendingTasks, setSelectedClientPendingTask } = pendingTasksSlice.actions;

export default pendingTasksSlice.reducer;
