import { PayrollScheme } from './periods';
import { Pagination, TimeRank, WhereClause } from './request';

export interface PayrollReceipt {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  employee: string;
  society: string;
  folio: number;
  dispersion: string;
  pdfURL: string;
  xmlURL: string;
  zipURL: string;
  payrollPeriod: string;
  payrollType: string;
  schemeType: PayrollScheme;
  client: string;
  company: string;
  json: string;
  status: PRStatus;
  emailStatus: PREmailStatus;
  billingUUID: string;
  canceledInFavorOfUUID: string;
  cancelInfo: string;
  startDatePeriod: string;
  endDatePeriod: string;
  employeeDepartment: string;
  employeeSocietyBranch: string;
  periodName: string;
  facturamaStampId: string;
  provider: string;
}

export enum PRStatus {
  PRPENDING = "PENDIENTE",
  PRCOMPLETED = "COMPLETADO",
  PRERROR = "ERROR",
  PRCANCELED = "CANCELADO",
  PRCANCELEDANDRESTAMPED = "CANCELADO Y RETIMBRADO",
}

export enum PREmailStatus {
  PREPending = "POR ENVIAR",
  PREScheduled = "PROGRAMADO",
  PRESent = "ENVIADO",
  PREError = "ERROR",
  PREALLBUTSENT = "ALLBUTSENT",
}

export interface PayrollReceiptFilter {
  id?: string;
  pagination?: Pagination;
  employee?: string;
  society?: string;
  dispersion?: string;
  billingUUID?: string;
  client?: string;
  folio?: string;
  status?: string;
  emailStatus?: string;
  emailStatusWC?: WhereClause;
  payrollPeriod?: string;
  payrollType?: string;
  schemeType?: string;
  canceledInFavorOf?: string;
  employeeDepartment?: string;
  employeeSocietyBranch?: string;
  payrollPeriodDates?: TimeRank;
  year?: number;
  societyWhereClause?: WhereClause;
  payrollTypeWhereClause?: WhereClause;
  mostRecentByEmployee?: boolean;
  hasEmail?: boolean;
}

export interface PayrollReceiptStampOutput {
  payrollReceiptID: string;
  PDF: string;
  XML: string;
  ZIP: string;
  employeeName: string;
  errors: string[];
  prevErrors?: string[];
}

export interface PayrollReceiptCancelOutput {
  employeeName: string;
  canceled: boolean;
  errors: string[];
  prevErrors?: string[];
}