import * as bankAccounts from './bankAccounts';
import * as dismissalAdd from './dismissalAdd';
import * as employees from './employees';
import * as extraordinaryPayrollAddMany from './extraordinaryPayrollAddMany';
import * as extraordinaryPayroll from './extraoridaryPayroll';
import * as payrolls from './payrolls';
import * as payrollType from './payrollType';
import * as periods from './periods';
import * as pppsPayrollAddMany from './pppsPayrollAddMany';
import * as sfprepayrollAddMany from './sfprepayrollAddMany';

const mutations = {
  ...bankAccounts,
  ...dismissalAdd,
  ...extraordinaryPayrollAddMany,
  ...extraordinaryPayroll,
  ...payrolls,
  ...employees,
  ...payrolls,
  ...payrollType,
  ...periods,
  ...pppsPayrollAddMany,
  ...sfprepayrollAddMany,
};

export default mutations;