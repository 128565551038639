import { CSSProperties, FC } from 'react';

import { Box, Divider, List, Paper, Typography } from '@mui/material';

interface Props {
  title?: string,
  titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit",
  icon?: JSX.Element,
  styleContainer?: CSSProperties,
  children?: JSX.Element | JSX.Element[],
  style?: React.CSSProperties,
};

const defaultStyleContainer: CSSProperties = {
  borderRadius: '20px',
  height: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const VerticalList: FC<Props> = ({
  children,
  icon,
  title,
  titleVariant,
  styleContainer,
  style,
}): JSX.Element => (
  <Paper
    style={{
      ...defaultStyleContainer,
      ...styleContainer,
      ...(style || {}),
    }}
  >
    {title && (
      <>
        <Box style={{ margin: '20px 20px 0 14px', display: 'flex' }}>
          {icon}
          <Typography
            gutterBottom
            style={{ marginLeft: '8px' }}
            variant={titleVariant}
          >
            {title}
          </Typography>
        </Box>
        <Divider />
      </>
    )}
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        height: '100%',
      }}
    >
      {children}
    </List>
  </Paper>
);

VerticalList.defaultProps = {
  title: '',
  titleVariant: 'h5',
  icon: undefined,
  styleContainer: {},
  children: undefined,
  style: undefined,
};

export default VerticalList;
