/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { ContractType } from '../../types';

export interface ContractTypesState {
  count: number,
  entities: Record<string, ContractType>,
  isLoading: boolean,
};

const initialState: ContractTypesState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchContractTypes = createAsyncThunk(
  'getContractTypes/fetchContractTypes',
  async () => {
    const { data: dataResponse } = await getPostRequest('v2/contracttype/all', {});
    const { data } = dataResponse;
    return data || [];
  }
);

export const contractTypesSlice = createSlice({
  name: 'contractTypes',
  initialState,
  reducers: {
    cleanContractTypes: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchContractTypes.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchContractTypes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanContractTypes } = contractTypesSlice.actions;

export default contractTypesSlice.reducer;
