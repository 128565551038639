import React from 'react';
import { useEffectOnce } from 'react-use';

import {
  cleanPagination,
  setLimit,
  setPage,
  setRowsPerPageOptions,
} from '../store/slices';
import { useAppDispatch, useAppSelector } from './redux';

type UsePagination = {
  page: number,
  limit: number,
  rowsPerPageOptions: number[],
  pagination: {
    page: number,
    limit: number,
  },
  handlePageChange: (_e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void,
  handleRowsPerPageChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  changeRowsPerPageOptions: (nextRowsPerPages: number[]) => void,
};

const usePagination = (initialLimit?: number) : UsePagination => {
  const { page, limit, rowsPerPageOptions } = useAppSelector(({ pagination }) => pagination);
  const dispatch = useAppDispatch();

  const pagination = {
    page: page + 1,
    limit,
  };

  const handlePageChange =
  (_e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number): void => {
    dispatch(setPage(newPage));
  };
  const handleRowsPerPageChange =
  (e: React.ChangeEvent<HTMLInputElement>) : void => {
    const nextLimit = Number(e.target.value);
    dispatch(setLimit(nextLimit));
  };
  const changeRowsPerPageOptions =
  (nextRowsPerPages: number[]) : void => {
    dispatch(setRowsPerPageOptions(nextRowsPerPages));
  };

  useEffectOnce(() => {
    if (initialLimit && initialLimit !== limit){
      dispatch(setLimit(initialLimit));
    }
    return () => {
      dispatch(cleanPagination());
    };
  });

  return {
    page,
    limit,
    rowsPerPageOptions,
    pagination,
    handlePageChange,
    handleRowsPerPageChange,
    changeRowsPerPageOptions,
  };
};

export default usePagination;
