import { CSSProperties, FC } from 'react';

import { List, ListItem } from '@mui/material';
import { v4 as uuid } from 'uuid';

interface Props {
  children: JSX.Element[],
  style?: CSSProperties,
  itemStyle?: CSSProperties,
};

const ShelfsV2: FC<Props> = ({ children, style, itemStyle }): JSX.Element => (
  <List
    style={{
      width: '100%',
      overflow: 'auto',
      height: '100%',
      ...style,
    }}
  >
    {children.map((item) => (
      <ListItem
        key={uuid()}
        style={{
          width: '100%',
          ...itemStyle
        }}
      >
        {item}
      </ListItem>
    ))}
  </List>
);

ShelfsV2.defaultProps = {
  style: {},
  itemStyle: {},
};

export default ShelfsV2;
