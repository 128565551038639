/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { BankAccount, BankAccountFilters } from '../../types';

export interface BankAccountState {
  count: number,
  entities: Record<string, BankAccount>,
  isLoading: boolean,
  selected: string,
};

const initialState: BankAccountState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: '',
};

export const fetchBankAccounts = createAsyncThunk(
  'getBankAccounts/fetchBankAccounts',
  async (filters: BankAccountFilters = {}) => {
    const request = await getPostRequest('v2/bankaccount/all', filters);
    const { data: dataResponse } = request;
    const { data } = dataResponse;
    return data;
  },
);

export const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState,
  reducers: {
    cleanBankAccounts: (state) => {
      state.count = 0;
      state.entities = {};
      state.selected = '';
    },
    setSelectedBankAccount: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccounts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBankAccounts.fulfilled, (state, action: PayloadAction<BankAccount[]>) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
        if (state.selected !== '' && state.entities[state.selected] === undefined) {
          state.selected = '';
        }
      })
      .addCase(fetchBankAccounts.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export const { cleanBankAccounts, setSelectedBankAccount } = bankAccountsSlice.actions;

export default bankAccountsSlice.reducer;
