/* eslint-disable no-console */
import axios from 'axios';

import { Nullable, UploadFile } from '../types';
import { getGetRequest } from './getGetRequest';
import getPostRequest from './getPostRequest';

export const uploadFile =
  async (file: File, bucket = 'levita-ocr'): Promise<UploadFile | null> => {
    // document-add/by-bucket/:name/:bucket?
    const response = await getGetRequest(`document-add/by-bucket/${file.name}/${bucket}`);
    const { status, data } = response;
    if (status === 200) {
      const { presignedURL } = data;
      const uploadResponse = await axios.put(presignedURL, file);
      const { status: uploadStatus } = uploadResponse;
      if (uploadStatus === 200) {
        return data;
      }
    }
    return null;
  };

export const makeAFilePublic =
  async (file: Nullable<UploadFile>, bucket = 'levita-ocr'): Promise<UploadFile | null> => {
    if (file === null) {
      return null;
    }
    const response = await getGetRequest(`bucket/provide-public-access/${bucket}/${file.id}`);
    const { status } = response;
    if (status === 200) {
      return file;
    }
    return null;
  };

type SearchInFileValue = {
  value: string;
  exact?: boolean;
  nospaces?: boolean;
  similarityFactor?: number;
}

export const searchInFile = async (file: UploadFile, searches: SearchInFileValue[]): Promise<boolean[]> => {
  if (file === null || searches.length === 0) {
    return [];
  }
  const values = searches.map(({ value, exact = true, nospaces = true, similarityFactor = 0.7 }) => ({
    value,
    exact,
    nospaces,
    similarityFactor,
  }));
  const response = await getPostRequest('lambdafunction/ocrnode', {
    values,
    fileKey: file.name,
  }, { timeout: 180000 });
  const { data } = response;
  if (data.errors === null || data.errors === undefined) {
    return searches.map(({ value }) => data.data.find(({ search }: { search: string }) => value === search) !== undefined);
  }
  return searches.map(() => false);
};