import { useCallback, useMemo, useRef } from 'react';

import _ from 'lodash';

import DefaultValueRenderer from '../../components/DataSheetVirtualized/DefaultValueRenderer';
import { getEntitiesArray, getRowsEntities } from '../../helpers/dataSheet';
import {
  Cell,
  CellUpdate,
  Change,
  Column,
  Entity,
  Error,
  GridKey,
  HandleChanges,
  UseDataSheet,
  UseDatasheetConfig,
} from '../../types';
import useDataSheetChanges from './useDataSheetChanges';
import useDataSheetErrors from './useDataSheetErrors';
import useDataSheetHeader from './useDataSheetHeader';
import useDataSheetHiddenColumns from './useDataSheetHiddenColumns';
import useDataSheetPagination from './useDataSheetPagination';
import useDataSheetRequired from './useDataSheetRequired';
import useDataSheetRows from './useDataSheetRows';
import useDataSheetTraspose from './useDataSheetTranspose';
import useDataSheetValidEntities from './useDataSheetValidEntities';

const validateGrid = (grid: Cell[][], { defaultIsRequired }: UseDatasheetConfig) => {
  const errors: Error[] = [];
  grid.forEach((row, rowIndex) => {
    row.forEach((cell) => {
      let message = '';
      if (cell.type === 'button' && _.get(cell, 'isRequired', defaultIsRequired) && _.get(cell, 'value', '') !== true) {
        message = `El valor ${cell.title} es requerido en la fila ${rowIndex + 1}`;
      }
      if (
        _.get(cell, 'isRequired', defaultIsRequired)
        && cell.type === 'check'
        && _.get(cell, 'value', '') !== true
      ) {
        message = `El valor ${cell.title} es requerido en la fila ${rowIndex + 1}`;
      }
      if (
        _.get(cell, 'isRequired', defaultIsRequired)
        && _.get(cell, 'value', '') === ''
      ) {
        message = `El valor ${cell.title} es requerido en la fila ${rowIndex + 1}`;
      }
      if (_.get(cell, 'className', '') === 'error') {
        message = 'Celda con errores';
      }
      if (message) {
        errors.push({
          ...cell,
          message,
        });
      }
    });
  });
  return {
    isValid: errors.length === 0,
    errors,
  };
};

const useDataSheet = (
  columns: Column[],
  entities: number | Entity[] | Record<string, Entity>,
  config: UseDatasheetConfig = {},
): UseDataSheet => {
  const entitiesArr = useMemo(() => getEntitiesArray(entities), [entities]);

  const {
    areAllRequired = false,
    defaultIsEnable = true,
    transpose = false,
    hasPagination = false,
    id: datasheetId = '',
    ValueRenderer = DefaultValueRenderer,
    getInitMetadata,
    metadata,
    discardNonRenderer,
  } = config;

  const {
    header,
    colsMapRef,
    updateColAtributes,
    addColumn,
  } = useDataSheetHeader(columns, areAllRequired, defaultIsEnable);

  const rows = useMemo(() => getRowsEntities(entitiesArr, header, metadata), [entitiesArr, header, metadata]);
  const entitiesMapRef = useRef(rows);

  const handleChangesRef = useRef<HandleChanges>();
  const updateCellAtributesRef = useRef<(row: GridKey, col: GridKey, newAttributes: CellUpdate) => void>();

  const {
    rowsGrid,
    rowsMapRef,
    updateCellAtributes,
    cleanCellUpdate,
    cleanCellUpdates,
    cleanRowUpdates,
    cleanMultipleRowUpdates,
    cleanNonRenderedUpdates,
    removeCellsUpdatesStorage,
    swapUpdatesBetweenRows,
  } = useDataSheetRows(header, entitiesArr, entitiesMapRef, {
    handleChangesRef,
    updateCellAtributesRef,
    getInitMetadata,
    metadata,
    id: datasheetId,
  });

  const {
    changedGrid,
    changes,
    handleChanges,
    cleanChanges,
    cleanCellChange,
    cleanRowChanges,
    cleanMultipleRowChanges,
    cleanNonRenderedChanges,
    removeChangesStorage,
    slideChangesUp,
  } = useDataSheetChanges(rowsGrid, rowsMapRef, colsMapRef, updateCellAtributes, cleanCellUpdate, cleanCellUpdates, swapUpdatesBetweenRows, discardNonRenderer, datasheetId);

  entitiesMapRef.current = useMemo(() => {
    const nextEntitiesMap = {
      ...(discardNonRenderer ? null : entitiesMapRef.current),
      ...getRowsEntities(entitiesArr, header, metadata),
    };

    Object.values(nextEntitiesMap)
      .forEach((entity) => {
        nextEntitiesMap[entity.id] = {
          ...entity,
          ..._.get(changes, entity.id, {}),
        };
      });

    return nextEntitiesMap;
  }, [changes, discardNonRenderer, entitiesArr, header, metadata]);

  const handleUpdateCellAtributes = useCallback((row: GridKey, col: GridKey, newAttributes: CellUpdate) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;
    const colId = typeof col === 'number' ? colsMapRef.current[col] : col;

    updateCellAtributes(rowId, colId, newAttributes);
  }, [colsMapRef, rowsMapRef, updateCellAtributes]);

  updateCellAtributesRef.current = handleUpdateCellAtributes;

  const {
    validatedGrid,
    errors,
    removeErrorsStorage,
  } = useDataSheetErrors(changes, changedGrid, rowsMapRef, colsMapRef, entitiesMapRef, handleUpdateCellAtributes, handleChanges);
  const { requiredMissingValues } = useDataSheetRequired(header, entitiesMapRef);
  const { validEntities } = useDataSheetValidEntities(entitiesMapRef, errors, requiredMissingValues);

  const paginatedGrid = useDataSheetPagination(validatedGrid, hasPagination);
  const { hiddenGrid: grid, hiddenColsMap } = useDataSheetHiddenColumns(header, paginatedGrid);
  const transposedGrid = useDataSheetTraspose(grid, transpose);

  const hasData = entitiesArr.length > 0;
  const isCalculating = entitiesArr.length !== changedGrid.length;

// <<<<<<< HEAD
//   const getCell = (row: GridKey, col: GridKey): Cell => {
//     const rowIndex = typeof row === 'string' ? rowsMapRef.current[row] : row;
//     const colIndex = typeof col === 'string' ? colsMapRef.current[col] : col;
// =======
//   const getCell = (row: string | number, col: string | number): Cell => {
//     const rowIndex = typeof row === 'string' ? rowsMap[row] : row;
//     const colIndex = typeof col === 'string' ? hiddenColsMap[col] : col;
//     return {
//       ...grid[rowIndex][colIndex],
//     };
//   };
// >>>>>>> develop
  const getCell = (row: string | number, col: string | number): Cell => {
    const rowIndex = typeof row === 'string' ? rowsMapRef.current[row] : row;
    const colIndex = typeof col === 'string' ? hiddenColsMap[col] : col;
    return { ...grid[rowIndex][colIndex] };
  };

  const handleSetChanges: HandleChanges = useCallback((edits: Change[]): void => {
    const formatedChanges = edits.map((change) => {
      const { row, col } = change;
      const formatedRow = typeof row === 'string' ? row : row - 1;
      const formatedCol = typeof col === 'string' ? col : hiddenColsMap[col];
      return {
        ...change,
        row: formatedRow,
        col: formatedCol,
      };
    });

    handleChanges(formatedChanges);
  }, [handleChanges, hiddenColsMap]);

  handleChangesRef.current = handleSetChanges;

  const handleCleanCellUpdates = (row: GridKey, col: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;
    const colId = typeof col === 'number' ? colsMapRef.current[col] : col;

    cleanCellUpdates(rowId, colId);
  };

  const handleCleanRowUpdates = (row: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;

    cleanRowUpdates(rowId);
  };

  const handleCleanMultipleRowUpdates = (rowsKeys: GridKey[]) => {
    const rowIds = rowsKeys.map((row) => {
      if (typeof row === 'number') {
        return rowsMapRef.current[row - 1];
      }
      return row
    });

    cleanMultipleRowUpdates(rowIds);
  };

  const handleCleanCellChange = (row: GridKey, col: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;
    const colId = typeof col === 'number' ? colsMapRef.current[col] : col;

    cleanCellChange(rowId, colId);
  };

  const handleCleanRowChanges = (row: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;

    cleanRowChanges(rowId);
  };

  const handleCleanMultipleRowChanges = (rowsKeys: GridKey[]) => {
    const rowIds = rowsKeys.map((row) => {
      if (typeof row === 'number') {
        return rowsMapRef.current[row - 1];
      }
      return row
    });

    cleanMultipleRowChanges(rowIds);
  };

  const cleanCellMemory = (row: GridKey, col: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;
    const colId = typeof col === 'number' ? colsMapRef.current[col]: col;
    cleanCellUpdates(rowId, colId);
    cleanCellChange(rowId, colId);
  };

  const cleanRowMemory = (row: GridKey) => {
    const rowId = typeof row === 'number' ? rowsMapRef.current[row - 1] : row;
    cleanRowUpdates(rowId);
    cleanRowChanges(rowId);
  };

  const cleanMultipleRowsMemory = (rowsKeys: GridKey[]) => {
    const rowIds = rowsKeys.map((row) => {
      if (typeof row === 'number') {
        return rowsMapRef.current[row - 1];
      }
      return row
    });

    cleanMultipleRowUpdates(rowIds);
    cleanMultipleRowChanges(rowIds);
  };

  const cleanNonRenderedMemory = () => {
    entitiesMapRef.current = getRowsEntities(entitiesArr, header, metadata);
    cleanNonRenderedUpdates();
    cleanNonRenderedChanges();
  };
  
  const cleanDataSheetLocalStorage = () => {
    removeCellsUpdatesStorage();
    removeChangesStorage();
    removeErrorsStorage();
  };

  const validate = () => {
    const validation = validateGrid(changedGrid, {
      defaultIsRequired: areAllRequired,
    });
    if (!validation.isValid) {
      const errorChanges: Change[] = validation.errors.map(({ message: msg, ...rest }) => ({
        value: rest.value,
        col: rest.field || '',
        row: rest.id || '',
        cell: { ...rest, className: 'error' },
      }));
      handleChanges(errorChanges);
    }
    return validation;
  };

  return {
    grid: transposedGrid,

    /// useHeader
    updateColumnAtributes: updateColAtributes,
    addColumn,

    // useRows
    rows,
    updateCellAtributes: handleUpdateCellAtributes,
    cleanCellUpdates: handleCleanCellUpdates,
    cleanRowUpdates: handleCleanRowUpdates,
    cleanMultipleRowUpdates: handleCleanMultipleRowUpdates,

    // useChanges
    changes,
    handleChanges: handleSetChanges,
    cleanChanges,
    cleanCellChange: handleCleanCellChange,
    cleanRowChanges: handleCleanRowChanges,
    cleanMultipleRowChanges: handleCleanMultipleRowChanges,
    slideChangesUp,

    // useErrors
    errors,

    requiredMissingValues,
    validEntities,

    // useDatasheet
    entities: entitiesMapRef.current,
    hasData,
    isCalculating,
    getCell,
    validate,
    cleanCellMemory,
    cleanRowMemory,
    cleanMultipleRowsMemory,
    cleanNonRenderedMemory,
    cleanDataSheetLocalStorage,
    // TODO: Fix handleChages type.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ValueRenderer,
  };
};

export default useDataSheet;
