/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGetRequest, getPostRequest } from '../../helpers';
import { Employee, EmployeeFilters } from '../../types';

export interface EmployeesState {
  count: number;
  entities: Record<string, Employee>;
  isLoading: boolean;
  selected: string;
}

const initialState: EmployeesState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: '',
};

export const fetchEmployees = createAsyncThunk(
  'getEmployees/fetchEmployees',
  async (filters?: EmployeeFilters) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/employee/all',
      filters
    );
    const { data } = dataResponse;
    return data || [];
  }
);

export const fetchEmployeesByPayrollPeriod = createAsyncThunk(
  'getEmployees/fetchEmployeesByPayrollPeriod',
  async (payrollPeriodId: string) => {
    const { data: dataResponse } = await getGetRequest(`v2/employee/all/${payrollPeriodId}`);
    const { data } = dataResponse;
    return data || [];
  }
);

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    cleanEmployees: (state) => {
      state.count = 0;
      state.entities = {};
      state.selected = '';
    },
    setSelectedEmployee: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchEmployees.fulfilled,
        (state, action: PayloadAction<Employee[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = _.keyBy(payload, 'id');
          if (
            state.selected !== '' &&
            state.entities[state.selected] === undefined
          ) {
            state.selected = '';
          }
        }
      )
      .addCase(fetchEmployees.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(fetchEmployeesByPayrollPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchEmployeesByPayrollPeriod.fulfilled,
        (state, action: PayloadAction<Employee[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = _.keyBy(payload, 'id');
          if (
            state.selected !== '' &&
            state.entities[state.selected] === undefined
          ) {
            state.selected = '';
          }
        }
      )
      .addCase(fetchEmployeesByPayrollPeriod.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanEmployees, setSelectedEmployee } = employeesSlice.actions;

export default employeesSlice.reducer;
