import { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { Column, PayrollCalculationSummary } from '../../../types';
import { useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';

const useCalculationPayrollSummaryDatasheet = () => {
  const {
    summary,
    deductionsList,
    isSummaryLoading: isLoading,
  } = useAppSelector(({ payrollCalculation }) => payrollCalculation);
  const period = useAppSelector(({ periods }) => periods.entities[periods.selected]);

  const [data, setData] = useState<PayrollCalculationSummary[]>([]);

  const {
    grid,
    handleChanges,
    entities: employees,
    ValueRenderer,
    changes,
    addColumn,
    validate,
    updateColumnAtributes,
  } = useDataSheet(columns, data, {
    transpose: true,
    defaultIsEnable: false,
    id: 'summary',
  });

  useDeepCompareEffect(() => {
    if (!isLoading) {
      deductionsList.forEach((deductionName, index) => {
        const newColumn: Column = {
          title: deductionName,
          field: `deductionName${index}`,
          pathValue: `perceptionModifiersMap["${deductionName}"]`,
          defaultValue: 0,
          type: 'money',
        };
        addColumn(newColumn, 2);
      });
    }
  }, [deductionsList, isLoading]);

  useEffect(() => {
    if (summary !== null) {
      setData([summary]);
    } else {
      setData([]);
    }
  }, [summary]);

  useEffect(() => {
    updateColumnAtributes('provision', { hidden: period?.type !== 'Ordinario' });

    updateColumnAtributes('comisionProvision', { hidden: period?.type !== 'Ordinario' });
  }, [period?.type, updateColumnAtributes]);

  return {
    grid,
    handleChanges,
    ValueRenderer,
    employees,
    validate,
    changes,
    summary,
    isLoading,
  };
};

export default useCalculationPayrollSummaryDatasheet