import { useCallback } from 'react';
import { useToggle } from 'react-use';

import { Button } from '@mui/material';
import { getPostRequest } from 'helpers';
import { useAdcEmail, useAppDispatch, useAppSelector } from 'hooks';
import _ from 'lodash';
import { UploaderFileModal } from 'modals';
import {
  addEmployeeDrafts,
  showMessage,
} from 'store/slices';
import {
  DataSheetButtonComponent,
  EmployeeDraftStatuses,
  ServerType,
  UploadFile,
} from 'types';
import { v4 as uuid } from 'uuid';

const EmployeeIdseConfirmationButton: DataSheetButtonComponent = ({
  entityID,
  entitiesMapRef,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isModalLoading, toggleIsModalLoading] = useToggle(false);

  const dispatch = useAppDispatch();
  const adcEmail = useAdcEmail();
  const client = useAppSelector(({ clients }) => clients.entities[clients.selected]);
  const entity = entitiesMapRef.current[entityID];

  const handleIDSEFiles = useCallback(async (vouchers: UploadFile[]) => {
    try {
      if (!entity) return;
      toggleIsModalLoading(true);
  
      if (adcEmail === '') {
        dispatch(showMessage({
          type: 'error',
          message: 'No se pudo encontrar el correo del adc',
        }));
        toggleIsModalLoading(false);
        return;
      }
  
      const id = uuid();
      const { payload } = await dispatch(addEmployeeDrafts({
        adcEmail,
        clientFullname: client.businessName,
        clientId: client.id,
        curp: _.get(entity, 'curp', ''),
        documentId: _.get(vouchers, '[0].id', ''),
        firstLastName: _.get(entity, 'firstLastName', ''),
        secondLastName: _.get(entity, 'secondLastName', ''),
        id,
        imss: _.get(entity, 'imss', ''),
        name: _.get(entity, 'name', ''),
        rfc: _.get(entity, 'rfc', ''),
        status: EmployeeDraftStatuses.Pending,
      }));
  
      if (!payload) {
        dispatch(showMessage({
          type: 'error',
          message: 'No se pudo crear la solicitud de revisión',
        }));
        toggleIsModalLoading(false);
        return;
      }
  
      const { status } = await getPostRequest(
        'employee-drafts/send-confirmation-email',
        { id },
        { server: ServerType.Node });
      if (status !== 200) {
        dispatch(showMessage({
          type: 'error',
          message: 'No se pudo enviar el correo al adc',
        }));
        toggleIsModalLoading(false);
        return;
      }
      dispatch(showMessage({
        type: 'success',
        message: 'Correo enviado exitosamente',
      }));
    } catch {
      dispatch(showMessage({
        type: 'error',
        message: 'Ocurrió un error al solicitar autorización...',
      }));
    }
    toggleIsModalLoading(false);
    toggleIsModalOpen(false);
  }, [adcEmail, client.businessName, client.id, dispatch, entity, toggleIsModalLoading, toggleIsModalOpen]);

  return (
    <>
      <UploaderFileModal
        bucket='levita-ocr'
        isLoading={isModalLoading}
        maxCountOfFiles={1}
        needsToBePublic
        onAccept={handleIDSEFiles}
        onClose={toggleIsModalOpen}
        open={isModalOpen}
        title={`Documento IDSE ${_.get(entity, 'name', '')} ${_.get(entity, 'firstLastName', '')} ${_.get(entity, 'secondLastName', '')}`}
      />
      <Button onClick={toggleIsModalOpen} variant="outlined">Solicitar autorización</Button>
    </>
  );
};

export default EmployeeIdseConfirmationButton;
