export const payrollPeriodEmployees = `query payrollPeriodEmployees(
  $payrollPeriod: ID!, 
  $pagination: Pagination) {
  employees(payrollPeriod: $payrollPeriod, pagination: $pagination, 
    search: {
      societyBranch: {
        ne: "000000000000000000000000"
      }
  }) {
    count
    entities {
      id
      key
      name
      firstLastName
      secondLastName
      status
      netSalary
      bankAccounts {
        id
        CLABE
        cardDefault
        bank
      }
      paymentMethod {
        id
        name
      }
      payrollType {
        id
        name
      }
    }
  }
}
`;
