import { useMemo } from 'react';

import { Cell } from '../../types';

const useDataSheetTranspose = (grid: Cell[][], isActive: boolean) => {
  const transposedGrid = useMemo<Cell[][]>(() => {
    if (!isActive) return grid;
    const rowsCount = grid.length;
    if (rowsCount === 0) return [];
    const colsCount = grid[0].length;
    const transpose: Cell[][] = [];
    for (let i = 0; i < colsCount; i += 1) {
      transpose.push([]);
      for (let j = 0; j < rowsCount; j += 1) {
        transpose[i].push(grid[j][i]);
      }
    }
    // return grid[0].map((_, colIndex) => grid.map(row => row[colIndex]));
    return transpose;
  }, [grid, isActive]);
  return transposedGrid;
};

export default useDataSheetTranspose;
