export const dismissalAdd = `mutation dismissalAdd($dismissalTypeID: ID!, $date: DateTime!, $employeeID: ID!, $dismissalCauseID: ID!) {
  dismissalAdd(dismissalTypeID: $dismissalTypeID, date: $date, employeeID: $employeeID, dismissalCauseID: $dismissalCauseID) {
    id
    name
    dismissDate
    dismissType {
      id
      description
      __typename
    }
    dismissCause {
      id
      name
      __typename
    }
    __typename
  }
}`
