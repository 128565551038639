/* eslint-disable @typescript-eslint/no-unused-vars */
import { GroupBase, Props } from 'react-select';

import CustomSelect from './CustomSelect';

export { default as getStyles } from './getStyles';
export { default as Input } from './Input';
export { default as Placeholder } from './Placeholder';

export default CustomSelect;

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    error?: boolean,
    required?: boolean,
    label?: string;
    helperText?: string,
    // placeholder?: string,
  }
};
