/* eslint-disable no-console */

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import {
  Employee as BaseEmployee,
  EmployeeFilters,
  LoginRequest,
} from '../../types';
import { EmployeesServiceErrorCodes as EC } from './constants';
import { FullEmployeee } from './types';
import { checkResponse, constructError, EmployeesServiceError } from './utils';

class EmployeesService {
  employeesAbortController: AbortController | null = null;

  dismissalErrors: EmployeesServiceError[] = [];

  logError(e: any) {
    if (e instanceof Array) e.forEach((error) => console.error(error));
    else console.error(e);
  }

  async getDismissalTypes() {
    try {
      const params = getGraphQLParameters('dismissalTypes', {});
      const response = await getPostRequest('', params);
      const { data } = response;

      checkResponse(response);

      if (!data.data.dismissalTypes)
        throw new EmployeesServiceError(EC.NoDismissalTypesFound);

      return data.data.dismissalTypes;
    } catch (e) {
      this.logError(e);

      return [];
    }
  }

  async getEmployees(
    // !! Pagination and status not working as expected.
    filters: Omit<EmployeeFilters, 'pagination' | 'status'> & {
      page: number;
      pageSize: number;
    }
  ): Promise<{ data: FullEmployeee[]; count: number }> {
    try {
      if (this.employeesAbortController) this.employeesAbortController.abort();
      this.employeesAbortController = new AbortController();

      const response = await getPostRequest(
        'v2/employee/all',
        {
          ...filters,
        },
        { controller: this.employeesAbortController }
      );

      const error = checkResponse(response, {
        codes: {
          [EC.NoDataFound]: EC.NoEmployeesFound,
        },
      });

      if (error) throw error;

      this.employeesAbortController = null;

      // TODO: Change filter and map with reduce.

      // TODO: Filter on server side.
      const data = response.data.data.filter((employee: BaseEmployee) => {
        let include = employee.status === 'Empleado';

        if (filters.payrollTypeID)
          include = include && employee.payrollType === filters.payrollTypeID;

        return include;
      });

      if (data.length === 0)
        throw new EmployeesServiceError(EC.NoEmployeesFound);

      const count = data.length;

      let newData = data.map(
        (employee: BaseEmployee): FullEmployeee => ({
          selected: false,
          id: employee.id, // !!id required for DataSheet to work.
          employeeID: employee.id,
          key: employee.key,
          fullName: `${employee.name} ${employee.firstLastName} ${employee.secondLastName}`,
          status: employee.status,
          dismissalCauseID: '',
          dismissalTypeID: '',
          date: new Date(),
          name: employee.name,
          payrollTypeID: employee.payrollType,
          firstLastName: employee.firstLastName,
          secondLastName: employee.secondLastName,
          dateOfFirstEntry: employee.dateOfFirstEntry,
          incorporation: employee.incorporation,
        } as FullEmployeee)
      ) as FullEmployeee[];

      // TODO: Paginate on server side.
      newData = newData.slice(
        filters.page * filters.pageSize,
        (filters.page + 1) * filters.pageSize
      );

      return {
        data: newData,
        count,
      };
    } catch (e) {
      const error = constructError(e);

      this.employeesAbortController = null;

      switch (error.code) {
        case EC.Cancelled:
          throw new EmployeesServiceError(EC.GetEmployeesCancelled);
        case EC.Error:
        case EC.Errors:
          throw new EmployeesServiceError(EC.ErrorGettingEmployees, {
            errors: error.errors,
          });
        default:
          throw error;
      }
    }
  }

  private async dismissalAdd(employee: FullEmployeee) {
    try {
      const variables = {
        employeeID: employee.employeeID,
        dismissalCauseID:
          employee.dismissalCauseID.length > 0
            ? employee.dismissalCauseID
            : null,
        dismissalTypeID:
          employee.dismissalTypeID.length > 0 ? employee.dismissalTypeID : null,
        date: employee.date,
      };

      const params = getGraphQLParameters('dismissalAdd', variables);
      const response = await getPostRequest('', params);

      const error = checkResponse(response, {
        employee,
      });

      if (error) throw error;
    } catch (e) {
      this.dismissalErrors.push(constructError(e));
    }
  }

  async disableEmployees(employees: FullEmployeee[]) {
    this.dismissalErrors = [];

    await Promise.all(employees.map((employee) => this.dismissalAdd(employee)));

    if (this.dismissalErrors.length > 0)
      throw new EmployeesServiceError(EC.ErrorDisablingEmployees, {
        errors: this.dismissalErrors,
      });
  }

  async confirm(credentials: LoginRequest) {
    try {
      const response = await getPostRequest(
        'operationalcontact/signin',
        credentials
      );

      const error = checkResponse(response);
      if (error) throw error;

      return true;
    } catch (e) {
      this.logError(e);

      return false;
    }
  }
}

export default new EmployeesService();
