export const PayrollPeriodReport =`query PayrollPeriodReport($id: String!) {
    payrollPeriodReport(id: $id)
  }
`;

export const payrollReceiptsZIPByPayroll = `query payrollReceiptsZIPByPayroll($payrollPeriod: ID!, $sendZIPTo: [String]) {
  payrollReceiptsZIPByPayroll(payrollPeriod: $payrollPeriod, sendZIPTo: $sendZIPTo) {
    errorMessages
    error
    downloadURL
    emailSentTo
    emailErrorTo
  }
}
`;

export const GetCalculatePPPSPayroll = `
query GetCalculatePPPSPayroll($payrollPeriod: String!) {
  pppsPayrolls(payrollPeriod: $payrollPeriod) {
    count
    data {
      amount
      totalToDisperse
      calculated
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
      }
      commission
      deductions {
        concepto
        importe
      }
      employee {
        id
        name
        firstLastName
        secondLastName
        key
        bankAccounts {
          accountNumber
          bank
          default
          cardDefault
          id
        }
        payrollBranch {
          id
          workRisk
        }
      }
      employeeBankAccount {
        id
        bank
        CLABE
        accountNumber
      }
      id
      paymentMethod {
        id
        name
      }
      payrollPeriod {
        id
        name
        payrollSchema
      }
      workedDays
    }
  }
}
`;

export const GetSummaryPPPSPayroll = `
query GetSummaryPPPSPayroll($payrollPeriod: String!) {
  pppsSummary(payrollPeriod: $payrollPeriod) {
    approved
    isSupervisorApproved
    payrollPeriodDiscounts {
      amount
      modifierType {
        name
        type
      }
    }
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    id
    iva
    payrollPeriod {
      id
      name
      fullName
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
    }
    subtotal
    totalToDisperse: totalAmount
    totalCommission
    totalInvoice
    totalPerceptions
    provision
    comisionProvision
    perceptionModifiers {
      amountPaidPerEmployee
      modifierName
      totalAmountToPay
    }
    clientPerceptionModifiers {
      amountPaidPerEmployee
      totalAmountToPay
      modifierName
      amount
      type
      discountValue
    }
    bonuses
    outstandingPayments
  }
}
`;

export const GetBillingPPPSPayroll = `
query GetBillingPPPSPayroll($payrollPeriod: String!) {
  pppsBilling(payrollPeriod: $payrollPeriod) {
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    concept
    id
    paymentMethod
    payrollPeriod {
      fullName
      id
      name
      noministaExtraordinario {
        name
        firstLastName
        secondLastName
      }
      payrollType {
        name
        nominista {
          name
          firstLastName
          secondLastName
        }
      }
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
    }
    pppsSummary {
      id
      iva
      subtotal
      totalAmount
      totalCommission
      totalInvoice
      perceptionModifiers {
        amountPaidPerEmployee
        modifierName
        totalAmountToPay
      }
      payrollPeriodDiscounts {
        amount
        modifierType {
          id
          name
          type
        }
      }
    }
    society {
      id
      name
    }
  }
}
`;

export const GetCalculateExtraordinaryPayroll = `
query GetCalculateExtraordinaryPayroll($payrollPeriod: String!) {
  extraordinaryPayrolls(payrollPeriod: $payrollPeriod) {
    count
    data {
      amount
      totalToDisperse
      commission
      employee {
        id
        name
        firstLastName
        secondLastName
        key
        bankAccounts {
          accountNumber
          bank
          default
          cardDefault
          id
        }
        payrollBranch {
          id
          workRisk
        }
      }
      employeeBankAccount {
        id
        bank
        CLABE
        accountNumber
      }
      id
      paymentMethod {
        id
        name
      }
      payrollPeriod {
        id
        name
        payrollSchema
      }
    }
  }
}
`;

export const GetSummaryExtraordinaryPayroll = `
query GetSummaryExtraordinaryPayroll($payrollPeriod: String!) {
  extraordinaryPayrollSummary(payrollPeriod: $payrollPeriod) {
    approved
    isSupervisorApproved
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    id
    iva
    payrollPeriod {
      id
      name
      fullName
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
    }
    subtotal
    totalAmount
    totalCommission
    totalInvoice
    totalToDisperse
    bonuses
    outstandingPayments
  }
}
`;

export const GetBillingExtraordinaryPayroll = `
query GetBillingExtraordinaryPayroll($payrollPeriod: String!) {
  billingExtraordinaryPayroll(payrollPeriod: $payrollPeriod) {
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    concept
    id
    paymentMethod
    payrollPeriod {
      fullName
      id
      name
      noministaExtraordinario {
        name
        firstLastName
        secondLastName
      }
      payrollType {
        name
        nominista {
          name
          firstLastName
          secondLastName
        }
      }
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
    }
    extraordinaryPayrollSummary {
      id
      iva
      subtotal
      totalAmount
      totalCommission
      totalInvoice
    }
    society {
      id
      name
    }
  }
}
`;

export const GetSummarySettlementPayroll = `
  query GetSummarySettlementPayroll($payrollPeriod: String!) {
    settlementSummary(payrollPeriod: $payrollPeriod) {
      approved
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
        societies {
          id
          name
        }
      }
      id
      iva
      payrollPeriod {
        id
        name
        fullName
        start
        end
        key
        payday
        dispersionInformation {
          date
        }
      }
      subtotal
      totalAmount
      totalCommission
      totalInvoice
    }
  }
`;

export const GetSummaryPayroll = `
query PayrollSummary($payrollPeriod: String) {
  payrollSummary(payrollPeriod: $payrollPeriod) {
    isFromHighDirectionSociety
    childSummary {
      isFromHighDirectionSociety
      approved
      bonuses
      isSupervisorApproved
      christmasBonusCommission
      childSociety {
        id
        name
        linkedNOM035Society {
          name
        }
      }
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
        societies {
          id
          name
        }
      }
      ordinaryPayrollSummary {
        ISNRetenido {
          baseGravable
          cliente
          empresa
          total
        }
        baseCotizacion {
          amount
          percentage
        }
        bonificaciones
        comision
        comisionAguinaldo
        costoSocial
        diferencia
        fonacot
        infonavit
        isr
        iva
        ivaRetenido
        modificadoresPercepcion {
          amount
          discountValue
          type
        }
        payrollPeriodDiscounts {
          amount
          modifierType {
            id
            name
            type
          }
        }
        otrasDeducciones
        pagosPendientes
        pretmex
        provisionAguinaldo
        subtotal
        totalADispersar
        totalAFacturar
      }
      extraordinaryPayrollSummaries {
        comision
        iva
        ivaRetenido
        subtotal
        totalAFacturar
        extraordinaryPayrollSummary {
          id
          isSupervisorApproved
          approved
          bonuses
          client {
            id
            basic {
              comercialName
            }
            societies {
              id
              name
            }
            general {
              tradeName
              businessName
            }
          }
          outstandingPayments
          payrollPeriod {
            id
            name
            fullName
            payday
            dispersionInformation {
              date
            }
          }
          retainedIVA
          subtotal
          totalAmount
          totalInvoice
          totalCommission
        }
      }
      totalExtraordinarySummary {
        comision
        iva
        ivaRetenido
        subtotal
        totalAFacturar
      }
      totalSummaryAmount {
        ISNRetenido {
          baseGravable
          cliente
          empresa
          total
        }
        baseCotizacion {
          amount
          percentage
        }
        bonificaciones
        comision
        comisionAguinaldo
        costoSocial
        diferencia
        fonacot
        infonavit
        isr
        iva
        ivaRetenido
        modificadoresPercepcion {
          amount
          discountValue
          type
        }
        payrollPeriodDiscounts {
          amount
          modifierType {
            id
            name
            type
          }
        }
        otrasDeducciones
        pagosPendientes
        pretmex
        provisionAguinaldo
        subtotal
        totalADispersar
        totalAFacturar
      }
      clientISN
      commission
      companyISN
      concept
      fonacot
      id
      infonavit
      isn
      isnTaxableBase
      isr
      iva
      otherDeductions
      outstandingPayments
      payrollPeriod {
        id
        name
        fullName
        start
        end
        key
        payday
        dispersionInformation {
          date
        }
        payrollType {
          id
          name
          billingCompany {
            id
            name
          }
          dispersingCompany {
            id
            name
          }
          isSuperPayroll
          childPayrolls {
            billingCompany {
              id
              name
            }
          }
        }
      }
      perceptionModifiers {
        amount
        discountValue
        type
      }
      pretmex
      provisionOfChristmasBonus
      socialCost
      subtotal
      totalToDisperse
      totalToInvoice
    }
    approved
    bonuses
    isSupervisorApproved
    christmasBonusCommission
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    ordinaryPayrollSummary {
      ISNRetenido {
        baseGravable
        cliente
        empresa
        total
      }
      baseCotizacion {
        amount
        percentage
      }
      bonificaciones
      comision
      comisionAguinaldo
      costoSocial
      diferencia
      fonacot
      infonavit
      isr
      iva
      ivaRetenido
      modificadoresPercepcion {
        amount
        discountValue
        type
      }
      payrollPeriodDiscounts {
          amount
          modifierType {
            id
            name
            type
          }
        }
      otrasDeducciones
      pagosPendientes
      pretmex
      provisionAguinaldo
      subtotal
      totalADispersar
      totalAFacturar
    }
    extraordinaryPayrollSummaries {
      comision
      iva
      ivaRetenido
      subtotal
      totalAFacturar
      extraordinaryPayrollSummary {
        id
        isSupervisorApproved
        approved
        bonuses
        client {
          id
          basic {
            comercialName
          }
          societies {
            id
            name
          }
          general {
            tradeName
            businessName
          }
        }
        outstandingPayments
        payrollPeriod {
          id
          name
          fullName
          payday
          dispersionInformation {
            date
          }
        }
        retainedIVA
        subtotal
        totalAmount
        totalInvoice
        totalCommission
      }
    }
    totalExtraordinarySummary {
      comision
      iva
      ivaRetenido
      subtotal
      totalAFacturar
    }
    totalSummaryAmount {
      ISNRetenido {
        baseGravable
        cliente
        empresa
        total
      }
      baseCotizacion {
        amount
        percentage
      }
      bonificaciones
      comision
      comisionAguinaldo
      costoSocial
      diferencia
      fonacot
      infonavit
      isr
      iva
      ivaRetenido
      modificadoresPercepcion {
        amount
        discountValue
        type
      }
      payrollPeriodDiscounts {
          amount
          modifierType {
            id
            name
            type
          }
        }
      otrasDeducciones
      pagosPendientes
      pretmex
      provisionAguinaldo
      subtotal
      totalADispersar
      totalAFacturar
    }
    clientISN
    commission
    companyISN
    concept
    fonacot
    id
    infonavit
    isn
    isnTaxableBase
    isr
    iva
    otherDeductions
    outstandingPayments
    payrollPeriod {
      id
      name
      fullName
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
      payrollType {
        id
        name
        dispersingCompany {
          id
          name
          linkedNOM035Society {
            name
          }
        }
        billingCompany {
          id
          name
        }
        isSuperPayroll
        childPayrolls {
          billingCompany {
            id
            name
          }
        }
      }
      type
    }
    perceptionModifiers {
      amount
      discountValue
      type
    }
    pretmex
    provisionOfChristmasBonus
    socialCost
    subtotal
    totalToDisperse
    totalToInvoice
  }
}
`;

export const payrollPeriodReport = `
query payrollPeriodReport($id: String!) {
  payrollPeriodReport(id: $id)
}
`;

export const payrollTypeLargestCode = `
query payrollTypeLargestCode($client: ID!) {
  payrollTypeLargestCode(client: $client)
}
`;

export const enumFrequencyType = `
query enumFrequencyType {
  enumFrequencyType {
    value
    displayValue
  }
}
`;
