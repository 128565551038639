export const GetTaxRegimesCatalog = `
query GetTaxRegimesCatalog {
  catalogueTaxRegimes(pagination: { limit: 100000, page: 1 }) {
    data {
      id
      description
      cRegimenFiscal
    }
  }
}
`;

export const GetCFDIConceptsCatalog = `
query GetCFDIConceptsCatalog {
  catalogueCFDIConcepts(pagination: { limit: 100000, page: 1 }) {
    data {
      id
      description
      cUsoCFDI
    }
  }
}
`;

export const GetPaymentMethodsCatalog = `
query GetPaymentMethodsCatalog {
  cataloguePayMethods(pagination: { limit: 100000, page: 1 }) {
    data {
      id
      description
      metodoPago
    }
  }
}
`;

export const GetPaymentMethodTypesCatalog = `
query GetPaymentMethodTypesCatalog {
  cataloguePaymentMethodTypes(pagination: {}) {
    count
    data {
      billingId
      formaPago
      id
      name
    }
  }
}
`;
