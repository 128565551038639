export interface AdditionalBenefits {
  infonavit?: boolean;
  fonacot?: boolean;
}

export interface CommitmentDates {
  deliveryDigitalContracts: boolean;
  deliveryPrintedContracts: boolean;
  retentionInfonavit: boolean;
  compromiseDateRetentionInfonavit: string;
}

export interface CustomerPayment {
  frequency: string;
  frequencies: string[];
  customerDepositBankAccount: string;
  bankAccountsToDepositPayroll: string[];
  employeeBanks: string;
  employeeCardsBank: string;
  frequencyTypes: FrequencyTypeEnum[];
  bankAccountsForPayrollDispersal: string[];
  frequenciesComment: string;
}

export interface CustomerInsurance {
  coverageStartDate: string;
  chargeStartDate: string;
}

export interface CustomerValidations {
  payrollSchemes: PayrollSchemeType[];
  riskClasses: RiskClassType[];
}

export interface ClientBasic {
  active?: boolean;
  comercialName: string;
  logo: string;
  numberOfEmployees: string;
}

export interface ClientContact {
  id: string;
  address: string;
  email: string;
  name: string;
  phone: string;
  schedule: string;
  LeadIDAPP4040?: number,
}

export interface ClientGeneral {
  address: string;
  bank: string;
  bankCards?: boolean;
  businessName: string;
  businessNames: string[];
  cellPhone: string;
  chargeDate?: string;
  dischargeDate?: string;
  IMSSChargeDate?: string;
  industry: string;
  municipality: string;
  municipalityWhereWork?: string;
  rfc: string;
  state: string;
  stateWhereWork?: string;
  telephone: string;
  tradeName: string;
  wageBank: string;
  webPage: string;
  workAddress: string;
  zipCode: string;
}

export interface PerceptionModifierDefaultValues {
  modifierType: string;
  percentagePaidByCompany: number;
  percentagePaidByCommissionAgent: number;
  percentagePaidByWorker: number;
  percentagePaidByEmployer: number;
  paymentFrequency: PaymentFrequencyPerceptionModifier;
}

export type Client = {
  id: string,
  businessName: string,
  // TODO: Fix typo
  comerialName?: string,
  commercialName: string,
};

export interface FullClient {
  id: string;
  LevitaOpsID: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  basic: ClientBasic;
  code: number;
  company: string;
  contact: ClientContact;
  contacts: ClientContact[];
  departments: string[];
  general: ClientGeneral;
  groupEntity: string;
  modifierTypes: string[];
  payment: CustomerPayment;
  payrollDefaultValues: PayrollDefaultValues;
  perceptionModifiersDefaultValues: PerceptionModifierDefaultValues[];
  place: string;
  payingCompanies: string[];
  status: ClientStatus;
  validations: CustomerValidations;
  additionalBenefits: AdditionalBenefits;
  hasForeigners: boolean;
  isBorderArea: boolean;
  provisionTypes: string[];
  globalPermissions: ClientGlobalPermissions;
}

export interface ClientGlobalPermissions {
  maintenanceMode: boolean;
  myEmployeesModule: {
    enabled: boolean;
    addPayroll: boolean;
  };
  employeeEntryModule: {
    enabled: boolean;
  };
  employeeDismissModule: {
    enabled: boolean;
  };
  employeeReentryModule: {
    enabled: boolean;
  };
  bankAccountsModule: {
    enabled: boolean;
  };
  periodsModule: {
    enabled: boolean;
    generateBill: boolean;
    requestBill: boolean;
  };
}

export type ClientFilters = null;

export enum ClientStatus {
  ClientStatusProspecto = 1,
  ClientStatusCliente = 2,
  ClientStatusInactivo = 3,
}

export enum FrequencyTypeEnum {
  LUNES = 1,
  MARTES = 2,
  MIERCOLES = 3,
  JUEVES = 4,
  VIERNES = 5,
  SABADO = 6,
  DOMINGO = 7,
}

export enum RiskClassType {
  RiskClass1 = 1,
  RiskClass2 = 2,
  RiskClass3 = 3,
  RiskClass4 = 4,
  RiskClass5 = 5,
}

export enum PayrollSchemeType {
  PayrollSchemaSYS = 1,
  PayrollSchemaPPPS = 2,
  PayrollSchemaSYSAndPPPS = 3,
  PayrollSchemaAsimilados = 4,
  PayrollSchemaNOM035 = 5,
}

export enum PaymentFrequencyPerceptionModifier {
  PERIODO = 1,
  MENSUAL = 2,
}

export interface PayrollDefaultValues {
  holidays: string;
  holidaysBonusPercentage: number;
  christmasBonusDays: number;
}

export interface FullClientFilter {
  clientID?: string;
  business?: string;
  placeID?: string;
  societyID?: string;
  page?: number;
  limit?: number;
  sortCode?: number;
  companyID?: string;
  isConstructor?: boolean;
  societyIDs?: string[];
  kardexADC?: string;
  clientIDs?: string[];
}
