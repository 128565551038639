/* eslint-disable no-underscore-dangle */
import { useMemo, useRef } from 'react';
// import { usePrevious } from 'react-use';

// import VirtualizedCellMeasurerDataSheet from '../../components/DataSheetVirtualized/index';
import { Cell } from '../../types';
import { useAppSelector } from '../redux';

const useDataSheetPagination =
(
  grid: Cell[][],
  isActive: boolean,
) : Cell[][] => {
  const paginatedGridRef = useRef<Cell[][]>(grid);
  const { page, limit } = useAppSelector(({ pagination }) => pagination);
  // const prevPage = usePrevious(page);
  // const prevLimit = usePrevious(limit);

  useMemo(() => {
    // if (
    //   DataSheetRef.current
    //   && DataSheetRef.current._cache
    //   && DataSheetRef.current._cache.clearAll
    //   && (prevPage !== page || prevLimit !== limit)
    // ) {
    //   DataSheetRef.current._cache.clearAll();
    // }
    if (isActive) {
      const startIndex = isActive ? page * limit : 0;
      const endIndex = isActive ? (startIndex + limit) : grid.length;
      paginatedGridRef.current = grid.slice(startIndex, endIndex);
    } else {
      paginatedGridRef.current = grid;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid, page, limit, isActive]);

  return paginatedGridRef.current;
};

export default useDataSheetPagination;
