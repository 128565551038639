/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import { Operations } from '../../helpers/getGraphQLParameters';
import {
  PayrollCalculation,
  PayrollCalculationBilling,
  PayrollCalculationSummary,
  PayrollPeriod,
  PayrollPeriodType,
  PayrollType,
} from '../../types';

export interface PayrollsCalculationState {
  isLoading: boolean;
  isSummaryLoading: boolean,
  isBillingLoading: boolean,
  count: number;
  entities: Record<string, PayrollCalculation>;
  summary: PayrollCalculationSummary | null,
  billing: PayrollCalculationBilling | null,
  deductionsList: string[],
};

const initialState: PayrollsCalculationState = {
  isLoading: false,
  isSummaryLoading: false,
  isBillingLoading: false,
  count: 0,
  entities: {},
  summary: null,
  billing: null,
  deductionsList: [],
};

type OperationType =
  'AddCalculate'
  | 'GetCalculate'
  | 'AddSummary'
  | 'EditSummary'
  | 'GetSummary'
  | 'AddBilling'
  | 'GetBilling';

const getPeriodOperationName = (period: PayrollPeriod, opetation: OperationType): Operations | null => {
  if (period.type === PayrollPeriodType.Extraordinary) {
    return `${opetation}ExtraordinaryPayroll`;
  }
  if (period.type === PayrollPeriodType.Ordinary) {
    if (period.payrollSchema === 'SYS + PPPS' || period.payrollSchema === 'SYS') {
      return null;
    }
    if (period.payrollSchema === 'PPPS' || period.payrollSchema === 'Nom. 035') {
      return `${opetation}PPPSPayroll`;
    }
  }
  return null;
}

export const fetchPayrollCalculation = createAsyncThunk(
  'mutatePayrollsCalculation/fetchPayrollCalculation',
  async (period: PayrollPeriod): Promise<PayrollCalculation[]> => {
    const variables = { payrollPeriod: period.id };
    const operationName: Operations | null = getPeriodOperationName(period, 'GetCalculate');
    if (operationName === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const graphQLParams = getGraphQLParameters(operationName, variables);
    const { data: dataResponse } = await getPostRequest<Record<string, { data: PayrollCalculation[], count: number }>>('', graphQLParams);
    const { data } = dataResponse;
    const result = Object.values(data)[0];
    if (result.count > 0) {
      return result.data;
    }
    const operation = getPeriodOperationName(period, 'AddCalculate');
    if (operation === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const params = getGraphQLParameters(operation, variables);
    const { data: response } = await getPostRequest('', params);
    const { data: result2 } = response;
    return Object.values(result2)[0] as PayrollCalculation[];
  },
);

export const fetchPayrollSummary = createAsyncThunk(
  'mutatePayrollsSummary/fetchPayrollSummary',
  async (period: PayrollPeriod): Promise<PayrollCalculationSummary> => {
    const variables = { payrollPeriod: period.id };
    const operationName: Operations | null = getPeriodOperationName(period, 'GetSummary');
    if (operationName === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const graphQLParams = getGraphQLParameters(operationName, variables);
    const { data: dataResponse } = await getPostRequest<Record<string, PayrollCalculationSummary | null>>('', graphQLParams);
    const { data } = dataResponse;
    const result = Object.values(data)[0];
    if (result !== null) {
      return result;
    }

    const operation = getPeriodOperationName(period, 'AddSummary');
    if (operation === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const params = getGraphQLParameters(operation, variables);
    const { data: response } = await getPostRequest('', params);
    const { data: result2 } = response;
    const summary = Object.values(result2)[0] as PayrollCalculationSummary;
    const approvedVariables = {
      id: summary.id,
      approved: true,
    };
    const operationNameEdit: Operations | null = getPeriodOperationName(period, 'EditSummary');
    if (operationNameEdit === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const aproveParams = getGraphQLParameters(operationNameEdit, approvedVariables);
    getPostRequest('', aproveParams);
    return summary;
  },
);

export const fetchPayrollBilling = createAsyncThunk(
  'mutatePayrollsBilling/fetchPayrollBilling',
  async ({ period, summary, paysheetType }: { period: PayrollPeriod, summary: PayrollCalculationSummary, paysheetType: PayrollType }): Promise<PayrollCalculationBilling> => {
    const society = paysheetType.isHighDirection ? paysheetType.billingCompany : paysheetType.dispersingCompany;
    const variables = {
      payrollPeriod: period.id,
      payrollSummary: summary.id,
      society,
    };
    const operationName: Operations | null = getPeriodOperationName(period, 'GetBilling');
    if (operationName === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const graphQLParams = getGraphQLParameters(operationName, variables);
    const { data: dataResponse } = await getPostRequest<Record<string, PayrollCalculationBilling | null>>('', graphQLParams);
    const { data } = dataResponse;
    const result = Object.values(data)[0];
    if (result !== null) {
      return result;
    }
    const operation = getPeriodOperationName(period, 'AddBilling');
    if (operation === null) {
      throw new Error("Periodo tipo de periodo no soportado");
    }
    const params = getGraphQLParameters(operation, variables);
    const { data: response } = await getPostRequest('', params);
    const { data: result2 } = response;
    return Object.values(result2)[0] as PayrollCalculationBilling;
  },
);

export const payrollsCalculationSlice = createSlice({
  name: 'payrollsCalculationSlice',
  initialState,
  reducers: {
    cleanPayrollsCalculation: (state) => {
      state.count = 0;
      state.entities = {};
      state.summary = null;
      state.billing = null;
      state.deductionsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayrollCalculation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPayrollCalculation.fulfilled,
        (state, action: PayloadAction<PayrollCalculation[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            const deductionsList = new Set<string>();
            const formatedEntities: PayrollCalculation[] = payload.map((payrollCalculation) => {
              const deductionsMap: Record<string, number> = {};
              let deductionsTotal = 0;
              payrollCalculation.deductions?.forEach((deduction) => {
                deductionsList.add(deduction.concepto);
                deductionsMap[deduction.concepto] = deduction.importe;
                deductionsTotal += deduction.importe;
              });
              return {
                ...payrollCalculation,
                deductionsMap,
                deductionsTotal,
              };
            });
            state.entities = _.keyBy(formatedEntities, 'id');
            state.deductionsList = Array.from(deductionsList);
          }
        },
      )
      .addCase(fetchPayrollCalculation.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchPayrollSummary.pending, (state) => {
        state.isSummaryLoading = true;
      })
      .addCase(fetchPayrollSummary.fulfilled, (state, action: PayloadAction<PayrollCalculationSummary>) => {
        const { payload } = action;
        state.isSummaryLoading = false;
        if (payload) {
          const perceptionModifiersMap: Record<string, number> = {};
          payload.perceptionModifiers?.forEach(({ totalAmountToPay, modifierName }) => {
            perceptionModifiersMap[modifierName] = totalAmountToPay;
          });
          const summary = {
            ...payload,
            perceptionModifiersMap,
          };
          state.summary = summary;
        }
      })
      .addCase(fetchPayrollSummary.rejected, (state) => {
        state.isSummaryLoading = false;
      });

    builder
      .addCase(fetchPayrollBilling.pending, (state) => {
        state.isBillingLoading = true;
      })
      .addCase(fetchPayrollBilling.fulfilled, (state, action: PayloadAction<PayrollCalculationBilling>) => {
        const { payload } = action;
        state.isBillingLoading = false;
        if (payload) {
          state.billing = payload;
        }
      })
      .addCase(fetchPayrollBilling.rejected, (state) => {
        state.isBillingLoading = false;
      });
  },
});

export const { cleanPayrollsCalculation } = payrollsCalculationSlice.actions;

export default payrollsCalculationSlice.reducer;
