import { FC } from 'react';

import { Grid } from '@mui/material';
import { v4 as uuid } from 'uuid';

interface Props {
  itemsPerRow?: number,
  spacing?: number,
  children: (JSX.Element | null)[],
};

const Shelfs: FC<Props> = ({ itemsPerRow = 5, spacing, children }): JSX.Element => {
  const lg = itemsPerRow;
  const md = typeof itemsPerRow === 'number' && itemsPerRow < 3 ? itemsPerRow : 3;
  const sm = typeof itemsPerRow === 'number' && itemsPerRow < 2 ? itemsPerRow : 2;
  const xs = 1;

  const filteredChildren = children.filter((child) => child);

  return (
    <Grid
      columns={{ lg, md, sm, xs }}
      container
      spacing={spacing}
    >
      {filteredChildren.map((item) => (
        <Grid
          item
          key={uuid()}
          lg={1}
          md={1}
          sm={1}
          xs={1}
        >
          {item}
        </Grid>
      ))}
    </Grid>
  );
};

Shelfs.defaultProps = {
  itemsPerRow: 5,
  spacing: 2,
};

export default Shelfs;
