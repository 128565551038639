import { useCallback, useEffect, useRef } from 'react';
import {
  useDeepCompareEffect,
  useEffectOnce,
  usePrevious,
  useToggle,
  useUpdateEffect,
} from 'react-use';

import _ from 'lodash';

import { useAppSelector, useStateLocalStorage } from '../..';
import {
  clabe,
  cleanMoneyValue,
  getPostRequest,
} from '../../../helpers';
import { findOption } from '../../../helpers/dataSheet';
import {
  createClientPendingTask,
  deleteClientPendingTask,
  deleteManyEmployeeDraft,
  fetchClientPendingTasksNoRedux,
  fetchEmployeeDrafts,
  fetchWorkdays,
  showMessage,
} from '../../../store/slices';
import {
  Cell,
  Change,
  ClientPendingTaskTitle,
  ClientPendingTaskType,
  Department,
  EmployeeDraftStatuses,
  EmployeeType,
  EntitiesState,
  Error,
  GridKey,
  HandleChanges,
  Municipality,
  Option,
  PaymentMethod,
  State,
  TableValues,
  UploadFile,
  ValueRendererType,
} from '../../../types';
import { useAppDispatch } from '../../redux';
import useDataSheet from '../../useDataSheet/index';
import { filteredColumns } from './columns';

type UseAddEmployeesDatasheet = {
  isLoading: boolean,
  grid: Cell[][],
  handleChanges: HandleChanges,
  ValueRenderer: ValueRendererType,
  employeesAmount?: number,
  setEmployeesAmount: React.Dispatch<React.SetStateAction<number | undefined>>,
  cleanLocalStorage: () => void,
  employees: EntitiesState,
  validate: () => {
    isValid: boolean,
    errors: Error[],
  },
  onIDSEFiles: (vouchers: UploadFile[]) => Promise<void>;
  validEmployees: EntitiesState;
  errors: EntitiesState;
  requiredMissingValues: EntitiesState;
  cleanMultipleRowsMemory: (rowsKeys: GridKey[]) => void;
  slideChangesUp: () => void;
};

const tableValuesParameters = {
  operationName: 'tableValues',
  query: 'query tableValues {  tableValues(isActive: true) {    id    effectiveAt    minimumSalary    borderMinimumSalary    __typename  }}',
  variables: {},
};

const useAddEmployeesDatasheet = (payrollSelected?: Option<string>): UseAddEmployeesDatasheet => {
  const [
    employeesAmount = 10,
    setEmployeesAmount,
    removeEmployeesAmount,
  ] = useStateLocalStorage<number>('useAddEmployeesDatasheet_employeesAmount', 10);
  const prevEmployeesAmount = usePrevious(employeesAmount);
  const [tableValues, setTableValues, removeTableValues] = useStateLocalStorage<TableValues | undefined>('useAddEmployeesDatasheet_tableValues', undefined);
  const [options, setOptions, removeOptions] = useStateLocalStorage<Record<string, Option[]>>('useAddEmployeesDatasheet_options', {});
  const [
    municipalities,
    setMunicipalities,
    removeMunicipalities,
  ] = useStateLocalStorage<Record<string, Option[]>>('useAddEmployeesDatasheet_municipalities', {});
  const [isLoading, toggleIsLoading] = useToggle(false);
  // const [hasSuccessfullyComplete, toggleHasSuccessfullyComplete] = useToggle(false);

  const columns = filteredColumns;

  const {
    grid,
    handleChanges,
    entities: employees,
    validEntities: validEmployees,
    errors,
    requiredMissingValues,
    ValueRenderer,
    updateColumnAtributes,
    updateCellAtributes,
    getCell,
    validate,
    changes,
    cleanDataSheetLocalStorage,
    cleanCellChange,
    cleanMultipleRowsMemory,
    slideChangesUp,
  } = useDataSheet(columns, employeesAmount || 10, { discardNonRenderer: true });

  const cleanLocalStorage = (): void => {
    removeEmployeesAmount();
    removeTableValues();
    removeMunicipalities();
    removeOptions();
    cleanDataSheetLocalStorage();
  };

  const handleAddEmployeesChanges: HandleChanges = (cchanges: Change[] = []): void => {
    const validatedChanges: Change[] = [];
    cchanges.forEach((change) => {
      const {
        value, col, row,
      } = change;
      const cell = change.cell || getCell(row, col);
      const { field, colIndex } = cell;
      const newChangeCell = {
        ...cell,
      };
      validatedChanges.push({
        ...change,
        cell: newChangeCell,
      });
      newChangeCell.className = 'edited';
      if (field === 'stateWhereWork' || field === 'state') {
        let stateID: string;
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          const option = value as Option;
          stateID = option.value;
        } else if (typeof value === 'string') {
          const { value: optionValue } = findOption(value, _.get(cell, 'options', []));
          stateID = optionValue;
        } else {
          stateID = `${value}`;
        }
        const nextOptions = _.get(municipalities, stateID, []);
        updateCellAtributes(row, colIndex + 1, { options: nextOptions });
      } else if (field === 'municipalityWhereWork') {
        let option;
        if (typeof value === 'string') {
          option = findOption(value, cell.options);
        } else {
          option = value as Option;
        }
        const { borderArea: isBorderArea } = option;
        const salaryCell = getCell(row, 'daylyPayrollSalary');
        const { value: salaryValue } = salaryCell;
        const numberValue = cleanMoneyValue(salaryValue);
        if (tableValues !== undefined) {
          const { borderMinimumSalary, minimumSalary } = tableValues;
          if (isBorderArea && numberValue < borderMinimumSalary) {
            salaryCell.className = 'error';
            validatedChanges.push({
              cell: salaryCell,
              row,
              col: salaryCell.col,
              value: salaryValue,
            });
          }
          if (!isBorderArea && numberValue < minimumSalary) {
            salaryCell.className = 'error';
            validatedChanges.push({
              cell: salaryCell,
              row,
              col: salaryCell.col,
              value: salaryValue,
            });
          }
        }
      } else if (field === 'daylyPayrollSalary') {
        const numberValue = cleanMoneyValue(value);
        const municipalityWhereWorkCell = getCell(row, 'municipalityWhereWork');
        const isBorderArea = _.get(municipalityWhereWorkCell, 'borderArea', false);
        if (tableValues !== undefined) {
          const { borderMinimumSalary, minimumSalary } = tableValues;
          if (isBorderArea && numberValue < borderMinimumSalary) {
            newChangeCell.className = 'error';
          }
          if (!isBorderArea && numberValue < minimumSalary) {
            newChangeCell.className = 'error';
          }
        }
      } else if (field === 'clabe') {
        const validation = clabe.validate(value);
        if (validation.ok) {
          validatedChanges.push({
            cell: null,
            col: 'bankAccountAccountNumber',
            row,
            value: validation.account,
          });
          validatedChanges.push({
            cell: null,
            col: 'bank',
            row,
            value: validation.tag,
          });
          validatedChanges.push({
            cell: null,
            col: 'bankAccountBranchNumber',
            row,
            value: validation.code.bank,
          });
          validatedChanges.push({
            cell: null,
            col: 'paymentMethod',
            row,
            value: 'Transferencia electrónica de fondos',
          });
        }
      }
    });
    handleChanges(validatedChanges);
  };

  const employeeDrafts = useAppSelector(({ employeeDraft }) => employeeDraft.entities);
  const selectedClientId = useAppSelector(({ clients }) => clients.selected);
  const selectedClient = useAppSelector(({ fullClients }) => fullClients.entities[selectedClientId]);
  const payrollTypes = useAppSelector(({ payrollsTypes }) => payrollsTypes.entities);
  const workdaysEntities = useAppSelector(({ workdays }) => workdays.entities)
  const changesAlertPublished = useRef(false);
  const prevTasksDeleted = useRef(false);
  const changesSnapshot = useRef({} as EntitiesState);
  const dispatch = useAppDispatch();

  const handleWebsiteUnload = async (changesParam: EntitiesState) => {
    // console.log("[D] Started Unload Process");
    if (changesParam) {
      if (Object.values(changesParam).length > 0) {

        const data = await fetchClientPendingTasksNoRedux({
          clientId: {
            eq: selectedClientId,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_REGISTRATION
          },
        })

        if (data.length === 0) {
          dispatch(createClientPendingTask({
            clientId: selectedClientId,
            type: ClientPendingTaskType.CPTT_EMPLOYEES_REGISTRATION,
          }))
          // console.log("[D] Finished Unload Process: Created Pending Task");
        } else {
          // console.log("[D] Finished Unload Process: Already Exists Pending Task");
        }
      } else {
        // console.log("[D] Finished Unload Process: Nothing happened [1]");
      }
    } else {
      // console.log("[D] Finished Unload Process: Nothing happened [0]");
    }

  }

  const warnAgainstWebsiteUnload = async (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '';
  }

  const handleDeleteAllPendingTasks = async () => {
    dispatch(deleteClientPendingTask({
      clientId: {
        eq: selectedClientId,
      },
      title: {
        eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_REGISTRATION
      },
    }))
  }

  // Unloading website
  useEffect(() => {
    changesSnapshot.current = changes;
    if (changes) {
      if (Object.values(changes).length > 0 && !changesAlertPublished.current) {
        prevTasksDeleted.current = false;
        // console.log("[D] Activated Unload Listener");
        window.addEventListener('beforeunload', warnAgainstWebsiteUnload)
        changesAlertPublished.current = true
      } else if (Object.values(changes).length === 0) {
        if (changesAlertPublished.current) {
          // console.log("[D] Deactivated Unload Listener");
          window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
        }
        changesAlertPublished.current = false
      }

      if (Object.values(changes).length === 0 && !prevTasksDeleted.current) {
        // console.log("[D] No changes detected, deleting pending tasks (if apply)");
        handleDeleteAllPendingTasks();
        prevTasksDeleted.current = true;
      }
    }

    return () => {
      window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  useDeepCompareEffect(() => {
    if (payrollSelected) {
      const result = payrollTypes[payrollSelected.value].taxSchema
      updateColumnAtributes('daylyPayrollSalary', { forceHidden: result === 'PPPS' });
    }
  }, [payrollSelected, updateColumnAtributes]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))
    return () => {
      window.removeEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))

      handleWebsiteUnload(changesSnapshot.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    const employeesTypesOptions = Object.values(EmployeeType).map((employeeType) => ({
      label: employeeType,
      value: employeeType,
    }));
    updateColumnAtributes('employeeType', { options: employeesTypesOptions });
  });

  useEffectOnce(() => {
    toggleIsLoading();
    const nextOptions: Record<string, Option[]> = {};
    const tableValuesRequest = getPostRequest('', tableValuesParameters)
      .then((response) => {
        const fetchedTableValues = _.get(response, 'data.data.tableValues', {});
        setTableValues(fetchedTableValues);
      });
    const civilStatusRequest = getPostRequest<string[]>('v2/civilstatus/all', {})
      .then(({ data: civilStatusResponse }) => {
        const { data: civilStatus } = civilStatusResponse;
        const civilStatusOptions = civilStatus.map(civilStatusValue => ({
          value: civilStatusValue,
          label: civilStatusValue,
        }));
        nextOptions.civilStatus = civilStatusOptions;
      });
    const municipalitiesRequest = getPostRequest<Municipality[]>('v2/municipality/all', {})
      .then(({ data: municipalitiesResponse }) => {
        const { data: municipalitiesData } = municipalitiesResponse;
        if (municipalitiesData.length > 0) {
          const municipalitiesOptions = municipalitiesData.map(({
            id, name, borderArea, state,
          }) => ({
            value: id,
            label: name,
            borderArea,
            state,
          }));
          setMunicipalities(_.groupBy(municipalitiesOptions, 'state'));
        }
      });
    const jobRequest = getPostRequest<string[]>('v2/job/all', {})
      .then(({ data: municipalitiesResponse }) => {
        const { data: jobs } = municipalitiesResponse;
        const jobOptions = jobs.map(jobValue => ({
          value: jobValue,
          label: jobValue,
        }));
        nextOptions.job = jobOptions;
      });
    const stateRequest = getPostRequest<State[]>('v2/state/all', { country: 'MEX' })
      .then(({ data: municipalitiesResponse }) => {
        const { data: states } = municipalitiesResponse;
        const statesOptions = states.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        nextOptions.stateWhereWork = statesOptions;
        nextOptions.state = statesOptions;
      });
    const salarytypeRequest = getPostRequest<string[]>('v2/salarytype/all', {})
      .then(({ data: municipalitiesResponse }) => {
        const { data: salaryTypes } = municipalitiesResponse;
        const salaryTypesOptions = salaryTypes.map(salaryTypeValue => ({
          value: salaryTypeValue,
          label: salaryTypeValue,
        }));
        nextOptions.salaryType = salaryTypesOptions;
      });
    const paymentmethodRequest = getPostRequest<PaymentMethod[]>('v2/paymentmethod/all', {})
      .then(({ data: municipalitiesResponse }) => {
        const { data: paymethods } = municipalitiesResponse;
        const paymethodsOptions = paymethods.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        nextOptions.paymentMethod = paymethodsOptions;
      });
    Promise.allSettled([
      tableValuesRequest,
      civilStatusRequest,
      municipalitiesRequest,
      jobRequest,
      stateRequest,
      salarytypeRequest,
      paymentmethodRequest,
    ])
      .then(() => {
        setOptions((prevOptions) => ({
          ...prevOptions,
          ...nextOptions,
        }));
      })
      .finally(() => {
        toggleIsLoading();
      });
  });

  useUpdateEffect(() => {
    if (selectedClient) {
      getPostRequest<Department[]>('v2/department/all', { departmentIDs: selectedClient.departments })
        .then(({ data: municipalitiesResponse }) => {
          const { data: departments } = municipalitiesResponse;
          const departmentOptions = departments.map(({ id, name }) => ({
            value: id,
            label: name,
          }));
          setOptions((prevOptions) => ({
            ...prevOptions,
            department: departmentOptions,
          }));
        });
    }
  }, [selectedClient]);

  useUpdateEffect(() => {
    if (prevEmployeesAmount === 0) {
      setEmployeesAmount(10);
    }
  }, [employeesAmount]);

  useDeepCompareEffect(() => {
    if (options !== undefined) {
      Object.keys(options).forEach((key) => {
        updateColumnAtributes(key, { options: options[key] });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    dispatch(fetchWorkdays({
      clientID: selectedClientId,
    }));
  }, [dispatch, selectedClientId]);

  useDeepCompareEffect(() => {
    const workdayTypeOptions: Option[] = Object.values(workdaysEntities)
      .map(({ id, name }) => ({ label: name, value: id }));
    updateColumnAtributes('workdayType', {
      options: workdayTypeOptions,
      ...(workdayTypeOptions.length > 0 ? { defaultValue: workdayTypeOptions[0].value, defaultLabel: workdayTypeOptions[0].label } : null),
    });
  }, [workdaysEntities])
  useEffectOnce(() => {
    dispatch(fetchEmployeeDrafts({
      limit: 100,
      page: 1,
      clientId: selectedClientId,
    }));
  });

  useDeepCompareEffect(() => {
    const ids: string[] = [];
    const newChanges: Change[] = [];
    const employeesArr = _.values(employees);
    const employeesMap = _.keyBy(employeesArr, ({
      curp,
      imss,
      rfc,
      name,
      firstLastName,
      secondLastName,
    }) => `${name}${firstLastName}${secondLastName}${curp}${imss}${rfc}`);
    const messages: string[] = Object.values(employeeDrafts)
      .filter(({ status }) => status !== EmployeeDraftStatuses.Pending)
      .map(({
        id,
        status,
        curp,
        firstLastName,
        imss,
        name,
        rfc,
        secondLastName,
      }) => {
        ids.push(id);
        const employeeId = _.get(employeesMap, `${name}${firstLastName}${secondLastName}${curp}${imss}${rfc}.id`, '') as string;
        if (status === EmployeeDraftStatuses.Declined) {
          return `La solicitud de aprobación del documento IDSE para el empleado ${name} ${firstLastName} ${secondLastName} fue rechazada`;
        }
        if (status === EmployeeDraftStatuses.Approved) {
          if (employeeId) {
            newChanges.push({
              cell: null,
              col: 'validatedIdse',
              row: employeeId,
              value: true,
            });
            updateCellAtributes(employeeId, 'validatedIdse', { type: 'check' });
          }
          return `La solicitud de aprobación del documento IDSE para el empleado ${name} ${firstLastName} ${secondLastName} fue aprobada`;
        }
        return `La solicitud de aprobación del documento IDSE para el empleado ${name} ${firstLastName} ${secondLastName} no pudo ser procesado`;
      });
    if (messages.length > 0) {
      if (newChanges.length > 0) {
        handleChanges(newChanges);
      }
      dispatch(deleteManyEmployeeDraft(ids));
      dispatch(showMessage({
        message: messages,
        type: 'warning',
      }));
    }
  }, [employeeDrafts]);

  const onIDSEFiles = useCallback(async (vouchers: UploadFile[]) => {
    const employeesArr = _.values(employees)
      .filter(({ name, firstLastName, imss, validatedIdse }) => (name !== '' && firstLastName !== '' && imss !== '' && validatedIdse !== true));
    const response = await getPostRequest<boolean[]>('v2/employee/add/validate/idses', {
      documentIDs: vouchers.map((voucher) => voucher?.id),
      inputs: employeesArr.map(({
        name,
        firstLastName,
        secondLastName,
        rfc,
        curp,
        imss,
      }) => ({ name, firstLastName, secondLastName, rfc, curp, imss })),
    });

    const { data: dataResponse } = response;
    const { data } = dataResponse;
    const newChanges: Change[] = [];

    data.forEach((wasValidated, index) => {
      const employee = employeesArr[index];
      updateCellAtributes(employee.id, 'validatedIdse', { type: wasValidated ? 'check' : 'button' });
      if (wasValidated) {
        newChanges.push({
          cell: null,
          value: true,
          row: employee.id,
          col: 'validatedIdse',
        });
      } else {
        cleanCellChange(employee.id, 'validatedIdse');
      }
    });
    handleChanges(newChanges);
  }, [cleanCellChange, employees, handleChanges, updateCellAtributes])

  return {
    isLoading,
    grid,
    handleChanges: handleAddEmployeesChanges,
    ValueRenderer,
    employeesAmount,
    setEmployeesAmount,
    cleanLocalStorage,
    employees,
    validate,
    onIDSEFiles,
    validEmployees,
    errors,
    requiredMissingValues,
    cleanMultipleRowsMemory,
    slideChangesUp,
  };
};

export default useAddEmployeesDatasheet;
