export interface WorkdayPeriod {
  day: number;
  start: number;
  end: number;
}

export interface Workday {
  id: string;
  DeletedAt?: string;
  createdAt: string;
  updatedAt: string;
  company: string;
  client: string;
  name: string;
  type: WorkdayType;
  journeys: WorkdayPeriod[];
  launchTime: WorkdayPeriod[];
  workdayType: IMSSWorkdayType;
  isDefault: boolean;
}

export interface WorkdayFilter {
  workdayID?: string;
  name?: string;
  clientID?: string;
  companyID?: string;
  type?: WorkdayType;
  workdayType?: IMSSWorkdayType;
  isDefault?: boolean;
  page?: number;
  limit?: number;
}

export enum WorkdayType {
  DIURNA = 1,
  NOCTURNA = 2,
  MIXTA = 3,
  PORHORA = 4,
  REDUCIDA = 5,
  CONTINUADA = 6,
  PARTIDA = 7,
  PORTURNOS = 8,
  OTRA = 9,
}

export enum IMSSWorkdayType {
  SEMANACOMPLETA = 1,
  UNDIA = 2,
  DOSDIAS = 3,
  TRESDIAS = 4,
  CUATRODIAS = 5,
  CINCODIAS = 6,
  MENOROCHOHORAS = 7,
}
