import React from 'react';

import { WhereClause } from './request';

export type Nullable<T> = T | null;

export type Route = {
  path: string,
  title?: string,
  description?: string,
  element: React.ReactElement,
  children?: Route[],
};

export type ApiError = {
  type: string,
  title: string,
  status: number,
  detail: string,
  instance: string,
};

export type ApiResponse<T = any> = {
  error: ApiError[] | null,
  errors: ApiError[] | null,
  data: T,
  status: number,
  count?: number,
};

export enum ServerType {
  Go = 'go',
  Node = 'node'
};

export type RequestConfig = {
  controller?: AbortController;
  server?: ServerType;
  timeout?: number;
};

export type UploadFile = {
  createdAt: string,
  description: string,
  group: string,
  id: string,
  lastDownloadAt: null,
  name: string,
  presignedURL: string,
  publicURL: string,
  society: string,
  success: boolean,
  type: string,
}; 

export type DocumentsFilter = {
  id?: WhereClause,
  documentID?: string,
  documentIDs?: string[],
};

export type LocalStorageParserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => T;
    };

export type VersionControl = {
  version: number;
  reload: boolean;
};

export type VersionControlState = {
  major: VersionControl;
  minor: VersionControl;
  feature: VersionControl;
  hotfix: VersionControl;
};
