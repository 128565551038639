import { CSSProperties, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, CardActionArea, CardContent, Chip, Typography } from '@mui/material';

import { useAppSelector } from '../hooks';

interface Props {
  title?: string,
  titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit",
  to?: string,
  onClick?: () => void,
  icon?: JSX.Element,
  children?: JSX.Element,
  style?: CSSProperties,
};

const defaultStyle: CSSProperties = {
  height: '100%',
  borderRadius: '15px',
};

const SquareButton: FC<Props> = ({
  children,
  title,
  titleVariant,
  to,
  onClick,
  icon,
  style,
}): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      navigate(to);
    }
  };
  const unread = useAppSelector(({ chat }) => chat.unread);
  return (
    <Card style={defaultStyle}>
      <CardActionArea
        onClick={handleClick}
        style={{
          ...defaultStyle,
          ...style,
        }}
      >
        <CardContent>
          {icon}
          {title && (
            <Typography component="div" gutterBottom variant={titleVariant}>
              {title}
            </Typography>
          )}
          {title === 'Ayuda y Soporte' && (
            unread > 0 && (
              <Chip color='error' label={unread} />
            )
          )
          }
          {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

SquareButton.defaultProps = {
  title: '',
  titleVariant: 'h4',
  to: '',
  icon: undefined,
  children: undefined,
  style: {},
  onClick: () => { },
};

export default SquareButton;
