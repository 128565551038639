import { useCallback, useRef } from 'react';

import EventEmitter from 'eventemitter3';

export type Listener = () => void | Promise<void>;

export type AddListener = (e: string, listener: Listener) => () => void;

export type Notify = (e: string) => void;

export default function useEventListeners() {
  const emitter = useRef(new EventEmitter());

  const addListener: AddListener = useCallback((e, listener) => {
    emitter.current.on(e, listener);

    return () => {
      emitter.current.off(e, listener);
    };
  }, []);

  const notify = useCallback((e: string) => {
    emitter.current.emit(e);
  }, []);

  return {
    addListener,
    notify,
  };
}