import { Client } from '../types';
import { useAppSelector } from './redux';

export default function useClient(id?: string) : Client | undefined
{
  return useAppSelector(state => {
    if (id)
      return state.clients.entities[id];

    return undefined;
  });
}