/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import { PayrollPeriod, PayrollPeriodEdit, PeriodFilters } from '../../types';

export interface PeriodsState {
  count: number;
  entities: Record<string, PayrollPeriod>;
  selected: string,
  periodsPerPayrollTypes: Record<string, PayrollPeriod[]>;
  isLoading: boolean;
  isEditing: boolean;
}

const initialState: PeriodsState = {
  count: 0,
  entities: {},
  selected: '',
  periodsPerPayrollTypes: {},
  isLoading: false,
  isEditing: false,
};

export const fetchPeriods = createAsyncThunk(
  'getPeriods/fetchPeriods',
  async (filters: PeriodFilters = {}) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/payrollperiod/all',
      filters
    );
    const { data } = dataResponse;
    return data || [];
  }
);

export const editPeriod = createAsyncThunk(
  'editPeriod/mutatePeriod',
  async (edit: PayrollPeriodEdit) => {
    const params = getGraphQLParameters('PayrollPeriodEdit', {
      ...edit,
      payrollStatus: edit?.payrollStatus === 'Cerrada' ? 'closed' : edit?.payrollStatus,
    });
    await getPostRequest('', params);
    return edit;
  },
);

// eslint-disable-next-line import/prefer-default-export
export const periodsSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    cleanPeriods: (state) => {
      state.count = 0;
      state.entities = {};
    },
    setSelectedPeriod: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      state.selected = payload || '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeriods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPeriods.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
        state.periodsPerPayrollTypes = _.groupBy(payload, 'paysheetType');
      })
      .addCase(fetchPeriods.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(editPeriod.pending, (state) => {
        state.isEditing = true;
      })
      .addCase(editPeriod.fulfilled, (state, action: PayloadAction<PayrollPeriodEdit>) => {
        const { payload } = action;
        state.isEditing = false;
        if (payload) {
          state.entities[payload.id] = {
            ...state.entities[payload.id],
            ...payload,
          };
        }
      })
      .addCase(editPeriod.rejected, (state) => {
        state.isEditing = false;
      });
  },
});

export const { cleanPeriods, setSelectedPeriod } = periodsSlice.actions;

export default periodsSlice.reducer;
