/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDataSheet from 'react-datasheet';
import DataCell from 'react-datasheet/lib/DataCell';
import {
  AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid,
} from 'react-virtualized';

// import _ from 'lodash';
import PropTypes from 'prop-types';

import DefaultCellRenderer from './DefaultCellRenderer';
import DefaultDataEditor from './DefaultDataEditor';
import DefaultDataRenderer from './DefaultDataRenderer';
import DefaultValueRenderer from './DefaultValueRenderer';
import DefaultValueViewer from './DefaultValueViewer';
import './styles/DataSheetVirtualized.scss';
import './styles/index.css';

const isEmpty = obj => Object.keys(obj).length === 0;

const range = (start, end) => {
  const array = [];
  const inc = end - start > 0;
  for (let i = start; inc ? i <= end : i >= end; inc ? i += 1 : i -= 1) {
    if (inc) {
      array.push(i);
    } else {
      array.unshift(i);
    }
  }
  return array;
};

// const defaultParsePaste = str => str.split(/\r\n|\n|\r/).map(row => row.split('\t'));

export default class VirtualizedCellMeasurerDataSheet extends ReactDataSheet {
  constructor(props, context) {
    super(props, context);
    // Auto ajustado de celdas
    const {
      columnWidth = 250,
      rowHeight = 40,
      fixedHeight = true,
      fixedWidth = false,
      minHeight,
      minWidth,
    } = props;
    this._cache = new CellMeasurerCache({
      defaultHeight: rowHeight,
      defaultWidth: columnWidth,
      fixedHeight,
      fixedWidth,
      minHeight,
      minWidth,
    });
    this._cellRenderer = this._cellRenderer.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    // const { start, end } = prevState;
    if (prevProps.data !== this.props.data) {
      // console.time('prevProps.data !== this.props.data');
      // // console.log('componentDidUpdate prevState', prevState);
      // const editedCellsPosition = getPositionsRange(start, end);
      // // console.log('componentDidUpdate editedCellsPosition', editedCellsPosition);
      // // console.log('componentDidUpdate _cache', this._cache);
      // editedCellsPosition.forEach(({ i: row, j: col }) => {
      // this._cache.clear(row, col);
      // this.grid.recomputeGridSize({ columnIndex: col, rowIndex: row });
      // this.grid.invalidateCellSizeAfterRender({ columnIndex: col, rowIndex: row });
      // });
      // // console.log('componentDidUpdate _cache', this._cache);
      // this._cache.clearAll();
      // console.timeEnd('prevProps.data !== this.props.data');
      this._cache.clearAll();
    }
    /* MultiGrid for some reason, only re-render the top-left <Grid> when DataSheet state changes
       Might be similar: https://github.com/bvaughn/react-virtualized/issues/1108 */
    else if (this.grid) {
      this.grid.forceUpdateGrids();
    }
  }

  handleCopy(e) {
    if (isEmpty(this.state.editing)) {
      e.preventDefault();
      const { dataRenderer, valueRenderer, data } = this.props;
      const { start, end } = this.getState();

      if (this.props.handleCopy) {
        this.props.handleCopy({
          event: e,
          dataRenderer,
          valueRenderer,
          data,
          start,
          end,
          range,
        });
      } else {
        const text = range(start.i, end.i)
          .map(i =>
            range(start.j, end.j)
              .map(j => {
                const cell = data[i][j];
                // const value = dataRenderer ? dataRenderer(cell, i, j) : null;
                const value = null;
                if (
                  value === '' ||
                  value === null ||
                  typeof value === 'undefined'
                ) {
                  return valueRenderer(cell, i, j);
                }
                return value;
              })
              .join('\t'),
          )
          .join('\n');
        if (window.clipboardData && window.clipboardData.setData) {
          window.clipboardData.setData('Text', text);
        } else {
          e.clipboardData.setData('text/plain', text);
        }
      }
    }
  }

  _cellRenderer({
    key, rowIndex, columnIndex, style, parent,
  }) {
    const {
      data,
      attributesRenderer,
      cellRenderer,
      valueRenderer,
      dataRenderer,
      valueViewer,
      dataEditor,
    } = this.props;
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div style={style}>
          <DataCell
            attributesRenderer={attributesRenderer}
            cell={data[rowIndex][columnIndex]}
            cellRenderer={cellRenderer}
            clearing={this.isClearing(rowIndex, columnIndex)}
            col={columnIndex}
            dataEditor={dataEditor}
            dataRenderer={dataRenderer}
            editing={this.isEditing(rowIndex, columnIndex)}
            // editing={(rowIndex > 0 && data[rowIndex][columnIndex]?.type === 'check') || this.isEditing(rowIndex, columnIndex)}
            forceEdit={this.state.forceEdit}
            onChange={this.onChange}
            onContextMenu={this.onContextMenu}
            onDoubleClick={this.onDoubleClick}
            onKey={this.handleKey}
            onMouseDown={this.onMouseDown}
            onMouseOver={this.onMouseOver}
            onNavigate={this.handleKeyboardCellMovement}
            onRevert={this.onRevert}
            row={rowIndex}
            selected={this.isSelected(rowIndex, columnIndex)}
            valueRenderer={valueRenderer}
            valueViewer={valueViewer}
          />
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const {
      data,
      fixedColumnCount = 0,
      fixedRowCount = 1,
      scrollToColumn = -1,
      scrollToRow = -1,
      scrollToAlignment,
      scrollTop,
      scrollLeft,
      onScroll,
    } = this.props;
    // const firstRowLength = data[0].length;
    // const totalLength = _.get(data, '[1].length', firstRowLength);
    return (
      <div
        className="data-grid-container"
        onBlur={() => {
          if (this.grid) {
            this.grid.forceUpdateGrids();
          }
        }}
        onKeyDown={this.handleKey.bind(this)}
        ref={(r) => { this.dgDom = r; }}
        style={{ height: '100%', width: '100%' }}
        tabIndex="0"
      >
        <AutoSizer>
          {({ width: sheetWidth, height: sheetHeight }) => (
            <div className="data-grid">
              <MultiGrid
                cellRenderer={this._cellRenderer}
                columnCount={data[0].length}
                columnWidth={this._cache.columnWidth}
                data={data}
                deferredMeasurementCache={this._cache}
                fixedColumnCount={fixedColumnCount}
                fixedRowCount={fixedRowCount}
                height={sheetHeight}
                hideBottomLeftGridScrollbar
                hideTopRightGridScrollbar
                onScroll={onScroll}
                overscanColumnCount={2}
                overscanRowCount={2}
                ref={(ref) => { this.grid = ref; }}
                rowCount={data.length}
                rowHeight={this._cache.rowHeight}
                scrollLeft={scrollLeft}
                scrollToAlignment={scrollToAlignment}
                scrollToColumn={scrollToColumn}
                scrollTop={scrollTop}
                scrollToRow={scrollToRow}
                width={sheetWidth}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

VirtualizedCellMeasurerDataSheet.propTypes = {
  ...VirtualizedCellMeasurerDataSheet.propTypes,
  sheetRenderer: PropTypes.any,
  rowRenderer: PropTypes.any,
};

VirtualizedCellMeasurerDataSheet.defaultProps = {
  ...VirtualizedCellMeasurerDataSheet.defaultProps,
  sheetRenderer: null, // Not use
  rowRenderer: null, // Not use
  cellRenderer: DefaultCellRenderer,
  dataEditor: DefaultDataEditor,
  dataRenderer: DefaultDataRenderer,
  valueRenderer: DefaultValueRenderer,
  valueViewer: DefaultValueViewer,
  minHeight: 40,
  minWidth: 40,
};

export {
  DefaultCellRenderer,
  DefaultDataEditor,
  DefaultDataRenderer,
  DefaultValueRenderer,
  DefaultValueViewer,
};
