export const payrollPeriodAdd = `mutation payrollPeriodAdd($payday: DateTime!, $administrativePeriod: Int!, $key: String, $type: PayrollPeriodType!, $paysheetType: String!, $name: String, $start: DateTime, $end: DateTime, $incidentsEnd: DateTime, $incidentsStart: DateTime, $parentOrdinaryPeriod: String, $accumulationMonth: AccumulationMonthInput) {
  payrollPeriodAdd(start: $start, end: $end, payday: $payday, administrativePeriod: $administrativePeriod, key: $key, type: $type, paysheetType: $paysheetType, name: $name, incidentsEnd: $incidentsEnd, incidentsStart: $incidentsStart, parentOrdinaryPeriod: $parentOrdinaryPeriod, accumulationMonth: $accumulationMonth) {
    administrativePeriod
    end
    id
    key
    payday
    start
    type
    name
    incidentsEnd
    incidentsStart
    parentOrdinaryPeriod {
      id
      name
      __typename
    }
    accumulationMonth {
      month
      year
      __typename
    }
    __typename
  }
}
`