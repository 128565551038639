/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPostRequest } from '../../helpers';

export interface JobsState {
  count: number;
  entities: string[];
  isLoading: boolean;
}

const initialState: JobsState = {
  count: 0,
  entities: [],
  isLoading: false,
};

export const fetchJobs = createAsyncThunk('getJobs/fetchJobs', async () => {
  const { data: dataResponse, status } = await getPostRequest(
    'v2/job/all',
    null
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
  }
  if ((status === 201 || status === 200) && !errors) {
    return data;
  }
  return errors;
});

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    cleanJobs: (state) => {
      state.count = 0;
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchJobs.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = payload;
        }
      )
      .addCase(fetchJobs.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanJobs } = jobsSlice.actions;

export default jobsSlice.reducer;
