/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getDeleteRequest, getPostRequest, getPutRequest } from '../../helpers';
import { Deadlock, DeadlockInput, DeadlocksFilter } from '../../types';

export interface DeadlocksState {
  count: number;
  entities: Record<string, Deadlock>;
  entity: Deadlock | null;
  isLoading: boolean;
}

const initialState: DeadlocksState = {
  count: 0,
  entities: {},
  isLoading: false,
  entity: null,
};

export const fetchDeadlocks = createAsyncThunk(
  'queries/fetchDeadlocks',
  async (filters: DeadlocksFilter) => {

    const { data: dataResponse, status } = await getPostRequest(
      'v2/deadlock/all',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const fetchSingleDeadlocks = async (filters: DeadlocksFilter) => new Promise<Deadlock[]>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getPostRequest(
    'v2/deadlock/one',
    filters,
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')));
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }
  reject(errors);
});

export const createDeadlock = async (input: DeadlockInput) => new Promise<Deadlock>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getPostRequest(
    'v2/deadlock/add',
    input
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')));
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }
  reject(errors);
});

// export const createDeadlock = createAsyncThunk(
//   'mutations/createDeadlock',
//   async (input: DeadlockInput) => {

//     const { data: dataResponse, status } = await getPostRequest(
//       'v2/deadlock/add',
//       input
//     );
//     const { data, errors } = dataResponse;
//     if (status === 400 && Array.isArray(errors)) {
//       throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
//     }
//     if ((status === 201 || status === 200) && !errors) {
//       return data;
//     }
//     return errors;
//   }
// );

export const editDeadlock = async (input: Deadlock) => new Promise<Deadlock>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getPutRequest(
    'v2/deadlock/edit',
    input
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')))
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }
  reject(errors);
});

// export const editDeadlock = createAsyncThunk(
//   'mutations/editDeadlock',
//   async (input: Deadlock) => {

//     const { data: dataResponse, status } = await getPutRequest(
//       'v2/deadlock/edit',
//       input
//     );
//     const { data, errors } = dataResponse;
//     if (status === 400 && Array.isArray(errors)) {
//       throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
//     }
//     if ((status === 201 || status === 200) && !errors) {
//       return data;
//     }
//     return errors;
//   }
// );

export const deleteDeadlock = async (input: Deadlock) => new Promise<Deadlock>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getDeleteRequest(
    `v2/deadlock/delete/${input.id}`,
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')));
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }

  reject(errors);
});

// export const deleteDeadlock = createAsyncThunk(
//   'mutations/deleteDeadlock',
//   async (input: Deadlock) => {

//     const { data: dataResponse, status } = await getDeleteRequest(
//       `v2/deadlock/delete/${input.id}`,
//     );
//     const { data, errors } = dataResponse;
//     if (status === 400 && Array.isArray(errors)) {
//       throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
//     }
//     if ((status === 201 || status === 200) && !errors) {
//       return data;
//     }
//     return errors;
//   }
// );

export const completeDeadlock = async (input: Deadlock) => new Promise<Deadlock>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getPostRequest(
    `v2/deadlock/complete/${input.id}`,
    input
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')));
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }

  reject(errors);
});

// export const completeDeadlock = createAsyncThunk(
//   'mutations/completeDeadlock',
//   async (input: Deadlock) => {

//     const { data: dataResponse, status } = await getPostRequest(
//       `v2/deadlock/complete/${input.id}`,
//       input
//     );
//     const { data, errors } = dataResponse;
//     if (status === 400 && Array.isArray(errors)) {
//       throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
//     }
//     if ((status === 201 || status === 200) && !errors) {
//       return data;
//     }
//     return errors;
//   }
// );

export const rejectDeadlock = async (input: Deadlock) => new Promise<Deadlock>(async (resolve, reject) => {
  const { data: dataResponse, status } = await getPostRequest(
    `v2/deadlock/reject/${input.id}`,
    input
  );
  const { data, errors } = dataResponse;
  if (status === 400 && Array.isArray(errors)) {
    reject(new Error(errors.map(({ detail }) => detail).join('\n\n')));
  }
  if ((status === 201 || status === 200) && !errors) {
    resolve(data);
  }

  reject(errors);
});

// export const rejectDeadlock = createAsyncThunk(
//   'mutations/rejectDeadlock',
//   async (input: Deadlock) => {

//     const { data: dataResponse, status } = await getPostRequest(
//       `v2/deadlock/reject/${input.id}`,
//       input
//     );
//     const { data, errors } = dataResponse;
//     if (status === 400 && Array.isArray(errors)) {
//       throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
//     }
//     if ((status === 201 || status === 200) && !errors) {
//       return data;
//     }
//     return errors;
//   }
// );

export const deadlocksSlice = createSlice({
  name: 'deadlocks',
  initialState,
  reducers: {
    cleanDeadlocks: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeadlocks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchDeadlocks.fulfilled,
        (state, action: PayloadAction<Deadlock[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
        }
      )
      .addCase(fetchDeadlocks.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanDeadlocks } = deadlocksSlice.actions;

export default deadlocksSlice.reducer;
