import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import { setSocketStatus } from '../store/slices';
import handleParsedData from './handleParsedData';

let stopTimeout : NodeJS.Timeout;

const useWSHook = () => {
  const token = localStorage.getItem('access_token');
  const socketUrl = `wss://wss.levita.app/listen?token=${token}`;

  const dispatch = useDispatch();

  const {
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    reconnectInterval: 100,
    reconnectAttempts: 20,
    retryOnError: true,
  });

  useEffect(() => {
    dispatch(setSocketStatus(readyState));
  }, [readyState, dispatch])

  useEffect(() => {
    if (lastMessage) {
      if (stopTimeout) {
        clearTimeout(stopTimeout)
      }
      stopTimeout = setTimeout(() => {
        getWebSocket()?.close();
      }, 15000)
      const parsedData = JSON.parse(lastMessage?.data)
      handleParsedData(parsedData, dispatch);
    }
  }, [lastMessage, dispatch, readyState, getWebSocket])

}

export default useWSHook;
