export const dismissalTypes = `query dismissalTypes {
  dismissalTypes {
    id
    description
    dismissalCauses {
      id
      description
      __typename
    }
    __typename
  }
}
`