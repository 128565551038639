import { Pagination, WhereClause } from './request';

export interface ClientPendingTask {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  clientId: string;
  payrollTypeId?: string;
  payrollPeriodId?: string;
  employeeId?: string;
  periodName?: string;
  employeeName?: string;
  title: ClientPendingTaskTitle;
  description: string;
  url: string;
  type: ClientPendingTaskType;
  section?: ClientPendingTaskSection;
}

export enum ClientPendingTaskTitle {
  CPTTi_MY_EMPLOYEES = "Mis Empleados",
  CPTTi_EMPLOYEES_REGISTRATION = "Alta de Empleados",
  CPTTi_EMPLOYEES_DEACTIVATION = "Desactivar Empleados",
  CPTTi_EMPLOYEES_REACTIVATION = "Reactivar Empleados",
  CPTTi_BANK_ACCOUNT_VERIFICATION = "Cuentas de Banco",
  CPTTi_PENDING_PERIODS = "Periodos y Archivos",
}

export enum ClientPendingTaskType {
  CPTT_MY_EMPLOYEES = "MY_EMPLOYEES",
  CPTT_EMPLOYEES_REGISTRATION = "EMPLOYEES_REGISTRATION",
  CPTT_EMPLOYEES_DEACTIVATION = "EMPLOYEES_DEACTIVATION",
  CPTT_EMPLOYEES_REACTIVATION = "EMPLOYEES_REACTIVATION",
  CPTT_BANK_ACCOUNT_VERIFICATION = "BANK_ACCOUNT_VERIFICATION",
  CPTT_PENDING_PERIODS = "PENDING_PERIODS",
}

export enum ClientPendingTaskSection {
  CPTS_CURRENT_CALCULATE = "CURRENT_CALCULATE",
  CPTS_CURRENT_AUTHORIZE = "CURRENT_AUTHORIZE",
  CPTS_CURRENT_CAPTURE = "CURRENT_CAPTURE",
  CPTS_CURRENT_BILLING = "CURRENT_BILLING",
  CPTS_CURRENT_PROGRAM = "CURRENT_PROGRAM",
  CPTS_CURRENT_STAMP = "CURRENT_STAMP",
  CPTS_PREVIOUS_CALCULATE = "PREVIOUS_CALCULATE",
  CPTS_PREVIOUS_AUTHORIZE = "PREVIOUS_AUTHORIZE",
  CPTS_PREVIOUS_PROGRAM = "PREVIOUS_PROGRAM",
  CPTS_PREVIOUS_STAMP = "PREVIOUS_STAMP",
  CPTS_PREVIOUS_CAPTURE = "PREVIOUS_CAPTURE",
  CPTS_PREVIOUS_BILLING = "PREVIOUS_BILLING",
  CPTS_FUTURE_AUTHORIZE = "FUTURE_AUTHORIZE",
  CPTS_FUTURE_PROGRAM = "FUTURE_PROGRAM",
  CPTS_FUTURE_STAMP = "FUTURE_STAMP",
  CPTS_FUTURE_CAPTURE = "FUTURE_CAPTURE",
  CPTS_FUTURE_BILLING = "FUTURE_BILLING",
}

export interface ClientPendingTaskInput {
  clientId?: string;
  payrollPeriodId?: string;
  employeeId?: string;
  payrollTypeId?: string;
  periodName?: string;
  employeeName?: string;
  type?: ClientPendingTaskType;
  section?: ClientPendingTaskSection;
}

export interface ClientPendingTaskFilters {
  id?: string;
  pagination?: Pagination;
  type?: WhereClause;
  section?: WhereClause;
  clientId?: WhereClause;
  payrollTypeId?: WhereClause;
  payrollPeriodId?: WhereClause;
  employeeId?: WhereClause;
  title?: WhereClause;
}