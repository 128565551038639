export interface PayrollPeriodDocuments {
  reciboPagoCliente: string;
  layoutsBancos: string[];
  comprobantesDispersion: string[];
  facturas: string[];
  otros: string[];
}

export interface BillingInformation {
  billingDate?: string;
  stampingDate?: string;
  programmingDate?: string;
  programmed?: boolean;
  totalInvoiceAmount: number;
}

export interface DispersionInformation {
  programmingDate?: string;
  date?: string;
  totalAmount?: number;
}

export interface PrePayrollTracker {
  completedAt?: string;
  completedBy: string;
  completedByEntity: string;
}

export interface ApprovePayrollTracker {
  status?: string;
  completedBy?: string;
  completedByEntity?: string;
  vouchers: string[];
  voucher?: string;
  approvedAt?: string;
}

export interface PerceptionModifiersPeriodConfiguration {
  modifierType: string;
  amount: number;
  percentagePaidByWorker: number;
  percentagePaidByEmployer: number;
  percentagePaidByIntela: number;
  percentagePaidByCommissionAgent: number;
}

export interface AccumulationMonth {
  month: number;
  year: number;
}

export interface CatalogsStamp {
  fiscalRegime: string;
  paymentMethod: string;
  paymentMethodType: string;
  useCFDI: string;
}

export interface PayrollPeriod {
  id: string;
  autoStamp?: boolean,
  catalogsStamp?: CatalogsStamp | null,
  createdAt?: string;
  updatedAt?: string;
  deleted_at?: string;
  company?: string;
  client?: string;
  paysheetType?: string;
  frequency?: string;
  payrollSchema?: PayrollScheme;
  name?: string;
  start?: string;
  end?: string;
  isClosed?: boolean;
  incidentsStart?: string;
  incidentsEnd?: string;
  payday?: string;
  administrativePeriod?: number;
  key?: string;
  type?: PayrollPeriodType;
  approvedAt?: string;
  approvedBy?: ApprovedType;
  prePayrolls?: number;
  isAuthorized?: boolean;
  payrollStatus?: PayrollStatus;
  invoiceStatus?: InvoiceStatus;
  dispersionStatus?: DispersionStatus;
  version?: number;
  calculationDate?: string;
  clientApproval?: boolean;
  billingInformation: BillingInformation;
  dispersionInformation: DispersionInformation;
  documents: PayrollPeriodDocuments;
  emailTracker: EmailTracker;
  prePayrollTracker: PrePayrollTracker;
  parentOrdinaryPeriod?: string;
  payrollApproveEmailTracker: EmailTracker;
  payrollApproveTracker: ApprovePayrollTracker;
  prePayrollComment: string;
  payrollComment: string;
  perceptionModifiers: PerceptionModifiersPeriodConfiguration[];
  accumulationMonth: AccumulationMonth;
  employeeStampStatus: EmployeeStampStatus;
  noministaExtraordinario: string;
  calculatedBy?: string;
  receiptsEmailTracker: EmailTracker;
  invoiceEmailTracker: EmailTracker;
}

export interface PayrollPeriodEdit {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  deleted_at?: string;
  company?: string;
  client?: string;
  paysheetType?: string;
  frequency?: string;
  payrollSchema?: PayrollScheme;
  name?: string;
  start?: string;
  end?: string;
  isClosed?: boolean;
  incidentsStart?: string;
  incidentsEnd?: string;
  payday?: string;
  administrativePeriod?: number;
  key?: string;
  type?: PayrollPeriodType;
  approvedAt?: string;
  approvedBy?: ApprovedType;
  prePayrolls?: number;
  isAuthorized?: boolean;
  payrollStatus?: PayrollStatus;
  invoiceStatus?: InvoiceStatus;
  dispersionStatus?: DispersionStatus;
  version?: number;
  calculationDate?: string;
  clientApproval?: boolean;
  autoStamp?: boolean,
  catalogsStamp?: CatalogsStamp | null,
  billingInformation?: BillingInformation;
  dispersionInformation?: DispersionInformation;
  documents?: PayrollPeriodDocuments;
  emailTracker?: EmailTracker;
  prePayrollTracker?: PrePayrollTracker;
  parentOrdinaryPeriod?: string;
  payrollApproveEmailTracker?: EmailTracker;
  payrollApproveTracker?: ApprovePayrollTracker;
  prePayrollComment?: string;
  payrollComment?: string;
  perceptionModifiers?: PerceptionModifiersPeriodConfiguration[];
  accumulationMonth?: AccumulationMonth;
  employeeStampStatus?: EmployeeStampStatus;
  noministaExtraordinario?: string;
  calculatedBy?: string;
  receiptsEmailTracker?: EmailTracker;
  invoiceEmailTracker?: EmailTracker;
}

export interface EmailTracker {
  firstOpenedAt: null;
  lastOpenedAt: Date;
  sentAt: null;
}
export interface SortFilters {
  payday?: 1 | -1;
  createdAt?: 1 | -1;
  start?: 1 | -1;
}

export interface PeriodFilters {
  client?: string;
  sort?: SortFilters;
}

export enum PayrollStatus {
  Authorized = "Autorizada",
  Closed = "Cerrada",
  Pendiente = "Pendiente",
  Prenomina = "Prenómina",
  Worked = "Trabajada",
  Approved = "Pre-nómina aprobada",
}

export enum DispersionStatus {
  Empty = "",
  Dispersada = "Dispersada",
  PendientePorDispersar = "Pendiente por dispersar",
  Programada = "Programada",
  Rechazada = "Rechazada",
  Cancelado = "cancelado",
}

export enum PayrollScheme {
  SchemaTypeSYS = "SYS",
  SchemaTypeSYSPPPS = "SYS + PPPS",
  SchemaTypePPPS = "PPPS",
  SchemaTypeAsimilados = "Asimilados",
  SchemaTypeNom = "Nom. 035",
  SchemaTypeTradicional = "Tradicional",
  SchemaTypeFiniquito = "Finiquito",
}

export enum InvoiceStatus {
  InvoiceStatusEmpty = "",
  InvoiceStatusPending = "Pendiente",
  InvoiceStatusRequested = "Factura solicitada",
  InvoiceStatusInvoiced = "Facturado",
  InvoiceStatusNoInvoice = "No requiere factura",
  InvoiceStatusChargeSocialCost = "Cobrar costo social",
  InvoiceStatusPPPS = "Nóminas de PPPS",
}

export enum EmployeeStampStatus {
  PendientePorTimbrar = "Pendiente por Timbrar",
  Timbrada = "Timbrada",
  TimbradaParcialmente = "Timbrada Parcialmente",
}

export enum PayrollPeriodType {
  Ordinary = "Ordinario",
  Extraordinary = "Extraordinario",
  Settlement = "Finiquito",
  Bonus = "Aguinaldo",
  Utility = "PTU"
}

export enum ApprovedType {
  ApprovedByZeroValue = -1,
  ApprovedByClient = 1,
  ApprovedByADC = 2,
}

export enum PayrollPeriodStage {
  _1_Initial = 1,
  _2_PrePayroll = 2,
  _3_PrePayrollApprovedByUser = 3,
  _4_PrePayrollApprovedByClient = 4,
  _5_PayrollCalculated = 5,
  _6_PayrollAuthorized = 6,
  _7_PayrollClosed = 7,
  _8_PayrollAuthorizedButNotApproved = 8,
  _9_PayrollClosedButNotApproved = 9,
  _10_PayrollAuthorizedAndApprovedByClient = 10,
  _11_PayrollClosedAndApproved = 11,
  _12_PayrollProgrammed = 12,
  _13_PayrollPendingToDisperse = 13,
  _14_PayrollDispersionRejected = 14,
  _15_PayrollDispersionApproved = 15,
  _16_PayrollPendingToStamp = 16,
  _17_PayrollPartiallyStamped = 17,
  _18_PayrollStamped = 18,
}