import React, { useEffect, useState } from 'react'

import { AccountBalance, AddCircleOutline, BadgeOutlined, CalendarToday, ExpandLess, ExpandMore, Notifications, RemoveCircleOutline, SyncAltOutlined } from '@mui/icons-material';
import { Avatar, Chip, Collapse, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import _ from 'lodash';

import { ListItemSkeleton } from '../../components';
import { VerticalList } from '../../containers';
import { myGet } from '../../helpers';
import { useAppSelector } from '../../hooks';
import { ClientPendingTaskSection, ClientPendingTaskType, PendingTaskListToggles, PendingTasksList } from '../../types';

const NotificationList = () => {

  const pendingTasksState = useAppSelector(({ pendingTasks }) => (pendingTasks))
  const selectedClientId = useAppSelector(({ clients }) => (clients.selected))

  const [pendingTasks, setpendingTasks] = useState<PendingTasksList>({} as PendingTasksList);
  const fullClients = useAppSelector((state) => state.fullClients.entities)
  const selectedClient = fullClients[selectedClientId]
  const globalPermissions = selectedClient?.globalPermissions

  useEffect(() => {
    if (!pendingTasksState.isLoading) {
      if (Object.keys(pendingTasksState.entities).length !== 0) {

        const filteredByClient = Object.values(pendingTasksState.entities).filter((val) => {
          if (val.clientId === selectedClientId) {
            return true
          }

          return false;
        })

        const taskListGrouped = _.groupBy(filteredByClient, 'type')

        const myEmployees = myGet(globalPermissions, 'myEmployeesModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_MY_EMPLOYEES] || []) : [];
        const bankAccount = myGet(globalPermissions, 'bankAccountsModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_BANK_ACCOUNT_VERIFICATION] || []) : [];
        const employeeReactivation = myGet(globalPermissions, 'employeeReentryModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_EMPLOYEES_REACTIVATION] || []) : [];
        const employeeRegistration = myGet(globalPermissions, 'employeeEntryModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_EMPLOYEES_REGISTRATION] || []) : [];
        const employeeDeactivation = myGet(globalPermissions, 'employeeDismissModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_EMPLOYEES_DEACTIVATION] || []) : [];
        const periods = myGet(globalPermissions, 'periodsModule.enabled', false) ? (taskListGrouped[ClientPendingTaskType.CPTT_PENDING_PERIODS] || []) : [];

        const periodsTaskListGroupedBySection = _.groupBy(periods, 'section')

        const newPendingTasks: PendingTasksList = {
          MyEmployees: myEmployees,
          BankAccount: bankAccount,
          EmployeeDeactivation: employeeDeactivation,
          EmployeeReactivation: employeeReactivation,
          EmployeeRegistration: employeeRegistration,
          Periods: periods,
          PeriodsMap: {
            PreviousCount: 0,
            PreviousMap: {
              Calculate: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_CALCULATE] || [],
              Authorize: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_AUTHORIZE] || [],
              Program: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_PROGRAM] || [],
              Stamp: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_STAMP] || [],
              Capture: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_CAPTURE] || [],
              Billing: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_PREVIOUS_BILLING] || [],
            },
            CurrentCount: 0,
            CurrentMap: {
              Calculate: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_CALCULATE] || [],
              Authorize: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_AUTHORIZE] || [],
              Program: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_PROGRAM] || [],
              Stamp: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_STAMP] || [],
              Capture: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_CAPTURE] || [],
              Billing: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_CURRENT_BILLING] || [],
            },
            FutureCount: 0,
            FutureMap: {
              Authorize: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_FUTURE_AUTHORIZE] || [],
              Program: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_FUTURE_PROGRAM] || [],
              Stamp: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_FUTURE_STAMP] || [],
              Capture: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_FUTURE_CAPTURE] || [],
              Billing: periodsTaskListGroupedBySection[ClientPendingTaskSection.CPTS_FUTURE_BILLING] || [],
            },
          }
        }

        newPendingTasks.PeriodsMap.PreviousCount = newPendingTasks.PeriodsMap.PreviousMap.Calculate.length +
          newPendingTasks.PeriodsMap.PreviousMap.Authorize.length +
          newPendingTasks.PeriodsMap.PreviousMap.Program.length +
          newPendingTasks.PeriodsMap.PreviousMap.Stamp.length +
          newPendingTasks.PeriodsMap.PreviousMap.Capture.length +
          newPendingTasks.PeriodsMap.PreviousMap.Billing.length;

        newPendingTasks.PeriodsMap.CurrentCount = newPendingTasks.PeriodsMap.CurrentMap.Calculate.length +
          newPendingTasks.PeriodsMap.CurrentMap.Authorize.length +
          newPendingTasks.PeriodsMap.CurrentMap.Program.length +
          newPendingTasks.PeriodsMap.CurrentMap.Stamp.length +
          newPendingTasks.PeriodsMap.CurrentMap.Capture.length +
          newPendingTasks.PeriodsMap.CurrentMap.Billing.length;

        newPendingTasks.PeriodsMap.FutureCount = newPendingTasks.PeriodsMap.FutureMap.Authorize.length +
          newPendingTasks.PeriodsMap.FutureMap.Program.length +
          newPendingTasks.PeriodsMap.FutureMap.Stamp.length +
          newPendingTasks.PeriodsMap.FutureMap.Capture.length +
          newPendingTasks.PeriodsMap.FutureMap.Billing.length;

        setpendingTasks(newPendingTasks)
      }
    }
  }, [pendingTasksState, selectedClientId, globalPermissions]);

  const [pendingTaskListToggles, setpendingTaskListToggles] = useState<PendingTaskListToggles>({
    PeriodsMap: {
      CurrentMap: {},
      PreviousMap: {},
      FutureMap: {},
    },
  } as PendingTaskListToggles);

  return (
    <VerticalList

      icon={
        <Notifications style={{ fontSize: '2rem', color: '#03b7d2' }} />
      }
      style={{ minHeight: 500, maxHeight: 650 }}
      styleContainer={{
        background: '#dfeef2',
      }}
      title="Pendientes"
    >
      {pendingTasksState.isLoading ? (
        <>
          <ListItemSkeleton hasIcon />
          <ListItemSkeleton hasIcon />
          <ListItemSkeleton hasIcon />
          <ListItemSkeleton hasIcon />
          <ListItemSkeleton hasIcon />
        </>
      ) : (
        <>
          {(pendingTasks.MyEmployees || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, MyEmployees: !pendingTaskListToggles.MyEmployees }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.MyEmployees || []).length} />
                }

              >
                <ListItemIcon>
                  <BadgeOutlined />
                </ListItemIcon>
                {pendingTaskListToggles.MyEmployees ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Mis Empleados" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.MyEmployees} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(pendingTasks.MyEmployees || []).map((task) => (
                    <ListItemButton
                      key={task.id}
                      onClick={() => {
                        if (task.url !== "") {
                          window.open(task.url, '_self')
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                          <BadgeOutlined />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={task.title} secondary={task.description} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {(pendingTasks.EmployeeRegistration || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, EmployeeRegistration: !pendingTaskListToggles.EmployeeRegistration }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.EmployeeRegistration || []).length} />
                }

              >
                <ListItemIcon>
                  <AddCircleOutline />
                </ListItemIcon>
                {pendingTaskListToggles.EmployeeRegistration ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Alta de Empleados" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.EmployeeRegistration} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(pendingTasks.EmployeeRegistration || []).map((task) => (
                    <ListItemButton
                      key={task.id}
                      onClick={() => {
                        if (task.url !== "") {
                          window.open(task.url, '_self')
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                          <AddCircleOutline />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={task.title} secondary={task.description} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {(pendingTasks.EmployeeReactivation || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, EmployeeReactivation: !pendingTaskListToggles.EmployeeReactivation }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.EmployeeReactivation || []).length} />
                }

              >
                <ListItemIcon>
                  <SyncAltOutlined />
                </ListItemIcon>
                {pendingTaskListToggles.EmployeeReactivation ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Reactivar Empleados" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.EmployeeReactivation} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(pendingTasks.EmployeeReactivation || []).map((task) => (
                    <ListItemButton
                      key={task.id}
                      onClick={() => {
                        if (task.url !== "") {
                          window.open(task.url, '_self')
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                          <SyncAltOutlined />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={task.title} secondary={task.description} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {(pendingTasks.EmployeeDeactivation || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, EmployeeDeactivation: !pendingTaskListToggles.EmployeeDeactivation }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.EmployeeDeactivation || []).length} />
                }

              >
                <ListItemIcon>
                  <RemoveCircleOutline />
                </ListItemIcon>
                {pendingTaskListToggles.EmployeeDeactivation ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Desactivar Empleados" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.EmployeeDeactivation} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(pendingTasks.EmployeeDeactivation || []).map((task) => (
                    <ListItemButton
                      key={task.id}
                      onClick={() => {
                        if (task.url !== "") {
                          window.open(task.url, '_self')
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                          <RemoveCircleOutline />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={task.title} secondary={task.description} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {(pendingTasks.BankAccount || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, BankAccount: !pendingTaskListToggles.BankAccount }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.BankAccount || []).length} />
                }

              >
                <ListItemIcon>
                  <AccountBalance />
                </ListItemIcon>
                {pendingTaskListToggles.BankAccount ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Cuentas de Banco" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.BankAccount} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(pendingTasks.BankAccount || []).map((task) => (
                    <ListItemButton
                      key={task.id}
                      onClick={() => {
                        if (task.url !== "") {
                          window.open(task.url, '_self')
                        }
                      }}
                    >

                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                          <AccountBalance />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={task.title} secondary={task.description} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          )}

          {(pendingTasks.Periods || []).length > 0 && (
            <>
              <ListItem
                button
                onClick={() => { setpendingTaskListToggles({ ...pendingTaskListToggles, Periods: !pendingTaskListToggles.Periods }) }}
                secondaryAction={
                  <Chip color='error' label={(pendingTasks.Periods || []).length > 99 ? '99+' : (pendingTasks.Periods || []).length} />
                }

              >
                <ListItemIcon>
                  <CalendarToday />
                </ListItemIcon>
                {pendingTaskListToggles.Periods ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="Periodos y Archivos" />
              </ListItem>
              <Collapse in={pendingTaskListToggles.Periods} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  {pendingTasks.PeriodsMap.CurrentCount > 0 && (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setpendingTaskListToggles({
                            ...pendingTaskListToggles,
                            PeriodsMap: {
                              ...pendingTaskListToggles.PeriodsMap,
                              Current: !pendingTaskListToggles.PeriodsMap.Current
                            }
                          })
                        }}
                        secondaryAction={
                          <Chip color='error' label={pendingTasks.PeriodsMap.CurrentCount} />
                        }
                        sx={{ pl: 4 }}

                      >
                        <ListItemIcon>
                          <CalendarToday />
                        </ListItemIcon>
                        {pendingTaskListToggles.PeriodsMap.Current ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary="Periodos Actuales" />
                      </ListItem>
                      <Collapse in={pendingTaskListToggles.PeriodsMap.Current} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          {pendingTasks.PeriodsMap.CurrentMap.Calculate.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Calculate: !pendingTaskListToggles.PeriodsMap.CurrentMap.Calculate
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Calculate || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Calculate ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Calcular Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Calculate} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Calculate || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.CurrentMap.Authorize.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Authorize: !pendingTaskListToggles.PeriodsMap.CurrentMap.Authorize
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Authorize || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Authorize ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Autorizar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Authorize} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Authorize || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.CurrentMap.Program.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Program: !pendingTaskListToggles.PeriodsMap.CurrentMap.Program
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Program || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Program ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Programar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Program} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Program || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.CurrentMap.Stamp.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Stamp: !pendingTaskListToggles.PeriodsMap.CurrentMap.Stamp
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Stamp || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Stamp ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Timbrar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Stamp} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Stamp || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.CurrentMap.Capture.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Capture: !pendingTaskListToggles.PeriodsMap.CurrentMap.Capture
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Capture || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Capture ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Capturar Comprobantes" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Capture} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Capture || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.CurrentMap.Billing.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      CurrentMap: {
                                        ...pendingTaskListToggles.PeriodsMap.CurrentMap,
                                        Billing: !pendingTaskListToggles.PeriodsMap.CurrentMap.Billing
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.CurrentMap.Billing || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.CurrentMap.Billing ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Facturar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.CurrentMap.Billing} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.CurrentMap.Billing || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                        </List>
                      </Collapse>
                    </>
                  )}

                  {pendingTasks.PeriodsMap.PreviousCount > 0 && (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setpendingTaskListToggles({
                            ...pendingTaskListToggles,
                            PeriodsMap: {
                              ...pendingTaskListToggles.PeriodsMap,
                              Previous: !pendingTaskListToggles.PeriodsMap.Previous
                            }
                          })
                        }}
                        secondaryAction={
                          <Chip color='error' label={pendingTasks.PeriodsMap.PreviousCount} />
                        }
                        sx={{ pl: 4 }}

                      >
                        <ListItemIcon>
                          <CalendarToday />
                        </ListItemIcon>
                        {pendingTaskListToggles.PeriodsMap.Previous ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary="Periodos Pasados" />
                      </ListItem>
                      <Collapse in={pendingTaskListToggles.PeriodsMap.Previous} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          {pendingTasks.PeriodsMap.PreviousMap.Calculate.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Calculate: !pendingTaskListToggles.PeriodsMap.PreviousMap.Calculate
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Calculate || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Calculate ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Calcular Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Calculate} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Calculate || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.PreviousMap.Authorize.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Authorize: !pendingTaskListToggles.PeriodsMap.PreviousMap.Authorize
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Authorize || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Authorize ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Autorizar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Authorize} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Authorize || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.PreviousMap.Program.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Program: !pendingTaskListToggles.PeriodsMap.PreviousMap.Program
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Program || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Program ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Programar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Program} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Program || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.PreviousMap.Stamp.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Stamp: !pendingTaskListToggles.PeriodsMap.PreviousMap.Stamp
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Stamp || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Stamp ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Timbrar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Stamp} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Stamp || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.PreviousMap.Capture.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Capture: !pendingTaskListToggles.PeriodsMap.PreviousMap.Capture
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Capture || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Capture ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Capturar Comprobantes" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Capture} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Capture || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.PreviousMap.Billing.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      PreviousMap: {
                                        ...pendingTaskListToggles.PeriodsMap.PreviousMap,
                                        Billing: !pendingTaskListToggles.PeriodsMap.PreviousMap.Billing
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.PreviousMap.Billing || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.PreviousMap.Billing ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Facturar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.PreviousMap.Billing} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.PreviousMap.Billing || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                        </List>
                      </Collapse>
                    </>
                  )}

                  {pendingTasks.PeriodsMap.FutureCount > 0 && (
                    <>
                      <ListItem
                        button
                        onClick={() => {
                          setpendingTaskListToggles({
                            ...pendingTaskListToggles,
                            PeriodsMap: {
                              ...pendingTaskListToggles.PeriodsMap,
                              Future: !pendingTaskListToggles.PeriodsMap.Future
                            }
                          })
                        }}
                        secondaryAction={
                          <Chip color='error' label={pendingTasks.PeriodsMap.FutureCount} />
                        }
                        sx={{ pl: 4 }}

                      >
                        <ListItemIcon>
                          <CalendarToday />
                        </ListItemIcon>
                        {pendingTaskListToggles.PeriodsMap.Future ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary="Periodos Proximos" />
                      </ListItem>
                      <Collapse in={pendingTaskListToggles.PeriodsMap.Future} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                          {pendingTasks.PeriodsMap.FutureMap.Authorize.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      FutureMap: {
                                        ...pendingTaskListToggles.PeriodsMap.FutureMap,
                                        Authorize: !pendingTaskListToggles.PeriodsMap.FutureMap.Authorize
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.FutureMap.Authorize || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.FutureMap.Authorize ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Autorizar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.FutureMap.Authorize} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.FutureMap.Authorize || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.FutureMap.Program.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      FutureMap: {
                                        ...pendingTaskListToggles.PeriodsMap.FutureMap,
                                        Program: !pendingTaskListToggles.PeriodsMap.FutureMap.Program
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.FutureMap.Program || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.FutureMap.Program ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Programar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.FutureMap.Program} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.FutureMap.Program || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.FutureMap.Stamp.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      FutureMap: {
                                        ...pendingTaskListToggles.PeriodsMap.FutureMap,
                                        Stamp: !pendingTaskListToggles.PeriodsMap.FutureMap.Stamp
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.FutureMap.Stamp || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.FutureMap.Stamp ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Timbrar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.FutureMap.Stamp} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.FutureMap.Stamp || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.FutureMap.Capture.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      FutureMap: {
                                        ...pendingTaskListToggles.PeriodsMap.FutureMap,
                                        Capture: !pendingTaskListToggles.PeriodsMap.FutureMap.Capture
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.FutureMap.Capture || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.FutureMap.Capture ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Capturar Comprobantes" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.FutureMap.Capture} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.FutureMap.Capture || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {pendingTasks.PeriodsMap.FutureMap.Billing.length > 0 && (
                            <>
                              <ListItem
                                button
                                onClick={() => {
                                  setpendingTaskListToggles({
                                    ...pendingTaskListToggles,
                                    PeriodsMap: {
                                      ...pendingTaskListToggles.PeriodsMap,
                                      FutureMap: {
                                        ...pendingTaskListToggles.PeriodsMap.FutureMap,
                                        Billing: !pendingTaskListToggles.PeriodsMap.FutureMap.Billing
                                      }
                                    }
                                  })
                                }}
                                secondaryAction={
                                  <Chip color='error' label={(pendingTasks.PeriodsMap.FutureMap.Billing || []).length} />
                                }
                                sx={{ pl: 6 }}

                              >
                                <ListItemIcon>
                                  <CalendarToday />
                                </ListItemIcon>
                                {pendingTaskListToggles.PeriodsMap.FutureMap.Billing ? <ExpandLess /> : <ExpandMore />}
                                <ListItemText primary="Facturar Nómina" />
                              </ListItem>
                              <Collapse in={pendingTaskListToggles.PeriodsMap.FutureMap.Billing} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {(pendingTasks.PeriodsMap.FutureMap.Billing || []).map((task) => (
                                    <ListItemButton
                                      key={task.id}
                                      onClick={() => {
                                        if (task.url !== "") {
                                          window.open(task.url, '_self')
                                        }
                                      }}
                                    >

                                      <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                          <CalendarToday />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={task.title} secondary={task.description} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </>
                          )}

                          {/* {(pendingTasks.PeriodsMap.Future || []).map((task) => (
                                <ListItemButton
                                  key={task.id}
                                  onClick={() => {
                                    if (task.url !== "") {
                                      window.open(task.url, '_self')
                                    }
                                  }}
                                  sx={{ pl: 4 }}>

                                  <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: '#BEDEE7', color: 'black' }}>
                                      <CalendarToday />
                                    </Avatar>
                                  </ListItemAvatar>

                                  <ListItemText primary={task.title} secondary={task.description} />
                                </ListItemButton>
                              ))} */}
                        </List>
                      </Collapse>
                    </>
                  )}
                </List>
              </Collapse>
            </>
          )}
        </>
      )}
      {Object.values(pendingTasksState.entities).filter((val) => (val.clientId === selectedClientId)).length === 0 && !pendingTasksState.isLoading ? (
        <>
          <ListItem style={{ textAlign: 'center', fontStyle: 'italic', paddingLeft: 20, paddingRight: 20 }}>
            <ListItemText secondary="Felicidades! No tienes ningun pendiente por el momento..." />
          </ListItem>
        </>
      ) : <></>}
    </VerticalList>
  )
}

export default NotificationList