/* eslint-disable react/require-default-props */
import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

function Loading({
  size,
  color,
}: {
  size?: number;
  color?: CircularProgressProps['color'];
}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress color={color} size={size} />
    </div>
  );
}

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  circularProgressSize?: number;
  circularProgressColor?: CircularProgressProps['color'];
}

export default function LoadingButton({
  loading,
  disabled,
  children,
  circularProgressSize,
  circularProgressColor,
  ...props
}: LoadingButtonProps) {
  return (
    <Button disabled={disabled || loading} {...props}>
      {loading ? (
        <Loading color={circularProgressColor} size={circularProgressSize} />
      ) : null}
      {children}
    </Button>
  );
}
