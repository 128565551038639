import { CSSProperties, FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useEffectOnce, useToggle } from 'react-use';

import { LinearProgress } from '@mui/material';

import { makeAFilePublic, uploadFile } from '../../helpers';
import { useAppDispatch } from '../../hooks'
import { showMessage } from '../../store/slices';
import { UploadFile } from '../../types';

type Props = {
  onChange: (file?: UploadFile) => void,
  onCommit: (file: UploadFile) => void,
  value: UploadFile,
}

const containerStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: '2px',
  right: '2px',
  left: 0,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#62a5d8',
  borderStyle: 'dashed',
  backgroundColor: 'inherit',
  color: 'inherit',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const loadingContainerStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: '2px',
  right: '2px',
  left: 0,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#62a5d8',
  borderStyle: 'dashed',
  backgroundColor: 'inherit',
  color: 'inherit',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const paragraphStyles: CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  margin: 0,
  textAlign: 'center',
}; 

const Dropzone: FC<Props> = ({ onChange, onCommit, value }) : JSX.Element => {
  const [isUploading, toggleIsUploading] = useToggle(false);
  const [files, setFiles] = useState<File[]>([]);
  const dispatch = useAppDispatch();

  const onDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      toggleIsUploading(true);
      uploadFile(acceptedFiles[0])
        .then(makeAFilePublic)
        .then((result) => {
          if (result === null) {
            throw new Error();
          }
          onCommit(result);
        })
        .catch(() => {
          dispatch(showMessage({
            message: 'No se pudo cargar el archivo correctamente',
            type: 'warning',
          }));
        })
        .finally(() => {
          toggleIsUploading(false);
        });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple: false,
    accept: 'image/*, .pdf',
  });

  const fileName = files.length === 0 ? value?.name : files[0].name;

  useEffectOnce(() => {
    onChange(undefined);
  })

  if (isUploading) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div {...getRootProps()} style={loadingContainerStyles}>
        <LinearProgress />
      </div>
    );
  }

  const hasFile = files.length > 0 || !!value;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...getRootProps()} style={isUploading ? loadingContainerStyles : containerStyles}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <p style={paragraphStyles}>
        {hasFile ? fileName : 'Suelte sus archivos aqui'}
      </p>
    </div>
  );
};

export default Dropzone;
