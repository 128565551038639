import { CSSProperties, useEffect, useRef } from 'react';
import ReactDataSheet from 'react-datasheet';

import { Cell, Value } from '../../types';

const DefaultCellRenderer: ReactDataSheet.CellRenderer<Cell, Value> =
  (props) => {
    const {
      attributesRenderer,
      cell,
      children,
      className,
      col,
      // editing,
      onContextMenu,
      onDoubleClick,
      /// onKeyUp,
      onMouseDown,
      onMouseOver,
      row,
      selected,
      style,
      // updated,
    } = props;
    const { type, readOnly } = cell;
    // // console.log('cell renderer props', props);
    // // console.log('cell renderer props');
    // const { colSpan, rowSpan } = cell;
    const attributes = attributesRenderer
      ? attributesRenderer(cell, row, col)
      : {};

    const ref = useRef<HTMLDivElement>(null);

    const handleFocus = () => {
      // ref.current?.scrollIntoView();
    };

    // const handleSelect = () => {
    //   console.log('handleSelect');
    //   ref.current?.scrollIntoView();
    // };

    useEffect(() => {
      if (selected) {
        ref.current?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }, [selected]);

    return (
      <div
        aria-hidden='true'
        className={className}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
        onFocus={handleFocus}
        onMouseDown={onMouseDown}
        // onKeyUp={onKeyUp}
        onMouseEnter={type === 'check' && !readOnly ? onDoubleClick : undefined}
        onMouseOver={onMouseOver}
        // onSelect={handleSelect}
        // onTouchEnd={onDoubleClick}
        ref={ref}
        role='button'
        style={style as CSSProperties}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      >
        {children}
      </div>
    );
  };

export default DefaultCellRenderer;
