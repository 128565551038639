import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

const defaultDelay = 100;

const getPlaceholdersArray = (placeholderOptions: string[]) : string[] => {
  const allPlaceholdersOptions = placeholderOptions.reduce((acc: string[], value) => {
    const placeholdersFromOption = [];
    const size = value.length;
    for (let index = 0; index <= size; index += 1) {
      const substringValue = value.substring(0, index);
      const nextPlaceholder = `${substringValue}|`;
      placeholdersFromOption.push(nextPlaceholder);
    }
    for (let index = 0; index < 10; index += 1) {
      placeholdersFromOption.push(value);
    }
    for (let index = size; index > 0; index -= 3) {
      const substringValue = value.substring(0, index);
      const nextPlaceholder = `${substringValue}|`;
      placeholdersFromOption.push(nextPlaceholder);
    }
    return [
      ...acc,
      ...placeholdersFromOption,
    ];
  }, []);
  return allPlaceholdersOptions;
};

const usePlaceholders = 
(placeholderOptions: string[] = [], basePlaceHolder = 'Buscar...', delay: number | null | undefined = defaultDelay) : string => {
  const [placeholder, setPlaceholder] = useState<string>(basePlaceHolder);
  const placeholdersListRef = useRef<string[]>([]);
  const placeholderIndexRef = useRef(0);

  useInterval(() => {
    const placeholders = placeholdersListRef.current;
    const index = placeholderIndexRef.current;
    const nextPlaceholder = `${basePlaceHolder} ${placeholders[index]}`;
    setPlaceholder(nextPlaceholder);
    if (placeholders.length > 0) {
      if (index < placeholders.length - 1) {
        placeholderIndexRef.current += 1;
      } else {
        placeholderIndexRef.current = 0;
      }
    }
  }, delay);

  useEffect(() => {
    placeholdersListRef.current = getPlaceholdersArray(placeholderOptions);
  }, [placeholderOptions]);

  return placeholder;
};

export default usePlaceholders;
