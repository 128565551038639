/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { FrequencyType } from '../../types';

export interface FrquencyState {
  count: number,
  entities: Record<string, FrequencyType>,
  isLoading: boolean,
}

const initialState : FrquencyState = {
  count: 0,
  entities: {},
  isLoading: false,
}

export const fetchFrequencies = createAsyncThunk(
  'getPeriods/fetchFrequencies',
  async () => {
    const { data: dataResponse } = await getPostRequest('v2/periodfrequency/all', {});
    const { data } = dataResponse;
    return data || [];
  },
);

// eslint-disable-next-line import/prefer-default-export
export const frquenciesSlice = createSlice({
  name: 'frequencies',
  initialState,
  reducers: {
    clearFrequencies: (state) => {
      state.count = 0;
      state.entities = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFrequencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFrequencies.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchFrequencies.rejected, (state) => {
        state.isLoading = false;
      })
  }
});

export const { clearFrequencies } = frquenciesSlice.actions;

export default frquenciesSlice.reducer;