/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { VariableSalary, VariableSalaryFilter } from '../../types';

export interface VariableSalariesState {
  count: number;
  entities: Record<string, VariableSalary>;
  isLoading: boolean;
}

const initialState: VariableSalariesState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchVariableSalaries = createAsyncThunk(
  'getVariableSalaries/fetchVariableSalaries',
  async (filters: VariableSalaryFilter) => {
    const { data: dataResponse, status } = await getPostRequest(
      'v2/variablesalaries/all',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const variableSalariesSlice = createSlice({
  name: 'variableSalaries',
  initialState,
  reducers: {
    cleanVariableSalaries: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVariableSalaries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchVariableSalaries.fulfilled,
        (state, action: PayloadAction<VariableSalary[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
        }
      )
      .addCase(fetchVariableSalaries.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanVariableSalaries } = variableSalariesSlice.actions;

export default variableSalariesSlice.reducer;
