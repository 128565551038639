/* eslint-disable default-case */
import { AxiosResponse } from 'axios';

import {
  EmployeesServiceErrorCodes as EC,
  EmployeesServiceMessages,
} from '../constants';
import { FullEmployeee } from '../types';
import { EmployeesServiceError } from './EmployeesServiceError';

type OverrideEC = EC.NoResponseData | EC.NoDataFound | EC.Error | EC.Errors;

type Options = {
  messages?: Partial<{
    [K in OverrideEC]: string;
  }>;
  codes?: Partial<{
    [K in OverrideEC]: EC;
  }>;
  employee?: FullEmployeee | null;
};

function getMessage(code: EC, message?: string) {
  return message || EmployeesServiceMessages[code];
}

function extractMessageFromError(e: any, fallback: string): string {
  switch (typeof e) {
    case 'string':
      return e;
    case 'object':
      if (!e.message) break;
      return e.message;
  }

  return fallback;
}

export default function checkResponse(
  response: AxiosResponse<any, any>,
  options: Options = {}
): EmployeesServiceError | null {
  const { data } = response;

  let overrideCode: OverrideEC | null = null;

  if (!data) {
    overrideCode = EC.NoResponseData;
  } else if (data.errors && data.errors.length) {
    overrideCode = EC.Errors;
  } else if (data.error) {
    overrideCode = EC.Error;
  } else if (!data.data) {
    overrideCode = EC.NoDataFound;
  } else {
    return null;
  }

  const optionsMessages = options.messages || {};
  const optionsCodes = options.codes || {};

  const code = optionsCodes[overrideCode] || overrideCode;
  let message = optionsMessages[overrideCode] || getMessage(code);

  if (overrideCode === EC.Error)
    message = extractMessageFromError(data.error, message);

  if (overrideCode === EC.Errors) {
    const errors = data.errors.map(
      (e: any) =>
        new EmployeesServiceError(code, {
          message: extractMessageFromError(e, message)
        })
    );

    return new EmployeesServiceError(code, {
      message,
      errors,
      employee: options.employee,
    });
  }

  return new EmployeesServiceError(code, {
    message,
    employee: options.employee,
    errors: data.errors,
  });
}
