/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';

import { getGetRequest, getPostRequest } from '../../helpers';
import { LoginRequest, User, VersionControl, VersionControlState } from '../../types';
// import VersionJSON from '../../version.json';

export interface SessionState {
  user?: User;
  status: 'logedIn' | 'loading' | 'failed' | 'unknown';
  error?: string;
  versionControl: VersionControlState | null,
  isNewVersionAvaiable: boolean,
  socketStatus: number,
}

const initialState: SessionState = {
  user: undefined,
  status: 'loading',
  versionControl: JSON.parse(localStorage.getItem('versionControl') || 'null'),
  isNewVersionAvaiable: false,
  socketStatus: 0,
};

export const login = createAsyncThunk(
  'login/fetchUser',
  async (request: LoginRequest) => {
    const { data: dataResponse } = await getPostRequest(
      'operationalcontact/signin',
      request
    );
    const { data } = dataResponse;
    const { token, ...rest } = data;
    localStorage.setItem('access_token', token);
    return rest;
  }
);

export const loginWithToken = createAsyncThunk(
  'loginWithToken/fetchMe',
  async () => {
    const { data: dataResponse, status } = await getGetRequest(
      'operationalcontact/me'
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      const { token, ...rest } = data;
      return rest;
    }
    return errors;
  }
);

export const getVersionControl = createAsyncThunk(
  'getVersionControl/fetchVersionControl',
  async (): Promise<VersionControlState> => {
    const { data } = await axios.get(
      `https://raw.githubusercontent.com/nomada-sh/version-control/master/levita-clients.json`,
    );
    return data;
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSocketStatus: (state, action) => {
      state.socketStatus = action.payload;
    },
    logout: (state) => {
      state.user = undefined;
      state.status = 'unknown';
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
      }
    },
    cleanVersionStorage: () => {
      const apiBaseDomain = localStorage.getItem('api_base_domain');
      const selectedClient = localStorage.getItem('selected_client');
      const accessToken = localStorage.getItem('access_token');
      const versionControl = localStorage.getItem('versionControl');

      localStorage.clear();

      localStorage.setItem('api_base_domain', apiBaseDomain || '');
      localStorage.setItem('selected_client', selectedClient || '');
      localStorage.setItem('access_token', accessToken || '');
      localStorage.setItem('versionControl', versionControl || 'null');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'logedIn';
        state.user = action.payload;
        state.error = undefined;
      })
      .addCase(login.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message;
      });
    builder
      .addCase(loginWithToken.fulfilled, (state, action) => {
        state.status = 'logedIn';
        state.user = action.payload;
      })
      .addCase(loginWithToken.rejected, (state, { error }) => {
        state.status = 'failed';
        state.error = error.message;
      });
    builder
      .addCase(getVersionControl.fulfilled, (state, action) => {
        const { payload } = action;
        state.isNewVersionAvaiable = Object.keys(payload)
          .some((key) => {
            const currentVersion: VersionControl = _.get(state, `versionControl.${key}`, null);
            const newestVersion: VersionControl = _.get(payload, key);

            return currentVersion !== null && newestVersion.version > currentVersion.version;
          });
        state.versionControl = payload;
        localStorage.setItem('versionControl', JSON.stringify(payload));
      })
  },
});

export const { logout, setSocketStatus, cleanVersionStorage } = sessionSlice.actions;

export default sessionSlice.reducer;
