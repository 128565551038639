import { FC } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';

interface Props {
  title?: string;
  message?: string;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
  isLoading?: boolean;
};

const ContinueWithErrors: FC<Props> = ({
  title,
  message,
  isLoading,
  onClose,
  onAccept,
  open,
  children,
}) => (
  <Dialog open={open}>
    <DialogTitle>
      <Typography fontWeight='bold' variant='h5'>{title?.toUpperCase()}</Typography>
    </DialogTitle>
    <DialogContent>
      {isLoading && (<LinearProgress />)}
      {message && (<Typography style={{ marginBottom: '10px' }} variant='body1'>{message}</Typography>)}
      {children}
    </DialogContent>
    <DialogActions>
      <Button
        color='error'
        disabled={isLoading}
        fullWidth
        onClick={onClose}
        variant='contained'
      >
        Cancelar
      </Button>
      <Button
        color='success'
        disabled={isLoading}
        fullWidth
        onClick={onAccept}
        variant='contained'
      >
        Continuar
      </Button>
    </DialogActions>
  </Dialog>
);

ContinueWithErrors.defaultProps = {
  isLoading: false,
  title: '¿Está seguro que desea continuar?',
  message: '',
}

export default ContinueWithErrors;
