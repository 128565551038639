import { ServerType } from '../types';

const getServerConfig = (type: ServerType): {
  domain: string | null,
  token: string | null,
} => {
  if (type === ServerType.Go) {
    return {
      domain: localStorage.getItem('api_base_domain'),
      token: localStorage.getItem('access_token'),
    };
  }
  if (type === ServerType.Node) {
    return {
      domain: localStorage.getItem('api_node_base_domain'),
      token: `Bearer ${localStorage.getItem('access_token')}`,
    };
  }
  return {
    domain: localStorage.getItem('api_base_domain'),
    token: localStorage.getItem('access_token'),
  };
};

export default getServerConfig;
