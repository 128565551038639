export const pppsPayrollAddMany = `mutation pppsPayrollAddMany($payrollPeriod: String!, $payroll: [InputPPPSPayroll!]!) {
  pppsPayrollAddMany(payrollPeriod: $payrollPeriod, payroll: $payroll) {
    amount
    calculated
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
    }
    commission
    employee {
      id
      name
      firstLastName
      secondLastName
    }
    id
    paymentMethod {
      id
      name
    }
    payrollPeriod {
      id
      name
    }
    workedDays
  }
}
`;
