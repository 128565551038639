/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDeleteRequest, getGetRequest, getPostRequest, getPutRequest } from 'helpers';
import _, { Dictionary } from 'lodash';
import { EmployeeDraft, EmployeeDraftFilter, EmployeeDraftUpdate, ServerType } from 'types';

export interface EmployeeDraftsState {
  count: number;
  entities: Dictionary<EmployeeDraft>;
  isLoading: boolean;
};

const initialState: EmployeeDraftsState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchEmployeeDrafts = createAsyncThunk(
  'getEmployeeDrafts/fetchEmployeeDrafts',
  async (filter: EmployeeDraftFilter): Promise<EmployeeDraft[]> => {
    const { page, limit, ...rest } = filter;

    const { status, data } = await getGetRequest(
      `employee-drafts/page/${page}/limit/${limit}`,
      { server: ServerType.Node }, rest);

    return status === 200 ? data : [];
  }
);

export const addEmployeeDrafts = createAsyncThunk(
  'postEmployeeDrafts/addEmployeeDrafts',
  async (draft: EmployeeDraft): Promise<EmployeeDraft | null> => {
    const { status } = await getPostRequest('employee-drafts', draft, { server: ServerType.Node });
    return status === 201 ? draft : null;
  },
);

export const editEmployeeDraft = createAsyncThunk(
  'putEmployeeDraft/editEmployeeDraft',
  async (draft: EmployeeDraftUpdate): Promise<EmployeeDraftUpdate | null> => {
    const { status } = await getPutRequest(`employee-drafts/${draft.id}`, draft, { server: ServerType.Node });

    return status === 200 ? draft : null;
  },
);

export const deleteEmployeeDraft = createAsyncThunk(
  'deleteEmployeeDraft/deleteEmployeeDraft',
  async (id: string): Promise<string | null> => {
    const { status } = await getDeleteRequest(`employee-drafts/${id}`, { server: ServerType.Node });

    return status === 200 ? id : null;
  },
);

export const deleteManyEmployeeDraft = createAsyncThunk(
  'deleteEmployeeDraft/deleteManyEmployeeDraft',
  async (ids: string[]): Promise<string[]> => {
    const promises = await Promise.all(ids.map((id) => getDeleteRequest(`employee-drafts/${id}`, { server: ServerType.Node })));
    const deletedIds: string[] = [];

    promises.forEach(({ status }, index) => {
      if (status === 200) {
        deletedIds.push(ids[index]);
      }
    });

    return deletedIds;
  },
);

export const employeeDraftsSlice = createSlice({
  name: 'employeeDrafts',
  initialState,
  reducers: {
    cleanEmployeeDrafts: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeDrafts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployeeDrafts.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchEmployeeDrafts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(addEmployeeDrafts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addEmployeeDrafts.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.count += 1;
          state.entities[payload.id] = payload;
        }
      })
      .addCase(addEmployeeDrafts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(editEmployeeDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEmployeeDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.entities[payload.id] = {
            ...state.entities[payload.id],
            ...payload,
          };
        }
      })
      .addCase(editEmployeeDraft.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteEmployeeDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEmployeeDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          delete state.entities[payload];
        }
      })
      .addCase(deleteEmployeeDraft.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(deleteManyEmployeeDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteManyEmployeeDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload.length > 0) {
          payload.forEach((id) => {
            delete state.entities[id];
          });
        }
      })
      .addCase(deleteManyEmployeeDraft.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanEmployeeDrafts } = employeeDraftsSlice.actions;

export default employeeDraftsSlice.reducer;
