import { FC, useState } from 'react';
import { useDeepCompareEffect, useToggle } from 'react-use';

import {
  CheckBox,
  CheckBoxOutlineBlank,
} from '@mui/icons-material';
import {
  Button,
  // CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import { getPostRequest } from '../helpers';
import { useAppSelector } from '../hooks';
import { EntitiesState, LoginRequest } from '../types';

interface Props {
  askForPassword?: boolean,
  askForPasswordMessage?: string,
  changes: EntitiesState,
  isLoading?: boolean,
  message?: string,
  onClose?: () => void,
  onConfirm?: (changes: EntitiesState) => void,
  open: boolean,
  rows: EntitiesState,
  showCheckButton?: boolean,
  title?: string,
};

type CheckListState = Record<string, boolean>;

const SaveDatasheetChangesModal: FC<Props> = ({
  askForPassword,
  askForPasswordMessage,
  changes,
  isLoading,
  message,
  onClose,
  onConfirm,
  open,
  rows,
  showCheckButton,
  title,
}): JSX.Element => {
  const [checks, setChecks] = useState<CheckListState>({});
  const user = useAppSelector(({ session }) => session.user);
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({ email: user?.email || '', password: '', subdomain: 'intela' });
  const [isChekingPassword, toggleIsChekingPassword] = useToggle(false);
  const [hasWrongPassword, toggleHasWrongPassword] = useToggle(false);

  const handleToggleCheck = (entityId: string) => {
    setChecks((prevChecks) => {
      const nextChecks: CheckListState = { ...prevChecks };
      nextChecks[entityId] = !nextChecks[entityId];
      return nextChecks;
    });
  };

  const handleClose = () => {
    if (!isChekingPassword && !isLoading && onClose) {
      onClose();
      toggleHasWrongPassword(false);
      setLoginRequest({
        ...loginRequest,
        password: '',
      });
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      const markedChanges: EntitiesState = {};
      _.keys(checks).forEach((changeId, index) => {
        if (checks[changeId]) {
          markedChanges[changeId] = _.get(changes, changeId, { id: `${index}` });
        }
      });
      if (askForPassword) {
        toggleIsChekingPassword(true);
        getPostRequest('operationalcontact/signin', loginRequest)
          .then(() => {
            toggleHasWrongPassword(false);
            onConfirm(markedChanges);
          })
          .catch(() => {
            toggleHasWrongPassword(true);
          })
          .finally(() => {
            toggleIsChekingPassword(false);
          });
        return;
      }
      onConfirm(markedChanges);
    }
  };

  useDeepCompareEffect(() => {
    const newsChecks: CheckListState = {};
    _.keys(changes).forEach((changeId) => {
      newsChecks[changeId] = true;
    });
    setChecks(newsChecks);
  }, [changes]);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      {isLoading && (<LinearProgress />)}
      <DialogContent>
        {message && (<DialogContentText>{message}</DialogContentText>)}
        {_.keys(changes).map((entityId) => {
          const name = _.get(rows, `${entityId}.name`);
          const firstLastName = _.get(rows, `${entityId}.firstLastName`);
          const secondLastName = _.get(rows, `${entityId}.secondLastName`);

          return (
            <Typography>
              {showCheckButton && (
                <IconButton onClick={() => {
                  handleToggleCheck(entityId);
                }}>
                  {_.get(checks, entityId, true) ? (<CheckBox color='primary' />) : (<CheckBoxOutlineBlank color='primary' />)}
                </IconButton>
              )}
              {`${name} ${firstLastName} ${secondLastName}`}
            </Typography>
          );
        })}
        {askForPassword && (
          <>
            <DialogContentText>
              {askForPasswordMessage}
            </DialogContentText>
            <TextField
              autoFocus
              error={hasWrongPassword}
              fullWidth
              helperText={hasWrongPassword && 'La contraseña es incorrecta, inténtelo nuevamente'}
              id='password'
              label='Contraseña'
              margin='dense'
              onChange={({ target }) => {
                setLoginRequest({
                  ...loginRequest,
                  password: target.value,
                })
              }}
              type='password'
              variant='standard'
            />
            {isChekingPassword && (<LinearProgress />)}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>Cancelar</Button>
        <Button disabled={isLoading} onClick={handleConfirm} variant='contained'>
          {/* {isLoading && (<CircularProgress size='1rem' />)} */}
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SaveDatasheetChangesModal.defaultProps = {
  askForPassword: true,
  askForPasswordMessage: 'Confirmar acción',
  isLoading: false,
  message: '',
  onClose: () => {},
  onConfirm: () => {},
  showCheckButton: false,
  title: '¿Desea guardar los cambios?',
};

export default SaveDatasheetChangesModal;
