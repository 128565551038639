// !! Do not use 0 as error code.
export enum EmployeesServiceErrorCodes {
  NoEmployeesFound = 10,
  NoDismissalTypesFound = 11,
  NoResponseData = 12,
  NoDataFound = 13,
  Error = 14,
  Errors = 15,
  Unknown = 16,
  Cancelled = 17,
  ErrorGettingEmployees = 20,
  GetEmployeesCancelled = 21,
  ErrorDisablingEmployees = 30,
  ErrorDisablingEmployee = 31,
}

const EC = EmployeesServiceErrorCodes;

export const EmployeesServiceMessages = {
  [EC.NoEmployeesFound]: 'No se encontraron empleados',
  [EC.NoDismissalTypesFound]: 'No se encontraron tipos de deshabilitación',
  [EC.NoResponseData]: 'No se encontraron datos en la respuesta',
  [EC.NoDataFound]: 'No se encontraron datos',
  [EC.Error]: 'Ha ocurrido un error',
  [EC.Errors]: 'Han ocurrido errores',
  [EC.Unknown]: 'Error desconocido',
  [EC.ErrorGettingEmployees]: 'Error al obtener empleados',
  [EC.GetEmployeesCancelled]: 'Petición para obtener empleados cancelada',
  [EC.Cancelled]: 'Petición cancelada',
  [EC.ErrorDisablingEmployees]: 'Error al deshabilitar empleados',
  [EC.ErrorDisablingEmployee]: 'Error al deshabilitar empleado',
}