import { Employee } from './employee';
import { PayrollReceipt, PRStatus } from './payrollreceipt';
import { EmailTracker, PayrollScheme } from './periods';
import { Pagination, WhereClause } from './request';

export interface Dispersion {
  id: string;
  createdAt: string;
  updatedAt: string;
  DeletedAt?: string;
  paid?: boolean;
  declined?: boolean;
  payrollScheme: PayrollScheme;
  totalToDisperse: number;
  concept: string;
  exempt: number;
  emisora: string;
  company: string;
  client: string;
  employee: string;
  paymentMethod: string;
  payrollBankAccount: string;
  employeeBankAccount: string;
  payrollType: string;
  payrollPeriod: string;
  society: string;
  EmailTracker: EmailTracker;
  finiquitoCategory: FiniquitoCategory;
  stampedRequestLog: StampedRequestLog[];
}

export interface DispersionComposed {
  id: string;
  createdAt: string;
  updatedAt: string;
  DeletedAt?: string;
  paid?: boolean;
  declined?: boolean;
  payrollScheme: PayrollScheme;
  totalToDisperse: number;
  concept: string;
  exempt: number;
  emisora: string;
  company: string;
  client: string;
  employee: string;
  paymentMethod: string;
  payrollBankAccount: string;
  employeeBankAccount: string;
  payrollType: string;
  payrollPeriod: string;
  society: string;
  EmailTracker: EmailTracker;
  finiquitoCategory: FiniquitoCategory;
  stampedRequestLog: StampedRequestLog[];
  postEmployeeData?: Employee;
  postReceiptData?: PayrollReceipt;
}

export enum FiniquitoCategory {
  FCFiniquito = "finiquito",
  FCLiquidacion = "liquidacion"
}

export interface StampedRequestLog {
  createdAt: string;
  employee: string;
  statusResult: PRStatus;
  errors: string[];
}

export interface DispersionFilter {
  id?: string;
  oldPayrollReceiptID?: string;
  newDispersionId?: string;
  payrollPeriod?: string;
  paymentMethod?: string;
  pagination?: Pagination;
  employees?: string[];
  justEmployees?: boolean;
  paid?: boolean;
  totalToDisperse?: WhereClause;
}