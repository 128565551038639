import { FC } from 'react';

import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

interface Props {
  onClick?: () => void;
  open: boolean,
  label?: string,
}

const BackdropLoader: FC<Props> = ({ open, onClick, label }) : JSX.Element => (
  <Backdrop
    onClick={onClick}
    open={open}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <Box alignItems='center' display='flex' flexDirection='column'>
      <CircularProgress color='inherit' style={{ marginBottom: '10px' }} />
      <Typography component='span' fontWeight='bold'>{label}</Typography>
    </Box>
  </Backdrop>
);

BackdropLoader.defaultProps = {
  onClick: () => {},
  label: 'Cargando...'
};

export default BackdropLoader;
