import { setNewMessage } from '../store/slices';
import { WebSocketJSON } from '../types'

const handleParsedData = (data: WebSocketJSON, dispatch: any) => {
  const { entityType, entity } = data;
  switch (entityType) {
    case 'Messages': {
      dispatch(setNewMessage(entity))
      break;
    }
    default: break;
  }
}

export default handleParsedData;
