import { FC, MutableRefObject, ReactElement } from 'react';
import ReactDataSheet from 'react-datasheet';

// import {
//   Cell as BaseCell,
//   Column as BaseColumn,
// } from './BaseDatasheet';
import { UploadFile } from './system';

export type CellType =
  | 'text'
  | 'email'
  | 'date'
  | 'money'
  | 'select'
  | 'boolean'
  | 'file'
  | 'check'
  | 'button';

export type CellClassName = '' | 'edited' | 'error';

export type Value =
  | string
  | boolean
  | number
  | Date
  | Option
  | UploadFile
  | null
  | undefined;

export const defaultValues: Record<CellType, Value> = {
  text: '',
  email: '',
  date: new Date(),
  money: 0,
  select: {
    value: '',
    label: '',
  },
  file: null,
  boolean: false,
  check: false,
  button: '',
};

export interface Option<V = string> {
  value: V;
  label: string;
  [index: string]: unknown;
}

export type GetInitMetadata = (entity: Entity) => CellUpdate;
export type Metadata = Record<string, unknown>;
export type UpdateCellAtributes = (
  row: number | string,
  field: string,
  nextAtributtes: CellUpdate
) => void;

export type OnValidate = (
  value: Value,
  cell: Cell,
  extra: {
    entities: EntitiesState,
    getCell: (row: string | number, col: string | number) => Cell,
    handleChanges: HandleChanges,
    updateCellAtributes: (
      row: GridKey,
      field: GridKey,
      nextAtributtes: CellUpdate
    ) => void,
  },
) => (CellValidation | Promise<CellValidation>);

type OnClickCellExtra = {
  metadata?: Metadata;
};

export type OnClickCell = (entityID: string, entities: MutableRefObject<EntitiesState>, onClickCellExtra: OnClickCellExtra) => void | Promise<void>;

export interface DataSheetButtonComponentProps {
  entityID: string;
  entitiesMapRef: MutableRefObject<EntitiesState>;
  handleChangesRef: MutableRefObject<HandleChanges | undefined>,
  updateCellAtributesRef: MutableRefObject<((row: GridKey, col: GridKey, newAttributes: CellUpdate) => void) | undefined>;
  metadata?: Metadata;
};

export type DataSheetButtonComponent = FC<DataSheetButtonComponentProps>;

// export interface CellString extends BaseCell<CellString, string> {
//   value: string;
//   initialValue: string;
//   type: 'text' | 'email';
// };

// export interface CellBoolean extends BaseCell<CellBoolean, boolean> {
//   value: boolean;
//   initialValue: boolean;
//   type: 'check' | 'boolean';
// };

// export interface CellNumber extends BaseCell<CellNumber, number> {
//   value: number;
//   initialValue: number;
//   minValue?: number;
//   maxValue?: number;
//   type: 'money' | 'number';
// };

// export interface CellDate extends BaseCell<CellDate, Date> {
//   value: Date;
//   initialValue: Date;
//   minValue?: Date;
//   maxValue?: Date;
//   type: 'date';
// };

// export interface CellSelect extends BaseCell<CellSelect, Option> {
//   value: string;
//   initialValue: string;
//   label: string;
//   type: 'select';
// };

// export interface CellFile extends BaseCell<CellFile, Nullable<UploadFile>> {
//   value: Nullable<UploadFile>;
//   initialValue: Nullable<UploadFile>;
//   minCount?: number;
//   maxCount?: number;
//   type: 'file';
// };

// export interface CellButton<V = unknown> extends BaseCell<CellButton<V>, V> {
//   value: V;
//   initialValue: V;
//   type: 'button';
//   onClick?: OnClickCell,
// };

// export type Cell = CellString
//   | CellBoolean
//   | CellNumber
//   | CellDate
//   | CellSelect
//   | CellFile
//   | CellButton;

// <T extends Cell<T, V>, V = string> extends BaseCell<T, V> {

//   type: CellType;
//   value: V;
//   initialValue: V;
//   label?: string;
//   options?: Option[];
//   // pathValue?: string | string[],
//   onClick?: () => void,
//   // ButtonComponent?: ButtonComponent,
//   buttonComponentPath?: string;
//   entitiesMapRef?: MutableRefObject<EntitiesState>;
//   [key: string]: any;
// };

export type Cell = {
  className: CellClassName;
  colIndex: number;
  field: string;
  id: string;
  initialValue: Value;
  // isEnabled: boolean,
  isRequired: boolean;
  label?: string;
  options?: Option[];
  // pathValue?: string | string[],
  readOnly: boolean;
  rowIndex: number;
  title: string;
  type: CellType;
  value: Value;
  component?: JSX.Element | ReactElement;
  forceComponent?: boolean;
  key?: string;
  width?: number | string;
  overflow?: 'wrap' | 'nowrap' | 'clip';
  isLoading: boolean;
  validation?: OnValidate;
  onClick?: () => void,
  // ButtonComponent?: ButtonComponent,
  cleanOnChange?: string[],
  cleanValuesOnChange?: string[],
  buttonComponentPath?: string;
  entitiesMapRef?: MutableRefObject<EntitiesState>;
  handleChangesRef: MutableRefObject<HandleChanges | undefined>,
  updateCellAtributesRef: MutableRefObject<((row: GridKey, col: GridKey, newAttributes: CellUpdate) => void) | undefined>,
  metadata?: Metadata;
  messageBeforeValidation?: string,
  forceRevalidation?: string[],
  minDateValue?: Date,
  maxDateValue?: Date,
  [key: string]: any;
};

export type CellUpdate = {
  className?: CellClassName;
  // field?: string;
  // id?: string;
  // initialValue?: Value;
  isRequired?: boolean;
  label?: string;
  options?: Option[];
  readOnly?: boolean;
  // title?: string;
  type?: CellType;
  value?: Value;
  onClick?: OnClickCell,
  // ButtonComponent?: ButtonComponent,
  cleanOnChange?: string[],
  buttonComponentPath?: string,
  minDateValue?: Date,
  maxDateValue?: Date,
  [key: string]: any;
};

// interface ColumnString extends BaseColumn<string> {
//   type: 'text' | 'email';
//   validation?: OnValidate,
// };

// interface ColumnBoolean extends BaseColumn<boolean> {
//   type: 'check' | 'boolean';
// };

// interface ColumnNumber extends BaseColumn<number> {
//   type: 'money' | 'number';
//   validation?: OnValidate,
// };

// interface ColumnDate extends BaseColumn<Date> {
//   type: 'date';
//   validation?: OnValidate,
// };

// interface ColumnSelect extends BaseColumn<Option> {
//   type: 'select';
//   defaultLabel?: string;
//   options?: Option[];
// };

// interface ColumnFile extends BaseColumn<UploadFile> {
//   type: 'file';
//   validation?: OnValidate,
// };

// interface ColumnButton<V = unknown> extends BaseColumn<V> {
//   type: 'button';
//   onClick?: OnClickCell,
// };

// export type Column = ColumnString
//   | ColumnBoolean
//   | ColumnNumber
//   | ColumnDate
//   | ColumnSelect
//   | ColumnFile
//   | ColumnButton;
// {
//   defaultValue?: Value;
//   field: string;
//   type?: CellType;
//   ButtonComponent?: ButtonComponent,
//   buttonComponentPath?: string,
// };

export type Column = {
  defaultLabel?: string;
  defaultValue?: Value;
  field: string;
  isEnabled?: boolean;
  isRequired?: boolean;
  options?: Option[];
  pathValue?: string | string[] | ((entity: Entity, metadata?: Metadata) => Value);
  separator?: string;
  title: string | string[];
  type?: CellType;
  component?: JSX.Element;
  forceComponent?: boolean;
  width?: number | string;
  overflow?: 'wrap' | 'nowrap' | 'clip';
  getInitMetadata?: GetInitMetadata;
  hidden?: boolean,
  forceHidden?: boolean,
  validation?: OnValidate,
  onClick?: OnClickCell,
  cleanOnChange?: string[],
  cleanValuesOnChange?: string[],
  // ButtonComponent?: ButtonComponent,
  buttonComponentPath?: string,
  messageBeforeValidation?: string,
  forceRevalidation?: string[],
  minDateValue?: Date,
  maxDateValue?: Date,
};

export type Header = Cell & {
  defaultLabel: string;
  defaultValue: Value;
  isEnabled: boolean;
  pathValue?: string | string[] | ((entity: Entity, metadata?: Metadata) => Value);
  separator: string;
  getInitMetadata?: GetInitMetadata;
  validation?: OnValidate;
  hidden: boolean;
  columnComponent?: JSX.Element;
  columnForceComponent: boolean;
  handleClick?: OnClickCell;
  forceHidden: boolean;
};

type IdsMap = Record<number, string>;
type IndexsMap = Record<string, number>;

export type ObjectMap = IdsMap & IndexsMap;

export interface Entity {
  id: string;
  [key: string]: any;
}

export type Change = {
  cell: CellUpdate | null;
  row: number | string;
  col: number | string;
  value: Value | null;
};

export type FormatedChange = {
  cell: Cell;
  row: number;
  col: number;
  value: Value;
};

export type HandleChanges = (changes: Change[]) => void
  | ReactDataSheet.CellsChangedHandler<Cell, Value>;

  // | ReactDataSheet.CellsChangedHandler<CellString, string>
  // | ReactDataSheet.CellsChangedHandler<CellBoolean, boolean>
  // | ReactDataSheet.CellsChangedHandler<CellNumber, number>
  // | ReactDataSheet.CellsChangedHandler<CellDate, Date>
  // | ReactDataSheet.CellsChangedHandler<CellSelect, Option>
  // | ReactDataSheet.CellsChangedHandler<CellFile, Nullable<UploadFile>>
  // | ReactDataSheet.CellsChangedHandler<CellButton, unknown>;

export type Error = CellUpdate & {
  message: string,
};

export type EntitiesState = Record<string, Entity>;

export type CellUpdatesState =
  | Record<string, Record<string, CellUpdate>>
  | undefined;

export type UseDataSheetHeader = {
  header: Header[];
  colsMapRef: MutableRefObject<ObjectMap>;
  updateColAtributes: (
    column: number | string,
    newAttributes: Record<string, unknown>
  ) => void;
  addColumn: (newColumn: Column, columnIndex?: number) => void,
};

export type DataEditorType = ReactDataSheet.DataEditor<any, string>;
export type ValueRendererType = ReactDataSheet.ValueRenderer<any, string>;

export type GridKey = string | number;

export type UseDataSheet = {
  grid: Cell[][],

  updateColumnAtributes: (
    col: number | string,
    nextAtributtes: Record<string, unknown>
  ) => void;
  addColumn: (newColumn: Column, columnIndex?: number) => void,

  rows: EntitiesState,
  updateCellAtributes: (
    ros: string | number,
    col: string | number,
    newAttributes: CellUpdate
  ) => void;
  cleanCellUpdates: (row: GridKey, col: GridKey) => void;
  cleanRowUpdates: (row: GridKey) => void;
  cleanMultipleRowUpdates: (rowsKeys: GridKey[]) => void;

  changes: EntitiesState;
  handleChanges: HandleChanges;
  cleanChanges: () => void;
  cleanCellChange: (row: GridKey, col: GridKey) => void;
  cleanRowChanges: (row: GridKey) => void;
  cleanMultipleRowChanges: (rowsKeys: GridKey[]) => void;
  slideChangesUp: () => void;

  errors: EntitiesState;

  requiredMissingValues: EntitiesState;
  validEntities: EntitiesState;

  entities: EntitiesState;
  hasData: boolean;
  isCalculating: boolean;
  getCell: (row: string | number, col: string | number) => Cell;
  cleanCellMemory: (row: GridKey, col: GridKey) => void;
  cleanRowMemory: (row: GridKey) => void;
  cleanMultipleRowsMemory: (rowsKeys: GridKey[]) => void;
  cleanNonRenderedMemory: () => void;
  cleanDataSheetLocalStorage: () => void;
  createOnClickEvent: (row: string, cb: OnClickCell) => () => void;
  ValueRenderer: ReactDataSheet.ValueRenderer<any, any>;

  // Legacy
  DataEditor: undefined;
  validate: () => {
    isValid: boolean,
    errors: Error[],
  };
};

export type UseDatasheetConfig = {
  areAllRequired?: boolean;
  defaultIsEnable?: boolean;
  hasPagination?: boolean;
  transpose?: boolean;
  id?: string;
  getInitMetadata?: GetInitMetadata;
  DataEditor?: undefined;
  ValueRenderer?: ReactDataSheet.ValueRenderer<any, string>;
  metadata?: Metadata;
  discardNonRenderer?: boolean;
  [EXTRA: string]: unknown;
};
/**
 * (JSX attribute) dataEditor?: ReactDataSheet.DataEditor<any, string> | undefined
Optional function or React Component to render a custom editor. Affects every cell in the sheet. Affects every cell in the sheet. See cell options to override individual cells.

Type 'unknown' is not assignable to type 'DataEditor<any, string>
 */

export type CellPosition = {
  i: number;
  j: number;
};

export type CellError = {
  message: string,
  cell?: Cell,
};

export type CellValidation = {
  isValid: boolean,
  error?: CellError | null,
}
