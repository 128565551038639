import _ from 'lodash';

import mutations from './mutations';
import queries from './queries';

const operations = {
  ...mutations,
  ...queries,
};

export type Operations = keyof typeof operations;

type GraphQLParameters = {
  operationName: string;
  variables: unknown;
  query: string;
};

const getGraphQLParameters = (
  queryOrMutation: Operations,
  variables: unknown,
): GraphQLParameters => {
  const query: string = _.get(operations, queryOrMutation);

  if (!query)
    throw new Error(`Query or mutation ${queryOrMutation} not defined`);

  return {
    operationName: queryOrMutation,
    variables,
    query,
  };
};

export default getGraphQLParameters;
