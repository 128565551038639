import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';

import periodService from '../../services/PeriodService/PeriodService';
import { Pagination } from '../../types/request';
import { PeriodState } from '../types';

type State = {
  period: PeriodState;
};

export const fetchPeriod = createAsyncThunk<
  {
    period: any;
    employees: {
      count: number;
      entities: Array<any>;
      pagination?: Pagination;
      calculated?: boolean;
    } | null;
    paymentMethods: Array<any>;
  },
  {
    payrollPeriod: string;
    employeePagination?: Pagination;
    fetchEmployees?: boolean;
    type?: string
  },
  {
    rejectValue: any;
    state: State;
  }
>(
  'period/fetchPeriod',
  async (
    { payrollPeriod, employeePagination, fetchEmployees, type },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const period = await periodService.get(payrollPeriod);
      const paymentMethods = await periodService.getPaymentMethods();
      const employees = fetchEmployees
        ? await periodService.getEmployees({
            payrollPeriod,
            pagination: employeePagination ?? state.period.employees.pagination,
            workedDays: period.workedDays,
            type
          })
        : null;

      return {
        period,
        employees,
        paymentMethods,
      };
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const fetchPeriodEmployees = createAsyncThunk<
  {
    count: number;
    entities: Array<any>;
    pagination?: Pagination;
    calculated?: boolean;
  },
  {
    payrollPeriod: string;
    pagination?: Pagination;
    taxSchema?: string;
    workedDays: number;
    type?: string;
  },
  {
    rejectValue: any;
    state: State;
  }
>(
  'period/fetchPeriodEmployees',
  async (
    { payrollPeriod, pagination, taxSchema, workedDays, type },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();

      return await periodService.getEmployees({
        payrollPeriod,
        pagination: pagination ?? state.period.employees.pagination,
        taxSchema,
        workedDays,
        type
      });
    } catch (e) {
      return rejectWithValue(serializeError(e));
    }
  }
);

export const savePrepayroll = createAsyncThunk<
  undefined,
  {
    payrollPeriod: string;
    employees: Array<any>;
    taxSchema: string;
    type: string;
  },
  {
    rejectValue: any;
  }
>('period/savePrepayroll', async (params, { rejectWithValue }) => {
  try {
    await periodService.savePrepayroll(params);
    return undefined;
  } catch (e) {
    return rejectWithValue(serializeError(e));
  }
});
