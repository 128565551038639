import { FC, useEffect, useRef, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import { useEffectOnce, useTimeout } from 'react-use';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';

import { Select } from '../components';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  cleanKardex,
  cleanOptionsCatalog,
  deleteClientPendingTask,
  editPeriod,
  fetchCFDIConceptsCatalog,
  fetchKardex,
  fetchPaymentMethodsCatalog,
  fetchPaymentMethodTypesCatalog,
  fetchTaxRegimesCatalog,
  promisedCalculateClientPendingTasksByPeriod,
  setSelectedCFDIConcept,
  setSelectedTaxPaymentMethod,
  setSelectedTaxPaymentMethodType,
  setSelectedTaxRegimen,
  showMessage,
} from '../store/slices';
import { fetchPeriod } from '../store/thunks';
import { ClientPendingTaskSection, ClientPendingTaskTitle, InvoiceStatus, Option } from '../types';

type ErrorsState = {
  taxReximen: boolean;
  cfdiUse: boolean;
  paymentMethod: boolean;
  paymentType: boolean;
};

const defaultErrors: ErrorsState = {
  taxReximen: false,
  cfdiUse: false,
  paymentMethod: false,
  paymentType: false,
};

interface Props {
  open: boolean
  onClose?: () => void
};

const AutoStampModal: FC<Props> = ({ open, onClose }): JSX.Element => {
  const [, , reset] = useTimeout(0);
  const dispatch = useAppDispatch();
  const {
    taxRegimesOptions,
    taxRegimesValue,
    taxRegimesLoading,
    CFDIConceptsOptions,
    CFDIConceptsValue,
    CFDIConceptsLoading,
    paymentMethodsOptions,
    paymentMethodsValue,
    paymentMethodsLoading,
    paymentMethodTypesOptions,
    paymentMethodTypesValue,
    paymentMethodTypesLoading,
  } = useAppSelector(({ optionsCatalog }) => ({
    taxRegimesOptions: optionsCatalog.taxRegimes.options,
    taxRegimesValue: optionsCatalog.taxRegimes.selected,
    taxRegimesLoading: optionsCatalog.taxRegimes.isLoading,
    CFDIConceptsOptions: optionsCatalog.CFDIConcepts.options,
    CFDIConceptsValue: optionsCatalog.CFDIConcepts.selected,
    CFDIConceptsLoading: optionsCatalog.taxRegimes.isLoading,
    paymentMethodsOptions: optionsCatalog.paymentMethods.options,
    paymentMethodsValue: optionsCatalog.paymentMethods.selected,
    paymentMethodsLoading: optionsCatalog.taxRegimes.isLoading,
    paymentMethodTypesOptions: optionsCatalog.paymentMethodTypes.options,
    paymentMethodTypesValue: optionsCatalog.paymentMethodTypes.selected,
    paymentMethodTypesLoading: optionsCatalog.taxRegimes.isLoading,
  }));
  const clientID = useAppSelector(({ clients }) => clients.selected);
  const kardexBilling = useAppSelector(({ kardex }) => kardex.entity?.billing);
  const period = useAppSelector(({ period: state }) => state.period);

  const { isEditing: isLoading, selected: periodId } = useAppSelector(({ periods }) => periods);
  const [errors, setErrors] = useState<ErrorsState>(defaultErrors);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleGenerateBill = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      taxReximen: taxRegimesValue === null,
      cfdiUse: CFDIConceptsValue === null,
      paymentMethod: paymentMethodsValue === null,
      paymentType: paymentMethodTypesValue === null,
    }));

    if (taxRegimesValue === null
      || CFDIConceptsValue === null
      || paymentMethodsValue === null
      || paymentMethodTypesValue === null
    ) {
      return;
    }
    dispatch(deleteClientPendingTask({
      payrollPeriodId: {
        eq: periodId,
      },
      clientId: {
        eq: clientID,
      },
      payrollTypeId: {
        eq: period.payrollType.id,
      },
      title: {
        eq: ClientPendingTaskTitle.CPTTi_PENDING_PERIODS
      },
      section: {
        inString: [
          ClientPendingTaskSection.CPTS_CURRENT_BILLING,
          ClientPendingTaskSection.CPTS_PREVIOUS_BILLING,
          ClientPendingTaskSection.CPTS_FUTURE_BILLING,
        ]
      }
    }))
    dispatch(editPeriod({
      id: periodId,
      autoStamp: true,
      catalogsStamp: {
        fiscalRegime: taxRegimesValue.value,
        useCFDI: CFDIConceptsValue.value,
        paymentMethod: paymentMethodsValue.value,
        paymentMethodType: paymentMethodTypesValue.value,
      },
      invoiceStatus: InvoiceStatus.InvoiceStatusRequested,
    })).then(async () => {
      await promisedCalculateClientPendingTasksByPeriod(period.id);

      dispatch(fetchPeriod({
        payrollPeriod: periodId,
      }))
      if (onClose) {
        onClose();
        dispatch(showMessage({
          message: 'Factura generada exitosamente',
          type: 'success',
        }));
      }
    });
  };

  const handleSelectChange = (newValue: SingleValue<Option>, meta: ActionMeta<Option>) => {
    const { name } = meta;
    switch (name) {
      case 'select-tax-regime':
        dispatch(setSelectedTaxRegimen(newValue));
        break;
      case 'select-cfdi-use':
        dispatch(setSelectedCFDIConcept(newValue));
        break;
      case 'select-payment-method':
        dispatch(setSelectedTaxPaymentMethod(newValue));
        break;
      case 'select-payment-type':
        dispatch(setSelectedTaxPaymentMethodType(newValue));
        break;
      default:
        break;
    }
  };

  useEffectOnce(() => {
    dispatch(fetchTaxRegimesCatalog());
    dispatch(fetchCFDIConceptsCatalog());
    dispatch(fetchPaymentMethodsCatalog());
    dispatch(fetchPaymentMethodTypesCatalog());

    return () => {
      dispatch(cleanOptionsCatalog());
      dispatch(cleanKardex());
    };
  });

  useEffect(() => {
    dispatch(fetchKardex({ clientID }));
  }, [clientID, dispatch, open]);

  useEffect(() => {
    if (kardexBilling && kardexBilling.fiscalRegime.length > 0) {
      const defaultValue = taxRegimesOptions.find(({ id }) => id === kardexBilling.fiscalRegime);
      if (defaultValue) {
        dispatch(setSelectedTaxRegimen(defaultValue));
      }
    }
  }, [kardexBilling, taxRegimesOptions, dispatch]);

  useEffect(() => {
    if (kardexBilling && kardexBilling.useCFDI.length > 0) {
      const defaultValue = CFDIConceptsOptions.find(({ id }) => id === kardexBilling.useCFDI);
      if (defaultValue) {
        dispatch(setSelectedCFDIConcept(defaultValue));
      }
    }
  }, [kardexBilling, CFDIConceptsOptions, dispatch]);

  useEffect(() => {
    if (kardexBilling && kardexBilling.paymentMethod.length > 0) {
      const defaultValue = paymentMethodsOptions.find(({ id }) => id === kardexBilling.paymentMethod);
      if (defaultValue) {
        dispatch(setSelectedTaxPaymentMethod(defaultValue));
      }
    }
  }, [kardexBilling, paymentMethodsOptions, dispatch]);

  useEffect(() => {
    if (kardexBilling && kardexBilling.paymentMethodType.length > 0) {
      const defaultValue = paymentMethodTypesOptions.find(({ id }) => id === kardexBilling.paymentMethodType);
      if (defaultValue) {
        dispatch(setSelectedTaxPaymentMethodType(defaultValue));
      }
    }
  }, [kardexBilling, paymentMethodTypesOptions, dispatch]);

  useEffect(() => {
    if (open) {
      reset();
      setErrors(defaultErrors);
    }
  }, [open, reset]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      ref={modalRef}
    >
      <DialogTitle>
        Factura automática
      </DialogTitle>
      {isLoading && (<LinearProgress />)}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>
              La factura se generará con las siguientes opciones que se
              obtienen de la información guardada en el Kardex del cliente.
              Antes de timbrar puedes revisar y modificar
              (esto no afecta a los campos guardados en el Kardex)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              error={errors.taxReximen}
              helperText='Debe seleccionar un régimen fiscal'
              id='select-tax-regime'
              inputId='input-tax-regime'
              isClearable
              isLoading={taxRegimesLoading}
              label='Régimen fiscal'
              menuPlacement='auto'
              menuPortalTarget={modalRef.current}
              menuPosition='absolute'
              name='select-tax-regime'
              onChange={handleSelectChange}
              options={taxRegimesOptions}
              placeholder='Seleccionar régimen fiscal'
              value={taxRegimesValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              error={errors.cfdiUse}
              helperText='Debe seleccionar un uso del CFDI'
              id='select-cfdi-use'
              isClearable
              isLoading={CFDIConceptsLoading}
              label='Uso del CFDI'
              menuPlacement='auto'
              menuPortalTarget={modalRef.current}
              menuPosition='absolute'
              name='select-cfdi-use'
              onChange={handleSelectChange}
              options={CFDIConceptsOptions}
              placeholder='Seleccionar uso del CFDI'
              value={CFDIConceptsValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              error={errors.paymentMethod}
              helperText='Debe seleccionar un método de pago'
              id='select-payment-method'
              isClearable
              isLoading={paymentMethodsLoading}
              label='Método de Pago'
              menuPlacement='auto'
              menuPortalTarget={modalRef.current}
              menuPosition='absolute'
              name='select-payment-method'
              onChange={handleSelectChange}
              options={paymentMethodsOptions}
              placeholder='Seleccionar método de Pago'
              value={paymentMethodsValue}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              error={errors.paymentType}
              helperText='Debe seleccionar una forma de pago'
              id='select-payment-type'
              isClearable
              isLoading={paymentMethodTypesLoading}
              label='Forma de pago'
              menuPlacement='auto'
              menuPortalTarget={modalRef.current}
              menuPosition='absolute'
              name='select-payment-type'
              onChange={handleSelectChange}
              options={paymentMethodTypesOptions}
              placeholder='Seleccionar forma de pago'
              value={paymentMethodTypesValue}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          fullWidth
          onClick={onClose}
          variant='contained'
        >
          Cancelar
        </Button>
        <Button
          color='primary'
          fullWidth
          onClick={handleGenerateBill}
          variant='contained'
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AutoStampModal.defaultProps = {
  onClose: undefined,
};

export default AutoStampModal;
