import {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { useDeepCompareEffect, useEffectOnce, useTimeout, useToggle } from 'react-use';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import { Select } from '../components';
import { getGraphQLParameters, getPostRequest } from '../helpers';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  cleanBankAccounts,
  cleanHolidays,
  cleanOptionsCatalog,
  cleanPeriodFrequencies,
  createPayrollType,
  fetchBankAccounts,
  fetchFrequenciesTypesCatalog,
  fetchHolidays,
  fetchPayrollsTypes,
  fetchPeriodFrequencies,
  setSelectedFrequencyType,
  setSelectedPeriodFrequency,
  setSelectedSociety,
  showMessage,
} from '../store/slices';
import { BankAccount, OperationalContact, Option, PayrollScheme, PayrollTypeAddVariables } from '../types';

function fillStringWithChars(text: number, char: number, length: number): string {
  try {
    const prefix = char.toString().repeat(length - text.toString().length);
    return prefix.concat(`${text}`);
  } catch (e) {
    return `${text}`;
  }
}

type SelectContacts = {
  fillPrepayrollContacs: string[],
  authorizePayrollContacts: string[],
  stampPayrollContacts: string[],
  vouchersPayrollContacts: string[],
};

const initialSelectContacts: SelectContacts = {
  fillPrepayrollContacs: [],
  authorizePayrollContacts: [],
  stampPayrollContacts: [],
  vouchersPayrollContacts: [],
}

interface Props {
  open: boolean;
  onClose?: () => void,
};

const AddPayrollModal: FC<Props> = ({
  open,
  onClose,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const modalRef = useRef<HTMLDivElement>(null);
  const [, , reset] = useTimeout(0);

  const [isLoading, toggleIsLoading] = useToggle(false);
  const [hasError, toggleHasError] = useToggle(false);

  const [payrollCode, setPayrollCode] = useState<string>('');
  const [payrollName, setPayrollName] = useState<string>('');

  const [contactsValues, setSelectContacts] = useState<SelectContacts>(initialSelectContacts);

  const client = useAppSelector(({ fullClients }) => fullClients.entities[fullClients.selected]);
  const holidaysMap = useAppSelector(({ holidays }) => holidays.entities);
  const bankAccountsMap = useAppSelector(({ bankAccounts }) => bankAccounts.entities);
  const society = useAppSelector(({ societies }) => societies.entities[societies.selected]);
  const frequency = useAppSelector(({ periodFrequencies }) => periodFrequencies.entities[periodFrequencies.selected]);
  const { options: frequenciesTypesOptions, selected: frequencyType } = useAppSelector(({ optionsCatalog }) => optionsCatalog.frequenciesTypes);

  const frequenciesOptions: Option[] = useAppSelector(({ periodFrequencies }) => Object
    .values(periodFrequencies.entities).map(({ id, name }) => ({ value: id, label: name })));

  const contactsOptions: Option[] = useMemo(() => {
    if (client === undefined) return [];
    if (client.contacts === null) return [];
    if (client.contacts.length === 0) return [];

    return client.contacts.map(({ id, name, email }) => ({ value: id, label: `${name} ( email: ${email} )` }));
  }, [client]);

  const handleSelectChange = (newValue: SingleValue<Option>, action: ActionMeta<Option>) => {
    const { name } = action;
    const { value } = newValue || { value: '' };

    if (name === 'payrollFrequency') {
      dispatch(setSelectedPeriodFrequency(value));
      return;
    };

    if (name === 'payrollFrequencyType') {
      dispatch(setSelectedFrequencyType(newValue));
      return;
    };
  }

  const handleContactsChange = (newValue: MultiValue<Option>, action: ActionMeta<Option>) => {
    const { name = '' } = action;
    const values = newValue.map(({ value }) => value);

    setSelectContacts((prevContact) => ({
      ...prevContact,
      [name]: values,
    }));
  };

  const handleSave = () => {
    toggleIsLoading(true);

    if (
      frequency === undefined
      || (frequency.id === '5d950b5e209d09672fea5531' && frequencyType === null)
    ) {
      toggleIsLoading(false);
      toggleHasError(true);
      return;
    }

    const bankAccountsArr = Object.values(bankAccountsMap);
    const bankAccountsByPayingCompany = _.groupBy(bankAccountsArr, 'payingCompany');
    const dispersionBankAccountsIDs = bankAccountsByPayingCompany[society.id].map(({ id }) => id);
    const dispersionBankAccountsNOM035 = bankAccountsByPayingCompany[society.linkedNOM035Society].map(({ id }) => id);
    const operationalContacts: OperationalContact[] = client.contacts
      .map(({ phone, schedule, LeadIDAPP4040, ...contact }) => ({ ...contact, phoneNumber: phone, }));
    const contactsMap = _.keyBy(operationalContacts, 'id');

    const variables: PayrollTypeAddVariables = {
      client: client.id,
      name: payrollName,
      key: payrollCode,
      frequency: frequency.id,
      taxSchema: PayrollScheme.SchemaTypePPPS,
      billingCompanyID: society.id,
      dispersingCompanyID: society.id,
      dispersingCompanyNOM035: society.linkedNOM035Society,
      operationalContacts: {
        prenomina: contactsValues.fillPrepayrollContacs.map((id) => (contactsMap[id])),
        nomina: contactsValues.authorizePayrollContacts.map((id) => (contactsMap[id])),
        facturas: contactsValues.stampPayrollContacts.map((id) => (contactsMap[id])),
        recibosNomina: contactsValues.vouchersPayrollContacts.map((id) => (contactsMap[id])),
      },
      periodCalculation: 30.4,
      holidayBonus: 25,
      bonus: 15,
      isSuperPayroll: false,
      payrollDeliveryDay: false,
      isHighDirection: false,
      isInterbank: false,
      holidays: _.get(Object.keys(holidaysMap), '[0]', ''),
      customerDepositBankAccountID: dispersionBankAccountsIDs[0],
      dispersionBankAccountsIDs,
      dispersionBankAccountsNOM035,
      contractStartAt: new Date(),
      calculationSettings: {
        imss: {
          defaultPayer: 'Cliente',
        },
        costoSocial: {
          defaultPayer: 'Cliente',
        },
        iva: {
          defaultPayer: 'Cliente',
        },
        isr: {
          defaultPayer: 'Cliente',
        },
        isn: {
          defaultPayer: 'Cliente',
        },
        commission: {
          defaultPayer: 'Cliente',
        },
      },
    };

    if (frequency.id === '5d950b5e209d09672fea5531'
      && frequencyType !== null) {
      variables.frequencyType = frequencyType.value;
    }

    dispatch(createPayrollType(variables))
      .then(() => {
        if (onClose) onClose();
        setSelectContacts(initialSelectContacts);
        dispatch(setSelectedPeriodFrequency(''));
        dispatch(setSelectedFrequencyType(null));
        toggleIsLoading(false);
        dispatch(fetchPayrollsTypes({ client: client.id }));
        dispatch(showMessage({
          type: 'success',
          message: 'Nómina agregada exitosamente',
        }))
      });
  };

  useEffectOnce(() => {
    dispatch(fetchPeriodFrequencies({}));

    return () => {
      dispatch(cleanBankAccounts());
      dispatch(cleanHolidays());
      dispatch(cleanOptionsCatalog());
      dispatch(cleanPeriodFrequencies());
    };
  });

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    if (client) {
      const { payment, payingCompanies } = client;

      const { frequencyTypes } = payment;
      dispatch(fetchFrequenciesTypesCatalog({ indexes: frequencyTypes }));
      dispatch(fetchHolidays());

      dispatch(fetchBankAccounts({ societyIDs: payingCompanies }))
        .then(({ payload }) => {
          const banckAccounts = payload as BankAccount[];
          const newBankAccount = banckAccounts.find(({ payingCompany }) => payingCompany.length > 0);
          if (newBankAccount) {
            dispatch(setSelectedSociety(newBankAccount.payingCompany));
          }
        });
    }
  }, [client, dispatch]);

  useEffect(() => {
    if (open && client) {
      const { code: clientCode } = client;
      const codeParameters = getGraphQLParameters('payrollTypeLargestCode', { client: client.id });
      getPostRequest('', codeParameters)
        .then(({ data }) => {
          const codeNumber: number = Number(_.get(data, 'data.payrollTypeLargestCode', 0)) + 1;
          const codePayroll = fillStringWithChars(codeNumber, 0, 2);
          // eslint-disable-next-line eqeqeq
          const codeClient = clientCode == 0 ? fillStringWithChars(clientCode, 0, 6) : clientCode;
          setPayrollCode(`${codeClient}-${codePayroll}`);
        });
    }
  }, [client, open]);

  useDeepCompareEffect(() => {
    if (society) {
      dispatch(fetchBankAccounts({ societyIDs: [society.id, society.linkedNOM035Society] }));
    }
  }, [dispatch, society]);

  useEffect(() => {
    let newPayrollName = '';
    if (client) newPayrollName += `${client.general.tradeName} `;
    if (frequency) newPayrollName += `${frequency.name.substring(0, 3)} `;
    if (society) newPayrollName += `${society.key} `;
    newPayrollName += PayrollScheme.SchemaTypeNom;
    setPayrollName(newPayrollName.toUpperCase());
  }, [client, frequency, society]);

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      ref={modalRef}
    >
      <DialogTitle>
        Agregar nueva nómina
      </DialogTitle>
      {isLoading && (<LinearProgress />)}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle2'>
              Para poder agregar un nuevo tipo de nómina, llena este formulario
            </Typography>
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label='Código de nómina'
              size='small'
              value={payrollCode}
            />
          </Grid>
          <Grid item xs={frequency?.id === '5d950b5e209d09672fea5531' ? 6 : 12}>
            <Select
              error={hasError && frequency === undefined}
              label='Periodo de nómina'
              menuPortalTarget={modalRef.current}
              name='payrollFrequency'
              onChange={handleSelectChange}
              options={frequenciesOptions}
            />
          </Grid>
          {frequency?.id === '5d950b5e209d09672fea5531' && (
            <Grid item xs={6}>
              <Select
                error={hasError && frequencyType === null}
                label='Periodo de nómina'
                menuPortalTarget={modalRef.current}
                name='payrollFrequencyType'
                onChange={handleSelectChange}
                options={frequenciesTypesOptions}
                value={frequencyType}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label='Esquema de nómina'
              size='small'
              value={PayrollScheme.SchemaTypeNom}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label='Empresa de nómina'
              size='small'
              value={society?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              label='Nombre de nómina'
              size='small'
              value={payrollName}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Typography variant='h4'>Contactos Operativos</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Llenar prenómina</InputLabel>
            <ReactSelect
              isMulti
              // error={hasError && '' === ''}
              menuPortalTarget={modalRef.current}
              name='fillPrepayrollContacs'
              onChange={handleContactsChange}
              options={contactsOptions}
              placeholder='Seleccione un contacto para llenar prenómina'
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Autorizar nómina</InputLabel>
            <ReactSelect
              isMulti
              // error={hasError && '' === ''}
              menuPortalTarget={modalRef.current}
              name='authorizePayrollContacts'
              onChange={handleContactsChange}
              options={contactsOptions}
              placeholder='Seleccione un contacto para autorizar nómina'
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Factura</InputLabel>
            <ReactSelect
              isMulti
              // error={hasError && '' === ''}
              menuPortalTarget={modalRef.current}
              name='stampPayrollContacts'
              onChange={handleContactsChange}
              options={contactsOptions}
              placeholder='Seleccione un contacto para facturar'
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Recibos de nómina timbrados</InputLabel>
            <ReactSelect
              isMulti
              // error={hasError && '' === ''}
              menuPlacement='top'
              menuPortalTarget={modalRef.current}
              name='vouchersPayrollContacts'
              onChange={handleContactsChange}
              options={contactsOptions}
              placeholder='Seleccione un contacto para recibos de nómina timbrados'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          fullWidth
          onClick={onClose}
          variant='contained'
        >
          Cancelar
        </Button>
        <Button
          color='primary'
          fullWidth
          onClick={handleSave}
          variant='contained'
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddPayrollModal.defaultProps = {
  onClose: undefined,
};

export default AddPayrollModal;
