export const extraordinaryPayrolls = `
query extraordinaryPayrolls($payrollPeriod: String!) {
  extraordinaryPayrolls(payrollPeriod: $payrollPeriod) {
    count
    data {
      amount
      commission
      employeeBankAccount {
        id
        CLABE
        bank
        accountNumber
      }
      employee {
        id
        key
        name
        firstLastName
        secondLastName
        bankAccounts {
          CLABE
          accountNumber
          bank
          cardDefault
          id
        }
        payrollBranch {
          id
          workRisk
        }
        grossSalary
      }
      id
      paymentMethod {
        id
        name
      }
      payrollPeriod {
        id
        name
        fullName
        payrollSchema
        type
      }
    }
  }
}
`;
