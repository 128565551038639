/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaginationState {
  page: number,
  limit: number,
  rowsPerPageOptions: number[],
}

const initialState: PaginationState = {
  page: 0,
  limit: 50,
  rowsPerPageOptions: [50, 100, 200],
};

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.page = payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      state.limit = payload;
    },
    setRowsPerPageOptions: (state, action: PayloadAction<number[]>) => {
      const { payload } = action;
      state.rowsPerPageOptions = payload;
    },
    cleanPagination: (state) => {
      state.page = 0;
      const [first] = state.rowsPerPageOptions;
      state.limit = first;
    },
  },
});

export const { cleanPagination, setLimit, setPage, setRowsPerPageOptions } = paginationSlice.actions;

export default paginationSlice.reducer;
