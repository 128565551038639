export const myEmployeesTableErrors = {
  name: {
    invalidCharacter: 'v2nameInvalidCharacter',
  },
  firstLastName: {
    invalidCharacter: 'v2firstLastNameInvalidCharacter',
  },
  secondLastName: {
    invalidCharacter: 'v2secondLastNameInvalidCharacter',
  },
  rfc: {
    regexStructure: 'v2rfcInvalidRegexStructure',
  },
  curp: {
    regexStructure: 'v2curpInvalidRegexStructure',
  },
  nss: {
    regexStructure: 'v2nssInvalidRegexStructure',
    verificationDigit: 'v2nssInvalidVerificationDigit',
  },
  citizen: {
    rfcCurpNssYearDate: 'v2citizenInvalidRfcCurpNssYear',
    rfcCurpBirthDate: 'v2citizenInvalidRfcCurpBirthDate',
  },
  tenureDate: {
    invalidDate: 'v2tenureDateInvalidDate',
  },
  incorporation: {
    invalidDate: 'v2incorporationInvalidDate',
  },
  dateOfFirstEntry: {
    invalidDate: 'v2dateOfFirstEntryInvalidDate',
  },
  email: {
    invalidEmail: 'v2emailInvalidEmail',
  },

  dictionary: {
    v2nameInvalidCharacter: 'El/Los nombre(s) del empleado tiene caractéres invalidos.',
    v2firstLastNameInvalidCharacter: 'El/Los primer(os) apellido(s) del empleado tiene caractéres invalidos.',
    v2secondLastNameInvalidCharacter: 'El/Los segundo(os) apellido(s) del empleado tiene caractéres invalidos.',
    v2emailInvalidEmail: 'El email del empleado tiene un formato invalido.',
    v2rfcInvalidRegexStructure: 'El RFC del empleado es invalido',
    v2curpInvalidRegexStructure: 'El CURP del empleado es invalido',
    v2nssInvalidRegexStructure: 'El NSS tiene un formato invalido',
    v2rfcInvalidBirthDate: 'La fecha del RFC no coincide con el CURP',
    v2curpInvalidBirthDate: 'La fecha del CURP no coincide con el RFC',
    v2nssInvalidVerificationDigit: 'El NSS tiene un digito verificador invalido',
    v2tenureDateInvalidDate: 'La fecha de antigüedad no puede ser establecida en el futuro',
    v2incorporationInvalidDate: 'La fecha de ingreso no puede ser establecida en el futuro',
    v2dateOfFirstEntryInvalidDate: 'La fecha de primer ingreso no puede ser establecida en el futuro',
    v2citizenInvalidRfcCurpNssYear: 'El RFC, CURP y NSS del empleado no coinciden en el año de nacimiento (Digito 5 y 6)',
    v2citizenInvalidRfcCurpBirthDate: 'El RFC y CURP del empleado no coinciden en el mes y dia de nacimiento (Digito 7, 8, 9, 10)',
  }

};