import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

// window.onload = () => {
// if (!localStorage.getItem('api_base_domain')) {
//   setTimeout(() => {
//     window.location.reload();
//   }, 500);
// }
localStorage.setItem('api_base_domain', 'https://graphql.levita.dev');
localStorage.setItem('api_node_base_domain', 'https://node.levita.dev');
// localStorage.setItem('api_node_base_domain', 'http://localhost:8000');
// localStorage.setItem('api_base_domain', 'https://api.levita.app');
// localStorage.setItem('api_node_base_domain', 'https://node.levita.app');
// localStorage.setItem('api_base_domain', 'https://staging.levita.app');

const topLevelDomain = document.location.host.split('.').pop();
if (topLevelDomain === 'dev') {
  localStorage.setItem('api_base_domain', 'https://graphql.levita.dev');
  localStorage.setItem('api_node_base_domain', 'https://node.levita.dev');
}
if (topLevelDomain === 'app') {
  localStorage.setItem('api_base_domain', 'https://api.levita.app');
  localStorage.setItem('api_node_base_domain', 'https://node.levita.app');
}

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// };
