/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { Dispersion, DispersionFilter } from '../../types';

export interface DispersionsState {
  count: number;
  entities: Record<string, Dispersion>;
  isLoading: boolean;
}

const initialState: DispersionsState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchDispersions = createAsyncThunk(
  'getdispersions/fetchDispersions',
  async (filters: DispersionFilter) => {

    if (!filters.justEmployees) {
      filters.justEmployees = true;
    }
    const { data: dataResponse, status } = await getPostRequest(
      'v2/dispersion/all',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const updatePayrollPeriodStampingStatus = createAsyncThunk(
  'getdispersions/updatePayrollPeriodStampingStatus',
  async (payrollPeriod: string) => {

    const filters: DispersionFilter = {
      payrollPeriod,
    }

    const { data: dataResponse, status } = await getPostRequest(
      'v2/dispersion/stamping-status',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const dispersionsSlice = createSlice({
  name: 'dispersions',
  initialState,
  reducers: {
    cleanDispersions: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDispersions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchDispersions.fulfilled,
        (state, action: PayloadAction<Dispersion[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
        }
      )
      .addCase(fetchDispersions.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanDispersions } = dispersionsSlice.actions;

export default dispersionsSlice.reducer;
