/* eslint-disable jsx-a11y/no-autofocus */
import { FC } from 'react';
import ReactDataSheet from 'react-datasheet';
import Select from 'react-select';

import Checkbox from '@mui/material/Checkbox';
import moment from 'moment-timezone';

import { cleanMoneyValue, findOption } from '../../helpers';
import { Cell, UploadFile, Value } from '../../types';
import Dropzone from './Dropzone';

type DataEditorProps<T = Cell, V = Value> = ReactDataSheet.DataEditorProps<
  T,
  V
> & {
  value: Value;
};

const getNoOptionsMessage = () => 'Sin opciones';

const DefaultDataEditor: FC<DataEditorProps> = (props): JSX.Element => {
  const {
    cell,
    onChange,
    value,
    onKeyDown,
    onCommit, // row, onRevert,
  } = props;
  const { type, value: cellValue } = cell;
  if (type === 'date') {
    const { minDateValue, maxDateValue } = cell;
    return (
      <input
        className="data-editor"
        max={maxDateValue && moment(maxDateValue).format('YYYY-MM-DD')}
        min={minDateValue && moment(minDateValue).format('YYYY-MM-DD')}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        type="date"
        value={moment(value).format('YYYY-MM-DD')}
      />
    );
  }
  if (type === 'money') {
    return (
      <input
        autoFocus
        className="data-editor"
        onChange={({ target }) => {
          const { value: newValue } = target;
          const cleanValue = cleanMoneyValue(newValue);
          if (!Number.isNaN(cleanValue) && cleanValue >= 0) {
            onChange(cleanValue);
          }
        }}
        onKeyDown={onKeyDown}
        // onRevert={onRevert}
        value={value as number}
      />
    );
  }
  if (type === 'select') {
    // blurInputOnSelect, closeMenuOnSelect, openMenuOnFocus
    const { options } = cell;
    const search = cellValue as string;
    const defaultOption = findOption(search, options);

    return (
      <Select
        autoFocus
        // closeOnSelect
        defaultValue={defaultOption}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuShouldScrollIntoView={false}
        noOptionsMessage={getNoOptionsMessage}
        onChange={(newValue) => onCommit(newValue)}
        openMenuOnFocus
        options={options}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({ ...base, minWidth: '250px' }),
          control: (base) => ({
            ...base,
            border: 'none',
            boxShadow: 'none',
            background: 'transparent',
          }),
        }}
      />
    );
  }
  if (type === 'boolean') {
    const options = [
      { label: 'Si', value: true },
      { label: 'No', value: false },
    ];
    return (
      <Select
        autoFocus
        // closeOnSelect
        defaultValue={value ? options[0] : options[1]}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuShouldScrollIntoView={false}
        noOptionsMessage={getNoOptionsMessage}
        // onChange={(optionSelected: Option) => {
        //   onCommit(optionSelected.value);
        //   return optionSelected;
        // }}
        onChange={(newValue) => onCommit(newValue?.value)}
        openMenuOnFocus
        options={options}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
    );
  }
  if (type === 'check') {
    return (
      <Checkbox
        checked={value as unknown as boolean}
        onChange={(event) => onCommit(event.target.checked)}
      />
    );
  }
  if (type === 'file') {
    return (
      <Dropzone
        onChange={onChange}
        onCommit={onCommit}
        value={cell.value as UploadFile}
      />
    );
  }
  return (
    <input
      autoFocus
      className="data-editor"
      onChange={(event) => {
        onChange(event.target.value);
      }}
      onKeyDown={onKeyDown}
      // onRevert={onRevert}
      value={value as string}
    />
  );
};

export default DefaultDataEditor;
