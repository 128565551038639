export const PayrollPeriodEdit = `
mutation PayrollPeriodEdit(
  $name: String
  $start: DateTime
  $incidentsEnd: DateTime
  $incidentsStart: DateTime
  $end: DateTime
  $payday: DateTime
  $administrativePeriod: Int
  $key: String
  $type: PayrollPeriodType
  $isClosed: Boolean
  $isAuthorized: Boolean
  $dispersionInformation: DispersionInformationInput
  $dispersionStatus: DispersionStatus
  $invoiceStatus: String
  $payrollApproveTracker: PayrollApproveTrackerInput
  $perceptionModifiers: [PerceptionModifiersPeriodConfigurationInput]
  $payrollStatus: PayrollStatus
  $accumulationMonth: AccumulationMonthInput
  $id: String!
  $autoStamp: Boolean
) {
  payrollPeriodEdit(
    name: $name
    start: $start
    incidentsEnd: $incidentsEnd
    incidentsStart: $incidentsStart
    end: $end
    payday: $payday
    administrativePeriod: $administrativePeriod
    invoiceStatus: $invoiceStatus
    dispersionStatus: $dispersionStatus
    key: $key
    type: $type
    isClosed: $isClosed
    isAuthorized: $isAuthorized
    dispersionInformation: $dispersionInformation
    payrollApproveTracker: $payrollApproveTracker
    perceptionModifiers: $perceptionModifiers
    payrollStatus: $payrollStatus
    accumulationMonth: $accumulationMonth
    id: $id
    autoStamp: $autoStamp
  ) {
    administrativePeriod
    name
    employeeStampStatus
    end
    id
    key
    payday
    clientApproval
    invoiceStatus
    dispersionInformation {
      date
    }
    start
    type
    incidentsEnd
    incidentsStart
    isClosed
    isAuthorized
    payrollStatus
    accumulationMonth {
      month
      year
    }
  }
}
`;

export const PayrollPeriodMarkAsFilled = `
mutation PayrollPeriodMarkAsFilled($id: ID!) {
  payrollPeriodMarkAsFilled(id: $id) {
    administrativePeriod
    name
    employeeStampStatus
    end
    id
    key
    payday
    clientApproval
    invoiceStatus
    dispersionInformation {
      date
    }
    start
    type
    incidentsEnd
    incidentsStart
    isClosed
    isAuthorized
    payrollStatus
    accumulationMonth {
      month
      year
    }
  }
}
`;

export const AddCalculatePPPSPayroll = `
mutation AddCalculatePPPSPayroll($payrollPeriod: String!) {
  pppsPayrollCalculate(payrollPeriod: $payrollPeriod) {
    amount
    totalToDisperse
    calculated
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
    }
    commission
    deductions {
      concepto
      importe
    }
    employee {
      id
      name
      firstLastName
      secondLastName
      key
      bankAccounts {
        accountNumber
        bank
        default
        cardDefault
        id
      }
      payrollBranch {
        id
        workRisk
      }
    }
    employeeBankAccount {
      id
      bank
      CLABE
      accountNumber
    }
    id
    paymentMethod {
      id
      name
    }
    payrollPeriod {
      id
      name
      payrollSchema
    }
    totalToDisperse
    workedDays
  }
}
`;

export const AddSummaryPPPSPayroll = `
  mutation AddSummaryPPPSPayroll($payrollPeriod: String!) {
    pppsSummaryAdd(payrollPeriod: $payrollPeriod) {
      client {
        id
        basic {
          comercialName
        }
        societies {
          id
          name
        }
        general {
          tradeName
          businessName
        }
      }
      id
      iva
      payrollPeriod {
        id
        name
        start
        end
      }
      subtotal
      totalToDisperse: totalAmount
      totalCommission
      totalInvoice
      totalPerceptions
      provision
      comisionProvision
      perceptionModifiers {
        amountPaidPerEmployee
        modifierName
        totalAmountToPay
      }
      clientPerceptionModifiers {
        amountPaidPerEmployee
        totalAmountToPay
        modifierName
        amount
        type
        discountValue
      }
      payrollPeriodDiscounts {
        amount
        modifierType {
          id
          name
          type
        }
      }
    }
  }
`;

export const EditSummaryPPPSPayroll = `
mutation EditSummaryPPPSPayroll(
  $id: String!
  $approved: Boolean
  $outstandingPayments: Float
  $bonuses: Float
  $provisionAguinaldo: Float
) {
  pppsSummaryEdit(
    id: $id
    approved: $approved
    outstandingPayments: $outstandingPayments
    bonuses: $bonuses
    provisionAguinaldo: $provisionAguinaldo
  ) {
    approved
    isSupervisorApproved
    approvedAt
    client {
      id
      basic {
        comercialName
      }
      societies {
        id
        name
      }
      general {
        tradeName
        businessName
      }
    }
    id
    iva
    payrollPeriod {
      id
      name
    }
    perceptionModifiers {
      amountPaidPerEmployee
      modifierName
      totalAmountToPay
    }
    payrollPeriodDiscounts {
      amount
      modifierType {
        id
        name
        type
      }
    }
    subtotal
    totalAmount
    totalCommission
    totalInvoice
    outstandingPayments
    bonuses
    comisionProvision
    provision
  }
}
`;

export const AddBillingPPPSPayroll = `
mutation AddBillingPPPSPayroll(
  $payrollSummary: String!
  $society: String!
  $concept: String
) {
  pppsBillingAdd(
    payrollSummary: $payrollSummary
    society: $society
    concept: $concept
  ) {
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
      societies {
        id
        name
      }
    }
    concept
    id
    paymentMethod
    payrollPeriod {
      fullName
      id
      name
      noministaExtraordinario {
        name
        firstLastName
        secondLastName
      }
      payrollType {
        name
        nominista {
          name
          firstLastName
          secondLastName
        }
      }
      start
      end
      key
      payday
      dispersionInformation {
        date
      }
    }
    pppsSummary {
      id
      iva
      subtotal
      totalAmount
      totalCommission
      totalInvoice
      perceptionModifiers {
        amountPaidPerEmployee
        modifierName
        totalAmountToPay
      }
      payrollPeriodDiscounts {
        amount
        modifierType {
          id
          name
          type
        }
      }
    }
    society {
      id
      name
    }
  }
}
`;

export const AddCalculateExtraordinaryPayroll = `
mutation AddCalculateExtraordinaryPayroll($payrollPeriod: String!) {
  extraordinaryPayrollCalculate(payrollPeriod: $payrollPeriod) {
    amount
    commission
    employee {
      id
      key
      name
      firstLastName
      secondLastName
      bankAccounts {
        id
        accountNumber
        bank
        default
        cardDefault
      }
    }
    employeeBankAccount {
      id
      bank
      accountNumber
      CLABE
    }
    id
    paymentMethod {
      id
      name
    }
    payrollPeriod {
      id
      name
      payrollSchema
    }
  }
}
`;

export const AddSummaryExtraordinaryPayroll = `
mutation AddSummaryExtraordinaryPayroll($payrollPeriod: String!) {
  extraordinaryPayrollSummaryAdd(payrollPeriod: $payrollPeriod) {
    approved
    isSupervisorApproved
    id
    iva
    client {
      id
      basic {
        comercialName
      }
      societies {
        id
        name
      }
      general {
        tradeName
        businessName
      }
    }
    payrollPeriod {
      id
      name
      key
      start
      end
      type
      payrollType {
        name
        isSuperPayroll
        id
        dispersingCompany {
          id
          name
        }
      }
      payday
      dispersionInformation {
        programmingDate
        date
      }
      approvedAt
      administrativePeriod
      fullName
    }
    retainedIVA
    subtotal
    totalAmount
    totalCommission
    totalInvoice
    totalToDisperse
    bonuses
    outstandingPayments
  }
}
`;

export const EditSummaryExtraordinaryPayroll = `
mutation EditSummaryExtraordinaryPayroll(
  $id: String!
  $approved: Boolean
  $bonuses: Float
  $retainedIVA: Float
  $outstandingPayments: Float
) {
  extraordinaryPayrollSummaryEdit(
    id: $id
    approved: $approved
    bonuses: $bonuses
    retainedIVA: $retainedIVA
    outstandingPayments: $outstandingPayments
  ) {
    approved
    isSupervisorApproved
    client {
      id
      basic {
        comercialName
      }
      societies {
        id
        name
      }
      general {
        tradeName
        businessName
      }
    }
    retainedIVA
    outstandingPayments
    bonuses
    id
    iva
    payrollPeriod {
      id
      name
      key
      approvedAt
      dispersionInformation {
        programmingDate
        date
      }
      fullName
      payrollType {
        name
        isSuperPayroll
        id
        dispersingCompany {
          id
          name
        }
      }
      type
      start
    }
    subtotal
    totalAmount
    totalCommission
    totalInvoice
    totalToDisperse
  }
}
`;

export const AddBillingExtraordinaryPayroll = `
mutation AddBillingExtraordinaryPayroll(
  $society: String!
  $concept: String
  $payrollSummary: String!
  $payrollPeriod: ID!
) {
  billingExtraordinaryPayrollAdd(
    society: $society
    concept: $concept
    payrollSummary: $payrollSummary
    payrollPeriod: $payrollPeriod
  ) {
    client {
      id
      basic {
        comercialName
      }
      general {
        tradeName
        businessName
      }
    }
    concept
    extraordinaryPayrollSummary {
      id
      iva
      isSupervisorApproved
      payrollPeriod {
        id
        name
      }
      subtotal
      totalAmount
      totalInvoice
    }
    id
    paymentMethod
    payrollPeriod {
      id
      name
      key
      start
      end
      payday
      dispersionInformation {
        date
        programmingDate
      }
      payrollType {
        name
        id
        isSuperPayroll
        nominista {
          id
          name
          secondLastName
          firstLastName
        }
      }
      fullName
      administrativePeriod
      type
    }
    society {
      id
      name
      key
      STPCode
    }
  }
}
`;

export const sfpayrollCalculate = `
mutation sfpayrollCalculate($payrollPeriod: String!) {
  sfpayrollCalculate(payrollPeriod: $payrollPeriod) {
    alimony
    awards
    bonus
    bonusExempt
    dailySalary
    diningRoom
    discountForPermits
    doubleExtraTimeSalary
    deducciones {
      empleado {
        concepto
        importe
      }
      cliente {
        concepto
        importe
      }
    }
    employee {
      id
      name
      firstLastName
      secondLastName
      key
      bankAccounts {
        id
        accountNumber
        bank
        cardNumber
        CLABE
        default
        cardDefault
      }
      payrollBranch {
        id
        workRisk
      }
    }
    employerAdditionalFixedFee
    employerAdditionalFixedFeeFactor
    employerDaycareAmount
    employerDaycareFactor
    employerDisabilityLifeFactorAmount
    employerDisabilityLifeFactorFactor
    employerFixedFeeAmount
    employerFixedFeeFactor
    employerIMSS
    employerInfonavitAmount
    employerInfonavitFactor
    employerJobRiskAmount
    employerJobRiskFactor
    employerMedicalExpensesAmount
    employerMedicalExpensesFactor
    employerMoneyFactorAmount
    employerMoneyFactorFactor
    employerRetirementAmount
    employerRetirementFactor
    employerUnemploymentOldAgeAmount
    employerUnemploymentOldAgeFactor
    employmentSubsidyTable {
      maximumIncome
      minimumIncome
      quantity
    }
    exempt
    extraTime
    extraTimeExempt
    extras
    fonacot
    fppToDisperse
    holidayWorked
    holidayWorkedExempt
    id
    imssCalculationDays
    imssNetSalary
    infonavit {
      amount
      discountType
      discountValue
      homeDamageInsurance
    }
    integratedDailyWage
    intelaCommission
    isnPercentage
    isnRetainedByClient
    isnRetainedByCompany
    isnTaxableBase
    isr
    isrToRetain
    marginalTax
    netSalary
    netToPay
    pantryVouchers
    paymentForSettlements
    periodDays
    prePayroll {
      id
      paymentMethod {
        id
        name
      }
      employeeBankAccount {
        id
        bank
        accountNumber
        CLABE
      }
      vacation
      vacationBonus
      sundaysWorked
      faults
      permissions
      doubleOvertime
      tripleOvertime
      restWorked
      holidayWorked
    }
    payrollPeriod {
      id
      payrollSchema
    }
    pretmex
    ptu
    ptuExempt
    restWorked
    restWorkedExempt
    retentionTable {
      fixedFee
      lowerLimit
      percentageOverSurplus
      upperLimit
    }
    salaryForDaysWorked
    salaryForSeventhDay
    savingFund
    socialCost
    sundayPremium
    sundayPremiumExempt
    surplus
    sysToDisperse
    taxBurden
    taxableBase
    threeUMAFactor
    totalCompensation
    totalDeductions
    totalLoan
    totalPaidInCash
    totalPerceptions
    totalSalaryForDaysWorked
    totalToDisperse
    totalWorkedDays
    totalWorkerIMSS
    tripleExtraTimeSalary
    twentyFiveUMAFactor
    uma
    umaFactor
    vacation
    vacationPremium
    vacationPremiumExempt
    workerAdditionalFixedFee
    workerAdditionalFixedFeeFactor
    workerDisabilityLifeFactorAmount
    workerDisabilityLifeFactorFactor
    workerIMSS
    workerMedicalExpensesAmount
    workerMedicalExpensesFactor
    workerMoneyFactorAmount
    workerMoneyFactorFactor
    workerUnemploymentOldAgeAmount
    workerUnemploymentOldAgeFactor
  }
}
`;

export const AddBillingSettlementPayroll = `
  mutation AddBillingSettlementPayroll($concept: String, $paymentMethod: PayrollPaymentMethod, $settlementSummary: String!, $society: String!) {
    settlementBillingAdd(concept: $concept, paymentMethod: $paymentMethod, settlementSummary: $settlementSummary, society: $society) {
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
        societies {
          id
          name
        }
      }
      concept
      id
      paymentMethod
      payrollPeriod {
        id
        name
        key
        start
        end
        payday
        dispersionInformation {
          date
        }
      }
      society {
        id
        name
      }
    }
  }
`;

export const AddBillingEditPayroll = `
  mutation AddBillingEditPayroll($secondConcept: String, $paymentMethod: PayrollPaymentMethod, $difference: Float, $id: String!, $payrollSummary: String, $society: String, $concept: String) {
    billingSummaryEdit(secondConcept: $secondConcept, paymentMethod: $paymentMethod, difference: $difference, id: $id, payrollSummary: $payrollSummary, society: $society, concept: $concept) {
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
      }
      concept
      department {
        id
        name
      }
      difference
      id
      paymentMethod
      payrollPeriod {
        id
        name
        key
        start
        end
        payday
        dispersionInformation {
          date
        }
        payrollType {
          id
          name
          dispersingCompany {
            id
            name
          }
          nominista {
            id
            name
            firstLastName
            secondLastName
          }
        }
      }
      payrollSummary {
        isFromHighDirectionSociety
        id
      }
      retainedIVA
      society {
        id
        name
      }
      secondConcept
      billingExtraordinarySummaries {
        client {
          id
          basic {
            comercialName
          }
          general {
            tradeName
            businessName
          }
        }
        concept
        id
        paymentMethod
        payrollPeriod {
          id
          name
          key
          start
          end
          payrollType {
            id
            name
            dispersingCompany {
              id
              name
            }
            nominista {
              id
              name
              firstLastName
              secondLastName
            }
          }
        }
        society {
          id
          name
        }
        extraordinaryPayrollSummary {
          approved
          approvedAt
          bonuses
          client {
            id
            basic {
              comercialName
            }
            general {
              tradeName
              businessName
            }
          }
          isSupervisorApproved
          id
          iva
          outstandingPayments
          payrollPeriod {
            id
            name
          }
          retainedIVA
          subtotal
          totalAmount
          totalCommission
          totalInvoice
        }
      }
    }
  }
`;

export const AddBillingPayroll = `
  mutation AddBillingPayroll($society: String!, $concept: String, $payrollSummary: String!) {
    billingSummaryAdd(society: $society, concept: $concept, payrollSummary: $payrollSummary) {
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
      }
      concept
      department {
        id
        name
      }
      difference
      id
      paymentMethod
      payrollPeriod {
        id
        name
        key
        start
        end
        payday
        dispersionInformation {
          date
        }
        payrollType {
          id
          name
          dispersingCompany {
            id
            name
          }
          isSuperPayroll
          nominista {
            id
            name
            firstLastName
            secondLastName
          }
        }
      }
      payrollSummary {
        id
        isFromHighDirectionSociety
      }
      retainedIVA
      society {
        id
        name
      }
      secondConcept
      billingExtraordinarySummaries {
        client {
          id
          basic {
            comercialName
          }
          general {
            tradeName
            businessName
          }
        }
        concept
        id
        paymentMethod
        payrollPeriod {
          id
          name
          key
          start
          end
          payrollType {
            id
            name
            dispersingCompany {
              id
              name
            }
            billingCompany {
              id
              name
            }
            isSuperPayroll
            nominista {
              id
              name
              firstLastName
              secondLastName
            }
          }
        }
        society {
          id
          name
        }
        extraordinaryPayrollSummary {
          approved
          approvedAt
          bonuses
          isSupervisorApproved
          client {
            id
            basic {
              comercialName
            }
            general {
              tradeName
              businessName
            }
          }
          id
          iva
          outstandingPayments
          payrollPeriod {
            id
            name
            key
            start
            end
            payrollType {
              id
              name
              dispersingCompany {
                id
                name
              }
              isSuperPayroll
              nominista {
                id
                name
                firstLastName
                secondLastName
              }
            }
          }
          retainedIVA
          subtotal
          totalAmount
          totalCommission
          totalInvoice
        }
      }
    }
  }
`;

export const payrollApproveSendEmail = `
mutation payrollApproveSendEmail($id: ID!) {
  payrollApproveSendEmail(id: $id)
}
`;

export const DispersadorSendEmail = `
  query DispersadorSendEmail($id: ID!) {
    dispersadorSendEmail(id: $id)
  }
`;
