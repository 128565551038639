/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Column } from '../../../types';

export default function createColumns(): Column[] {
  return [
    {
      title: '',
      field: 'selected',
      type: 'check',
      // @ts-ignore
      defaultValue: false,
    },
    // {
    //   title: 'Estatus',
    //   field: 'status',
    //   pathValue: 'status',
    //   isEnabled: false,
    // },
    {
      title: 'Clave',
      field: 'key',
      pathValue: 'key',
      isEnabled: false,
    },
    {
      title: 'Nombre completo',
      field: 'fullName',
      pathValue: 'fullName',
      isEnabled: false,
    },
    {
      title: 'Fecha de baja',
      field: 'date',
      type: 'date',
      pathValue: 'date',
      isRequired: true,
    },
    {
      title: 'Tipo de baja',
      field: 'dismissalTypeID',
      isRequired: true,
      type: 'select',
      options: [],
      defaultValue: '',
    },
    {
      title: 'Causa de baja',
      field: 'dismissalCauseID',
      type: 'select',
      isRequired: true,
      defaultValue: '',
    },
  ];
}
