import { FC } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  // DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface Props {
  open: boolean
  title?: string
  onClose?: () => void
  onConfirm?: () => void
}

const ConfirmationModal: FC<Props> = ({
  open,
  title,
  onClose,
  onConfirm,
}): JSX.Element => (
  <Dialog onClose={onClose} open={open}>
    <DialogTitle id='alert-dialog-title'>
      <Typography fontWeight="bold" variant="h5">
        {title}
      </Typography>
    </DialogTitle>
    <DialogActions>
      <Button
        color='error'
        fullWidth
        onClick={onClose}
        variant='contained'
      >
        Cancelar
      </Button>
      <Button
        color='primary'
        fullWidth
        onClick={onConfirm}
        variant='contained'
      >
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationModal.defaultProps = {
  title: '¿Desea continuar?',
  onClose: () => {},
  onConfirm: () => {},
};

export default ConfirmationModal;
