import _ from 'lodash';

import {
  clabe,
  CURPpattern,
  doesCurpAndImssMatches,
  doesRfcAndCurpMatches,
  doesRfcAndImssMatches,
  getGetRequest,
  NSSPattern,
  RFCpattern,
} from '../../../helpers';
import { Column } from '../../../types';

export const filteredColumns: Column[] = [
  {
    title: 'Numero de empleado cliente',
    field: 'clientNumberEmployee',
  },
  {
    title: 'Nombre',
    field: 'name',
    isRequired: true,
  },
  {
    title: 'Apellido Paterno',
    field: 'firstLastName',
    isRequired: true,
  },
  {
    title: 'Apellido Materno',
    field: 'secondLastName',
    // isRequired: true,
  },
  {
    title: 'RFC',
    field: 'rfc',
    isRequired: true,
    validation: async (value, cell, { entities }) => {
      const rfc = value as string;
      if (rfc.length === 0) {
        return { isValid: true };
      }

      if (!RFCpattern.test(rfc)) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'El rfc no tiene el formato apropiado',
          },
        };
      }

      const isDuplicated = _.values(entities)
        .some(currentEmployee => cell.id !== currentEmployee.id && value === currentEmployee.rfc);
      if (isDuplicated) {
        return {
          isValid: false,
          error: {
            cell,
            message: `El rfc ${rfc} ya fue utilizado`,
          },
        };
      }

      const curp = _.get(entities, `${cell.id}.curp`, '') as string;
      const imss = _.get(entities, `${cell.id}.imss`, '') as string;
      const matchWithCurp = doesRfcAndCurpMatches({ rfc, curp });
      const matchWithImss = doesRfcAndImssMatches({ rfc, imss });
      if (!matchWithCurp && !matchWithImss) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'El rfc no coincide con la curp ni el imss',
          },
        };
      }
      return { isValid: true };
    },
  },
  {
    title: 'CURP',
    field: 'curp',
    isRequired: true,
    validation: (value, cell, { entities }) => {
      const curp = value as string;

      if (curp.length === 0) {
        return { isValid: true };
      }

      if (!CURPpattern.test(curp)) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'La curp no posee el formato correcto',
          },
        };
      }
      const rfc = _.get(entities, `${cell.id}.rfc`, '') as string;
      const imss = _.get(entities, `${cell.id}.imss`, '') as string;
      const matchWithRfc = doesRfcAndCurpMatches({ rfc, curp });
      const matchWithImss = doesCurpAndImssMatches({ curp, imss });
      if (!matchWithImss && !matchWithRfc) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'La curp no coincide con el imss ni el rfc',
          },
        };
      }
      return { isValid: true };
    },
    forceRevalidation: ['imss'],
  },
  {
    title: 'Numero de seguro social',
    field: 'imss',
    isRequired: true,
    validation: (value, cell, { entities }) => {
      const imss = value as string;

      if (imss.length === 0) {
        return { isValid: true };
      }

      const isDuplicated = _.values(entities)
        .some(employee => cell.id !== employee.id && imss === employee.imss);
      if (isDuplicated) {
        return {
          isValid: false,
          error: {
            cell,
            message: `El imss ${imss} ya fue utilizado`,
          },
        };
      }

      if (!NSSPattern.test(imss)) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'El numero de seguro social no tiene el formato apropiado',
          },
        };
      }
      const rfc = _.get(entities, `${cell.id}.rfc`, '') as string;
      const curp = _.get(entities, `${cell.id}.curp`, '') as string;
      const matchWithRfc = doesRfcAndImssMatches({ rfc, imss });
      const matchWithCurp = doesCurpAndImssMatches({ curp, imss });
      if (!matchWithCurp && !matchWithRfc) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'El numero de seguro social no coincide con el rfc ni la curp',
          },
        };
      }
      return { isValid: true };
    },
    forceRevalidation: ['rfc', 'curp'],
    cleanOnChange: ['validatedIdse'],
  },
  {
    title: 'Fecha de ingreso',
    field: 'entryDate',
    type: 'date',
    isRequired: true,
  },
  {
    title: 'Fecha de primer ingreso',
    field: 'firstEntryDate',
    type: 'date',
    isRequired: true,
  },
  {
    title: 'Fecha de antiguedad',
    field: 'antiqueDate',
    type: 'date',
    defaultValue: new Date(),
    isRequired: true,
  },
  {
    title: 'Departamento',
    field: 'department',
    type: 'select',
    defaultValue: '60c377d230fbeb8e45aadc3d',
    defaultLabel: 'General',
    isRequired: true,
  },
  {
    title: 'Salario Diario Nominal',
    field: 'daylyPayrollSalary',
    type: 'money',
  },
  {
    title: 'Jornada Laboral',
    field: 'workdayType',
    type: 'select',
    isRequired: true,
  },
  {
    title: 'Tipo de empleado',
    field: 'employeeType',
    type: 'select',
    defaultValue: 'Operativo',
    defaultLabel: 'Operativo',
  },
  {
    title: 'Puesto',
    field: 'job',
    type: 'select',
    isRequired: true,
  },
  {
    title: 'Domicilio del trabajo',
    field: 'addressWhereWork',
  },
  {
    title: 'Estado donde labora',
    field: 'stateWhereWork',
    type: 'select',
    isRequired: true,
    cleanValuesOnChange: ['municipalityWhereWork'],
  },
  {
    title: 'Municipio del trabajo',
    field: 'municipalityWhereWork',
    type: 'select',
    isRequired: true,
  },
  {
    title: 'Codigo postal del trabajo',
    field: 'cpWhereWork',
  },
  {
    title: 'Domicilio donde habita',
    field: 'address',
  },
  {
    title: 'Numero exterior',
    field: 'outsideNumber',
  },
  {
    title: 'Numero interior',
    field: 'insideNumber',
  },
  {
    title: 'Colonia',
    field: 'colony',
  },
  {
    title: 'Estado',
    field: 'state',
    type: 'select',
    width: '500px',
    cleanValuesOnChange: ['municipality'],
  },
  {
    title: 'Municipio',
    field: 'municipality',
    type: 'select',
  },
  {
    title: 'Pais',
    field: 'country',
    defaultValue: 'Mexico',
  },
  {
    title: 'Codigo postal',
    field: 'cp',
  },
  {
    title: 'Email',
    field: 'email',
    type: 'email',
  },
  {
    title: 'Estado Civil',
    field: 'civilStatus',
    type: 'select',
  },
  {
    title: 'Nacionalidad',
    field: 'nacionality',
    defaultValue: 'Mexicana',
  },
  {
    title: 'Metodo de Pago',
    field: 'paymentMethod',
    type: 'select',
  },
  {
    title: 'CLABE',
    field: 'clabe',
    validation: async (value, cell) => {
      if (value === '') {
        return {
          isValid: true,
        };
      }
      const clabeVal = value as string;
      const clabeValidation = clabe.validate(clabeVal);
      if (!clabeValidation.ok) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'La CLABE no tiene el formato adecuado',
          },
        };
      }
      const { data: clabeValidationResponse } = await getGetRequest(`v2/bankaccount/exists/${value}-`);
      const { data } = clabeValidationResponse;
      const { exists, who } = data;
      if (exists) {
        return {
          isValid: false,
          error: {
            cell,
            message: `La clabe ${clabeVal} ya esta registrada para ${who}`,
          },
        };
      }
      return {
        isValid: true,
      };
    },
    cleanOnChange: ['bankAccountAccountNumber'],
  },
  {
    title: 'Cuenta',
    field: 'bankAccountAccountNumber',
    validation: async (value) => {
      if (value === '') {
        return {
          isValid: true,
        };
      }
      const accountNumber = value as string; 
      const cuentaPattern11 = /^\d{11}$/;
      const cuentaPattern10 = /^\d{10}$/;
      const cuentaPattern9 = /^\d{9}$/;
      const validation = (cuentaPattern9.test(accountNumber) || cuentaPattern10.test(accountNumber) || cuentaPattern11.test(accountNumber));
      if (!validation) {
        return {
          isValid: false,
          error: {
            message: `El numero de cuenta ${accountNumber} no esta en el formato adecuado`,
          },
        };
      }

      const { data: clabeValidationResponse } = await getGetRequest(`v2/bankaccount/exists/-${accountNumber}`);
      const { data } = clabeValidationResponse;
      const { exists, who } = data;
      if (exists) {
        return {
          isValid: false,
          error: {
            message: `La cuenta de banco ${accountNumber} ya esta registrada para ${who}`,
          },
        };
      }

      return {
        isValid: true,
      };
    },
  },
  {
    title: 'Banco',
    field: 'bank',
  },
  {
    title: 'Sucursal de banco',
    field: 'bankAccountBranchNumber',
  },
  {
    title: 'IDSE Validado',
    field: 'validatedIdse',
    type: 'check',
    defaultValue: false,
    isEnabled: false,
    buttonComponentPath: 'components/EmployeeIdseConfirmationButton',
    isRequired: true,
  },
];
