import { FC, useCallback, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Dropzone } from 'components';
import { makeAFilePublic } from 'helpers';
import { UploadFile } from 'types';

interface Props {
  isLoading?: boolean;
  onAccept: (vouchers: UploadFile[]) => void;
  onClose: () => void;
  open: boolean;
  title?: string;
  content?: string;
  minCountOfFiles?: number;
  maxCountOfFiles?: number;
  needsToBePublic?: boolean;
  bucket?: string;
  initialVouchers?: UploadFile[];
  confirmButtonText?: string;
};

const UploaderFileModal: FC<Props> = ({
  content,
  onClose,
  open,
  title,
  onAccept,
  isLoading,
  minCountOfFiles = 1,
  maxCountOfFiles,
  needsToBePublic,
  bucket,
  initialVouchers = [],
  confirmButtonText,
}) => {
  const [vouchers, setVouchers] = useState<UploadFile[]>(initialVouchers);

  const placeholder: string = useMemo(() => {
    let value = `Aquí debes subir al menos ${minCountOfFiles} archivo(s),`;
    if (minCountOfFiles === maxCountOfFiles) {
      if (maxCountOfFiles === 1) {
        value = `Aquí debes subir el archivo,`;
      } else {
        value = `Aquí debes subir los ${maxCountOfFiles} archivos,`;
      }
    } else if (maxCountOfFiles) {
      value += ` y máximo ${maxCountOfFiles} archivo(s),`
    }
    value += ` solo se permiten archivos del tipo PDF, JPG, JPEG y PNG`;
    return value;
  }, [maxCountOfFiles, minCountOfFiles]);

  const handleAccept = () => {
    if (onAccept) {
      onAccept(vouchers);
    }
  };

  const handleDropChange = useCallback((newfiles: UploadFile[]) => {
    setVouchers((prevVouchers = []) => {
      const nextVouchers = prevVouchers.concat(newfiles);
      if (maxCountOfFiles !== undefined && nextVouchers?.length > maxCountOfFiles) {
        nextVouchers.splice(maxCountOfFiles);
      }
      return nextVouchers;
    });
    if (needsToBePublic) {
      newfiles.forEach((file) => {
        makeAFilePublic(file, bucket); // TODO REVISAR LO DE LA EXTENSION -DEV 'levita-uploads-dev'
      });
    }
  }, [bucket, maxCountOfFiles, needsToBePublic, setVouchers]);

  const handleDropRemove = useCallback((removedFile: UploadFile) => {
    setVouchers((prevVouchers = []) => {
      const indexToRemove = prevVouchers?.indexOf(removedFile);
      const nextVouchers = [...prevVouchers];
      nextVouchers.splice(indexToRemove, 1);

      return nextVouchers;
    });
  }, [setVouchers]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography fontWeight='bold' variant='h5'>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}
        {content && (<DialogContentText>{content}</DialogContentText>)}
        <Dropzone
          bucket={bucket}
          files={vouchers}
          onChange={handleDropChange}
          onRemoveFile={handleDropRemove}
          placeHolder={placeholder}
          style={{ maxHeight: '320px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          disabled={isLoading}
          fullWidth
          onClick={onClose}
          variant='contained'
        >
          Cerrar
        </Button>
        <Button
          color='success'
          disabled={isLoading || vouchers.length < minCountOfFiles}
          fullWidth
          onClick={handleAccept}
          variant='contained'
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploaderFileModal.defaultProps = {
  isLoading: false,
  title: 'Seleccione sus archivos',
  minCountOfFiles: 1,
  maxCountOfFiles: undefined,
  content: '',
  needsToBePublic: false,
  bucket: 'levita-uploads',
  initialVouchers: [],
  confirmButtonText: 'Aceptar',
};

export default UploaderFileModal;

