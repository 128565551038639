/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
import Select, {components} from 'react-select';

type Value = {
  name: string;
  id: string;
};

interface Props<V extends Value> {
  value: V | undefined;
  options: V[];
  onChange: (value: V | undefined) => void;
  loading?: boolean;
}

const Input = (props: any) => <components.Input {...props} autoComplete="off" />

function PayrollTypeSelect<V extends Value>({
  value,
  onChange,
  options,
  loading,
}: Props<V>) {
  return (
    <Select
      components={{
        Input
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isClearable
      isLoading={loading}
      onChange={(option) => {
        if (option) onChange(option);
        else onChange(undefined);
      }}
      options={options}
      placeholder="Seleccione nómina"
      styles={{
        control: (provided) => ({
          ...provided,
          // !!Based on TextField height.
          height: 56,
          zIndex: 1,
          border: 'none',
          // !!Taken from Paper box-shadow.
          boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      value={value}
    />
  );
}

PayrollTypeSelect.defaultProps = {
  loading: false,
};

export default PayrollTypeSelect;
