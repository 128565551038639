import {
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { getObjectMap, getRowsGrid } from 'helpers';
import _ from 'lodash';
import {
  Cell,
  CellUpdate,
  CellUpdatesState,
  EntitiesState,
  Entity,
  GetInitMetadata,
  GridKey,
  HandleChanges,
  Header,
  Metadata,
  ObjectMap,
} from 'types';

import useStateLocalStorage from '../useStateLocalStorage';

type Config = {
  handleChangesRef: MutableRefObject<HandleChanges | undefined>,
  updateCellAtributesRef: MutableRefObject<((row: GridKey, col: GridKey, newAttributes: CellUpdate) => void) | undefined>,
  getInitMetadata?: GetInitMetadata;
  id?: string;
  metadata?:  Metadata;
};

type UseDataSheetRows = {
  rowsGrid: Cell[][];
  rowsMapRef: MutableRefObject<ObjectMap>,
  updateCellAtributes: (
    row: string,
    field: string,
    nextAtributtes: CellUpdate
  ) => void;
  cleanCellUpdate: (rowId: string, colId: string, attributeKey: string) => void;
  cleanCellUpdates: (rowId: string, colId: string) => void;
  cleanRowUpdates: (rowId: string) => void;
  cleanMultipleRowUpdates: (rowId: string[]) => void;
  cleanNonRenderedUpdates: () => void;
  removeCellsUpdatesStorage: () => void;
  swapUpdatesBetweenRows: (swaps: {from: string, to: string}[]) => void;
};

const useDataSheetRows = (
  header: Header[],
  entities: Entity[],
  entitiesMapRef: MutableRefObject<EntitiesState>,
  {
    handleChangesRef,
    updateCellAtributesRef,
    metadata,
    getInitMetadata,
    id,
  }: Config,
): UseDataSheetRows => {
  const { pathname } = useLocation();

  const [
    cellsUpdates = {},
    setCellsUpdates,
    removeCellsUpdatesStorage,
  ] = useStateLocalStorage<CellUpdatesState>(`useDataSheetRows_cellsUpdates${pathname}${id}`, {});
  const [extraCellsUpdates, setExtraCellsUpdates] = useState<CellUpdatesState>();

  const rowsMapRef = useRef<ObjectMap>(getObjectMap(entities, 'id'));

  const rowsGrid = useMemo(() => {
    rowsMapRef.current = getObjectMap(entities, 'id');

    return getRowsGrid(
      header,
      entities,
      entitiesMapRef,
      handleChangesRef,
      updateCellAtributesRef,
      metadata,
      getInitMetadata,
      cellsUpdates,
      extraCellsUpdates,
    );
  }, [cellsUpdates, entities, entitiesMapRef, extraCellsUpdates, getInitMetadata, handleChangesRef, updateCellAtributesRef, header, metadata]);

  const updateCellAtributes = useCallback((
    rowId: string,
    field: string,
    nextAtributtes: CellUpdate
  ) => {
    const { onClick } = nextAtributtes;
    const nextExtraUpdates = { onClick };

    setCellsUpdates((prevCellsUpdates) => {
      const nextCellsUpdates = {
        ...prevCellsUpdates,
        [rowId]: {
          ..._.get(prevCellsUpdates, rowId, {}),
          [field]: {
            ..._.get(prevCellsUpdates, `${rowId}.${field}`, {}),
            ...nextAtributtes,
            ...('isEnabled' in nextAtributtes && {
              readOnly: !nextAtributtes.isEnabled,
            }),
          },
        },
      };
      return nextCellsUpdates;
    });

    setExtraCellsUpdates((prevExtraUpdates) => ({
      ...prevExtraUpdates,
      [rowId]: {
        ..._.get(prevExtraUpdates, rowId, {}),
        [field]: {
          ..._.get(prevExtraUpdates, `${rowId}.${field}`, {}),
          ...nextExtraUpdates,
        },
      }
    }));
  }, [setCellsUpdates]);

  const cleanCellUpdate = useCallback((rowId: string, colId: string, attributeKey: string) => {
    setCellsUpdates((prevCellsUpdates) => {
      const nextCellsUpdates = { ...prevCellsUpdates };

      if (nextCellsUpdates[rowId] && nextCellsUpdates[rowId][colId]) {
        delete nextCellsUpdates[rowId][colId][attributeKey];
      }

      return nextCellsUpdates;
    });
  }, [setCellsUpdates]);

  const cleanCellUpdates = useCallback((rowId: string, colId: string) => {
    setCellsUpdates((prevCellsUpdates) => {
      const nextCellsUpdates = { ...prevCellsUpdates };

      if (nextCellsUpdates[rowId]) {
        delete nextCellsUpdates[rowId][colId];
      }

      return nextCellsUpdates;
    });
  }, [setCellsUpdates]);

  const cleanRowUpdates = useCallback((rowId: string) => {
    setCellsUpdates((prevCellsUpdates) => {
      const nextCellsUpdates = { ...prevCellsUpdates };
      delete nextCellsUpdates[rowId];
      return nextCellsUpdates;
    });
  }, [setCellsUpdates]);

  const cleanMultipleRowUpdates = useCallback((rows: (string)[]) => {
    setCellsUpdates((prevCellsUpdates) => {
      const nextCellsUpdates = { ...prevCellsUpdates };

      rows.forEach((rowId) => {
        delete nextCellsUpdates[rowId];
      });

      return nextCellsUpdates;
    });
  }, [setCellsUpdates]);

  const cleanNonRenderedUpdates = useCallback(() => {
    setCellsUpdates((prevCellsUpdates) => {
      const fields = Object.values(rowsMapRef.current);
      const nextCellsUpdates: CellUpdatesState = _.pick(prevCellsUpdates, fields) as CellUpdatesState;
      return nextCellsUpdates;
    })
  }, [setCellsUpdates]);

  const swapUpdatesBetweenRows = useCallback((swaps: {from: string, to: string}[]) => {
    const nextCellsUpdates = { ...cellsUpdates };

    swaps.forEach(({ from, to }) => {
      const updatesHelper = cellsUpdates[to];
      nextCellsUpdates[to] = cellsUpdates[from];
      nextCellsUpdates[from] = updatesHelper;
    });
    setCellsUpdates(nextCellsUpdates);
  }, [cellsUpdates, setCellsUpdates]);

  return {
    rowsGrid,
    rowsMapRef,
    updateCellAtributes,
    removeCellsUpdatesStorage,
    cleanCellUpdate,
    cleanCellUpdates,
    cleanRowUpdates,
    cleanMultipleRowUpdates,
    cleanNonRenderedUpdates,
    swapUpdatesBetweenRows,
  };
};

export default useDataSheetRows;
