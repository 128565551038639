import { RRule } from 'rrule';

import BiWeek from './BiWeekClass';
import Decennial from './DecennialClass';
import Fortnight from './FortnightClass';
import Week from './WeekClass';

const currentDate = new Date();
const year = currentDate.getFullYear();

const frequenciesGenerator = (frequency: string | number) => {
  switch (frequency) {
    case 'Semanal': {
      const rule = new RRule({
        freq: RRule.WEEKLY,
        dtstart: new Date(year, 0, 1, 0, 0, 0),
        until: new Date(year + 1, 0, 1, 0, 0, 0),
        interval: 1,
        byweekday: RRule.MO
      });
      const weeks = rule.all().map((el) => new Week(el));
      const index = weeks.findIndex((week) => week.isBetween(currentDate));
      return index + 1;
    }
    case 'Quincenal': {
      const rule = new RRule({
        freq: RRule.MONTHLY,
        dtstart: new Date(year, 0, 1, 0, 0, 0),
        until: new Date(year + 1, 0, 1, 0, 0, 0),
        interval: 1,
        bymonthday: [1, 16]
      });
      const fortnights = rule.all().map((el) => new Fortnight(el));
      const index = fortnights.findIndex((fortnight) => fortnight.isBetween(currentDate));
      return index + 1;
    }
    case 'Catorcenal': {
      const rule = new RRule({
        freq: RRule.WEEKLY,
        dtstart: new Date(year, 0, 1, 0, 0, 0),
        until: new Date(year + 1, 0, 1, 0, 0, 0),
        interval: 2
      })
      const biweeks = rule.all().map((el) => new BiWeek(el));
      const index = biweeks.findIndex((biweek) => biweek.isBetween(currentDate));
      return index + 1;
    }
    case 'Decenal': {
      const rule = new RRule({
        freq: RRule.DAILY,
        dtstart: new Date(year, 0, 1, 0, 0, 0),
        until: new Date(year + 1, 0, 1, 0, 0, 0),
        interval: 10
      });
      const decennials = rule.all().map((el) => new Decennial(el));
      const index = decennials.findIndex((decennial) => decennial.isBetween(currentDate));
      return index + 1;
    }
    case 'Mensual': {
      const month = new Date().getMonth();
      return month + 1;
    }
    default: break;
  }
  return 0;
}

export default frequenciesGenerator;
