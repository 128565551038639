import bankAccountDraftsReducer from './bankAccountDraftsSlice';
import bankAccountsReducer from './bankAccountsSlice';
import chatReducer from './chatSlice';
import civilStatusReducer from './civilStatusSlice';
import clientsReducer from './clientsSlice';
import contractReducer from './contractsSlice';
import contractTypesReducer from './contractTypesSlice';
import deadlocksReducer from './deadlocksSlice';
import departmentsReducer from './departmentsSlice';
import dispersionsReducer from './dispersionsSlice';
import documentsReducer from './documentsSlice';
import employeeDraftsReducer from './employeeDraftsSlice';
import employeesReducer from './employeesSlice';
import frequenciesReducer from './frequencies';
import fullClientsReducer from './fullClientsSlice';
import holidaysReducer from './holidaysSlice';
import jobsReducer from './jobsSlice';
import kardexReducer from './kardexSlice';
import municipalitiesReducer from './municipalitiesSlice';
import optionsCatalogReducer from './optionsCatalogSlice';
import paginationReducer from './paginationSlice';
import paymentMethodsReducer from './paymentMethodsSlice';
import payrollCalculationSlice from './payrollCalculationSlice';
import payrollReceiptReducer from './payrollReceiptSlice';
import payrollsTypesReducer from './payrollsTypesSlice';
import pendingTasksReducer from './pendingTasksSlice';
import pensionTypesReducer from './pensionTypesSlice';
import periodFrequenciesReducer from './periodFrequenciesSlice';
import periodReducer from './periodSlice';
import payrollReducer from './periodsSlice';
import reentryEmployeesReducer from './reentryEmployeesSlice';
import salaryTypesReducer from './salaryTypesSlice';
import sessionReducer from './sessionSlice';
import snackbarMessageReducer from './snackbarMessageSlice';
import societiesReducer from './societiesSlice';
import societyBranchesReducer from './societyBranchesSlice';
import stampingErrorsReducer from './stampingErrorsSlice';
import statesReducer from './statesSlice';
import variableSalariesReducer from './variableSalariesSlice';
import workdaysReducer from './workdaysSlice';
import worksReducer from './worksSlice';

const RootReducer = {
  session: sessionReducer,
  bankAccountDrafts: bankAccountDraftsReducer,
  bankAccounts: bankAccountsReducer,
  civilStatus: civilStatusReducer,
  clients: clientsReducer,
  contractTypes: contractTypesReducer,
  departments: departmentsReducer,
  documents: documentsReducer,
  employeeDraft: employeeDraftsReducer,
  employees: employeesReducer,
  frequencies: frequenciesReducer,
  fullClients: fullClientsReducer,
  holidays: holidaysReducer,
  jobs: jobsReducer,
  kardex: kardexReducer,
  municipalities: municipalitiesReducer,
  optionsCatalog: optionsCatalogReducer,
  pagination: paginationReducer,
  paymentMethods: paymentMethodsReducer,
  payrollCalculation: payrollCalculationSlice,
  payrollsTypes: payrollsTypesReducer,
  pendingTasks: pendingTasksReducer,
  pensionTypes: pensionTypesReducer,
  periodFrequencies: periodFrequenciesReducer,
  period: periodReducer,
  periods: payrollReducer,
  salaryTypes: salaryTypesReducer,
  snackbarMessage: snackbarMessageReducer,
  societies: societiesReducer,
  societyBranches: societyBranchesReducer,
  states: statesReducer,
  contracts: contractReducer,
  variableSalaries: variableSalariesReducer,
  works: worksReducer,
  reentryEmployees: reentryEmployeesReducer,
  workdays: workdaysReducer,
  dispersions: dispersionsReducer,
  payrollReceipt: payrollReceiptReducer,
  stampingErrors: stampingErrorsReducer,
  deadlocks: deadlocksReducer,
  chat: chatReducer,
};

export * from './sessionSlice';
export * from './bankAccountDraftsSlice';
export * from './bankAccountsSlice';
export * from './civilStatusSlice';
export * from './clientsSlice';
export * from './contractTypesSlice';
export * from './departmentsSlice';
export * from './documentsSlice';
export * from './employeeDraftsSlice';
export * from './employeesSlice';
export * from './frequencies';
export * from './fullClientsSlice';
export * from './holidaysSlice';
export * from './jobsSlice';
export * from './kardexSlice';
export * from './municipalitiesSlice';
export * from './optionsCatalogSlice';
export * from './paginationSlice';
export * from './paymentMethodsSlice';
export * from './payrollCalculationSlice';
export * from './payrollsTypesSlice';
export * from './pendingTasksSlice';
export * from './pensionTypesSlice';
export * from './periodFrequenciesSlice';
export * from './periodSlice';
export * from './periodsSlice';
export * from './salaryTypesSlice';
export * from './snackbarMessageSlice';
export * from './societiesSlice';
export * from './societyBranchesSlice';
export * from './statesSlice';
export * from './contractsSlice';
export * from './variableSalariesSlice';
export * from './workdaysSlice';
export * from './worksSlice';
export * from './reentryEmployeesSlice';
export * from './dispersionsSlice';
export * from './payrollReceiptSlice';
export * from './stampingErrorsSlice';
export * from './deadlocksSlice';
export * from './chatSlice';

export default RootReducer;
