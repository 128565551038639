import { lazy } from 'react';

import Home from '../pages/Home'
import { Route } from '../types';

const AddEmployees = lazy(() => import('../pages/AddEmployees'));
const BankAccounts = lazy(() => import('../pages/BankAccounts'));
const Chat = lazy(() => import('../pages/Chat'));
const DisableEmployees = lazy(() => import('../pages/DisableEmployees'));
// const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Login'));
// import MyEmployees, { EditEmployeesDatasheet } from '../pages/MyEmployees';
const MyEmployees = lazy(() => import('../pages/MyEmployees'));
const NewVersionAvaiable = lazy(() => import('../pages/NewVersionAvaiable'));
const EditEmployeesDatasheet = lazy(() => import('../pages/MyEmployees/MyEmployeesDatasheet'));
const PayrollCalculation = lazy(() => import('../pages/PayrollCalculation'));
const PayrollSelection = lazy(() => import('../pages/PayrollSelection'));
const Periods = lazy(() => import('../pages/Periods'));
const ReentryEmployees = lazy(() => import('../pages/ReentryEmployees'));
const Support = lazy(() => import('../pages/Support'));

const routesList: Route[] = [
  {
    path: '/uptade-avaiable',
    title: 'Actualización',
    description: 'Hay una nueva versión disponible',
    element: <NewVersionAvaiable path='/uptade-avaiable' />,
  },
  {
    path: '/',
    title: 'Clientes | Levita',
    description: 'Dashboard de clientes',
    element: <Home path='/' />,
  },
  {
    path: '/login',
    title: 'Clientes | Inicio de Sesión',
    description: 'Inicio de Sesión',
    element: <Login path='/login' />,
  },
  {
    path: '/my-employees',
    element: <MyEmployees path='/my-employees' />,
    title: 'Clientes | Mis Empleados',
    description: 'Mis Empleados',
    children: [
      {
        path: ':clientId',
        element: <PayrollSelection />,
      },
      {
        path: ':clientId/:payrollId',
        element: <EditEmployeesDatasheet />,
      },
    ],
  },
  {
    path: '/support',
    element: <Chat />,
  },
  {
    path: '/add-employees/:clientId',
    title: 'Clientes | Alta de Empleados',
    description: 'Alta de Empleados',
    element: <AddEmployees path='/add-employees/:clientId' />,
  },
  {
    path: '/bank-accounts/:clientId',
    title: 'Clientes | Cuentas de Banco',
    description: 'Cuentas de Banco',
    element: <BankAccounts path='/bank-accounts/:clientId' />,
  },
  {
    path: '/periods/:clientId',
    title: 'Clientes | Periodos y Archivos',
    description: 'Periodos y Archivos',
    element: <Periods path='/periods/:clientId' />,
  },
  {
    path: '/period/:payrollPeriod',
    title: 'Clientes | Cálculo de Nómina',
    description: 'Cálculo de Nómina',
    element: <PayrollCalculation path='/period/:payrollPeriod' />,
  },
  {
    path: '/disable-employees/:clientId',
    title: 'Clientes | Desactivar Empleados',
    description: 'Desactivar Empleados',
    element: <DisableEmployees path='/disable-employees/:clientId' />,
  },
  {
    path: '/reentry-employees/:clientId',
    title: 'Clientes | Reactivar Empleados',
    description: 'Reactivar Empleados',
    element: <ReentryEmployees path='/reentry-employees/:clientId' />,
  },
  {
    path: '/support',
    title: 'Clientes | Soporte',
    description: 'Soporte',
    element: <Support path='/support' />,
  },
];

export default routesList;
