import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import './App.css';

import Layout from '../containers/Layout';
import { useWSHook } from '../hooks';
import Routes from '../Routes';

const App = (): JSX.Element => {
  useWSHook();
  return (
    <IntlProvider locale='en'>
      <HelmetProvider>

        <BrowserRouter>
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </HelmetProvider>
    </IntlProvider>
  );
}

export default App;
