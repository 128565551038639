export type EmployeeDraft = {
  id: string;
  clientId: string;
  documentId: string;
  name: string;
  firstLastName: string;
  secondLastName: string;
  rfc: string;
  curp: string;
  imss: string;
  adcEmail: string;
  clientFullname: string;
  status: EmployeeDraftStatuses;
};

export type EmployeeDraftUpdate = {
  id: string;
  clientId?: string;
  documentId?: string;
  name?: string;
  firstLastName?: string;
  secondLastName?: string;
  rfc?: string;
  curp?: string;
  imss?: string;
  adcEmail?: string;
  clientFullname?: string;
  status?: EmployeeDraftStatuses;
};

export enum EmployeeDraftStatuses {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
  Invalidated = 'invalidated',
};

export type EmployeeDraftFilter = {
  page: number;
  limit: number;
  clientId?: string;
};