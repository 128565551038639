/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import { PayrollType, PayrollTypeAddVariables, PayrollTypeFilters } from '../../types';

export interface PayrollsTypesState {
  count: number,
  entities: Record<string, PayrollType>,
  isLoading: boolean,
};

const initialState: PayrollsTypesState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchPayrollsTypes = createAsyncThunk(
  'getPayrollsTypes/fetchPayrollsTypes',
  async (request: PayrollTypeFilters = {}) => {
    const { data: dataResponse } = await getPostRequest('v2/payrolltype/all', request);
    const { data } = dataResponse;
    return data || [];
  },
);

export const createPayrollType = createAsyncThunk(
  'createPayrollType/addPayrollType',
  async (variables: PayrollTypeAddVariables) => {
    const params = getGraphQLParameters('AddPayrollType', variables);
    await getPostRequest('', params);
    // const response = await getPostRequest('', params);
    // console.log(response);
  },
);

export const payrollsTypesSlice = createSlice({
  name: 'payrollsTypes',
  initialState,
  reducers: {
    cleanPayrollsTypes: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayrollsTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPayrollsTypes.fulfilled, (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchPayrollsTypes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanPayrollsTypes } = payrollsTypesSlice.actions;

export default payrollsTypesSlice.reducer;
