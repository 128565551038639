import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiResponse, RequestConfig, ServerType } from '../types';
import getServerConfig from './getServerConfig';

export const getGetRequest = (url: string, config: RequestConfig = {}, params?: any): Promise<AxiosResponse> => {
  const {
    controller,
    server = ServerType.Go,
  } = config;
  const { domain, token } = getServerConfig(server);
  const headers: AxiosRequestConfig = {
    headers: { Authorization: token || '' },
    params,
    validateStatus: (status) => status < 500,
    signal: controller ? controller.signal : undefined,
  };
  return axios.get<ApiResponse>(`${domain}/${url}`, headers);
};

export const getV2APIGetRequest = async<T = any>(url: string): Promise<AxiosResponse<ApiResponse<T>>> => {
  const domain = localStorage.getItem('api_base_domain');
  const token = localStorage.getItem('access_token');
  const headers: AxiosRequestConfig = {
    headers: { Authorization: token || '' },
    validateStatus: (status) => status < 500,
  };
  return axios.get<ApiResponse<T>>(`${domain}/${url}`, headers);
};

export default getGetRequest;
