import { useCallback } from 'react';
import { useToggle } from 'react-use';

import { Button } from '@mui/material';
import { getPostRequest, searchInFile } from 'helpers';
import { useAdcEmail, useAppDispatch, useAppSelector } from 'hooks';
import _ from 'lodash';
import { UploaderFileModal } from 'modals';
import {
  addBankAccountDraft,
  showMessage,
} from 'store/slices';
import {
  BankAccountDraftStatuses,
  DataSheetButtonComponent,
  ServerType,
  UploadFile,
} from 'types';
import { v4 as uuid } from 'uuid';

const BankAccountConfirmationButton: DataSheetButtonComponent = ({
  entityID,
  entitiesMapRef,
  handleChangesRef,
  updateCellAtributesRef,
}) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isModalLoading, toggleIsModalLoading] = useToggle(false);

  const dispatch = useAppDispatch();

  const adcEmail = useAdcEmail();
  const entity = entitiesMapRef.current[entityID];
  // const userEmail = useAppSelector(({ session }) => session.user?.email);
  const client = useAppSelector(({ clients }) => clients.entities[clients.selected]);
  // const bankDraftsByEmployees = useAppSelector(({ bankAccountDrafts }) => bankAccountDrafts.entitiesByEmployee);

  const handleClick = useCallback(async (vouchers: UploadFile[]) => {
    if (!entity) return;
    if (!vouchers[0]) return;
    toggleIsModalLoading(true);

    const clabeDelEmpleado = _.get(entity, 'clabe', '');
    const fullName = _.get(entity, 'fullName', '');
    const errorMessages: string[] = [];

    try {
      const founds = await searchInFile(vouchers[0], [
        { value: clabeDelEmpleado },
        { value: fullName, similarityFactor: 0.6, exact: false },
      ]);
      if (founds[0] && founds[1]) {
        dispatch(showMessage({
          type: 'success',
          message: 'La nueva cuenta de banco fue validada con éxito',
        }));
        toggleIsModalLoading(false);
        toggleIsModalOpen(false);
        if (handleChangesRef.current) {
          handleChangesRef.current([{
            cell: null,
            col: 'validatedFile',
            row: entityID,
            value: true,
          }]);
        }
        if (updateCellAtributesRef.current) {
          updateCellAtributesRef.current(entityID, 'validatedFile', { type: 'check' });
        }
        return;
      }
      if (!founds[0]) {
        errorMessages.push('CLABE no encontrada en el archivo');
      }
      if (!founds[1]) {
        errorMessages.push('Empleado no encontrado en el archivo');
      }
    } catch {
      errorMessages.push('No se pudo leer el archivo correctamente');
    }

    if (adcEmail === '') {
      errorMessages.push('No se pudo enviar el correo al adc');
      dispatch(showMessage({
        type: 'error',
        message: errorMessages,
      }));
      toggleIsModalLoading(false);
      return;
    }
    const id = uuid();
    const { payload } = await dispatch(addBankAccountDraft({
      CLABE: entity.clabe,
      accountNumber: entity.accountNumber,
      adcEmail,
      branchNumber: entity.bankBranch,
      clientFullname: client.businessName,
      clientId: client.id,
      documentId: vouchers[0].id,
      employeeFullname: entity.fullName,
      employeeId: entity.id,
      id,
      status: BankAccountDraftStatuses.Pending,
    }));

    if (!payload) {
      errorMessages.push('No se pudo crear el borrador de cuenta de banco');
      dispatch(showMessage({
        type: 'error',
        message: errorMessages,
      }));
      toggleIsModalLoading(false);
      return;
    }

    const { status } = await getPostRequest(
      'bank-account-drafts/send-confirmation-email',
      { id },
      { server: ServerType.Node });
    if (status === 200) {
      errorMessages.push('Correo enviado al adc para solicitar autorización');
    } else {
      errorMessages.push('Ocurrió un error en el envio de correo al adc');
    }
    dispatch(showMessage({
      type: 'error',
      message: errorMessages,
    }));
    toggleIsModalLoading(false);
    toggleIsModalOpen(false);
  }, [adcEmail, client.businessName, client.id, dispatch, entity, entityID, handleChangesRef, toggleIsModalLoading, toggleIsModalOpen, updateCellAtributesRef]);

  return (
    <>
      <UploaderFileModal
        bucket='levita-ocr'
        confirmButtonText='Validar documento'
        isLoading={isModalLoading}
        maxCountOfFiles={1}
        needsToBePublic
        onAccept={handleClick}
        onClose={toggleIsModalOpen}
        open={isModalOpen}
        title={`Documento de cuenta de banco ${_.get(entity, 'fullName', '')}`}
      />
      <Button onClick={toggleIsModalOpen} variant="outlined">Solicitar autorización</Button>
    </>
  );
};

export default BankAccountConfirmationButton;
