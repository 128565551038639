import { Box, CircularProgress, Container } from '@mui/material';

const Loader = () : JSX.Element => (
  <Container maxWidth='xs'>
    <Box sx={{
      display: 'flex',
      height: '100vh',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <CircularProgress />
    </Box>
  </Container>
);

export default Loader;
