import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Grid } from '@mui/material';

import { BackdropLoader } from  '../components';
import NotFound from '../components/NotFound';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  cleanfullClients,
  cleanKardex,
  cleanSocieties,
  fetchFullClients,
  fetchKardex,
  fetchSocieties,
  setSelectedClient,
} from '../store/slices';

const ClientParamValidatorWrapper: FC = ({ children }) : JSX.Element => {
  const { clientId } = useParams();
  const dispatch = useAppDispatch();

  const { entities, isLoading, selected } = useAppSelector(({ clients }) => clients);
  const fullClient = useAppSelector(({ fullClients }) => fullClients.entities[selected] )
  const selectedClient = clientId !== undefined ? entities[clientId] : undefined;

  useEffect(() => {
    dispatch(fetchSocieties({ societies: fullClient?.payingCompanies }));

    return () => {
      dispatch(cleanSocieties());
    };
  }, [fullClient, dispatch]);

  useEffect(() => {
    if (clientId !== selected) {
      dispatch(setSelectedClient(clientId));
    }
    dispatch(fetchFullClients({ clientID: clientId }));
    dispatch(fetchKardex({ clientID: clientId }));

    return () => {
      dispatch(cleanfullClients());
      dispatch(cleanKardex());
    };
  }, [clientId, selected, dispatch]);

  if (isLoading) {
    return (<BackdropLoader open />);
  }

  if (selectedClient === undefined) {
    return (
      <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }} xs={12}>
        <Grid container spacing={0}>
          <NotFound title='El cliente no fue encontrado' />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {children}
    </>
  );
}

export default ClientParamValidatorWrapper;
