/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchPeriod, fetchPeriodEmployees, savePrepayroll } from '../thunks';
import { PayrollPeriodExtended, PeriodState, SelectedEmployees } from '../types';

const initialState: PeriodState = {
  period: {} as PayrollPeriodExtended,
  paymentMethods: [],
  loading: true,
  loadError: null,
  saving: false,
  saveError: null,
  employees: {
    count: 0,
    entities: [],
    error: null,
    loading: true,
    pagination: {
      page: 1,
      limit: 5,
    },
    selected: {},
  },
};

export const periodSlice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    selectEmployees: (state, action: PayloadAction<SelectedEmployees>) => {
      state.employees.selected = action.payload;
    },
    setEmployeesLoading: (state, action: PayloadAction<boolean>) => {
      state.employees.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    /**
     * fetchPeriod
     */

    builder.addCase(fetchPeriod.pending, (state, { meta }) => {
      const arg = meta.arg;

      state.loading = true;

      if (arg.fetchEmployees) state.employees.loading = true;
    });

    builder.addCase(fetchPeriod.fulfilled, (state, action) => {
      const arg = action.meta.arg;

      state.loading = false;
      state.loadError = null;

      state.period = action.payload.period;
      state.paymentMethods = action.payload.paymentMethods;

      if (action.payload.employees && arg.fetchEmployees) {
        state.employees.loading = false;
        state.employees.error = null;

        const employees = action.payload.employees;

        state.employees.count = employees.count;
        state.employees.entities = employees.entities;
        state.period.calculated = Boolean(employees.calculated);

        // if (employees.pagination)
        //   state.employees.pagination = employees.pagination;
      }
    });

    builder.addCase(fetchPeriod.rejected, (state, action) => {
      const arg = action.meta.arg;

      state.loadError = action.payload ?? action.error;
      state.loading = false;

      if (arg.fetchEmployees) {
        state.employees.loading = false;
        state.employees.error = action.payload ?? action.error;
      }
    });

    /**
     * fetchPeriodEmployees
     */

    builder.addCase(fetchPeriodEmployees.pending, (state) => {
      state.employees.loading = true;
    });

    builder.addCase(fetchPeriodEmployees.fulfilled, (state, action) => {
      state.employees.loading = false;
      state.employees.error = null;
      state.employees.count = action.payload.count;
      state.employees.entities = action.payload.entities;
      state.period.calculated = Boolean(action.payload.calculated);

      // if (action.payload.pagination)
      //   state.employees.pagination = action.payload.pagination;
    });

    builder.addCase(fetchPeriodEmployees.rejected, (state, action) => {
      state.employees.loading = false;
      state.employees.error = action.error;

      state.employees.error = action.payload ?? action.error;
    });

    /**
     * savePrepayroll
     */

    builder.addCase(savePrepayroll.pending, (state) => {
      state.saving = true;
    });

    builder.addCase(savePrepayroll.fulfilled, (state) => {
      state.saving = false;
      state.saveError = null;
    });

    builder.addCase(savePrepayroll.rejected, (state, action) => {
      state.saving = false;
      state.saveError = action.payload ?? action.error;
    });
  },
});

export const { selectEmployees, setEmployeesLoading } = periodSlice.actions;

export default periodSlice.reducer;
