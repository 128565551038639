/* eslint-disable import/no-cycle */
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { SingleValue } from 'react-select';
import { useEffectOnce, useToggle } from 'react-use';

import { Business } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home';
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  fetchFullClients,
  fetchKardex,
  // fetchUnreadMessagesCount,
  getVersionControl,
  loginWithToken,
  logout,
  setSelectedClient,
} from '../../store/slices';
import { ITheme } from './index';

const getNoOptionsMessage = (): string => 'Sin opciones';

const useStyles = makeStyles((theme: ITheme) => ({
  title: {
    fontSize: '2rem',
    fontWeight: '500',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: '1.5rem',
    fontWeight: '300',
  },
  leftHeaderGridItem: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: "10%",
    },
  },
  rightHeaderGridItem: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: "10%",
    },
  },
}))

const StyledBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== "socketStatus" })<{ socketStatus: number }>(({ socketStatus }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: socketStatus === 1 ? '#44b700' : 'red',
    color: socketStatus === 1 ? '#44b700' : 'red',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Header = (): JSX.Element => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const hasLoadedDefaultClientSelection = useRef(false)
  const [isUserMenuOpen, toggleUserMenuOpen] = useToggle(false);

  const { status, user, socketStatus, versionControl, isNewVersionAvaiable } = useAppSelector(({ session }) => session);
  // const kardexEntity = useAppSelector(({ kardex }) => kardex.entity);
  const { entities: clientsMap, selected } = useAppSelector(({ clients }) => clients);
  const dispatch = useAppDispatch();

  const clientsOptions = Object.values(clientsMap)
    .map(({ id, businessName, comerialName }) => ({ value: id, label: `${businessName} - ${comerialName}` }));

  const selectedClient = clientsOptions.find(({ value }) => value === selected);

  const handleLogout = () => {
    dispatch(logout());
    toggleUserMenuOpen();
  };

  const handleSelectClient = (newValue: SingleValue<{ value: string; label: string; }>) => {
    dispatch(setSelectedClient(newValue?.value || selected));
  };

  useEffectOnce(() => {
    if (!user) {
      dispatch(loginWithToken());
    }
    dispatch(getVersionControl());
  });

  useEffect(() => {
    if (Object.values(clientsMap).length > 0) {

      dispatch(fetchFullClients({ clientIDs: Object.keys(clientsMap) }));

      if (!selected) {

        if (!hasLoadedDefaultClientSelection.current) {

          if (dispatch) {
            hasLoadedDefaultClientSelection.current = true;
            dispatch(setSelectedClient(Object.keys(clientsMap)[0]));
          }
        }
      }

    }
  }, [selected, clientsMap, dispatch]);

  useEffect(() => {
    dispatch(fetchKardex({
      clientID: selected
    }))
  }, [dispatch, selected]);

  useEffect(() => {
    if (status === 'logedIn' && pathname !== '/' && isNewVersionAvaiable) {
      navigate('/uptade-avaiable');
    }
  }, [status, isNewVersionAvaiable, navigate, pathname]);

  // type UnReadData = {
  //   clientID: string | undefined,
  //   adcID: string | undefined,
  // }

  // const unReadData: UnReadData = {
  //   clientID: selectedClient?.value,
  //   adcID: kardexEntity?.service.adc,
  // };

  // useUpdateEffect(()=>{
  //   if (kardexEntity?.service.adc ) {
  //     dispatch(fetchUnreadMessagesCount(unReadData));
  //   }
  // },[unReadData ]);

  return (
    <>
      <AppBar position='sticky' style={{ flexDirection: 'row', padding: '12px', boxShadow: 'none' }}>
        <Grid
          alignItems='center'
          container
          justifyContent='space-between'
          style={{ width: '100%' }}
        >

          {/** Home - Dashboard */}
          {pathname === '/' && (
            <Grid item md={5}
              xs={12}>
              <Box sx={{ p: 2, textAlign: 'left' }}>
                <Typography className={classes.title}>Bienvenid@, {user?.name}!👋</Typography>
                <Typography className={classes.subtitle}>
                  <Business
                    style={{
                      marginRight: '0.5rem',
                    }}
                  />
                  Intela México
                  <Typography style={{ color: '#1976d2', marginLeft: 10 }} variant='caption'>
                    {`${versionControl?.major.version}.${versionControl?.minor.version}.${versionControl?.feature.version}.${versionControl?.hotfix.version}`}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          )}

          {pathname === '/' && (
            <Grid item md={2} xs={12} />
          )}

          {pathname === '/' && (
            <Grid item md={5}
              xs={12}>
              <Grid alignItems='center' container justifyContent='center'>

                <Grid item style={{ display: 'grid', placeContent: 'flex-end', paddingLeft: 10 }} xs={10}>
                  <Select
                    noOptionsMessage={getNoOptionsMessage}
                    onChange={handleSelectClient}
                    options={clientsOptions}
                    placeholder='Seleccionar'
                    styles={{
                      option: base => ({ ...base, color: 'black' }),
                    }}
                    value={selectedClient}
                  />
                </Grid>

                <Grid item style={{ textAlign: 'right' }} xs={2}>
                  <Box sx={{ p: 1 }}>
                    <Tooltip title='Abrir configuraciones'>
                      <StyledBadge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        overlap="circular"
                        socketStatus={socketStatus}
                        variant="dot"
                      >
                        <IconButton onClick={toggleUserMenuOpen} sx={{ p: 0 }}>
                          <Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
                        </IconButton>
                      </StyledBadge>
                    </Tooltip>
                    <Menu
                      anchorEl={document.body}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      id='menu-appbar'
                      keepMounted
                      onClose={toggleUserMenuOpen}
                      open={isUserMenuOpen}
                      sx={{ mt: '45px' }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem key='menu-item-logout' onClick={handleLogout}>
                        <Typography textAlign="center">Cerrar Sesión</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/** Other Pages */}
          {pathname !== '/' && pathname !== '/login' && (
            <Grid className={classes.leftHeaderGridItem} item md={6}
              xs={6}>
              <IconButton onClick={() => { navigate('/') }} sx={{ p: 2, color: 'white' }}>
                <HomeIcon style={{ fontSize: '2rem' }} />
              </IconButton>

            </Grid>
          )}

          {pathname !== '/' && pathname !== '/login' && (
            <Grid className={classes.rightHeaderGridItem} item md={6}
              xs={6}>
              {status === 'logedIn' && (
                <>
                  <Grid alignItems="flex-end" container justifyContent="flex-end">
                    <Grid item style={{ textAlign: 'right' }} xs={12}>
                      <Box sx={{ p: 1 }}>
                        <Tooltip title='Abrir configuraciones'>
                          <StyledBadge
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            overlap="circular"
                            socketStatus={socketStatus}
                            variant="dot"
                          >
                            <IconButton onClick={toggleUserMenuOpen} sx={{ p: 0 }}>
                              <Avatar alt='Remy Sharp' src='/static/images/avatar/2.jpg' />
                            </IconButton>
                          </StyledBadge>
                        </Tooltip>
                        <Menu
                          anchorEl={document.body}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          id='menu-appbar'
                          keepMounted
                          onClose={toggleUserMenuOpen}
                          open={isUserMenuOpen}
                          sx={{ mt: '45px' }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem key='menu-item-logout' onClick={handleLogout}>
                            <Typography textAlign="center">Cerrar Sesión</Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}

        </Grid>

      </AppBar>
      <AppBar className='page__background' component='div' />
    </>
  );
};

export default Header;
