/* eslint-disable react/jsx-props-no-spreading */
import { CSSProperties, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import Lottie from 'react-lottie';
import { useToggle } from 'react-use';

import {
  Delete,
  FileDownload,
} from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import uploadAnimation from '../assets/static/icons8-upload-to-the-cloud.json'
import { getGetRequest, uploadFile } from '../helpers';
import { useAppDispatch } from '../hooks';
import { showMessage } from '../store/slices';
import { UploadFile } from '../types';
import FileIcon from './FileIcon';

import './styles/Dropzone.css';

type Props = {
  onChange: (file: UploadFile[]) => void,
  onRemoveFile?: (file: UploadFile) => void,
  title?: string,
  placeHolder?: string,
  files: UploadFile[],
  multiple?: boolean,
  accept?: string,
  bucket?: string,
  style?: CSSProperties,
  disabled?: boolean,
};

const Dropzone: FC<Props> = ({
  onChange,
  onRemoveFile,
  placeHolder,
  title,
  multiple,
  accept,
  bucket,
  files,
  style,
  disabled,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isLoading, toggleIsLoading] = useToggle(false);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      toggleIsLoading(true);
      const uploadPromises = acceptedFiles.map((file) => uploadFile(file, bucket));

      Promise.all(uploadPromises).then((uploadedFiles) => {
        const successFiles: UploadFile[] = uploadedFiles.filter((file) => file !== null) as UploadFile[];
        onChange(successFiles);
        dispatch(showMessage({
          message: 'Archivos cargados con éxito',
          type: 'success',
          autoHideDuration: 6000,
        }));
      }).finally(() => toggleIsLoading());
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple,
    accept,
    disabled,
  });

  const handleDownloadClick = (file: UploadFile) => {
    getGetRequest(`upload/${file?.id}`)
      .then((response) => {
        const downloadUrl = _.get(response, 'data.request.responseURL', '');
        window.open(downloadUrl);
      });
  };

  return (
    <>
      <div className='dropzone' style={style}>
        <div {...getRootProps({ className: 'dropzone__container' })}>
          <div className='dropzone__container--content'>
            <Lottie
              height={80}
              isPaused={false}
              isStopped={false}
              options={{
                loop: true,
                autoplay: true,
                animationData: uploadAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                },
              }}
              width={80}
            />
            <Typography gutterBottom variant='h5'>{title}</Typography>
            <Typography margin={1} variant='body2'>{placeHolder}</Typography>
          </div>
          <input {...getInputProps()} />
          {isLoading && (<span className='dropzone__container--loader' />)}
        </div>
        {files.length > 0 && (
          <List style={{
            width: '100%',
            // bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
          }}
          >
            {files.map((file) => (
              <ListItem key={`file-list-item-${file?.id}`}>
                <ListItemIcon>
                  <FileIcon file={file} />
                </ListItemIcon>
                <ListItemText primary={file?.name} secondary={file?.description} />
                <ListItemIcon>
                  <IconButton aria-label='download' color='info'
                    onClick={() => { handleDownloadClick(file); }}>
                    <FileDownload />
                  </IconButton>
                </ListItemIcon>
                {onRemoveFile && (
                  <ListItemIcon>
                    <IconButton aria-label='delete' color='error' disabled={disabled}
                      onClick={() => { onRemoveFile(file); }}>
                      <Delete />
                    </IconButton>
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </>
  );
};

Dropzone.defaultProps = {
  title: 'Arrastra o da click para cargar el archivo',
  placeHolder: '',
  multiple: true,
  accept: 'image/*, .pdf',
  bucket: 'levita-uploads',
  style: {},
  onRemoveFile: undefined,
  disabled: false,
};

export default Dropzone;
