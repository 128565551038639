import { TimeRank, WhereClause } from './request';

export interface Work {
  id: string;
  createdAt: string;
  DeletedAt?: string;
  updatedAt: string;
  company: string;
  adcNameInCharge?: string;
  address: string;
  amount: number;
  client: string;
  clientWorkRecord?: string;
  endDate: string;
  fatherWorkRecord?: string;
  municipality: string;
  name: string;
  societyBranch: string;
  startDate: string;
  status?: WorkStatus;
  clientSiroc: number;
  workComments: string;
  workRecords: string[];
  documents: string[];
}

export enum WorkStatus {
  WORKSTATUSACTIVA = 1,
  WORKSTATUSCANCELADA = 2,
  WORKSTATUSCERRADA = 3,
  WORKSTATUSSUSPENDIDA = 4,
  WORKSTATUSSINREGISTRO = 5,
}

export interface WorkFilter {
  companyID?: string;
  work?: WhereClause;
  workID?: string;
  workIDs?: string[];
  clientID?: string;
  clientIDs?: string[];
  societyBranchID?: string;
  societyBranchIDs?: string[];
  societyID?: string;
  societyIDs?: string[];
  status?: WorkStatus[];
  rangeDate?: TimeRank;
  page?: number;
  limit?: number;
}
