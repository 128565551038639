/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { FullClient, FullClientFilter } from '../../types';

export interface FullClientsState {
  count: number;
  entities: Record<string, FullClient>;
  isLoading: boolean;
  selected: string;
}

const initialState: FullClientsState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: localStorage.getItem('selected_client') || '',
};

export const fetchFullClients = createAsyncThunk(
  'getFullClients/fetchFullClients',
  async (filters: FullClientFilter) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/client/all',
      filters,
    );
    const { data } = dataResponse;
    return data;
  }
);

export const fullClientsSlice = createSlice({
  name: 'fullClients',
  initialState,
  reducers: {
    cleanfullClients: (state) => {
      state.count = 0;
      state.entities = {};
    },
    setSelectedFullClient: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      state.selected = payload || '';
      localStorage.setItem('selected_client', state.selected);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFullClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchFullClients.fulfilled,
        (state, action: PayloadAction<FullClient[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
          // if (state.selected === '' || state.entities[state.selected] === undefined) {
          //   state.selected = payload[0].id;
          //   localStorage.setItem('selected_client', payload[0].id);
          // }
        }
      )
      .addCase(fetchFullClients.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanfullClients, setSelectedFullClient } = fullClientsSlice.actions;

export default fullClientsSlice.reducer;
