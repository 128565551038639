import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce, useLocalStorage } from 'react-use';

import { ActionCreatorWithoutPayload, AsyncThunk } from '@reduxjs/toolkit';

import {
  cleanContracts,
  cleanDepartments,
  cleanEmployees,
  cleanMunicipalities,
  cleanPaymentMethods,
  cleanPeriodFrequencies,
  cleanSocieties,
  cleanStates,
  cleanVariableSalaries,
  cleanWorkdays,
  cleanWorks,
  fetchContracts,
  fetchDepartments,
  fetchEmployees,
  fetchMunicipalities,
  fetchPaymentMethods,
  fetchPeriodFrequencies,
  fetchSocieties,
  fetchStates,
  fetchVariableSalaries,
  fetchWorkdays,
  fetchWorks,
  showMessage,
} from '../store/slices';
import { useAppDispatch } from './redux';

type FetchMethod = AsyncThunk<any, any | undefined, any>;

type FetchesMethods =
  | 'employees'
  | 'departments'
  | 'societies'
  | 'municipalities'
  | 'states'
  | 'contracts'
  | 'variableSalaries'
  | 'workdays'
  | 'works'
  | 'periodFrequencies'
  | 'paymentMethods';

const filtersTypes: Record<FetchesMethods, FetchMethod> = {
  employees: fetchEmployees,
  departments: fetchDepartments,
  societies: fetchSocieties,
  municipalities: fetchMunicipalities,
  states: fetchStates,
  contracts: fetchContracts,
  variableSalaries: fetchVariableSalaries,
  workdays: fetchWorkdays,
  works: fetchWorks,
  periodFrequencies: fetchPeriodFrequencies,
  paymentMethods: fetchPaymentMethods,
};

const cleanersTypes: Record<FetchesMethods, ActionCreatorWithoutPayload> = {
  employees: cleanEmployees,
  departments: cleanDepartments,
  societies: cleanSocieties,
  municipalities: cleanMunicipalities,
  states: cleanStates,
  contracts: cleanContracts,
  variableSalaries: cleanVariableSalaries,
  workdays: cleanWorkdays,
  works: cleanWorks,
  periodFrequencies: cleanPeriodFrequencies,
  paymentMethods: cleanPaymentMethods,
};

const useQueryFilter = (
  type: FetchesMethods,
  initialOtherVariables = {},
  minSearchLength = 5,
  delay = 800
): [string, React.Dispatch<React.SetStateAction<string | undefined>>] => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useLocalStorage<string>(
    `query_filter_value${pathname}`,
    ''
  );

  const otherVariablesRef = useRef(initialOtherVariables);
  if(JSON.stringify(initialOtherVariables) !== JSON.stringify(otherVariablesRef.current))
    otherVariablesRef.current = initialOtherVariables;

  const otherVariables = otherVariablesRef.current;

  useDebounce(
    () => {
      if (value !== undefined && value.length >= minSearchLength) {
        const fetchMethod = filtersTypes[type];
        if (fetchMethod) {
          dispatch(
            fetchMethod({
              query: value,
              ...otherVariables,
            })
          );
        }
      } else {
        const cleanerAction = cleanersTypes[type];
        if (cleanerAction) {
          dispatch(cleanerAction());
        }
        dispatch(
          showMessage({
            message: 'Por favor realice una búsqueda de al menos 5 caracteres',
            type: 'warning',
          })
        );
      }
    },
    delay,
    [value, otherVariables]
  );

  return [value || '', setValue];
};

export default useQueryFilter;
