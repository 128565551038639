import { useEffect, useRef } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';

import { clabe } from 'helpers';
import _ from 'lodash';

// import { clabe, getGetRequest, searchInFile } from '../../../helpers'
import {
  // addBankAccountDraft,
  cleanBankAccounts,
  cleanEmployees,
  createClientPendingTask,
  deleteClientPendingTask,
  deleteManyBankAccountDraft,
  fetchBankAccountDrafts,
  fetchBankAccounts,
  fetchClientPendingTasksNoRedux,
  showMessage,
  updateClientPendingTask,
} from '../../../store/slices';
import {
  BankAccountDraftStatuses,
  // BankAccountDraftStatuses,
  Cell,
  Change,
  // Change,
  ClientPendingTask,
  ClientPendingTaskTitle,
  ClientPendingTaskType,
  Employee,
  EntitiesState,
  Error,
  HandleChanges,
  // OnClickCell,
  // ServerType,
  // UploadFile,
} from '../../../types';
import { useAppDispatch, useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';
import useDenormalizedEmployees from './useDenormalizedEmployees';

type UseBankAccountsDatasheet = {
  isLoading: boolean,
  hasData: boolean,
  hasPendingChanges: boolean,
  hasPendigFilesValidation: boolean,
  hasAtLeastAFileValidated: boolean,
  count: number,
  rows: EntitiesState,
  changes: EntitiesState,
  grid: Cell[][],
  handleChanges: HandleChanges,
  ValueRenderer: ReactDataSheet.ValueRenderer<any, any>,
  cleanDataSheetLocalStorage: () => void,
  cleanChanges: () => void,
  validate: () => {
    isValid: boolean,
    errors: Error[],
  },
};

const useBankAccountsDatasheet = (): UseBankAccountsDatasheet => {
  const entities = useDenormalizedEmployees();
  const dispatch = useAppDispatch();
  const employees = useAppSelector((state) => state.employees);
  const bankAccounts = useAppSelector((state) => state.bankAccounts);
  const bankAccountDrafts = useAppSelector((state) => state.bankAccountDrafts.entities);

  const {
    hasData,
    isCalculating,
    grid,
    // getCell,
    rows,
    changes,
    handleChanges,
    cleanChanges,
    cleanDataSheetLocalStorage,
    // updateCellAtributes,
    ValueRenderer,
    cleanMultipleRowsMemory,
    // entities: changedEntities,
    // validate,
  } = useDataSheet(columns, entities);

  const selectedClientId = useAppSelector(({ clients }) => clients.selected);
  const changesAlertPublished = useRef(false);
  const prevTasksDeleted = useRef(false);
  const changesSnapshot = useRef({} as EntitiesState);
  const employeesSnapshot = useRef<Record<string, Employee>>({});

  const handleBankAccountsChanges: HandleChanges = (edits) => {
    const formatedEdits: Change[] = [];

    edits.forEach((change) => {
      formatedEdits.push(change);

      const { cell = {}, value, row } = change;

      if (cell?.field === 'clabe' && value !== '') {
        const validation = clabe.validate(value);
        if (!validation.ok) return;
        formatedEdits.push({
          cell: null,
          row,
          col: 'accountNumber',
          value: validation.account,
        });
      }
    });

    handleChanges(formatedEdits);
  };

  const handleWebsiteUnload = async (changesParam: EntitiesState) => {
    // console.log("[D] Started Unload Process");
    if (changesParam) {
      if (Object.values(changesParam).length > 0) {

        const data = await fetchClientPendingTasksNoRedux({
          clientId: {
            eq: selectedClientId,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_BANK_ACCOUNT_VERIFICATION
          },
        })

        Object.values(changesParam).forEach((change) => {
          const { id } = change;

          const prevTask = data.find((task: ClientPendingTask) => task.employeeId === id);

          if (prevTask) {
            // console.log(`[D] Unload Process: Already Exists Pending Task [${i + 1}/${Object.values(changesParam).length}]`);
            dispatch(updateClientPendingTask(prevTask))
          } else {

            const employee = employeesSnapshot.current[id];

            dispatch(createClientPendingTask({
              clientId: selectedClientId,
              employeeId: id,
              type: ClientPendingTaskType.CPTT_BANK_ACCOUNT_VERIFICATION,
              employeeName: `${employee?.firstLastName} ${employee?.secondLastName} ${employee?.name}`,
            }))
            // console.log(`[D] Unload Process: Created Pending Task [${i + 1}/${Object.values(changesParam).length}]`);

          }

        })

      } else {
        // console.log("[D] Finished Unload Process: Nothing happened [1]");
      }
    } else {
      // console.log("[D] Finished Unload Process: Nothing happened [0]");
    }

  }

  const warnAgainstWebsiteUnload = async (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '';
  }

  const handleDeleteAllPendingTasks = async () => {
    dispatch(deleteClientPendingTask({
      clientId: {
        eq: selectedClientId,
      },
      title: {
        eq: ClientPendingTaskTitle.CPTTi_BANK_ACCOUNT_VERIFICATION
      },
    }))
  }

  // Unloading website
  useEffect(() => {
    changesSnapshot.current = changes;
    if (changes) {
      if (Object.values(changes).length > 0 && !changesAlertPublished.current) {
        prevTasksDeleted.current = false;
        // console.log("[D] Activated Unload Listener");
        window.addEventListener('beforeunload', warnAgainstWebsiteUnload)
        changesAlertPublished.current = true
      } else if (Object.values(changes).length === 0) {
        if (changesAlertPublished.current) {
          // console.log("[D] Deactivated Unload Listener");
          window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
        }
        changesAlertPublished.current = false
      }

      if (Object.values(changes).length === 0 && !prevTasksDeleted.current) {
        // console.log("[D] No changes detected, deleting pending tasks (if apply)");
        handleDeleteAllPendingTasks();
        prevTasksDeleted.current = true;
      }
    }

    return () => {
      window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))
    return () => {
      window.removeEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))

      handleWebsiteUnload(changesSnapshot.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    employeesSnapshot.current = employees.entities;
  }, [employees.entities]);

  const hasPendingChanges = Object.keys(changes || {}).length > 0;
  const hasPendigFilesValidation = _.keys(changes).some((changeId) => !_.get(changes, `${changeId}.validatedFile`, false));
  const hasAtLeastAFileValidated = _.keys(changes).some((changeId) => _.get(changes, `${changeId}.validatedFile`, false));

  const validate = () => {
    const response: {
      isValid: boolean,
      errors: Error[],
    } = {
      isValid: true,
      errors: [],
    };
    _.keys(changes).forEach((employeeId) => {
      const wasFileValidated = _.get(changes, `${employeeId}.validatedFile`, false);
      const fullName = ['name', 'firstLastName', 'secondLastName']
        .map((key) => _.get(rows, `${employeeId}.${key}`))
        .join(' ');

      if (!wasFileValidated) {
        response.isValid = false;
        response.errors.push({
          message: `El archivo del empleado ${fullName} no ha pasado las validaciones`,
        });
      }
    });
    return response;
  };

  useEffectOnce(() => {
    // dispatch(fetchBankAccounts({ clientID }));
    dispatch(fetchBankAccounts());
    dispatch(fetchBankAccountDrafts({ limit: 100, page: 1, clientId: selectedClientId }));
    return () => {
      dispatch(cleanEmployees());
      dispatch(cleanBankAccounts());
    };
  });

  useDeepCompareEffect(() => {
    const ids: string[] = [];
    // const messages: string[] = [];
    Object.values(bankAccountDrafts).forEach(({ id, status }) => {
      if (status !== BankAccountDraftStatuses.Pending) {
        ids.push(id);
      }
    });
    dispatch(deleteManyBankAccountDraft(ids))
      .then(({ payload }) => {
        const deletedIds = payload as string[];
        const changesIds: string[] = [];
        const messages = deletedIds
          .map((id) => {
            changesIds.push(bankAccountDrafts[id].employeeId);

            if (bankAccountDrafts[id].status === BankAccountDraftStatuses.Declined) {
              return `La solicitud de creacion de una cuenta de banco para el empleado ${bankAccountDrafts[id].employeeFullname} fue rechazada`;
            }
            if (bankAccountDrafts[id].status === BankAccountDraftStatuses.Approved) {
              return `La solicitud de creacion de una cuenta de banco para el empleado ${bankAccountDrafts[id].employeeFullname} fue aprobada`;
            }
            return `La solicitud de creacion de una cuenta de banco con CLABE: ${
              bankAccountDrafts[id].CLABE} para el empleado ${
                bankAccountDrafts[id].employeeFullname} no pudo ser procesada`;
          });
        if (messages.length > 0) {
          dispatch(showMessage({
            message: messages,
            type: 'warning',
          }));
          cleanMultipleRowsMemory(changesIds);
        }
      });
  }, [bankAccountDrafts]);

  return {
    isLoading: employees.isLoading || bankAccounts.isLoading || isCalculating,
    // isSaving,
    // isFetching,
    hasData,
    hasPendingChanges,
    hasPendigFilesValidation,
    hasAtLeastAFileValidated,
    count: employees.count,
    rows,
    changes,
    grid,
    handleChanges: handleBankAccountsChanges,
    ValueRenderer,
    cleanDataSheetLocalStorage,
    validate,
    cleanChanges,
  };
};

export default useBankAccountsDatasheet;
