import { Column } from '../../../types';

const columns: Column[] = [
  {
    title: 'Concepto',
    field: 'title',
    defaultValue: 'Valor',
    isEnabled: false,
  },
  {
    title: 'Total a dispersar',
    field: 'totalAmount',
    pathValue: 'totalToDisperse',
    type: 'money',
  },
  // {
  //   title: 'Total a dispersar',
  //   field: 'totalAmount',
  //   pathValue: 'totalAmount',
  //   type: 'money',
  // },
  {
    title: 'Comisión',
    field: 'totalCommission',
    pathValue: 'totalCommission',
    type: 'money',
  },
  {
    title: 'Cobros pendientes',
    field: 'pendingAmount',
    // hidden: true,
    pathValue: 'outstandingPayments',
    type: 'money',
  },
  {
    title: 'Bonificaciones',
    field: 'modifierAmount',
    // hidden: true,
    pathValue: 'bonuses',
    type: 'money',
  },
  {
    title: 'Provisión',
    field: 'provision',
    // hidden: true,
    pathValue: 'provision',
    type: 'money',
  },
  {
    title: 'Comisión de provisión',
    field: 'comisionProvision',
    // hidden: true,
    pathValue: 'comisionProvision',
    type: 'money',
  },
  {
    title: 'Subtotal',
    field: 'subtotal',
    pathValue: 'subtotal',
    type: 'money',
  },
  {
    title: '(+) IVA 16%',
    field: 'iva',
    pathValue: 'iva',
    type: 'money',
  },
  // {
  //   title: '(-) IVA Retenido 6%',
  //   field: 'totalPerceptions',
  //   pathValue: 'totalPerceptions',
  // },
  {
    title: 'Total a Facturar',
    field: 'totalInvoice',
    pathValue: 'totalInvoice',
    type: 'money',
  },
];

export default columns;
