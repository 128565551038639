export enum Bank {
  Afirme = "AFIRME",
  Bajio = "BAJIO",
  Bajío = "BAJÍO",
  Banamex = "BANAMEX",
  Bancoppel = "BANCOPPEL",
  Banorte = "BANORTE",
  BbvaBancomer = "BBVA BANCOMER",
  Empty = "",
  Hsbc = "HSBC",
  Ixe = "IXE",
  Santander = "SANTANDER",
  Stp = "STP",
};

export type BankAccount = {
  id:                string,
  LevitaOpsID:       string,
  createdAt:         Date,
  updated_at:        Date,
  deletedAt:         null,
  company:           string,
  client:            string,
  payingCompany:     string,
  paymentMethodType: string,
  employee:          string,
  accountName:       string,
  accountNumber:     string,
  bank:              Bank,
  bankCards:         boolean,
  branchNumber:      string,
  cardDefault:       boolean,
  cardNumber:        string,
  description:       string,
  informationNumber: string,
  CLABE:             string,
  issuers:           string[] | null,
};

export type BankAccountFilters = {
  bankAccountIDs?:  string[],
  employeeID?:      string,
  employeeIDs?:     string[],
  companyID?:       string,
  clientID?:        string,
  societyID?:       string,
  societyIDs?:      string[],
  accountNumber?:   string,
  cardNumber?:      string,
  clabe?:           string,
  issuer?:          string,
  cardDefault?:     boolean,
  page?:            number,
  limit?:           number,
};
export interface BankDocument {
  bankName?: string,
  createdAt?: string,
  deletedAt?: string,
  dispersionSummary?: string,
  downloadURL?: string,
  id?: string,
  payrollPeriods?: string,
  updatedAt?: string,
}