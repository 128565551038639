export const pppsPayrolls = `
query pppsPayrolls($payrollPeriod: String!) {
  pppsPayrolls(payrollPeriod: $payrollPeriod) {
    count
    data {
      amount
      calculated
      client {
        id
        basic {
          comercialName
        }
        general {
          tradeName
          businessName
        }
      }
      deductions {
        concepto
        esquema
        importe
      }
      commission
      employeeBankAccount {
        id
        CLABE
        bank
        accountNumber
      }
      employee {
        id
        key
        name
        firstLastName
        secondLastName
        bankAccounts {
          CLABE
          accountNumber
          bank
          cardDefault
          id
        }
        payrollBranch {
          id
          workRisk
        }
        grossSalary
      }
      id
      paymentMethod {
        id
        name
      }
      payrollPeriod {
        id
        name
        fullName
        payrollSchema
        type
      }
      workedDays
      totalToDisperse
    }
  }
}
`;
