import { useDeepCompareEffect } from 'react-use';

import { Column } from '../../../types';
import { useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';

const useCalculationPayrollDatasheet = () => {
  const { entities, deductionsList, isLoading } = useAppSelector(({ payrollCalculation }) => payrollCalculation);

  const {
    grid,
    handleChanges,
    entities: employees,
    ValueRenderer,
    addColumn,
    validate,
  } = useDataSheet(columns, entities, { defaultIsEnable: false });

  useDeepCompareEffect(() => {
    if (!isLoading) {
      deductionsList.forEach((deductionName, index) => {
        const newColumn: Column = {
          title: deductionName,
          field: `deductionName${index}`,
          pathValue: `deductionsMap["${deductionName}"]`,
          defaultValue: 0,
          type: 'money',
          hidden: true,
        };
        addColumn(newColumn, 7);
      });
    }
  }, [deductionsList, isLoading]);

  return {
    grid,
    handleChanges,
    ValueRenderer,
    employees,
    validate,
    isLoading,
  };
};

export default useCalculationPayrollDatasheet;
