import { useEffect, useState } from 'react';

import { getGraphQLParameters, getPostRequest } from 'helpers';
import _ from 'lodash';

import { useAppSelector } from './redux';

const useAdcEmail = () => {
  const [adcEmail, setAdcEmail] = useState('');
  const adcId = useAppSelector(({ kardex }) => kardex.entity?.service.adc);

  useEffect(() => {
    const fetchData = async () => {
      const params = getGraphQLParameters('EmployeeEmail', { id: adcId });
      const adcResponse = await getPostRequest('', params);
      setAdcEmail(_.get(adcResponse, 'data.data.employee.email', ''));
    }
    fetchData();
  }, [adcId]);

  return adcEmail;
};

export default useAdcEmail;
