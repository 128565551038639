import { Pagination } from './request';

export interface StampingError {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  payrollPeriod: string;
  type: StampingErrorType;
  errorCode: string;
  error: string;
  employeeName: string;
  employeeId: string;
}

export enum StampingErrorType {
  SETSAT = "SAT",
  SETBackend = "Backend"
}

export interface StampingErrorsFilter {
  id?: string;
  pagination?: Pagination;
  payrollPeriod?: string;
  employee?: string;
  type?: string;
}