import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';

interface Props {
  title?: string;
}

const NotFound: FC<Props> = ({ title }): JSX.Element => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
        variant="h4"
      >
        {title}
      </Typography>
    </Grid>
    <Grid item md={5} xs={1} />
    <Grid item md={2} xs={10}>
      <Link to="/">
        <Button
          color="info"
          fullWidth
          size="large"
          style={{
            padding: '10px',
          }}
          variant="contained"
        >
          Inicio
        </Button>
      </Link>
    </Grid>
    <Grid item md={5} xs={1} />
  </Grid>
);

NotFound.defaultProps = {
  title: 'Página no encontrada',
};

export default NotFound;
