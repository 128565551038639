/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPostRequest } from '../../helpers';

export interface PensionTypesState {
  count: number;
  entities: string[];
  isLoading: boolean;
}

const initialState: PensionTypesState = {
  count: 0,
  entities: [],
  isLoading: false,
};

export const fetchPensionTypes = createAsyncThunk(
  'getPensionTypes/fetchPensionTypes',
  async () => {
    const { data: dataResponse, status } = await getPostRequest(
      'v2/pensiontype/all',
      null
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const pensionTypesSlice = createSlice({
  name: 'pensionTypes',
  initialState,
  reducers: {
    cleanPensionTypes: (state) => {
      state.count = 0;
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPensionTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPensionTypes.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = payload;
        }
      )
      .addCase(fetchPensionTypes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanPensionTypes } = pensionTypesSlice.actions;

export default pensionTypesSlice.reducer;
