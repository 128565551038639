export const PayrollPeriodEdit = `
  mutation PayrollPeriodEdit(
    $name: String
    $start: DateTime
    $incidentsEnd: DateTime
    $incidentsStart: DateTime
    $end: DateTime
    $payday: DateTime
    $administrativePeriod: Int
    $key: String
    $type: PayrollPeriodType
    $isClosed: Boolean
    $isAuthorized: Boolean
    $dispersionInformation: DispersionInformationInput
    $dispersionStatus: DispersionStatus
    $invoiceStatus: String
    $payrollApproveTracker: PayrollApproveTrackerInput
    $perceptionModifiers: [PerceptionModifiersPeriodConfigurationInput]
    $payrollStatus: PayrollStatus
    $accumulationMonth: AccumulationMonthInput
    $id: String!
    $autoStamp: Boolean
  ) {
    payrollPeriodEdit(
      name: $name
      start: $start
      incidentsEnd: $incidentsEnd
      incidentsStart: $incidentsStart
      end: $end
      payday: $payday
      administrativePeriod: $administrativePeriod
      invoiceStatus: $invoiceStatus
      dispersionStatus: $dispersionStatus
      key: $key
      type: $type
      isClosed: $isClosed
      isAuthorized: $isAuthorized
      dispersionInformation: $dispersionInformation
      payrollApproveTracker: $payrollApproveTracker
      perceptionModifiers: $perceptionModifiers
      payrollStatus: $payrollStatus
      accumulationMonth: $accumulationMonth
      id: $id
      autoStamp: $autoStamp
    ) {
      administrativePeriod
      name
      employeeStampStatus
      end
      id
      key
      payday
      clientApproval
      invoiceStatus
      dispersionStatus
      dispersionInformation {
        totalAmount
        programmingDate
        date
      }
      billingInformation {
        totalInvoiceAmount
        stampingDate
        programmingDate
        programmed
        billingDate
      }
      start
      type
      incidentsEnd
      incidentsStart
      isClosed
      isAuthorized
      payrollStatus
      accumulationMonth {
        month
        year
      }
    }
  }
`;
