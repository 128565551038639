import { Column } from 'types'

import {
  clabe,
  getGetRequest,
} from '../../../helpers';

const columns: Column[] = [
  {
    title: 'Nombre completo',
    field: 'fullName',
    pathValue: ['name', 'firstLastName', 'secondLastName'],
    isEnabled: false,
    separator: ' ',
  },
  {
    title: ['RFC', 'CURP', 'NSS'],
    field: 'rfc',
    pathValue: ['rfc', 'curp', 'imss'],
    separator: ' | ',
    isEnabled: false,
  },
  {
    title: 'CLABE',
    field: 'clabe',
    pathValue: 'bankAccount.CLABE',
    validation: async (value, cell, { updateCellAtributes }) => {
      if (value === '') {
        return {
          isValid: true,
        };
      }
      const { id: row } = cell;
      const clabeVal = value as string;
      const clabeValidation = clabe.validate(clabeVal);
      if (!clabeValidation.ok) {
        return {
          isValid: false,
          error: {
            cell,
            message: 'La CLABE no tiene el formato adecuado',
          },
        };
      }
      // handleChanges([{ cell: null, row, col: 'accountNumber', value: clabeValidation.account }]);
      const { data: clabeValidationResponse } = await getGetRequest(`v2/bankaccount/exists/${value}-`);
      const { data } = clabeValidationResponse;
      const { exists, who } = data;
      if (exists) {
        return {
          isValid: false,
          error: {
            cell,
            message: `La clabe ${clabeVal} ya esta registrada para ${who}`,
          },
        };
      }
      updateCellAtributes(row, 'validatedFile', {
        isRequired: value !== '',
        // isEnabled: value !== '',
        type: value !== '' ? 'button' : 'check',
      });
      return {
        isValid: true,
      };
    },
    cleanOnChange: ['accountNumber', 'validatedFile'],
    isEnabled: true,
  },
  {
    title: 'Número de Cuenta',
    field: 'accountNumber',
    pathValue: 'bankAccount.accountNumber',
    validation: async (value) => {
      if (value === '') {
        return {
          isValid: true,
        };
      }
      const accountNumber = value as string; 
      const cuentaPattern11 = /^\d{11}$/;
      const cuentaPattern10 = /^\d{10}$/;
      const cuentaPattern9 = /^\d{9}$/;
      const validation = (cuentaPattern9.test(accountNumber) || cuentaPattern10.test(accountNumber) || cuentaPattern11.test(accountNumber));
      if (!validation) {
        return {
          isValid: false,
          error: {
            message: `El numero de cuenta ${accountNumber} no esta en el formato adecuado`,
          },
        };
      }

      const { data: clabeValidationResponse } = await getGetRequest(`v2/bankaccount/exists/-${accountNumber}`);
      const { data } = clabeValidationResponse;
      const { exists, who } = data;
      if (exists) {
        return {
          isValid: false,
          error: {
            message: `La cuenta de banco ${accountNumber} ya esta registrada para ${who}`,
          },
        };
      }

      return {
        isValid: true,
      };
    },
    isEnabled: true,
  },
  {
    title: 'Sucursal de Banco',
    field: 'bankBranch',
    pathValue: 'bankAccount.branchNumber',
    isEnabled: true,
    // validation: (cell, row, entities) => CellError | Promise<CellError>,
  },
  {
    title: 'Validado',
    field: 'validatedFile',
    type: 'check',
    defaultValue: false,
    isEnabled: false,
    buttonComponentPath: 'components/BankAccountConfirmationButton',
    // component: BankAccountConfirmationButton,
    // forceComponent: true,
  },
];

export default columns;