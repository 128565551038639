import { FC, useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/MobileDatePicker';
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';

import { getGraphQLParameters, getPostRequest } from '../helpers';

interface Props {
  entity: Record<string, any>,
  open: boolean,
  handleClose: () => void,
  periodsPerPayrollTypes: Record<string, any>,
  getPeriods: () => void,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ModalComponent: FC<Props> = (props): JSX.Element => {
  const {
    entity,
    open,
    handleClose,
    periodsPerPayrollTypes,
    getPeriods
  } = props;

  const [name, setName] = useState<string>('');
  const [payDay, setPayDay] = useState<Date>(new Date());
  const [acumulationDay, setAcumulationDay] = useState<Date>(new Date());
  const [periods, setPeriods] = useState<Array<any>>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');

  useEffect(() => {
    setName(`EXT ${entity.name}`);
    const date = new Date();
    const elements = [...(periodsPerPayrollTypes[entity.id] ? periodsPerPayrollTypes[entity.id] : [])];
    const foundIndex = elements.findIndex((period: any) => new Date(period.start) <= date && new Date(period.end) >= date)
    setPeriods(elements.slice(foundIndex + 1, elements.length));
  }, [entity, periodsPerPayrollTypes])

  useUpdateEffect(() => {
    const newPeriods = periods.filter((period) => new Date(period.start) < new Date(acumulationDay) && new Date(period.end) > new Date(acumulationDay));
    if (newPeriods.length > 0) {
      setSelectedPeriod(newPeriods[0].id);
    } else {
      setSelectedPeriod('')
    }
  }, [payDay]);

  const handleChange = (date: any) => {
    setPayDay(date);
    setAcumulationDay(date);
  }

  const handleSelectChange = (e: any) => {
    setSelectedPeriod(e.target.value)
  }

  const handleAddExtraordinary = async () => {
    try {
      const variables = {
        payday: payDay,
        administrativePeriod: 1,
        type: 'Extraordinary',
        paysheetType: entity.id,
        parentOrdinaryPeriod: selectedPeriod,
        name,
        accumulationMonth: {
          month: acumulationDay.getMonth() + 1,
          year: acumulationDay.getFullYear(),
        },
      }
      const params = getGraphQLParameters('payrollPeriodAdd', variables)
      const { data } = await getPostRequest('', params);
      if (!data.errors) {
        getPeriods();
        handleClose();
      }
    } catch (e) {
      // console.log(e);
    }
  }

  return (
    <Modal
      onClose={handleClose}
      open={open}
    >
      <Box sx={style}>
        <Typography
          component="h2"
          id="modal-modal-title"
          style={{ marginBottom: 25 }}
          variant="h6"
        >
          Agregar Periodo en Nómina
        </Typography>
        <TextField
          id="periodName"
          label="Nombre"
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', marginBottom: 15 }}
          value={name}
          variant="standard"
        />
        <div style={{ marginBottom: 15 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Fecha de Pago"
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
              value={payDay}
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </div>
        <Typography
          component="h2"
          variant="body2"
        >
          Periodo Ordinario
        </Typography>
        <Select
          id="periodo ordinario-select"
          onChange={handleSelectChange}
          style={{ marginBottom: 25, width: '100%' }}
          value={selectedPeriod}
        >
          {periods.filter((period) => (
            period.type !== 'Extraordinario'
          )).map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {`(${el.administrativePeriod}) ${el.name} (${moment(new Date(el.start)).format('YYYY-MM-DD')} a ${moment(new Date(el.end)).format('YYYY-MM-DD')})`}
            </MenuItem>
          ))}
        </Select>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleAddExtraordinary}>
            Agregar
          </Button>
        </div>
      </Box>
    </Modal>
  )
};

export default ModalComponent;
