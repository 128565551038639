/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import { useCallback, useEffect, useRef, useState } from 'react';

import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '../..';
import { findOption } from '../../../helpers';
import EmployeesService, {
  FullEmployeee,
} from '../../../services/EmployeesService';
import {
  createClientPendingTask,
  deleteClientPendingTask,
  fetchClientPendingTasksNoRedux,
  updateClientPendingTask,
} from '../../../store/slices';
import {
  Change,
  ClientPendingTask,
  ClientPendingTaskTitle,
  ClientPendingTaskType,
  Employee,
  EntitiesState,
  GetInitMetadata,
  Option,
} from '../../../types';
import useDataSheet from '../../useDataSheet';
import createColumns from './createColumns';

const columns = createColumns();

function instanceOfOption(opt: any): opt is Option {
  if (!opt) return false;
  return typeof opt.value === 'string' && typeof opt.label === 'string';
}

const getInitMetadata: GetInitMetadata = (entity) => ({
  minDateValue: new Date(_.get(entity, 'incorporation', undefined)),
});

export default function useDisableEmployeesDatasheet(
  initialEmployees: FullEmployeee[]
) {
  // TODO: Create Dismissal type.
  const [dismissalTypes, setDismissalTypes] = useState<any[]>([]);

  const getDismissalTypes = useCallback(async () => {
    const newDismissalTypes = await EmployeesService.getDismissalTypes();

    const dimissalTypesOptions = newDismissalTypes.map(
      (dismissalType: any) => ({
        value: dismissalType.id,
        label: dismissalType.description,
        dismissalCauses: dismissalType.dismissalCauses,
      })
    );

    setDismissalTypes(dimissalTypesOptions);
  }, []);

  const findDismissalType = useCallback(
    (id: string) =>
      dismissalTypes.find((dismissalType) => dismissalType.value === id),
    [dismissalTypes]
  );

  const {
    grid,
    handleChanges,
    entities,
    DataEditor,
    ValueRenderer,
    updateColumnAtributes,
    cleanDataSheetLocalStorage,
    cleanChanges,
    updateCellAtributes,
    changes,
  } = useDataSheet(columns, initialEmployees, { getInitMetadata });

  useEffect(() => {
    updateColumnAtributes('dismissalTypeID', {
      options: dismissalTypes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissalTypes]);

  const employeesMap = entities as Record<string, FullEmployeee>;
  const employees = useAppSelector((state) => state.employees);

  const pendingUpdatesRef = useRef<Array<() => void>>([]);

  if (pendingUpdatesRef.current.length) {
    pendingUpdatesRef.current.forEach((update) => update());
    pendingUpdatesRef.current = [];
  }

  const onChange = useCallback(
    (
      change: Change,
      dismissalCausesPerRow: {
        [row: number | string]: Array<Option> | undefined;
      } = {}
    ): Change[] => {
      try {
        // TODO: Type this.
        const validatedChanges: Change[] = [];

        if (change.cell === null) throw new Error('Cell is null');

        // TODO: Type fields with an enum.
        const field = change.cell.field as string;
        const options = change.cell.options;
        const { row, value } = change;
        const col = Number(change.col);

        // TODO: Fix types.
        const employeeId = change.cell.id as string;
        const employee = employeesMap[
          employeeId as keyof typeof employeesMap
        ] as Employee;

        // !! Should never happen.
        if (!employee) throw new Error('Employee not found');

        switch (field) {
          case 'date': {
            if (typeof value !== 'string' || value === '')
              validatedChanges.push({
                cell: {},
                row,
                col,
                value: new Date(),
              });
            break;
          }
          case 'dismissalCauseID': {
            try {
              let dismissalCauseID = '';

              const newOptions = dismissalCausesPerRow[row];

              if (typeof value === 'string') {
                dismissalCauseID = findOption(
                  value,
                  newOptions ?? options
                ).value;
              } else if (instanceOfOption(value))
                dismissalCauseID = value.value;

              if (dismissalCauseID === '')
                throw new Error('Invalid dismissal cause ID');

              validatedChanges.push({
                cell: {
                  options: newOptions ?? options,
                },
                row,
                col,
                value: dismissalCauseID,
              });
            } catch (e) {
              console.error(e);

              validatedChanges.push({
                cell: {},
                row,
                col,
                value: '',
              });
            }
            break;
          }
          case 'dismissalTypeID': {
            let dismissalCausesOptions: Option[] = [];

            try {
              let dismissalTypeID = '';

              if (typeof value === 'string')
                dismissalTypeID = findOption(value, options).value;
              else if (instanceOfOption(value)) dismissalTypeID = value.value;

              if (dismissalTypeID === '')
                throw new Error('Invalid dismissal type ID');

              const dismissalType = findDismissalType(dismissalTypeID);
              if (!dismissalType) throw new Error('Dismissal type not found');

              dismissalCausesOptions = dismissalType.dismissalCauses.map(
                (dismissalCause: any) => ({
                  value: dismissalCause.id,
                  label: dismissalCause.description,
                })
              );

              validatedChanges.push({
                cell: {},
                row,
                col,
                value: dismissalTypeID,
              });
            } catch (e) {
              console.error(e);

              validatedChanges.push({
                cell: {},
                row,
                col,
                value: '',
              });
            }

            // validatedChanges.push({
            //   cell: {
            //     options: dismissalCausesOptions,
            //   },
            //   row,
            //   col: 'dismissalCauseID',
            //   value: '',
            // });

            updateCellAtributes(
              row,
              'dismissalCauseID',
              {
                options: dismissalCausesOptions,
              }
            )

            // eslint-disable-next-line no-param-reassign
            dismissalCausesPerRow[row] = dismissalCausesOptions;

            break;
          }
          default:
            break;
        }

        return validatedChanges;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [employeesMap, findDismissalType, updateCellAtributes]
  );

  const onChanges = useCallback(
    (validatedChanges: Change[]): void => {
      const extraChanges: Change[] = [];

      const dismissalCauses: {
        [row: number]: Array<Option>;
      } = {};

      validatedChanges.forEach((change) => {
        const newChanges = onChange(change, dismissalCauses);
        extraChanges.push(...newChanges);
      });

      handleChanges([...validatedChanges, ...extraChanges]);
    },
    [handleChanges, onChange]
  );

  const selectedClientId = useAppSelector(({ clients }) => clients.selected);
  const changesAlertPublished = useRef(false);
  const prevTasksDeleted = useRef(false);
  const changesSnapshot = useRef({} as EntitiesState);
  const dispatch = useAppDispatch();
  const employeesSnapshot = useRef<Record<string, FullEmployeee>>({});

  useEffect(() => {
    getDismissalTypes();
  }, [getDismissalTypes]);

  const handleWebsiteUnload = async (changesParam: EntitiesState) => {
    // console.log("[D] Started Unload Process");
    if (changesParam) {
      if (Object.values(changesParam).length > 0) {
        const data = await fetchClientPendingTasksNoRedux({
          clientId: {
            eq: selectedClientId,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_DEACTIVATION,
          },
        });

        Object.values(changesParam).forEach((change) => {
          const { id } = change;

          const prevTask = data.find(
            (task: ClientPendingTask) => task.employeeId === id
          );

          if (prevTask) {
            // console.log(`[D] Unload Process: Already Exists Pending Task [${i + 1}/${Object.values(changesParam).length}]`);
            dispatch(updateClientPendingTask(prevTask));
          } else {
            const employee = employeesSnapshot.current[id];

            dispatch(
              createClientPendingTask({
                clientId: selectedClientId,
                employeeId: id,
                type: ClientPendingTaskType.CPTT_EMPLOYEES_DEACTIVATION,
                employeeName: `${employee?.firstLastName} ${employee?.secondLastName} ${employee?.name}`,
              })
            );
            // console.log(`[D] Unload Process: Created Pending Task [${i + 1}/${Object.values(changesParam).length}]`);
          }
        });
      } else {
        // console.log("[D] Finished Unload Process: Nothing happened [1]");
      }
    } else {
      // console.log("[D] Finished Unload Process: Nothing happened [0]");
    }
  };

  const warnAgainstWebsiteUnload = async (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleDeleteAllPendingTasks = async () => {
    dispatch(
      deleteClientPendingTask({
        clientId: {
          eq: selectedClientId,
        },
        title: {
          eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_DEACTIVATION,
        },
      })
    );
  };

  // Unloading website
  useEffect(() => {
    changesSnapshot.current = changes;
    if (changes) {
      if (Object.values(changes).length > 0 && !changesAlertPublished.current) {
        prevTasksDeleted.current = false;
        // console.log("[D] Activated Unload Listener");
        window.addEventListener('beforeunload', warnAgainstWebsiteUnload);
        changesAlertPublished.current = true;
      } else if (Object.values(changes).length === 0) {
        if (changesAlertPublished.current) {
          // console.log("[D] Deactivated Unload Listener");
          window.removeEventListener('beforeunload', warnAgainstWebsiteUnload);
        }
        changesAlertPublished.current = false;
      }

      if (Object.values(changes).length === 0 && !prevTasksDeleted.current) {
        // console.log("[D] No changes detected, deleting pending tasks (if apply)");
        handleDeleteAllPendingTasks();
        prevTasksDeleted.current = true;
      }
    }

    return () => {
      window.removeEventListener('beforeunload', warnAgainstWebsiteUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  useEffect(() => {
    window.addEventListener('beforeunload', () =>
      handleWebsiteUnload(changesSnapshot.current)
    );
    return () => {
      window.removeEventListener('beforeunload', () =>
        handleWebsiteUnload(changesSnapshot.current)
      );

      handleWebsiteUnload(changesSnapshot.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fullEmployeesMap: Record<string, FullEmployeee> = {};

    if (Object.keys(employees.entities).length > 0) {
      Object.keys(employees.entities).forEach((emp) => {
        fullEmployeesMap[emp] = employees?.entities[emp] as FullEmployeee;
      });
    } else if (Object.keys(employeesMap).length > 0) {
      Object.keys(employeesMap).forEach((emp) => {
        fullEmployeesMap[emp] = employeesMap[emp] as FullEmployeee;
      });
    }

    employeesSnapshot.current = fullEmployeesMap;
  }, [employees.entities, employeesMap]);

  return {
    grid,
    employees: employeesMap,
    handleChanges: onChanges,
    DataEditor,
    ValueRenderer,
    cleanDataSheetLocalStorage,
    cleanChanges,
  };
}
