import { MutableRefObject } from 'react';

import _ from 'lodash';
import { EntitiesState } from 'types';

const useDataSheetValidEntities = (entitiesMapRef: MutableRefObject<EntitiesState>, errors: EntitiesState, requiredMissingValues: EntitiesState) => {
  const validEntities: EntitiesState = {};

  _.values(entitiesMapRef.current).forEach((entity) => {
    const isInvalidEntity = _.has(requiredMissingValues, `${entity.id}`);
    if (isInvalidEntity) {
      return;
    }
    _.set(validEntities, `${entity.id}.id`, entity.id);
    _.keys(entity).forEach((field) => {
      const value = entity[field];
      const isInvalid = _.has(errors, `${entity.id}.${field}`);
      if (!isInvalid) {
        _.set(validEntities, `${entity.id}.${field}`, value);
      }
    });
  });

  return { validEntities };
};

export default useDataSheetValidEntities;
