import { MutableRefObject, useMemo, useState } from 'react'
// import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect, usePreviousDistinct } from 'react-use';

import { noop } from 'helpers';
import { useAppDispatch } from 'hooks/redux';
import _ from 'lodash';
import { showMessage } from 'store/slices';

import { Cell, CellUpdate, EntitiesState, GridKey, HandleChanges, ObjectMap, Value } from '../../types';
// import useStateLocalStorage from '../useStateLocalStorage';

type UseDataSheetErrors = {
  errors: EntitiesState;
  validatedGrid: Cell[][];
  removeErrorsStorage: () => void;
};

const useDataSheetErrors = (
  changes: EntitiesState,
  changedGrid: Cell[][],
  rowsMapRef: MutableRefObject<ObjectMap>,
  colsMapRef: MutableRefObject<ObjectMap>,
  rowsEntities: MutableRefObject<EntitiesState>,
  updateCellAtributes: (
    row: GridKey,
    field: GridKey,
    nextAtributtes: CellUpdate
  ) => void,
  handleChanges: HandleChanges,
  // localStorageId?: string,
): UseDataSheetErrors => {
  // const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const prevChanges = usePreviousDistinct(changes, _.isEqual);
  // const [
  //   errors = {},
  //   setErrors,
  //   removeErrorsStorage,
  // ] = useStateLocalStorage<EntitiesState>(`useDataSheetErrors_errors${pathname}${localStorageId}`, {});
  const [errors, setErrors] = useState<EntitiesState>({});
  const prevErrorsRender = usePreviousDistinct(errors, _.isEqual);

  const validatedGrid = useMemo(() => {
    const nextValidatedGrid = changedGrid.map((changedGridRow) => [...changedGridRow]);
    Object.values(errors || {}).forEach(({ id: entityId, ...entityErrors }) => {
      Object.keys(entityErrors).forEach((field) => {
        const rowIndex = rowsMapRef.current[entityId];
        const colIndex = colsMapRef.current[field];
        if (rowIndex === undefined || colIndex === undefined) return;
        nextValidatedGrid[rowIndex][colIndex] = {
          ...nextValidatedGrid[rowIndex][colIndex],
          className: 'error',
        };
      });
    });

    return nextValidatedGrid;
  }, [changedGrid, colsMapRef, errors, rowsMapRef]);

  const getCell = (row: string | number, col: string | number): Cell => {
    const rowIndex = typeof row === 'string' ? rowsMapRef.current[row] : row;
    const colIndex = typeof col === 'string' ? colsMapRef.current[col] : col;;
    return {
      ..._.get(changedGrid, `${rowIndex}.${colIndex}`, {}),
    };
  };

  const handleValidation = async (cell: Cell, value: Value) => {
    if (typeof cell.validation !== 'function') return;
    const { isValid, error } = await cell.validation(value, cell, {
      entities: rowsEntities.current,
      getCell,
      handleChanges,
      updateCellAtributes,
    });
    if (isValid) {
      setErrors((prevErrors) => {
        const nextErrors = { ...prevErrors };
        if (nextErrors[cell.id]) {
          delete nextErrors[cell.id][cell.field];
          if (Object.keys(nextErrors[cell.id]).length === 1) {
            delete nextErrors[cell.id];
          }
        }
        return nextErrors;
      })
      return;
    }
    setErrors((prevErrors) => {
      const nextErrors: EntitiesState = {
        ...prevErrors,
        [cell.id]: {
          ..._.get(prevErrors, cell.id, {}),
          id: cell.id,
          [cell.field]: _.get(error, 'message', 'Validacion no aprobada'),
        }
      };
      return nextErrors;
    });
  }

  useDeepCompareEffect(() => {
    const nextErrors: EntitiesState = {};
    _.values(changes).forEach(({ id, ...entityChanged }) => {
      _.keys(entityChanged).forEach((changeKey) => {
        if (_.get(errors, `${id}.${changeKey}`)) {
          nextErrors[id] = {
            ..._.get(nextErrors, id, {}),
            id,
            [changeKey]: _.get(errors, `${id}.${changeKey}`),
          };
        }
      });
      nextErrors[entityChanged.id] = {
        ..._.pick(nextErrors, _.keys(entityChanged)),
        id: entityChanged.id,
      };
      if (_.keys(nextErrors[entityChanged.id]).length === 1) {
        delete nextErrors[entityChanged.id];
      }
    });
    setErrors(_.pick(nextErrors, _.keys(changes)));
    // setErrors({});
    _.values(changes).forEach(({ id: entityId, ...changeEntity }) => {
      Object.keys(changeEntity).forEach((field) => {
        const rowIndex = rowsMapRef.current[entityId];
        const colIndex = colsMapRef.current[field];
        if (rowIndex === undefined || colIndex === undefined) return;
        if (_.get(changes, `${entityId}.${field}`, '') === _.get(prevChanges, `${entityId}.${field}`, '')) return;
        // if (validatedGridRef.current.length === 0) return;
        const value: Value = changeEntity[field];
        const cell = changedGrid[rowIndex][colIndex];
        if (cell.validation === undefined) return;

        if (cell.messageBeforeValidation?.length && _.get(prevChanges, `${entityId}.${field}`, '') !== value) {
          dispatch(showMessage({
            message: cell.messageBeforeValidation,
            type: 'info',
          }))
        }
        handleValidation(cell, value);
        cell.forceRevalidation?.forEach((fieldToBeRevalidate) => {
          const cellToBeRevalidate = getCell(entityId, fieldToBeRevalidate);
          if (cellToBeRevalidate.validation === undefined) return;
          const valueToBeRevalidate = changeEntity[fieldToBeRevalidate];
          if (valueToBeRevalidate !== undefined) {
            handleValidation(cellToBeRevalidate, valueToBeRevalidate);
          }
        })
      });
    });
  }, [changes]);

  // useEffectOnce(() => {
  //   _.values(errors).forEach(({ id, ...errorsEntity }) => {
  //     _.keys(errorsEntity).forEach((fieldWithError) => {
  //       const errorMessage = _.get(errorsEntity, fieldWithError, '');
  //       dispatch(showMessage({
  //         message: errorMessage,
  //         type: 'error',
  //       }));
  //     });
  //   });
  // });

  useDeepCompareEffect(() => {
    _.values(errors).forEach(({ id, ...errorsEntity }) => {
      _.keys(errorsEntity).forEach((fieldWithError) => {
        const errorMessage = _.get(errorsEntity, fieldWithError, '');
        const prevErrorMessage = _.get(prevErrorsRender, `${id}.${fieldWithError}`, '');
        if (prevErrorMessage !== errorMessage) {
          dispatch(showMessage({
            message: errorMessage,
            type: 'error',
          }));
        }
      });
    });
  }, [errors]);

  return {
    errors,
    validatedGrid,
    removeErrorsStorage: noop,
  };
};

export default useDataSheetErrors;
