export const validateNSS = (nss) => {
  if (nss.length !== 11) return false;

  const cad = nss.charAt(0)
    + (parseInt(nss.charAt(1), 10) * 2).toString()
    + parseInt(nss.charAt(2), 10)
    + (parseInt(nss.charAt(3), 10) * 2).toString()
    + parseInt(nss.charAt(4), 10)
    + (parseInt(nss.charAt(5), 10) * 2).toString()
    + parseInt(nss.charAt(6), 10)
    + (parseInt(nss.charAt(7), 10) * 2).toString()
    + parseInt(nss.charAt(8), 10)
    + (parseInt(nss.charAt(9), 10) * 2).toString();
  let sum = 0;
  for (let i = 0; i < cad.length; i += 1) {
    sum += parseInt(cad.charAt(i), 10);
  }

  if (sum.toString().charAt(sum.toString().length - 1) === '0') return nss.charAt(10) === '0';

  const lastDigit = 10 - parseInt(sum.toString().charAt(sum.toString().length - 1), 10);
  return nss.charAt(10) === lastDigit.toString();
}