import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import _ from 'lodash';

import { Employee } from '../../../types';
import { useAppSelector } from '../../redux';

const useDenormalizedEmployees = (): Employee[] => {
  const employeesMap = useAppSelector(({ employees }) => employees.entities);
  const banks = useAppSelector(({ bankAccounts }) => bankAccounts.entities);
  const [employees, setEmployees] = useState<Employee[]>([]);

  useDeepCompareEffect(() => {
    const nextEmployees: Employee[] = Object.values(employeesMap)
      .map((employee) => {
        const employeeBankAccounts = employee.bankAccounts || [];
        const bankAccountId = employeeBankAccounts.find((accountId) => _.get(banks, `${accountId}.cardDefault`, false));
        const defaultBankAccount = bankAccountId === undefined ? null : banks[bankAccountId]
        const formatedEmployee = {
          ...employee,
          bankAccount: defaultBankAccount,
        };
        return formatedEmployee;
      });
    setEmployees(nextEmployees);
  }, [employeesMap, banks]);
  return employees;
};

export default useDenormalizedEmployees;
