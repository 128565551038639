/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
export const RFCpattern = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9])$/;
export const CURPpattern = /^[A-Z][AEIOU][A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z][0-9]$/;
export const NSSPattern = /^(\d{2})(\d{2})(\d{2})\d{5}$/;
export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const namePattern = /\w+(\s\w+)+/;
export const noSpecialCharacters = /^(([a-zA-Z]*)(\s*))*$/;
export const emailPatternV2 = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const doesRfcAndCurpMatches = ({ rfc, curp }: { rfc: string; curp: string }) => {
  if (curp.length === 0 || rfc.length === 0) {
    return true;
  }
  const firstLetterCurp = curp.substring(0, 1);
  const firstLetterRfc = rfc.substring(0, 1);
  const yearOfBirthCurp = curp.substring(4, 6);
  const yearOfBirthRfc = rfc.substring(4, 6);

  return firstLetterCurp === firstLetterRfc && yearOfBirthCurp === yearOfBirthRfc;
};

export const doesRfcAndImssMatches = ({ rfc, imss }: { rfc: string; imss: string }) => {
  if (imss.length === 0 || rfc.length === 0) {
    return true;
  }
  const yearOfBirthImss = Number(imss.substring(4, 6));
  const yearOfBirthRfc = Number(rfc.substring(4, 6));

  return yearOfBirthImss === yearOfBirthRfc || yearOfBirthImss === yearOfBirthRfc - 1;
};

export const doesCurpAndImssMatches = ({ curp, imss }: { curp: string; imss: string }) => {
  if (imss.length === 0 || curp.length === 0) {
    return true;
  }
  const yearOfBirthImss = Number(imss.substring(4, 6));
  const yearOfBirthCurp = Number(curp.substring(4, 6));

  return yearOfBirthImss === yearOfBirthCurp || yearOfBirthImss === yearOfBirthCurp - 1;
};
