import {
  ContainerProps,
  CSSObjectWithLabel,
  GroupBase,
  StylesConfig,
} from 'react-select';

import { Option as CustomOption } from '../../types';

const getContainerStyles = <
Option = CustomOption,
IsMulti extends boolean = false,
Group extends GroupBase<Option> = GroupBase<Option>
> (base: CSSObjectWithLabel, { selectProps }: ContainerProps<Option, IsMulti, Group>): CSSObjectWithLabel => {
  const { error, helperText } = selectProps;
  return {
    ...base,
    marginBottom: (error && helperText) ? '20px' : '0',
  };
};

const getControlStyles = (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    borderColor: 'transparent',
    boxShadow: '0 0 0 1px transparent',
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: '0 0 0 1px transparent',
    },
    // '&:focus': {
    //   borderColor: 'transparent',
    //   boxShadow: '0 0 0 1px transparent',
    // },
  });

const getMenuPortalStyles = (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...base,
  // zIndex: 1,
  // backgroundColor: hasError ? '#d32f2f' : 'inherit',
});

const getSingleValueStyles = (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...base,
  position: 'absolute',
  width: '100%',
  padding: '2px 8px 2px 12px',
});

const getValueContainerStyles = (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...base,
  overflow: 'visible',
  padding: 0,
  zIndex: 1,
});

const getIndicatorsContainerStyle = (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...base,
  borderColor: 'hsl(0, 0%, 80%)',
  borderWidth: '1px',
  borderStyle: 'solid',
});

const getStyles = <
  Option = CustomOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(generalBase?: StylesConfig<Option, IsMulti, Group>): StylesConfig<Option, IsMulti, Group> => 
  ({
    container: getContainerStyles,
    control: getControlStyles,
    menuPortal: getMenuPortalStyles,
    singleValue: getSingleValueStyles,
    valueContainer: getValueContainerStyles,
    indicatorsContainer: getIndicatorsContainerStyle,
    ...generalBase,
  });

export default getStyles;
