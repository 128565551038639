/* eslint-disable no-underscore-dangle */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sticky, StickyContainer } from 'react-sticky';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Button,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';

import { myGet } from '../helpers';
import { useAppDispatch } from '../hooks';
import { useAppSelector } from '../hooks/redux';
import { fetchClientPendingTasks, promisedCalculateClientPendingTasksByClient } from '../store/slices/pendingTasksSlice';
import { ClientPendingTask } from '../types';
import ExpandableComponent from './ExpandableComponent';

interface Props {
  elements: Array<any>,
  entity: Record<string, any>,
  frequency: string,
  foundIndex: number,
  handleExtraordinaryClick: (entity: Record<string, any>) => void,
  pendingTaskMap: Record<string, ClientPendingTask>,
}

const PayrollPeriodList: FC<Props> = (props): JSX.Element => {
  const {
    elements,
    entity,
    frequency,
    foundIndex,
    handleExtraordinaryClick,
    pendingTaskMap,
  } = props;
  const dispatch = useAppDispatch();

  const analyzedPeriodsForNotifications = useRef(false)
  const [counter, setCounter] = useState<number>(4);
  const [frequencyValueCounter, setFrequencyValueCounter] = useState<number>(1);
  const [elementsState, setElementsState] = useState<Array<any>>([])
  const [pastElementsState, setPastElementsState] = useState<Array<any>>([]);
  const [nextPeriods, setNextPeriods] = useState<Array<any>>([]);
  const [originalNextPeriods, setOriginalNextPeriods] = useState<Array<any>>([]);

  const navigate = useNavigate();
  const fullClients = useAppSelector((state) => state.fullClients.entities)
  const clientId = useAppSelector((state) => state.clients.selected);
  const selectedClient = fullClients[clientId]
  const globalPermissions = selectedClient?.globalPermissions

  useEffect(() => {
    if (globalPermissions && !myGet(globalPermissions, 'periodsModule.enabled', false) && navigate) {
      navigate("/");
    }
  }, [globalPermissions, navigate])

  useEffect(() => {

    const process = async () => {

      await promisedCalculateClientPendingTasksByClient(clientId)
      dispatch(fetchClientPendingTasks({}))
    }

    process();

  }, [dispatch, clientId]);

  useEffect(() => {
    setElementsState(elements)
  }, [elements])

  useEffect(() => () => {
    analyzedPeriodsForNotifications.current = false;
  }, [])

  useEffect(() => {
    if (foundIndex !== -1) {

      setPastElementsState(elements.slice(foundIndex + 1, elements.length));
      setNextPeriods(elements.slice(0, foundIndex));
      setOriginalNextPeriods(elements.slice(0, foundIndex));
    }
  }, [elements, foundIndex])

  useEffect(() => {
    setNextPeriods(originalNextPeriods.slice(originalNextPeriods.length - frequencyValueCounter, originalNextPeriods.length))
  }, [frequencyValueCounter, originalNextPeriods])

  const elementsFiltered = useMemo(() => (
    elementsState.filter((element) => {
      const date = new Date();
      const { type } = element;
      if (type === 'Extraordinario') {
        const payday = moment(element.payday).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        if (payday === today) {
          return true;
        }
        return false;
      }
      return new Date(element.start) < date && new Date(element.end) >= date;
    })
  ), [elementsState])

  const pastElementsFiltered = useMemo(() => (
    pastElementsState.filter((pastElement) => (
      !elementsFiltered.includes(pastElement)
    ))
  ), [elementsFiltered, pastElementsState])

  return (
    <StickyContainer key={entity.id} style={{ marginBottom: 130 }}>
      <Sticky bottomOffset={-80} topOffset={-80}>
        {({
          style,
        }) => (
          <div style={{ ...style, top: 88, zIndex: 2, background: 'white' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10,
                boxSizing: 'border-box',
              }}
            >
              <Typography component='div' gutterBottom variant='h5'>
                {entity.name}
              </Typography>
              <Button
                onClick={() => handleExtraordinaryClick(entity)}
                size='small'
                variant='outlined'
              >
                <AddCircleOutlineIcon />
                Crear nomina extraordinaria
              </Button>
            </div>
            {
              elementsState.length > 0 && (
                <div
                  style={{
                    padding: 10,
                    width: 500,
                    display: 'flex',
                  }}
                >
                  {counter === pastElementsState.length ? (
                    <Button
                      onClick={() => setCounter(4)}
                      style={{ marginRight: 30 }}
                      variant='outlined'
                    >
                      Contraer
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setCounter(pastElementsState.length)}
                      style={{ marginRight: 30 }}
                      variant='outlined'
                    >
                      Mostrar todos
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setFrequencyValueCounter(frequencyValueCounter + 1)
                    }}
                    variant='outlined'
                  >
                    Mostrar Siguiente
                  </Button>
                </div>
              )
            }
          </div>
        )}
      </Sticky>
      <ExpandableComponent
        counter={nextPeriods.length}
        elements={nextPeriods}
        frequency={frequency}
        highlightColor='#DFDFDF'
      />
      {
        elementsState[foundIndex] && (
          <ExpandableComponent
            counter={elementsFiltered.length}
            elements={elementsFiltered}
            frequency={frequency}
            highlightColor='#E5F6FD'
          />
        )
      }
      <ExpandableComponent
        counter={counter}
        elements={pastElementsFiltered}
        frequency={frequency}
        highlightColor="white"
      />
    </StickyContainer>
  )
}

export default PayrollPeriodList;
