import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { getObjectMap } from 'helpers';

import {
  Column,
  Header,
  ObjectMap,
  UseDataSheetHeader,
} from '../../types';

const getHeaderFromColumn = (
  column: Column,
  colIndex: number,
  defaultIsEnable: boolean,
  areAllRequired: boolean,
  ref: MutableRefObject<undefined>,
): Header => {
  const {
    defaultLabel = '',
    defaultValue = '',
    isEnabled = defaultIsEnable,
    isRequired = areAllRequired,
    separator = ', ',
    type = 'text',
    hidden = false,
    title,
    component,
    forceComponent: columnForceComponent = false,
    onClick: handleClick,
    forceHidden = false,
    ...rest
  } = column;
  const value = Array.isArray(title) ? title.join(separator) : title;
  const header: Header = {
    initialValue: '',
    className: '',
    colIndex,
    id: '-1',
    defaultLabel,
    defaultValue,
    readOnly: true,
    rowIndex: -1,
    isEnabled,
    isRequired,
    separator,
    title: value,
    type,
    value,
    hidden,
    isLoading: false,
    columnComponent: component,
    columnForceComponent,
    handleClick,
    forceHidden,
    handleChangesRef: ref,
    updateCellAtributesRef: ref,
    ...rest,
  };
  return header;
};

const getHeaderFromColumns = (
  columns: Column[],
  areAllRequired: boolean,
  defaultIsEnable: boolean,
  ref: MutableRefObject<undefined>,
): Header[] => {
  const headers: Header[] = columns.map((column, colIndex) => getHeaderFromColumn(column, colIndex, defaultIsEnable, areAllRequired, ref));
  return headers;
};

const useDataSheetHeader = (
  columns: Column[],
  areAllRequired: boolean,
  defaultIsEnable: boolean
): UseDataSheetHeader => {
  const ref = useRef();
  const [header, setHeader] = useState<Header[]>(
    getHeaderFromColumns(columns, areAllRequired, defaultIsEnable, ref)
  );
  const colsMapRef = useRef<ObjectMap>(getObjectMap(header, 'field'));

  const updateColAtributes = useCallback((
    column: number | string,
    newAttributes: Record<string, unknown>
  ) => {
    setHeader((prevHeader) => {
      const nextHeader = [...prevHeader];
      const colIndex = typeof column === 'string'
        ? colsMapRef.current[column] : column;

      nextHeader[colIndex] = {
        ...nextHeader[colIndex],
        ...newAttributes,
      };
      return nextHeader;
    });
  }, []);

  const addColumn = useCallback((column: Column, index?: number) => {
    const fieldList = Object.keys(colsMapRef.current);
    if (fieldList.includes(column.field)) {
      return; // Si la columna a agregar ya existe en el datasheet entonces no se hace nada
    }
    const isValidIndex = (index !== undefined && index >= 0 && index < header.length);
    const indexToBeInserted = isValidIndex ? index : header.length;
    const newHeader = getHeaderFromColumn(column, indexToBeInserted, defaultIsEnable, areAllRequired, ref);

    setHeader((prevHeader) => {
      const nextHeader = [...prevHeader];
      if (indexToBeInserted === nextHeader.length) {
        nextHeader.push(newHeader);
      } else if (indexToBeInserted === 0) {
        nextHeader.unshift(newHeader);
      } else {
        nextHeader.splice(indexToBeInserted, 0, newHeader);
      }
      const formatedNextHeader: Header[] = nextHeader.map((headerCol, colIndex) => ({
        ...headerCol,
        colIndex,
      }));
      return formatedNextHeader;
    });
  }, [areAllRequired, defaultIsEnable, header.length]);

  useLayoutEffect(() => {
    colsMapRef.current = getObjectMap(header, 'field');
  }, [header]);

  return {
    header,
    colsMapRef,
    updateColAtributes,
    addColumn,
  };
};

export default useDataSheetHeader;
