import React, { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import LoadingButton from '../../components/LoadingButton';
import { useAppSelector } from '../../hooks';
import EmployeesService from '../../services/EmployeesService';
import Base from './Base';
import { StepProps } from './types';

export interface AuthorizationProps extends StepProps {}

export default function Authorization({
  onFinish,
  onCancel,
  title
}: AuthorizationProps) {
  const { spacing } = useTheme();
  const user = useAppSelector((state) => state.session.user);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const disabled = password.length === 0;

  const onAuthorize = () => {
    if (!user) return;

    setLoading(true);

    EmployeesService.confirm({
      password,
      email: user.email,
      // TODO: Remove hardcoded subdomain.
      subdomain: 'intela',
    }).then((confirmed) => {
      if (confirmed) onFinish('authorization');

      setError(!confirmed);
      setLoading(false);
    });
  };

  const actions = [
    <LoadingButton
      color="error"
      fullWidth
      onClick={() => onCancel()}
      size="large"
      variant="contained"
    >
      Cancelar
    </LoadingButton>,
    <LoadingButton
      circularProgressSize={28}
      disabled={disabled}
      fullWidth
      loading={loading}
      onClick={() => onAuthorize()}
      size="large"
      variant="contained"
    >
      Autorizar
    </LoadingButton>,
  ];

  return (
    <Base
      actions={actions}
      contentContainerStyle={{
        padding: spacing(3),
      }}
      // primary="Ingresa tu contraseña para autorizar la desactivación de los empleados seleccionados"
      title={title}
    >
      <TextField
        disabled={loading}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Contraseña"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && onAuthorize()}
        type={showPassword ? 'text' : 'password'}
        value={password}
      />
      {error ? (
        <Typography
          color="error"
          style={{
            marginTop: spacing(2),
          }}
          variant="body1"
        >
          La contraseña ingresada es incorrecta.
        </Typography>
      ) : null}
    </Base>
  );
}
