export const sfprepayrollAddMany = `
  mutation sfprepayrollAddMany($payrollPeriod: String!, $prepayroll: [InputSalariesFundPrePayroll!]!) {
    sfprepayrollAddMany(payrollPeriod: $payrollPeriod, prepayroll: $prepayroll) {
      alimony
      awards
      bonus
      createdAt
      calculoFiscal
      diningRoom
      disabilityDays
      doubleOvertime
      employee {
        id
        name
        firstLastName
        secondLastName
      }
      extraTime
      faults
      holidayWorked
      id
      netSalary
      pantryVouchers
      paymentMethod {
        id
        name
      }
      payrollPeriod {
        id
        name
      }
      ptu
      restWorked
      savingFund
      sundayBonus
      sundaysWorked
      tripleOvertime
      updatedAt
      vacation
      vacationBonus
      workedDays
    }
  }
`