/* eslint-disable no-debugger */
/* eslint-disable no-case-declarations */
import { useEffect, useRef, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';

import { AxiosResponse } from 'axios';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest, myGet } from '../../../helpers';
import {
  createClientPendingTask,
  deleteClientPendingTask,
  fetchCivilStatus,
  fetchClientPendingTasksNoRedux,
  fetchContracts,
  fetchDepartments,
  fetchFullClients,
  fetchJobs,
  fetchPaymentMethods,
  fetchPayrollsTypes,
  fetchPensionTypes,
  fetchPeriodFrequencies,
  fetchSalaryTypes,
  fetchSocieties,
  fetchSocietyBranches,
  fetchVariableSalaries,
  fetchWorkdays,
  fetchWorks,
  updateClientPendingTask,
} from '../../../store/slices';
import {
  ApiResponse,
  Cell,
  Change,
  ClientPendingTask,
  ClientPendingTaskTitle,
  ClientPendingTaskType,
  Employee,
  EntitiesState,
  HandleChanges,
  Option,
} from '../../../types';
import { ContractTypeElement } from '../../../types/employee';
import { useAppDispatch, useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';
import config from './config';
import useMyEmployeesChangesHandler from './useMyEmployeesChangesHandler';

type UseMyEmployeesDatasheet = {
  isLoading: boolean;
  hasData: boolean;
  hasPendingChanges: boolean;
  count: number;
  rows: EntitiesState;
  changes: EntitiesState;
  grid: Cell[][];
  handleChanges: HandleChanges;
  ValueRenderer: ReactDataSheet.ValueRenderer<any, any>;
  cleanDataSheetLocalStorage: () => void;
  parsePaste: (str: string) => string[][]
  selectedFromSheet: ReactDataSheet.Selection;
  handleOnSelect: (selected: ReactDataSheet.Selection) => void
  backToLastPage: () => void;
  dialogValues: DialogValues;
  valueStatistics: ValueStatistics;
  employees: Record<string, Employee>;
  flags: Flags;
  saveResultInstances: ResultInstances;
};

interface DialogValues {
  undoChangesDialogVisible: boolean;
  handleUndoChangesDialogOpen: () => void;
  handleUndoChangesDialogClose: () => void;
  handleConfirmUndoChanges: () => void;
  confirmChangesDialogVisible: boolean;
  handleConfirmChangesDialogOpen: () => void;
  handleConfirmChangesDialogClose: () => void;
  handleConfirmSaveChanges: () => void;
  exportEmployeesDialogVisible: boolean;
  handleexportEmployeesDialogOpen: () => void;
  handleexportEmployeesDialogClose: () => void;
}

interface ValueStatistics {
  dataSnapshots: Record<string, any>;
  dataErrors: Record<string, any>;
}

export interface Flags {
  loading: {
    saveProcess: boolean;
  },
  process: {
    hasSaved: boolean;
  }
}

export interface ResultInstances {
  accepted: Record<string, any>;
  errors: Record<string, any>;
}

const useMyEmployeesDatasheet = (): UseMyEmployeesDatasheet => {
  /** Globals */
  const { clientId, payrollId } = useParams();
  const dispatch = useAppDispatch();
  const prevTasksDeleted = useRef(false);
  const loadedLocalStorageData = useRef(false);
  const changesAlertPublished = useRef(false);
  const changesSnapshot = useRef({} as EntitiesState);
  const employeesSnapshot = useRef<Record<string, Employee>>({});

  /** States and Selectors */
  const navigate = useNavigate()
  const [selectedFromSheet, setselectedFromSheet] = useState<ReactDataSheet.Selection>({ end: { i: 0, j: 0 }, start: { i: 0, j: 0 } });
  const [employeesEntities, setEmployeesEntities] = useState<Record<string, Employee>>({});
  const [undoChangesDialogVisible, setundoChangesDialogVisible] = useState(false);
  const [confirmChangesDialogVisible, setconfirmChangesDialogVisible] = useState(false);
  const [exportEmployeesDialogVisible, setexportEmployeesDialogVisible] = useState(false);

  const { entities: employeesMap, isLoading, count } = useAppSelector(({ employees }) => employees);
  const departmentsMap = useAppSelector(({ departments }) => departments.entities);
  const clientsMap = useAppSelector(({ fullClients }) => fullClients.entities);
  const selectedClientId = useAppSelector(({ fullClients }) => fullClients.selected);
  const selectedClient = clientsMap[selectedClientId];
  const departmentsFromClient = selectedClient?.departments;
  const societiesFromClient = selectedClient?.payingCompanies;
  const payrollsTypesMap = useAppSelector(({ payrollsTypes }) => payrollsTypes.entities);
  const societyBranchesMap = useAppSelector(({ societyBranches }) => societyBranches.entities);
  const jobsMap = useAppSelector(({ jobs }) => jobs.entities);
  const salaryTypesMap = useAppSelector(({ salaryTypes }) => salaryTypes.entities);
  const contractsMap = useAppSelector(({ contracts }) => contracts.entities);
  const pensionTypesMap = useAppSelector(({ pensionTypes }) => pensionTypes.entities);
  const variableSalariesMap = useAppSelector(({ variableSalaries }) => variableSalaries.entities);
  const workdaysMap = useAppSelector(({ workdays }) => workdays.entities);
  const civilStatusMap = useAppSelector(({ civilStatus }) => civilStatus.entities);
  const worksMap = useAppSelector(({ works }) => works.entities);
  const periodFrequenciesMap = useAppSelector(({ periodFrequencies }) => periodFrequencies.entities);
  const paymentMethodsMap = useAppSelector(({ paymentMethods }) => paymentMethods.entities);

  // Changes Variables
  const [dataSnapshots, setDataSnapshots] = useState<Record<string, any>>({});
  const [dataErrors, setDataErrors] = useState<Record<string, []>>({});
  const [saveResultInstances, setsaveResultInstances] = useState<ResultInstances>({
    accepted: {},
    errors: {},
  });
  const [flags, setflags] = useState<Flags>({
    loading: {
      saveProcess: false,
    },
    process: {
      hasSaved: false,
    }
  });

  const handleDataSnapshotChange = (updates: Record<string, any>) => {
    setDataSnapshots(updates);
  }
  const handleDataErrorsChange = (updates: Record<string, any>) => {
    setDataErrors(updates);
  }

  const {
    hasData,
    isCalculating,
    grid,
    getCell,
    rows,
    changes,
    handleChanges,
    ValueRenderer,
    updateColumnAtributes,
    updateCellAtributes,
    cleanDataSheetLocalStorage,
    cleanChanges,
    cleanMultipleRowChanges,
    entities,
  } = useDataSheet(columns, employeesEntities, config);

  const hasPendingChanges = Object.keys(changes || {}).length > 0;

  /** Functions */

  const handleOnSelect = ({ start, end }: ReactDataSheet.Selection) => {
    setselectedFromSheet({ start, end });
  };

  const parsePaste = (str: string) => {
    const cells = str.split(/\r\n|\n|\r/).map((row) => row.split('\t'));
    const selectedNumberOfRows = Math.abs(selectedFromSheet.end.i - selectedFromSheet.start.i) + 1;
    const selectedNumberOfCols = Math.abs(selectedFromSheet.end.j - selectedFromSheet.start.j) + 1;

    if (cells.length === 0) return [];

    const curRows = Math.max(selectedNumberOfRows, cells.length);
    const cols = Math.max(selectedNumberOfCols, cells[0].length);

    return _.range(curRows).map((row) => _.range(cols).map((col) => cells[row % cells.length][col % cells[0].length]));
  };

  const backToLastPage = () => {
    navigate(-1)
  }

  const handleConfirmUndoChanges = () => {
    cleanChanges();
    cleanDataSheetLocalStorage();

    if (Object.keys(dataSnapshots).length > 0) {
      dispatch(deleteClientPendingTask({
        clientId: {
          eq: clientId,
        },
        payrollTypeId: {
          eq: payrollId,
        },
        title: {
          eq: ClientPendingTaskTitle.CPTTi_MY_EMPLOYEES
        },
      }))
    }

    let updatedEmployeeData = { ...employeesEntities };

    Object.keys(dataSnapshots).forEach((employeeId) => {
      const rollbackData = dataSnapshots[employeeId];

      Object.keys(rollbackData).forEach((key) => {
        updatedEmployeeData = {
          ...updatedEmployeeData,
          [employeeId]: {
            ...updatedEmployeeData[employeeId],
            [key]: rollbackData[key],
          },
        };
      });
    });
    setEmployeesEntities(updatedEmployeeData);

    setDataSnapshots({});
    setDataErrors({});
    setundoChangesDialogVisible(false);
  }

  const handleConfirmSaveChanges = async () => {

    setflags({
      ...flags,
      loading: {
        ...flags.loading,
        saveProcess: true,
      }
    })

    const preEmployeesToSave = Object.keys(dataSnapshots).filter((employeeId) => {
      if (dataErrors[employeeId]) {
        if (dataErrors[employeeId].length > 0) {
          return false;
        }
      }

      return true
    })

    const editInputs = preEmployeesToSave.map((key) => {
      const employee = employeesMap[key];
      let data: Record<string, any> = {}
      if (changes) {
        data = changes[key];
      }

      const input: Record<string, any> = {}

      Object.keys(data).forEach((uKey) => {
        const prevActiveContract = employee?.contractTypes?.find((contract) => contract.isActive);
        const prevContracts = employee?.contractTypes?.filter((contract) => !contract.isActive);

        switch (uKey) {
          case 'civilStatus':
          case 'daysType':
          case 'department':
          case 'job':
          case 'paymentMethod':
          case 'work':
          case 'payrollBranch':
          case 'pensionType':
          case 'payrollType':
          case 'salaryType':
            input[uKey] = data[uKey];
            break;
          case 'contractType':
            input[uKey] = data[uKey];

            const newContract: ContractTypeElement = {
              ...prevActiveContract,
              contractType: data[uKey],
              isActive: true,
              expiresOn: contractsMap[data[uKey]]?.days,
            }

            const newContracts: ContractTypeElement[] = [...(prevContracts || []), newContract];
            input.contractTypes = newContracts;

            break;
          default:
            input[uKey] = data[uKey];
            break
          case 'id':
            break;
        }
      })

      return {
        id: employee.id,
        input,
      }
    });

    const results = await Promise.allSettled<AxiosResponse<ApiResponse>>(editInputs.map(async (variables) => {
      const params = getGraphQLParameters('EmployeesEdit', variables)
      return getPostRequest('', params);
    }));

    const cellsToReset: Cell[] = []

    const acceptedResponses = {} as Record<string, any>;
    const rejectedResponses = {} as Record<string, any>;
    results.forEach((result) => {

      if (result.status === 'fulfilled') {
        const { value } = result;
        const { data } = value;

        if (myGet(data, 'errors', null) === null) {
          acceptedResponses[myGet(data, 'data.employeeEdit.id', '')] = true;

          dispatch(deleteClientPendingTask({
            clientId: {
              eq: clientId,
            },
            payrollTypeId: {
              eq: payrollId,
            },
            title: {
              eq: ClientPendingTaskTitle.CPTTi_MY_EMPLOYEES
            },
            employeeId: {
              eq: myGet(data, 'data.employeeEdit.id', '')
            }
          }))

          grid.forEach((row) => {
            row.forEach((cell) => {
              if (cell.className === 'edited' && myGet(cell, 'employeeID', null) === myGet(data, 'data.employeeEdit.id', '')) {
                cellsToReset.push(cell);
              }
            })
          })

        } else {
          const reqJSON = JSON.parse(myGet(value, 'config.data', '{}'));
          const employeeId = myGet(reqJSON, 'variables.id', '');
          rejectedResponses[employeeId] = data;
          // console.log('[ERR_1]', result);
        }
      } else {
        // console.log('[ERR_0]', result);
      }

    })

    setsaveResultInstances({
      accepted: acceptedResponses,
      errors: rejectedResponses,
    });

    setflags({
      ...flags,
      loading: {
        ...flags.loading,
        saveProcess: false,
      },
      process: {
        ...flags.process,
        hasSaved: true,
      }
    })

    const changesToBeMade: Change[] = [];
    cellsToReset.forEach((cell) => {

      changesToBeMade.push({
        cell: {
          ...cell,
          className: '',
        },
        row: cell.id,
        col: cell.colIndex,
        value: cell.value,
      })
    })

    if (changesToBeMade.length > 0) {
      const rowIndexes: (string | number)[] = []
      // handleChanges(changesToBeMade);

      changesToBeMade.forEach((c) => {
        rowIndexes.push(c.cell?.id || c.row)
      })

      cleanMultipleRowChanges(rowIndexes);
    }
  }

  const handleWebsiteUnload = async (changesParam: EntitiesState) => {
    // console.log("[D] Started Unload Process");
    if (changesParam) {
      if (Object.values(changesParam).length > 0) {

        const data = await fetchClientPendingTasksNoRedux({
          clientId: {
            eq: clientId,
          },
          payrollTypeId: {
            eq: payrollId,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_MY_EMPLOYEES
          },
        })

        Object.values(changesParam).forEach((change) => {
          const { id } = change;

          const prevTask = data.find((task: ClientPendingTask) => task.employeeId === id);

          if (prevTask) {
            // console.log(`[D] Unload Process: Already Exists Pending Task [${i + 1}/${Object.values(changesParam).length}]`);
            dispatch(updateClientPendingTask(prevTask))
          } else {
            const employee = employeesSnapshot.current[id];

            dispatch(createClientPendingTask({
              clientId,
              payrollTypeId: payrollId,
              employeeId: id,
              type: ClientPendingTaskType.CPTT_MY_EMPLOYEES,
              employeeName: `${employee?.firstLastName} ${employee?.secondLastName} ${employee?.name}`,
            }))
            // console.log(`[D] Unload Process: Created Pending Task [${i + 1}/${Object.values(changesParam).length}]`);

          }

        })
      } else {
        // console.log("[D] Finished Unload Process: Nothing happened [1]");
      }
    } else {
      // console.log("[D] Finished Unload Process: Nothing happened [0]");
    }

  }

  const warnAgainstWebsiteUnload = async (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '';
  }

  const handleDeleteAllPendingTasks = async () => {
    dispatch(deleteClientPendingTask({
      clientId: {
        eq: clientId,
      },
      payrollTypeId: {
        eq: payrollId,
      },
      title: {
        eq: ClientPendingTaskTitle.CPTTi_MY_EMPLOYEES
      },
    }))
  }

  /** Hooks */

  const { changesValidator } = useMyEmployeesChangesHandler({
    dataSnapshots,
    dataErrors,
    handleDataSnapshotChange,
    handleDataErrorsChange,
    employees: employeesEntities,
    handleEmployeesChange: setEmployeesEntities,
    updateCellParams: updateCellAtributes,
    getCell,
  })

  const handleMyEmployeesChanges: HandleChanges = (edits: Change[]): void => {
    const validatedChanges = changesValidator(edits, true);
    handleChanges(validatedChanges);
  };

  useEffect(() => {
    employeesSnapshot.current = employeesMap;
  }, [employeesMap]);

  /** Effects */
  // Build the employee Data
  useDeepCompareEffect(() => {
    const newEmployees: Record<string, Employee> = {};

    Object.values(employeesMap).forEach((employee) => {
      const newEmployee: Employee = {
        ...employee,
      };

      if (newEmployee.payrollType !== payrollId) {
        return;
      }

      const curClientId = employee.client;
      const societyBranchId = employee.payrollBranch;

      // Variable Salaries
      if (variableSalariesMap) {
        newEmployee.variableSalariesData =
          variableSalariesMap[newEmployee.variableSalaries];
      }

      // Payroll Type
      if (payrollsTypesMap) {
        newEmployee.payrollTypeData = payrollsTypesMap[newEmployee.payrollType];

        if (newEmployee.payrollTypeData) {
          newEmployee.payrollFrequencyData =
            periodFrequenciesMap[newEmployee.payrollTypeData.frequency];
        }
      }

      // Work
      if (worksMap) {
        newEmployee.workData = worksMap[newEmployee.work];
      }

      if (worksMap && societyBranchesMap) {
        const worksByClientAndBranch = Object.values(worksMap).filter((entity) => entity.client === curClientId && entity.societyBranch === societyBranchId);
        newEmployee.worksByClientAndBranch = worksByClientAndBranch;
      }

      // Client
      if (clientsMap) {
        newEmployee.clientData = clientsMap[newEmployee.client];
      }

      // ContractType 
      const activeContract = employee.contractTypes?.find((contract) => contract.isActive)
      newEmployee.actualContractTypeId = activeContract?.contractType;

      newEmployees[employee.id] = newEmployee;
    });

    setEmployeesEntities(newEmployees);
  }, [
    employeesMap,
    payrollsTypesMap,
    variableSalariesMap,
    periodFrequenciesMap,
  ]);

  useEffect(() => {
    console.log('changes', changes)
    changesSnapshot.current = changes;
    if (changes) {
      if (Object.values(changes).length > 0 && !changesAlertPublished.current) {
        prevTasksDeleted.current = false;
        // console.log("[D] Activated Unload Listener");
        window.addEventListener('beforeunload', warnAgainstWebsiteUnload)
        changesAlertPublished.current = true
      } else if (Object.values(changes).length === 0) {
        if (changesAlertPublished.current) {
          // console.log("[D] Deactivated Unload Listener");
          window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
        }
        changesAlertPublished.current = false
      }

      if (Object.values(changes).length === 0 && !prevTasksDeleted.current) {
        // console.log("[D] No changes detected, deleting pending tasks (if apply)");
        handleDeleteAllPendingTasks();
        prevTasksDeleted.current = true;
      }
    }

    return () => {
      window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))
    return () => {
      window.removeEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))

      handleWebsiteUnload(changesSnapshot.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    dispatch(fetchFullClients({
      clientID: clientId,
    }));
  });

  useEffect(() => {
    if (!loadedLocalStorageData.current) {
      if (employeesMap && Object.keys(employeesMap).length > 1) {
        if (entities && changes && hasPendingChanges) {

          if (Object.keys(entities).length > 0) {
            if (Object.keys(changes).length > 0 && grid.length > 1) {
              const preChanges: Change[] = []

              Object.keys(changes).forEach((key) => {
                const change = changes[key];

                Object.keys(change).forEach((k) => {
                  const cellValue = change[k];

                  if (k === 'id') {
                    return;
                  }

                  preChanges.push({
                    cell: {
                      employeeID: key,
                      id: key,
                      field: k,
                    },
                    row: key,
                    col: k,
                    value: cellValue
                  } as Change)

                });
              });

              loadedLocalStorageData.current = true;
              changesValidator(preChanges, false);
            }
          }
        }
      }

    }
  }, [employeesMap, changes, hasPendingChanges, changesValidator, grid, entities])

  useEffect(() => {
    const options: Option[] = Object.values(jobsMap).map((val) => ({
      value: val,
      label: val,
    }));
    updateColumnAtributes('job', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsMap]);

  useEffect(() => {
    const options: Option[] = Object.values(departmentsMap).filter((department) => (departmentsFromClient || []).includes(department.id)).map(
      ({ id, name }) => ({ value: id, label: name })
    );

    updateColumnAtributes('department', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsMap]);

  useEffect(() => {
    const options: Option[] = Object.values(societyBranchesMap).filter((societyBra) => (societiesFromClient || []).includes(societyBra.society)).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('payrollBranch', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyBranchesMap]);

  useEffect(() => {
    const options: Option[] = Object.values(workdaysMap).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('daysType', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workdaysMap]);

  useEffect(() => {
    const options: Option[] = Object.values(salaryTypesMap).map((val) => ({
      value: val,
      label: val,
    }));
    updateColumnAtributes('salaryType', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salaryTypesMap]);

  useEffect(() => {
    const options: Option[] = Object.values(pensionTypesMap).map((val) => ({
      value: val,
      label: val,
    }));
    updateColumnAtributes('pensionType', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pensionTypesMap]);

  useEffect(() => {
    const options: Option[] = Object.values(contractsMap).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('contractType', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsMap]);

  useEffect(() => {
    const options: Option[] = Object.values(civilStatusMap).map((val) => ({
      value: val,
      label: val,
    }));
    updateColumnAtributes('civilStatus', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [civilStatusMap]);

  useEffect(() => {
    const options: Option[] = Object.values(payrollsTypesMap).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('payrollType', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollsTypesMap]);

  useEffect(() => {
    const options: Option[] = Object.values(paymentMethodsMap).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('paymentMethod', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodsMap]);

  useEffect(() => {
    const options: Option[] = Object.values(worksMap).filter((work) => work.client === selectedClientId).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
    updateColumnAtributes('work', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksMap]);

  useEffect(() => {
    dispatch(fetchFullClients({
      clientID: clientId,
    }));

    dispatch(fetchDepartments({}));

    dispatch(fetchSocieties({}));

    dispatch(fetchPayrollsTypes({ client: clientId }));

    dispatch(fetchSocietyBranches({}));

    // dispatch(fetchStates({}));

    // dispatch(fetchMunicipalities({}));

    dispatch(fetchJobs());

    dispatch(fetchSalaryTypes());

    dispatch(fetchContracts());

    dispatch(fetchPensionTypes());

    dispatch(fetchVariableSalaries({}));

    dispatch(
      fetchWorkdays({
        clientID: clientId,
      })
    );

    dispatch(fetchCivilStatus());

    dispatch(
      fetchWorks({
        clientID: clientId,
      })
    );

    dispatch(fetchPeriodFrequencies({}));

    dispatch(fetchPaymentMethods({}));
  }, [clientId, dispatch]);

  return {
    isLoading: isLoading || isCalculating,
    // isSaving,
    // isFetching,
    hasData,
    hasPendingChanges,
    count,
    rows,
    changes,
    grid,
    handleChanges: handleMyEmployeesChanges,
    // handleSave,
    ValueRenderer,
    cleanDataSheetLocalStorage,
    parsePaste,
    selectedFromSheet,
    handleOnSelect,
    backToLastPage,
    dialogValues: {
      undoChangesDialogVisible,
      handleUndoChangesDialogClose: () => setundoChangesDialogVisible(false),
      handleUndoChangesDialogOpen: () => setundoChangesDialogVisible(true),
      handleConfirmUndoChanges,
      confirmChangesDialogVisible,
      handleConfirmChangesDialogOpen: () => {
        setflags({
          ...flags,
          loading: {
            ...flags.loading,
            saveProcess: false,
          },
          process: {
            ...flags.process,
            hasSaved: false,
          }
        })
        setconfirmChangesDialogVisible(true)
      },
      handleConfirmChangesDialogClose: () => {
        setconfirmChangesDialogVisible(false)
      },
      handleConfirmSaveChanges,
      exportEmployeesDialogVisible,
      handleexportEmployeesDialogClose: () => setexportEmployeesDialogVisible(false),
      handleexportEmployeesDialogOpen: () => setexportEmployeesDialogVisible(true),
    },
    valueStatistics: {
      dataSnapshots,
      dataErrors,
    },
    employees: employeesEntities,
    saveResultInstances,
    flags,
  };
};

export default useMyEmployeesDatasheet;
