/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
 SnackbarShowMessageOptions
} from '../../types';

export interface SnackbarMessage {
  open: boolean,
  vertical: 'top' | 'bottom',
  horizontal: 'left' | 'center' | 'right',
  message: string,
  type: 'success' | 'info' | 'warning' | 'error',
  autoHideDuration: number,
};

const initialState: SnackbarMessage = {
  open: false,
  vertical: 'top',
  horizontal: 'right',
  message: '',
  type: 'info',
  autoHideDuration: 6000,
};

export const snackbarMessageSlice = createSlice({
  name: 'snackbarMessage',
  initialState,
  reducers: {
    closeMessage: (state) => {
      state.open = false;
      state.message = '';
    },
    openMessage: (state) => {
      state.open = true;
    },
    showMessage: (state, action: PayloadAction<SnackbarShowMessageOptions>) => {
      const { payload } = action;
      const {
        vertical,
        horizontal,
        message,
        type,
        autoHideDuration,
      } = payload;
      if (vertical) state.vertical = vertical;
      if (horizontal) state.horizontal = horizontal;
      if (type) state.type = type;
      if (autoHideDuration) state.autoHideDuration = autoHideDuration;
      const newMessage = typeof message === 'string' ? message : message.join('\n');
      state.message = state.open ? `${state.message}\n${newMessage}` : newMessage;
      state.open = true;
    },
  },
});

export const { closeMessage, openMessage, showMessage } = snackbarMessageSlice.actions;

export default snackbarMessageSlice.reducer;
