export const AddPayrollType = `
mutation AddPayrollType(
  $client: ID!
  $key: String
  $periodCalculation: String
  $holidayBonus: Float
  $bonus: Int
  $frequency: ObjectID
  $payrollDeliveryDay: Boolean
  $taxSchema: String
  $holidays: ID
  $isHighDirection: Boolean
  $isInterbank: Boolean
  $customerDepositBankAccountID: ID
  $dispersingCompanyID: ID
  $dispersionBankAccountsIDs: [ID]
  $name: String!
  $dispersingCompanyNOM035: ID
  $dispersionBankAccountsNOM035: [ID]
  $noministaID: ID
  $adcID: ID
  $dispersadorID: ID
  $contractStartAt: DateTime!
  $billingCompanyID: ID
  $frequencyType: FrequencyType
  $cfdiConcept: ID
  $payMethod: ID
  $calculationSettings: CalculationSettingsInput
  $isSuperPayroll: Boolean
  $superPayroll: ID
  $superPayrollChilds: [ID!]
  $operationalContacts: OperationalContactsInput
) {
  paysheetTypeAdd(
    client: $client
    key: $key
    periodCalculation: $periodCalculation
    holidayBonus: $holidayBonus
    operationalContacts: $operationalContacts
    bonus: $bonus
    frequency: $frequency
    payrollDeliveryDay: $payrollDeliveryDay
    taxSchema: $taxSchema
    holidays: $holidays
    isHighDirection: $isHighDirection
    isInterbank: $isInterbank
    customerDepositBankAccountID: $customerDepositBankAccountID
    dispersingCompanyID: $dispersingCompanyID
    dispersionBankAccountsIDs: $dispersionBankAccountsIDs
    name: $name
    dispersingCompanyNOM035: $dispersingCompanyNOM035
    dispersionBankAccountsNOM035: $dispersionBankAccountsNOM035
    noministaID: $noministaID
    adcID: $adcID
    dispersadorID: $dispersadorID
    contractStartAt: $contractStartAt
    billingCompanyID: $billingCompanyID
    frequencyType: $frequencyType
    cfdiConcept: $cfdiConcept
    payMethod: $payMethod
    calculationSettings: $calculationSettings
    isSuperPayroll: $isSuperPayroll
    superPayroll: $superPayroll
    superPayrollChilds: $superPayrollChilds
  ) {
    id
    name
    isSuperPayroll
    superPayroll {
      id
      name
      key
    }
    operationalContacts {
      nomina {
        name
        id
        email
        address
        phoneNumber
      }
      prenomina {
        name
        id
        email
        address
        phoneNumber
      }
      facturas {
        name
        id
        email
        address
        phoneNumber
      }
      recibosNomina {
        name
        id
        email
        address
        phoneNumber
      }
    }
    bonus
    frequencyType
    holidayBonus
    payrollDeliveryDay
    key
    calculationSettings {
      imss {
        defaultPayer
      }
      isr {
        defaultPayer
      }
      iva {
        defaultPayer
      }
      costoSocial {
        defaultPayer
      }
      isn {
        defaultPayer
      }
      commission {
        defaultPayer
      }
    }
    contractStartAt
    contractEndAt
    holidays {
      id
      type
    }
    isHighDirection
    isInterbank
    periodCalculation
    client {
      id
      general {
        businessName
      }
    }
    frequency {
      id
      name
    }
    dispersionBankAccounts {
      id
      accountName
      CLABE
      accountNumber
    }
    dispersionBankAccountsNOM035 {
      id
      accountName
      CLABE
      accountNumber
    }
    taxSchema
    customerDepositBankAccount {
      id
      accountName
      CLABE
      accountNumber
    }
    dispersador {
      id
      name
      firstLastName
      secondLastName
    }
    adc {
      id
      name
      firstLastName
      secondLastName
    }
    dispersingCompany {
      id
      name
    }
    dispersingCompanyNOM035 {
      id
      name
    }
    billingCompany {
      id
      name
    }
    nominista {
      id
      name
      firstLastName
      secondLastName
    }
    cfdiConcept {
      id
      description
    }
    payMethod {
      id
      description
    }
  }
}
`;
