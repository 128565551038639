/* eslint-disable no-console */
import { useCallback } from 'react';

import { showMessage } from '../store/slices';
import { SnackbarShowMessageOptions } from '../types';
import { useAppDispatch } from './redux';

export default function useShowSnackbarMessage() {
  const dispatch = useAppDispatch();

  const handleShowMessage = useCallback(
    (options: SnackbarShowMessageOptions) => {
      dispatch(showMessage(options));
    },
    [dispatch]
  );

  const showError = useCallback(
    (error: any) => {
      let message = '';

      if (error instanceof Error) message = error.message;
      else if (typeof error === 'object' && error.message)
        message = error.message;
      else if (error instanceof Array) {
        const flatten = error.flat();
        flatten.forEach((e: any) => {
          if (e instanceof Error) message += `${e.message}\n`;
          else if (typeof e === 'object' && e.message)
            message += `${e.message}\n`;
          else message += `${String(e)}\n`;
        });
      } else message = String(error);

      handleShowMessage({
        message,
        type: 'error',
      });

      console.error(message);
    },
    [handleShowMessage]
  );

  const showSuccess = useCallback(
    (message: string) => {
      handleShowMessage({
        message,
        type: 'success',
      });
    },
    [handleShowMessage]
  );

  const showWarning = useCallback(
    (message: string) => {
      handleShowMessage({
        message,
        type: 'warning',
      });
    },
    [handleShowMessage]
  );

  return {
    showMessage: handleShowMessage,
    showSuccess,
    showError,
    showWarning,
  };
}
