export const EmployeesEntry = `
mutation EmployeesEntry(
  $payrollBranch: ObjectID
  $employees: [ObjectID]
  $date: DateTime
) {
  entry(
    payingCompanyBrach: $payrollBranch
    employees: $employees
    date: $date
  )
}`;

export const EmployeesEdit = `
mutation EmployeesEdit($id:ID!, $input:EmployeeInput!) {
  employeeEdit(id: $id, input: $input) {
    id
  }
}
`