import {
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { noop } from '../helpers';
import { LocalStorageParserOptions } from '../types';

const useStateLocalStorage = <T>(
  key: string,
  initialValue?: T,
  options?: LocalStorageParserOptions<T>
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {

  const deserializer: (value: string) => T | undefined = useMemo(() => {
    if (options) {
      if (options.raw) {
        return (value) => value;
      }
      return options.deserializer;
    }
    return JSON.parse;
  }, [options]);

  const serializer = useMemo(() => {
    if (options) {
      if (options.raw) {
        return String;
      }
      return options.serializer;
    }
    return JSON.stringify;
  }, [options]);

  const initializer = useRef((keyVal: string) => {
    try {
      const localStorageValue = localStorage.getItem(keyVal);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      }
      if (initialValue) {
        localStorage.setItem(keyVal, serializer(initialValue));
      }
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  const [state, setState] = useState<T | undefined>(() => initializer.current(key));

  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  const getStateValue = useCallback((nextState) => {
    if (options) {
      if (options.raw) {
        if (typeof nextState === 'string') {
          return nextState;
        }
        return JSON.stringify(nextState);
      }
      if (options.serializer) {
        return options.serializer(nextState);
      }
    }
    return JSON.stringify(nextState);
  }, [options]);

  const set: Dispatch<SetStateAction<T | undefined>> = useCallback(
    (valOrFunc) => {
      try {
        if (typeof valOrFunc === 'function') {
          setState((prevState) => {
            const newState = (valOrFunc as (prev?: T) => T)(prevState);
            if (typeof newState === 'undefined') return undefined;
            const value: string = getStateValue(newState);

            localStorage.setItem(key, value);
            return deserializer(value);
          });
          return;
        }
        const newState = valOrFunc;
        if (typeof newState === 'undefined') return;
        const value: string = getStateValue(newState);

        localStorage.setItem(key, value);
        setState(deserializer(value));
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [deserializer, getStateValue, key]
  );

  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key]);

  if (typeof window === 'undefined') return [initialValue, noop, noop];

  if (!key) throw new Error('useStateLocalStorage key may not be falsy');

  return [state, set, remove];
};

export default useStateLocalStorage;
