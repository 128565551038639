import { FC } from 'react';

import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from '@mui/material';

interface Props {
  hasIcon?: boolean,
};

const ListItemSkeleton: FC<Props> = ({ hasIcon }) : JSX.Element => (
  <ListItem style={{ padding: 0 }}>
    <ListItemButton style={{ padding: '6px 12px' }}>
      {hasIcon && (
        <ListItemAvatar>
          <Avatar>
            <Skeleton height='100%' variant='circular' width='100%' />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={<Skeleton variant='text' />}
        secondary={<Skeleton variant='text' />}
        />
    </ListItemButton>
  </ListItem>
);

ListItemSkeleton.defaultProps = {
  hasIcon: true,
};

export default ListItemSkeleton;
