import { useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LoadingButton from '../../components/LoadingButton';
import Base from './Base';
import { StepProps } from './types';

export interface ConfirmationProps extends StepProps {
  confirming?: boolean;
  selectedEmployees: any[];
  onChangeSelectedEmployees: (employee: any[]) => void;
  confirmDisabled?: boolean;
}

export default function Confirmation({
  onFinish,
  onCancel,
  title,
  confirming,
  selectedEmployees,
  onChangeSelectedEmployees,
  confirmDisabled
}: ConfirmationProps) {
  const { spacing } = useTheme();

  const actions = [
    <LoadingButton
      color="error"
      fullWidth
      onClick={() => onCancel()}
      size="large"
      variant="contained"
    >
      Cancelar
    </LoadingButton>,
    <LoadingButton
      circularProgressSize={28}
      disabled={confirmDisabled}
      fullWidth
      loading={confirming}
      onClick={() => onFinish('confirmation')}
      size="large"
      variant="contained"
    >
      Confirmar
    </LoadingButton>,
  ];

  return (
    <Base actions={actions} title={title}>
      <List disablePadding>
        {selectedEmployees.map((employee) => {
          // eslint-disable-next-line prefer-destructuring
          let name = employee.name;
          if (employee.fullName) name = employee.fullName;

          const checked = employee.selected || employee.choosed;

          return (
            <ListItem disablePadding key={employee.id}>
              <ListItemButton
                onClick={() => {
                  const newSelectedEmployees = selectedEmployees.map(
                    (selectedEmployee) => {
                      if (selectedEmployee.id === employee.id) {
                        return {
                          ...selectedEmployee,
                          selected: !checked,
                          choosed: !checked,
                        };
                      }

                      return selectedEmployee;
                    }
                  );

                  onChangeSelectedEmployees(newSelectedEmployees);
                }}
                style={{
                  paddingLeft: spacing(3),
                  paddingRight: spacing(3),
                  height: 32,
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked}
                    disableRipple
                    edge="start"
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText primary={`${name} (Clave: ${employee.key})`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Base>
  );
}

Confirmation.defaultProps = {
  confirming: false,
  confirmDisabled: false,
}