/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import { Holiday, HolidayFilters } from '../../types';

export interface HolidaysState {
  count: number;
  entities: Record<string, Holiday>;
  isLoading: boolean;
};

const initialState: HolidaysState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchHolidays = createAsyncThunk(
  'getHolidays/fetchHolidays',
  async (search?: HolidayFilters) => {
    const params = getGraphQLParameters('GetHolidays', { search });
    const { data: response } = await getPostRequest<{ holidays: Holiday[] }>('', params);
    const { data } = response;
    return data.holidays;
  }
);

export const holidaysSlice = createSlice({
  name: 'holidaysSlice',
  initialState,
  reducers: {
    cleanHolidays: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHolidays.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        state.count = payload.length;
        state.entities = _.keyBy(payload, 'id');
      })
      .addCase(fetchHolidays.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanHolidays } = holidaysSlice.actions;

export default holidaysSlice.reducer;
