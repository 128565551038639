export const GetHolidays = `
query GetHolidays($search: HolidaySearch) {
  holidays(search: $search) {
    client {
      id
    }
    createdAt
    id
    type
    periods {
      days
      endYear
      startYear
    }
  }
}
`;
