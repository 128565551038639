import axios from 'axios';

import {
  EmployeesServiceErrorCodes as EC,
} from '../constants';
import { EmployeesServiceError } from './EmployeesServiceError';

export default function constructError(e: any) : EmployeesServiceError {
  if (e instanceof EmployeesServiceError)
    return e;

  if (axios.isCancel(e))
    return new EmployeesServiceError(
      EC.Cancelled,
    );

  const message = typeof e === 'string' ? e : e.message;

  return new EmployeesServiceError(
    EC.Unknown, {
      message,
    }
  );
}
