import { BankAccount } from './bankAccount';
import { FullClient } from './client';
import { EmployeeType } from './employeeType';
import { PayrollType } from './payrollType';
import { PeriodFrequency } from './periodFrequency';
import { Pagination } from './request';
import { VariableSalary } from './variableSalary';
import { Work } from './work';

export interface Employee {
  active: boolean;
  customRole: string;
  deletedAt: null;
  infonavit_inf: InfonavitInf;
  isInTransferProcess: boolean;
  isInfonavitCreditActive: boolean;
  infonavitMovementsHistory: null;
  pin: string;
  supervisor: string;
  password: string;
  payrollType: string;
  paymentMethod: string;
  hasBeneficiaries: boolean;
  beneficiaries: null;
  employerRegistrations: null;
  haveOtherInsurance: boolean;
  id: string;
  LevitaOpsID: string;
  HasBankAccount: boolean;
  bankAccounts: string[] | null;
  bankAccount: BankAccount | null | undefined;
  client: string;
  company: string;
  department: string;
  job: string;
  payrollBranch: string;
  createdAt: Date;
  updatedAt: Date;
  address: string;
  bornDate: Date;
  civilStatus: string;
  contact: Contact;
  contractEnd: Date;
  contractRisk: number;
  contractStart: Date;
  contractType: string;
  contractTypes: ContractTypeElement[] | null;
  curp: string;
  dateOfFirstEntry: Date;
  daysType: string;
  economicZone: boolean;
  email: string;
  firstLastName: string;
  gender: Gender;
  hasChildren: boolean;
  imss: string;
  incorporation: Date;
  key: number;
  name: string;
  nationality: string;
  plantDate: Date;
  rfc: string;
  secondLastName: string;
  sonsNumber: number;
  telephone: string;
  tenureDate: Date;
  type: EmployeeType;
  integratedDailyWage: number;
  dailyWage: number;
  infonavitCreditNumber: string;
  discountStartDate: Date;
  InfonavitDiscountType: number;
  discountType: string;
  discountValue: number;
  status: Status;
  netSalary: number;
  minimumWage: number;
  customKey: string;
  workerType: string;
  pensionType: PensionType;
  salaryType: SalaryType;
  completeFile: boolean;
  completeFileDate: Date;
  completeInformationDate: Date;
  reentryDate: null;
  withdrawalDate: Date;
  stamp: boolean;
  immsRegistration: boolean;
  infonacot: boolean;
  infonavit: boolean;
  pretmex: boolean;
  waitingReceptionHiring: boolean;
  waitConfirmationHiring: boolean;
  errorConfirmationHiring: boolean;
  waitingReceptionReentry: boolean;
  waitingConfirmationReentry: boolean;
  errorConfirmationReentry: boolean;
  waitingReceptionDismiss: boolean;
  waitingConfirmationDismiss: boolean;
  errorConfirmationDismiss: boolean;
  waitingReceptionSalary: boolean;
  waitingConfirmationSalary: boolean;
  errorConfirmationSalary: boolean;
  imssErrorMessage: string;
  dismissCause: string;
  dismissType: string;
  dismissDate: Date;
  businessName: string;
  imssWorkerType: number;
  imssSalaryType: number;
  imssPensionType: number;
  imssWorkdayType: number;
  integrationFactor: number;
  statusIMSS: StatusIMSS;
  gratification: number;
  imssDocuments: ImssDocuments;
  work: string;
  fonacotKey: string;
  isUnionized: boolean;
  incidentControl: IncidentControl;
  isAltaDireccion: boolean;
  hasClosedPayroll: boolean;
  variableSalaries: string;
  clientBranch: string;
  Likes: null;
  Dislikes: null;
  personalInformation: Information;
  jobInformation: Information;
  childSupportAlimony: ChildSupportAlimony;

  variableSalariesData?: VariableSalary;
  payrollTypeData?: PayrollType;
  payrollFrequencyData?: PeriodFrequency;
  workData?: Work;
  clientData?: FullClient;
  worksByClientAndBranch?: Work[];
  actualContractTypeId?: string;
  [key: string]: any;
}

export interface ChildSupportAlimony {
  hasChildSupport: boolean;
  childSupportDependant: string;
  parentEmployee: string;
}

interface Contact {
  name: string;
  relationship: string;
  email: string;
  cellPhone: string;
}

export interface ContractTypeElement {
  contractType: string;
  expiresOn: number;
  isActive: boolean;
  contractID?: string;
}

export enum Gender {
  Empty = '',
  Hombre = 'Hombre',
  Mujer = 'Mujer',
}

export interface ImssDocuments {
  ingresosIDSE: null;
  ingresosSUA: null;
  reingresosIDSE: string[] | null;
  reingresosSUA: string[] | null;
  bajasIDSE: null;
  bajasSUA: null;
  modificacionesSueldoIDSE: null;
  modificacionesSueldoSUA: null;
}

export interface IncidentControl {
  Incidents: null;
  hasActiveIncidentGroup: boolean;
  hasFinished: boolean;
}

export interface InfonavitInf {
  DiscountRetentionDoc: string;
}

export interface Information {
  address: Address;
}

export interface Address {
  street: string;
  number: string;
  interiorNumber: string;
  colony: string;
  municipality: string;
  municipalityName: string;
  state: string;
  stateName: string;
  country: string;
  zipCode: string;
}

enum PensionType {
  Empty = '',
  PensiónDeCesantíaOVejez = 'Pensión de cesantía o vejez',
  SinPensión = 'Sin pensión',
}

enum SalaryType {
  Empty = '',
  Fijo = 'Fijo',
  Mixto = 'Mixto',
}

enum Status {
  Baja = 'Baja',
  Borrador = 'Borrador',
  Empleado = 'Empleado',
}

interface StatusIMSS {
  inRegistrationProcess?: boolean;
  inDismissalProcess?: boolean;
  inReentryProcess?: boolean;
  inSalaryModificationProcess?: boolean;
}

export type EmployeeFilters = {
  client: string;
  query?: string;
  status?: string;
  isSecured?: string;
  canItBeDispersed?: string;
  isFileComplete?: string;
  payrollTypeID?: string;
  pagination?: Pagination;
};
