/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PayrollType } from '../../types';
import { ReentryEmployeesState } from '../types';

const initialState: ReentryEmployeesState = {
  selectedEmployees: [],
};

export const reentryEmployeesSlice = createSlice({
  name: 'reentryEmployees',
  initialState,
  reducers: {
    setPayrollType: (state, action: PayloadAction<PayrollType | undefined>) => {
      state.payrollType = action.payload;
    },
    setSelectedEmployees: (state, action: PayloadAction<any[]>) => {
      state.selectedEmployees = action.payload;
    },
  },
});

export const { setPayrollType, setSelectedEmployees } =
  reentryEmployeesSlice.actions;

export default reentryEmployeesSlice.reducer;
