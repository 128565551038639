import { useMemo } from 'react';

import { Cell, Header, ObjectMap } from '../../types';

const hasValue = (cell: Cell): boolean => {
  if (!cell) return false;
  const { value, type } = cell;
  if (type === 'money' && value === 0) {
    return false;
  }
  if ((type === 'text' || type === 'email') && value === '') return false;
  const Xd = cell.value !== '';
  return Xd;
};

const getColsMap = (columns: Header[]):  ObjectMap => {
  const colsMap: ObjectMap = {};
  columns.forEach((column, index) => {
    const { field } = column;
    colsMap[field] = index;
    colsMap[index] = field;
  });
  return colsMap;
};

const useDataSheetHiddenColumns = (headers: Header[], grid: Cell[][]) => {
  const result = useMemo(() => {
    const colIndexesToHide: number[] = headers
      // .flatMap(({ hidden, field }) => {
      //   if (!hidden) return [];
      //   if ()
      // })
      .filter(({ hidden, colIndex, forceHidden }) => forceHidden || (hidden && !grid.some((row) => hasValue(row[colIndex]))))
      .map(({ colIndex }) => colIndex);

    const nextHeaders = headers.filter(({ colIndex }) => !colIndexesToHide.includes(colIndex));
    const nextGrid = grid.map(row => {
      const nextRow = [...row];
      let removedColumnsCount = 0;
      colIndexesToHide.forEach((colIndex) => {
        nextRow.splice(colIndex + removedColumnsCount, 1);
        removedColumnsCount -= 1;
      });
      return nextRow;
    });

    const hiddenGrid = [nextHeaders, ...nextGrid];
    const hiddenColsMap = getColsMap(nextHeaders);

    return { hiddenGrid, hiddenColsMap };
  }, [headers, grid]);

  return result;
}

// const useDataSheetHiddenColumns2 = (headers: Header[], grid: Cell[][], colsMap: ObjectMap): Cell[][] => {
//   const [hiddenColumns, setHiddenColumns] = useState<Header[]>(headers);
//   const [hiddenGrid, setHiddenGrid] = useState<Cell[][]>(grid);

//   useDeepCompareEffect(() => {
//     const nextHiddenColumns = headers.filter(({ hidden }) => hidden);
//     setHiddenColumns(nextHiddenColumns);
//   }, [headers]);

//   useDeepCompareEffect(() => {
//     console.clear();
//     // console.log('useDataSheetHiddenColumns useDeepCompareEffect headers', headers);
//     const colIndexesToHide: number[] = headers
//       .filter(({ hidden, field }) => {
//         const colIndex = colsMap[field];
//         return hidden && !grid.some((row) => hasValue(row[colIndex]));
//       })
//       .map(({ field }) => colsMap[field]);
//     // console.log('useDataSheetHiddenColumns useDeepCompareEffect colIndexesToHide', colIndexesToHide);
//     const nextGrid = grid.map(row => {
//       const nextRow = [...row];
//       colIndexesToHide.forEach((colIndex) => {
//         nextRow.splice(colIndex, 1);
//       });
//       return nextRow;
//     });
//     // console.log('useDataSheetHiddenColumns useDeepCompareEffect nextGrid', nextGrid);
//     setHiddenGrid(nextGrid);
//   }, [headers, grid]);

//   return hiddenGrid;
// };

export default useDataSheetHiddenColumns;
