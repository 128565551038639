import { Column } from '../../../types';

const columns: Column[] = [
  {
    title: 'Concepto',
    field: 'title',
    defaultValue: 'Valor',
    isEnabled: false,
  },
  {
    title: 'Empresa',
    field: 'company',
    pathValue: 'society.name',
  },
  {
    title: 'Cliente',
    field: 'client',
    pathValue: 'client.general.businessName',
  },
  {
    title: 'Concepto',
    field: 'concept',
    pathValue: 'concept',
    defaultValue: 'Servicios legales respecto de la aplicación e implementación de mecanismos para el cumplimiento de la NOM-035-STP',
  },
  {
    title: 'Subtotal',
    field: 'subtotal',
    pathValue: ['pppsSummary.subtotal', 'extraordinaryPayrollSummary.subtotal'],
    type: 'money',
  },
  {
    title: 'IVA',
    field: 'iva',
    pathValue: ['pppsSummary.iva', 'extraordinaryPayrollSummary.iva'],
    type: 'money',
  },
  // {
  //   title: '(-) IVA Retenido 6%',
  //   field: 'totalPerceptions',
  //   // pathValue: '',
  //   type: 'money',
  // },
  {
    title: 'Total a Facturar',
    field: 'totalInvoice',
    pathValue: ['pppsSummary.totalInvoice', 'extraordinaryPayrollSummary.totalInvoice'],
    type: 'money',
  },
  {
    title: 'Comision',
    field: 'totalCommission',
    pathValue: ['pppsSummary.totalCommission', 'extraordinaryPayrollSummary.totalCommission'],
    type: 'money',
  },
  {
    title: 'Nombre de la nómina',
    field: 'payrollName',
    pathValue: 'payrollPeriod.payrollType.name',
  },
  {
    title: 'Periodo',
    field: 'period',
    pathValue: 'payrollPeriod.payday',
    type: 'date',
  },
  {
    title: 'Clave del periodo',
    field: 'periodKey',
    pathValue: 'payrollPeriod.fullName',
  },
  // {
  //   title: 'Nominista',
  //   field: 'nominist',
  //   pathValue: [
  //     'payrollPeriod.payrollType.nominista.name',
  //     'payrollPeriod.payrollType.nominista.firstLastName',
  //     'payrollPeriod.payrollType.nominista.secondLastName',
  //   ],
  //   separator: ' ',
  // },
  {
    title: 'Fecha dispersión',
    field: 'dispersionDate',
    pathValue: 'payrollPeriod.payday',
    type: 'date',
  },
];

export default columns;
