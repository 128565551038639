import { Suspense } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import Loader from '../pages/Loader';
import NotFound from '../pages/NotFound';
import routesList from './routesList';

const Routes = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <ReactRoutes>
      {Object.values(routesList).map(({ path, element, children }) => (
        <Route element={element} key={uuid()} path={path} >
          {children && children.map(({ path: childPath, element: childElement }) => (
            <Route element={childElement} key={uuid()} path={childPath} />
          ))}
        </Route>
      ))}
      <Route element={<NotFound path="*" />} path='*' />
    </ReactRoutes>
  </Suspense>
);

export default Routes;