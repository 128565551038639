/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import {
  useNavigate
} from 'react-router-dom'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Button,
  Tooltip,
} from '@mui/material';
import moment from 'moment'

import { getGetRequest, getGraphQLParameters, getPostRequest } from '../helpers';
import myGet from '../helpers/myGet'
import { useAppDispatch } from '../hooks';
import downloadZippedFiles from '../jsutils/downloadZippedFiles';
import { showMessage } from '../store/slices';

interface Props {
  counter: number,
  frequency: string,
  elements: Array<any>,
  highlightColor: string,
}

const ExpandableComponent: FC<Props> = (props) : JSX.Element => {
  const navigate = useNavigate();

  const {
    elements,
    frequency,
    counter,
    highlightColor,
  } = props;

  const dispatch = useAppDispatch();

  const getDocsInfo = async (arrayOfDocs: Array<any>) => getPostRequest('v2/document/all', { documentIDs: arrayOfDocs });

  const handleDownloadVouchers = async (e: any, arrayOfDocs: any, folderName: string) => {
    e.stopPropagation();
    try {
      const { data } = await getDocsInfo(arrayOfDocs);
      const response = await downloadZippedFiles(data.data, folderName);
      if (response === 'Success') {
        dispatch(showMessage({
          message: 'El archivo se bajo correctamente',
          autoHideDuration: 4000,
          type: 'success'
        }))
      }
    } catch (err) {
      dispatch(showMessage({
        message: 'Ha ocurrido un problema',
        autoHideDuration: 4000,
        type: 'error'
      }))
    }
  }

  const handleDownloadSummary = async (e: any, id: string) => {
    e.stopPropagation();

    const response = await fetch(`${localStorage.getItem('api_base_domain')}/reportes/${id}`, {
      headers: {
        Authorization: `${localStorage.getItem('access_token')}`,
      }
    })

    const myFile = await response.blob();
    const file = window.URL.createObjectURL(myFile);
    window.open(file, '_blank');
  }

  const handleStampedPayrollReceipt = async (e: any, payrollPeriod: string, sendZIPTo: Array<string>) => {
    e.stopPropagation();
    const params = getGraphQLParameters('payrollReceiptsZIPByPayroll', {payrollPeriod, sendZIPTo})
    const { data: queryData } = await getPostRequest('', params);
    const { data } = queryData;
    const { payrollReceiptsZIPByPayroll } = data;
    window.open(payrollReceiptsZIPByPayroll.downloadURL, '_top');
  }

  const handleInvoiceStatusDownload = async (e: any, id: string) => {
    e.stopPropagation();
    const response = await getGetRequest(`download/billing/${id}`);
    const { data } = response;
    console.log(response, 'response');
    if (data && myGet(data, 'request.responseURL', '')) {
      window.open(myGet(data, 'request.responseURL', ''), '_blank');
      showMessage({
        message: 'El archivo de Factura se ha bajado correctamente',
        autoHideDuration: 4000,
        type: 'success'
      })
    } else {
      showMessage({
        message: 'Ha habido un error',
        autoHideDuration: 4000,
        type: 'error'
      })
    }
  }

  const downloadButtonStyles = {
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    color: '#1976D2',
    marginRight: 5,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer'
  }

  return (
    <>
      <div>
        {elements
        && elements
        .slice(0,counter)
        .map((val: any) => {
          const payrollStatus = myGet(val, 'payrollStatus', '');
          return (
            <div
              key={val.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 10,
                boxSizing: 'border-box',
              }}
            >
              <div
                style={{
                  width: '75%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '5px 20px',
                  boxSizing: 'border-box',
                  background: highlightColor,
                }}
              >
                {`
                  ${val.type === 'Extraordinario' ? 'EXT' : ''}
                  ${frequency.slice(0,3).toUpperCase()}
                  ${val.administrativePeriod}
                  ${val.type === 'Extraordinario' ? `(${moment(val.payday).format('YYYY-MM-DD')})` : (`(${moment(val.start).format('YYYY-MM-DD')} a ${moment(val.end).format('YYYY-MM-DD')})`)}
                `}
                <div style={{ display: 'flex' }}>
                  {myGet(val, 'payrollApproveTracker.vouchers', []).length > 0 && (
                    <Tooltip title="Descargar comprobante">
                      <div
                        aria-hidden
                        onClick={(e) => handleDownloadVouchers(e, myGet(val, 'payrollApproveTracker.vouchers', []), 'comprobante')}
                        style={{
                          ...downloadButtonStyles,
                          backgroundImage: 'url(/static/money.png)'
                        }}
                      />
                    </Tooltip>
                  )}
                  {payrollStatus
                    && payrollStatus !== 'Pendiente'
                    && payrollStatus !== 'Prenómina'
                    && payrollStatus !== 'Trabajada'
                    && (
                      <Tooltip title="Descargar Resumen">
                        <div
                          aria-hidden
                          onClick={(e) => handleDownloadSummary(e, myGet(val, 'id', ''))}
                          style={{
                            ...downloadButtonStyles,
                            backgroundImage: 'url(/static/excel.png)'
                          }}
                        />
                      </Tooltip>
                    )}
                  {
                    myGet(val, 'employeeStampStatus', '') === 'Timbrada' && (
                      <Tooltip title="Descargar recibo de nomina timbrada">
                        <div
                          aria-hidden
                          onClick={(e) => handleStampedPayrollReceipt(e, myGet(val, 'id', ''), ['test@levita.app'])}
                          style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 5
                          }}
                        >
                          <CloudDownloadIcon style={{ cursor: 'pointer' }} />
                        </div>
                      </Tooltip>
                    )
                  }
                  {
                    myGet(val, 'documents.facturas', []).length > 0 && (
                      <Tooltip title="Descargar factura">
                        <div
                          aria-hidden
                          onClick={(e) => handleInvoiceStatusDownload(e, myGet(val, 'id', ''))}
                          style={{
                            ...downloadButtonStyles,
                            backgroundImage: 'url(/static/invoice.png)'
                          }}
                        />
                      </Tooltip>
                    )
                  }
                </div>
              </div>
              <div style={{ width: '20%' }}>
                <Button
                  color={val.prePayrollTracker.completedAt ? 'info' : 'success'}
                  onClick={() => navigate(`/period/${val.id}`)}
                  size='small'
                  style={{ width: '100%' }}
                  variant="contained"
                >
                  {val.prePayrollTracker.completedAt ? 'Ver' : 'Calcular'}
                </Button>
              </div>
            </div>
          )}
        )}
      </div>
    </>
  )
}

export default ExpandableComponent;