/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { PaymentMethod, PaymentMethodFilter } from '../../types';

export interface PaymentMethodsState {
  count: number;
  entities: Record<string, PaymentMethod>;
  isLoading: boolean;
}

const initialState: PaymentMethodsState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchPaymentMethods = createAsyncThunk(
  'getPaymentMethods/fetchPaymentMethods',
  async (filters: PaymentMethodFilter) => {
    const { data: dataResponse, status } = await getPostRequest(
      'v2/paymentmethod/all',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    cleanPaymentMethods: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPaymentMethods.fulfilled,
        (state, action: PayloadAction<PaymentMethod[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
        }
      )
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanPaymentMethods } = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
