/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getPostRequest } from '../../helpers';
import { Kardex, KardexFilter } from '../../types';

export interface KardexState {
  entity: Kardex | null;
  isLoading: boolean;
};

const initialState: KardexState = {
  entity: null,
  isLoading: false,
};

export const fetchKardex = createAsyncThunk(
  'getKardex/fetchKardex',
  async (filter: KardexFilter) => {
    const response = await getPostRequest<Kardex>('v2/kardex/one', filter);
    return response.data.data;
  },
);

export const kardexSlice = createSlice({
  name: 'kardex',
  initialState,
  reducers: {
    cleanKardex: (state) => {
      state.entity = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKardex.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchKardex.fulfilled, (state, action) => {
        state.isLoading = false;
        const { payload } = action;
        if (payload) {
          state.entity = payload;
        }
      })
      .addCase(fetchKardex.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanKardex } = kardexSlice.actions;

export default kardexSlice.reducer;
