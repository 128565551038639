import {
  FC,
  lazy,
  MutableRefObject,
  Suspense,
  useRef,
} from 'react';
import ReactDataSheet from 'react-datasheet';

import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import BankAccountConfirmationButton from 'components/BankAccountConfirmationButton'
import EmployeeIdseConfirmationButton from 'components/EmployeeIdseConfirmationButton';
import {
  Cell,
  DataSheetButtonComponent,
  UploadFile,
  Value,
} from 'types';

import FileViewer from './FileViewer';

const nonRef = { current: {} };

type ValueViewerProps<T = Cell, V = Value> = ReactDataSheet.ValueViewerProps<T, V> & {
  value: Value,
};

const DefaultValueViewer: FC<ValueViewerProps<Cell, Value>> = ({
  cell,
  row,
  value,
}) : JSX.Element => {
  const buttonComponentRef: MutableRefObject<React.LazyExoticComponent<DataSheetButtonComponent> | null> = useRef(null);
  const { type, readOnly, rowIndex } = cell;

  if (row === 0 || rowIndex === -1) {
    return <strong>{value}</strong>;
  }
  if (type === 'date' && value !== '') {
    return <span>{value}</span>;
  }
  if (type === 'money') {
    return <span>{value}</span>;
  }
  if (type === 'select') {
    return <span>{value}</span>;
  }
  if (type === 'boolean') {
    return <span>{value}</span>;
  }
  if (type === 'check') {
    return (
      <Checkbox
        checked={value as unknown as boolean}
        disabled={readOnly}
        inputProps={{
          'aria-label': 'Checkbox demo',
        }}
      />
    );
  }
  if (type === 'file') {
    const file = typeof cell.value === 'string' ? null : cell.value as UploadFile;
    return (<FileViewer cell={cell} file={file} />);
  }
  if (type === 'button') {
    const { buttonComponentPath, entitiesMapRef, metadata, handleChangesRef, updateCellAtributesRef } = cell;
    if (buttonComponentPath === 'components/BankAccountConfirmationButton') {
      return (
        <BankAccountConfirmationButton
          entitiesMapRef={entitiesMapRef || nonRef}
          entityID={cell.id}
          handleChangesRef={handleChangesRef}
          metadata={metadata}
          updateCellAtributesRef={updateCellAtributesRef}
        />
      )
    }
    if (buttonComponentPath === 'components/EmployeeIdseConfirmationButton') {
      return (
        <EmployeeIdseConfirmationButton
          entitiesMapRef={entitiesMapRef || nonRef}
          entityID={cell.id}
          handleChangesRef={handleChangesRef}
          metadata={metadata}
          updateCellAtributesRef={updateCellAtributesRef}
        />
      );
    }
    if (buttonComponentRef.current === null && buttonComponentPath === 'components/BankAccountConfirmationButton') {
      buttonComponentRef.current = lazy<DataSheetButtonComponent>(() => import('../BankAccountConfirmationButton'));
    }
    if (buttonComponentRef.current) {
      const MyButtonComponent = buttonComponentRef.current;
      return (
        <div>
          <Suspense fallback={(
            <LoadingButton loading variant="outlined">
              {value}
            </LoadingButton>
          )}>
            <MyButtonComponent
              entitiesMapRef={entitiesMapRef || nonRef}
              entityID={cell.id}
              handleChangesRef={handleChangesRef}
              metadata={metadata}
              updateCellAtributesRef={updateCellAtributesRef}
            />
          </Suspense>
        </div>
      );
    }
    return (<Button onClick={cell.onClick} variant="outlined">{value}</Button>);
  }
  return <span>{value}</span>;
};

export default DefaultValueViewer;
