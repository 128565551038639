/* eslint-disable no-case-declarations */
import moment from 'moment';

import { useAppDispatch } from '../..';
import { CURPpattern, emailPatternV2, myEmployeesTableErrors, myGet, noSpecialCharacters, NSSPattern, RFCpattern, validateNSS } from '../../../helpers';
import { showMessage } from '../../../store/slices';
import {
  Cell,
  Change, Employee,
} from '../../../types';
import { CellUpdate } from '../../../types/datasheet';

type UseMyEmployeesChangesHandler = {
  changesValidator: (change: Change[], performEmployeeUpdate: boolean) => Change[];
};

export interface ChangesHandlerProps {
  employees: Record<string, Employee>;
  dataSnapshots: { [key: string]: any };
  dataErrors: { [key: string]: any };
  handleDataSnapshotChange: (change: { [key: string]: any }) => void;
  handleDataErrorsChange: (change: { [key: string]: any }) => void;
  handleEmployeesChange: (value: React.SetStateAction<Record<string, Employee>>) => void;
  getCell: (row: string | number, col: string | number) => Cell;
  updateCellParams: (ros: string | number, col: string | number, newAttributes: Record<string, unknown>) => void
}

const useMyEmployeesChangesHandler = (props: ChangesHandlerProps): UseMyEmployeesChangesHandler => {
  /** Globals */
  const {
    employees,
    dataSnapshots,
    dataErrors,
    handleDataSnapshotChange,
    handleDataErrorsChange,
    handleEmployeesChange,
    getCell,
  } = props
  const dispatch = useAppDispatch();
  // const errorKeys = {
  //   name: {
  //     invalidCharacter: 'v2nameInvalidCharacter',
  //   },
  //   firstLastName: {
  //     invalidCharacter: 'v2firstLastNameInvalidCharacter',
  //   },
  //   secondLastName: {
  //     invalidCharacter: 'v2secondLastNameInvalidCharacter',
  //   },
  //   rfc: {
  //     regexStructure: 'v2rfcInvalidRegexStructure',
  //   },
  //   curp: {
  //     regexStructure: 'v2curpInvalidRegexStructure',
  //   },
  //   nss: {
  //     regexStructure: 'v2nssInvalidRegexStructure',
  //     verificationDigit: 'v2nssInvalidVerificationDigit',
  //   },
  //   citizen: {
  //     rfcCurpNssYearDate: 'v2citizenInvalidRfcCurpNssYear',
  //     rfcCurpBirthDate: 'v2citizenInvalidRfcCurpBirthDate',
  //   },
  //   tenureDate: {
  //     invalidDate: 'v2tenureDateInvalidDate',
  //   },
  //   incorporation: {
  //     invalidDate: 'v2incorporationInvalidDate',
  //   },
  //   dateOfFirstEntry: {
  //     invalidDate: 'v2dateOfFirstEntryInvalidDate',
  //   },
  //   email: {
  //     invalidEmail: 'v2emailInvalidEmail',
  //   },

  // };

  /** States and Selsectors */

  /** Functions */
  const rowValidationsOnCellChange = (changes: Change[], performEmployeeUpdate: boolean): Change[] => {
    const validatedChanges: Change[] = [];

    // New States
    let newChanges = {
      ...employees,
    }

    let newErrors = {
      ...dataErrors,
    }

    let newSnapshots = {
      ...dataSnapshots,
    }

    const performRegisterEditError = (error: string): boolean => {
      dispatch(showMessage({
        message: error,
        autoHideDuration: 6000,
        type: 'error',
      }));

      return false
    };

    changes.forEach((change) => {
      const { cell, col, value, row } = change;
      let pCell = cell;
      if (!pCell) {
        pCell = getCell(cell?.id || row, col);
      }

      const newCell: CellUpdate = {
        ...pCell,
      }

      const newChangeCell: Change = {
        ...change,
        cell: newCell,
      }

      validatedChanges.push(newChangeCell);

      // Globals
      const { field: headerKey } = pCell
      if (!headerKey) {
        return;
      }

      const { employeeID } = pCell;
      const currentEmployee = employees[employeeID];
      const oldFieldValue = myGet(currentEmployee, headerKey, '');
      let curEmployeeErrors = [...(myGet(dataErrors, `${employeeID}`, []))];
      let validUpdate = true;

      // // console.log(`K: ${headerKey} :: Old Value: ${oldFieldValue} -> New Value: ${value}`);

      // Error Validations
      switch (headerKey) {
        case 'name':
          if (!noSpecialCharacters.test(value as string)) {
            performRegisterEditError('El nombre no puede contener números ni caracteres especiales');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.name.invalidCharacter)) {
              curEmployeeErrors.push(myEmployeesTableErrors.name.invalidCharacter);
            }
          } else {
            newCell.className = 'edited';

            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.name.invalidCharacter);
          }
          break;
        case 'firstLastName':
          if (!noSpecialCharacters.test(value as string)) {
            performRegisterEditError('El/Los primer(os) apellido(s) no puede contener números ni caracteres especiales');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.firstLastName.invalidCharacter)) {
              curEmployeeErrors.push(myEmployeesTableErrors.firstLastName.invalidCharacter);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.firstLastName.invalidCharacter);
          }
          break;
        case 'secondLastName':
          if (!noSpecialCharacters.test(value as string)) {
            performRegisterEditError('El/Los segundo(s) apellido(s) no puede contener números ni caracteres especiales');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.secondLastName.invalidCharacter)) {
              curEmployeeErrors.push(myEmployeesTableErrors.secondLastName.invalidCharacter);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.secondLastName.invalidCharacter);
          }
          break;
        case 'rfc':
          if (!RFCpattern.test(value as string)) {
            validUpdate = performRegisterEditError('El RFC no tiene un formato válido');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.rfc.regexStructure)) {
              curEmployeeErrors.push(myEmployeesTableErrors.rfc.regexStructure);
            }
          } else {
            validUpdate = true
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.rfc.regexStructure);
          }

          break;
        case 'curp':
          if (!CURPpattern.test(value as string)) {
            validUpdate = performRegisterEditError('El CURP no tiene un formato válido');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.curp.regexStructure)) {
              curEmployeeErrors.push(myEmployeesTableErrors.curp.regexStructure);
            }
          } else {
            validUpdate = true
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.curp.regexStructure);
          }

          break;
        case 'imss':
          if (!NSSPattern.test(value as string)) {
            validUpdate = performRegisterEditError('El NSS no tiene un formato válido');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.nss.regexStructure)) {
              curEmployeeErrors.push(myEmployeesTableErrors.nss.regexStructure);
            }
          } else if (!validateNSS(value as string)) {
            validUpdate = performRegisterEditError('El ultimo digito verificador es incorrecto');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.nss.verificationDigit)) {
              curEmployeeErrors.push(myEmployeesTableErrors.nss.verificationDigit);
            }
          } else {
            validUpdate = true
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.nss.regexStructure && error !== myEmployeesTableErrors.nss.verificationDigit);
          }
          break;
        case 'incorporation':
          if (moment(new Date(value as string)).isAfter(moment(new Date()))) {
            performRegisterEditError('La fecha de Incorporación no debe ser en el futuro');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.incorporation.invalidDate)) {
              curEmployeeErrors.push(myEmployeesTableErrors.incorporation.invalidDate);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.incorporation.invalidDate);
          }
          break;
        case 'dateOfFirstEntry':
          if (moment(new Date(value as string)).isAfter(moment(new Date()))) {
            performRegisterEditError('La fecha de Primer Ingreso no debe ser en el futuro');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.dateOfFirstEntry.invalidDate)) {
              curEmployeeErrors.push(myEmployeesTableErrors.dateOfFirstEntry.invalidDate);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.dateOfFirstEntry.invalidDate);
          }
          break;
        case 'tenureDate':
          const dateValue = new Date(value as string);

          const utcincorporationDate = new Date(currentEmployee.incorporation);
          const utcmaxDate = new Date();

          const incorporationDate = new Date(utcincorporationDate.getTime()
            + utcincorporationDate.getTimezoneOffset() * 60000);
          const maxDate = new Date(utcmaxDate.getTime() + utcmaxDate.getTimezoneOffset() * 60000);

          if (moment(dateValue).isAfter(moment(incorporationDate).isBefore(maxDate)
            ? moment(incorporationDate) : moment(maxDate))) {
            performRegisterEditError('La fecha de Primer Ingreso no debe ser en el futuro');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.tenureDate.invalidDate)) {
              curEmployeeErrors.push(myEmployeesTableErrors.tenureDate.invalidDate);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.tenureDate.invalidDate);
          }
          break;
        case 'email':
          if (!emailPatternV2.test(value as string)) {
            performRegisterEditError('La fecha de Primer Ingreso no debe ser en el futuro');
            newCell.className = 'error';
            if (!curEmployeeErrors.includes(myEmployeesTableErrors.email.invalidEmail)) {
              curEmployeeErrors.push(myEmployeesTableErrors.email.invalidEmail);
            }
          } else {
            newCell.className = 'edited';
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.email.invalidEmail);
          }
          break;
        default:
          break;
      }

      if (!curEmployeeErrors.includes(myEmployeesTableErrors.rfc.regexStructure) &&
        !curEmployeeErrors.includes(myEmployeesTableErrors.curp.regexStructure) &&
        !curEmployeeErrors.includes(myEmployeesTableErrors.nss.regexStructure)) {
        const rfc = headerKey === 'rfc' ? value as string : currentEmployee?.rfc;
        const curp = headerKey === 'curp' ? value as string : currentEmployee?.curp;
        const nss = headerKey === 'imss' ? value as string : currentEmployee?.imss;

        if (rfc && curp && nss && rfc.length > 5 && curp.length > 5 && nss.length > 5) {

          const rfcYear = rfc.substring(4, 6);
          const curpYear = curp.substring(4, 6);
          const nssYear = nss.substring(4, 6);

          const rfcCell = getCell(row || (pCell.id || employeeID), 'rfc');
          const curpCell = getCell(row || (pCell.id || employeeID), 'curp');
          const imssCell = getCell(row || (pCell.id || employeeID), 'imss');

          /** Year Test */
          if (rfcYear !== curpYear || rfcYear !== nssYear || curpYear !== nssYear) {
            validUpdate = false
            dispatch(showMessage({
              message: 'El RFC, CURP y NSS del empleado no coinciden en el año de nacimiento (Digito 5 y 6)',
              autoHideDuration: 6000,
              type: 'error',
            }));

            if (!curEmployeeErrors.includes(myEmployeesTableErrors.citizen.rfcCurpNssYearDate)) {
              curEmployeeErrors.push(myEmployeesTableErrors.citizen.rfcCurpNssYearDate);
            }
          } else if (headerKey === 'rfc' || headerKey === 'curp' || headerKey === 'imss' && validUpdate) {
            // console.log('rfc curp imss year edited');
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.citizen.rfcCurpNssYearDate);
          }

          const rfcBirth = rfc.substring(6, 10);
          const curpBirth = curp.substring(6, 10);
          /** Month and Day Test */
          if (rfcBirth !== curpBirth) {
            validUpdate = false
            dispatch(showMessage({
              message: 'El RFC y CURP del empleado no coinciden en el mes y dia de nacimiento (Digito 7, 8, 9, 10)',
              autoHideDuration: 6000,
              type: 'error',
            }));

            if (!curEmployeeErrors.includes(myEmployeesTableErrors.citizen.rfcCurpBirthDate)) {
              curEmployeeErrors.push(myEmployeesTableErrors.citizen.rfcCurpBirthDate);
            }
          } else if (headerKey === 'rfc' || headerKey === 'curp' && validUpdate) {
            curEmployeeErrors = curEmployeeErrors.filter((error) => error !== myEmployeesTableErrors.citizen.rfcCurpBirthDate);
          }

          if (curEmployeeErrors.includes(myEmployeesTableErrors.citizen.rfcCurpNssYearDate) ||
            curEmployeeErrors.includes(myEmployeesTableErrors.citizen.rfcCurpBirthDate)) {
            validatedChanges.push(
              {
                row: pCell.id || row,
                col: 'rfc',
                cell: {
                  className: 'error',
                },
                value: headerKey === 'rfc' ? value : rfcCell.value,
              },
              {
                row: pCell.id || row,
                col: 'curp',
                cell: {
                  className: 'error',
                },
                value: headerKey === 'curp' ? value : curpCell.value,
              },
            );
          } else {
            validatedChanges.push(
              {
                row: pCell.id || row,
                col: 'rfc',
                cell: {
                  className: 'edited',
                },
                value: headerKey === 'rfc' ? value : rfcCell.value,
              },
              {
                row: pCell.id || row,
                col: 'curp',
                cell: {
                  className: 'edited',
                },
                value: headerKey === 'curp' ? value : curpCell.value,
              },
            );
          }

          if (curEmployeeErrors.includes(myEmployeesTableErrors.citizen.rfcCurpNssYearDate)) {
            validatedChanges.push(
              {
                row: pCell.id || row,
                col: 'imss',
                cell: {
                  className: 'error',
                },
                value: headerKey === 'imss' ? value : imssCell.value,
              },
            )
          } else {
            validatedChanges.push(
              {
                row: pCell.id || row,
                col: 'imss',
                cell: {
                  className: 'edited',
                },
                value: headerKey === 'imss' ? value : imssCell.value,
              },
            )
          }

          // validatedChanges.push(
          //   {
          //     row: pCell.id || row,
          //     col: 'rfc',
          //     cell: {
          //       className: 'error',
          //     },
          //     value: headerKey === 'rfc' ? value : rfcCell.value,
          //   },
          //   {
          //     row: pCell.id || row,
          //     col: 'curp',
          //     cell: {
          //       className: 'error',
          //     },
          //     value: headerKey === 'curp' ? value : curpCell.value,
          //   },
          //   {
          //     row: pCell.id || row,
          //     col: 'imss',
          //     cell: {
          //       className: 'error',
          //     },
          //     value: headerKey === 'imss' ? value : imssCell.value,
          //   },
          // )
        }
      }

      /** Perform changes in grid */
      newChanges = {
        ...newChanges,
        [employeeID]: {
          ...newChanges[employeeID],
          [headerKey]: value,
        },
      };

      // If a change is present, but the employee doesn't have a Snapshot yet
      if (!newSnapshots[employeeID]) {
        // Create the employee + value snapshot
        newSnapshots = {
          ...newSnapshots,
          [employeeID]: {
            ...newSnapshots[employeeID],
            [headerKey]: oldFieldValue,
          },
        };
      } else {
        // If a change is present, and the employee has a Snapshot
        const prevSnapshot = newSnapshots[employeeID];

        // If the snapshot already has the header key
        if (prevSnapshot[headerKey]) {
          const previousSnapshotValue = prevSnapshot[headerKey];
          if (previousSnapshotValue) {
            // If the value is different from the snapshot
            if (oldFieldValue !== previousSnapshotValue) {
              newSnapshots = {
                ...newSnapshots,
                [employeeID]: {
                  ...newSnapshots[employeeID],
                  [headerKey]: previousSnapshotValue,
                },
              };
            } else {
              delete newSnapshots[employeeID][headerKey];

              if (Object.keys(newSnapshots[employeeID]).length === 0) {
                delete newSnapshots[employeeID];
              }
            }
          } else {
            newSnapshots = {
              ...newSnapshots,
              [employeeID]: {
                ...newSnapshots[employeeID],
                [headerKey]: oldFieldValue,
              },
            };
          }
        } else {
          // If the snapshot doesn't have the header key
          newSnapshots = {
            ...newSnapshots,
            [employeeID]: {
              ...newSnapshots[employeeID],
              [headerKey]: oldFieldValue,
            },
          };
        }
      }

      if (curEmployeeErrors.length > 0) {
        newErrors = {
          ...newErrors,
          [employeeID]: curEmployeeErrors,
        }
        handleDataErrorsChange(newErrors);
      } else {
        const tmpEmployeeErrors = newErrors;
        delete tmpEmployeeErrors[employeeID];

        handleDataErrorsChange(newErrors);
      }

      // End Map
    })

    handleDataSnapshotChange(newSnapshots);

    if (performEmployeeUpdate) {
      handleEmployeesChange(newChanges);
    }

    return validatedChanges
  };

  return {
    changesValidator: rowValidationsOnCellChange,
  };
};

export default useMyEmployeesChangesHandler;
