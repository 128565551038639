import { Pagination, WhereClause } from './request';

export interface Deadlock {
  id: string;
  createdAt: string;
  updatedAt: string;
  operationType: DeadlockType;
  payrollPeriod: string;
  employee: string;
  status: DeadlockStatus;
  messages: string[];
  extra?: DeadlockExtraFields;
}

export interface DeadlockExtraFields {
  stpId?: string;
  stpClaveRastreo?: string;
}

export interface DeadlockInput {
  operationType: DeadlockType;
  payrollPeriod: string;
  employee: string;
  messages: string[];
}

export enum DeadlockType {
  DLT_DISPERSION_STP = "DLT_DISPERSION_STP",
  DLT_STAMPING_STP = "DLT_STAMPING_STP",
  DLT_CANCELING_STP = "DLT_CANCELING_STP",
}

export enum DeadlockStatus {
  DLS_COMPLETED = "DLS_COMPLETED",
  DLS_PENDING = "DLS_PENDING",
  DLS_ERROR = "DLS_ERROR",
}

export interface DeadlocksFilter {
  id?: string;
  pagination?: Pagination;
  payrollPeriod?: WhereClause;
  employee?: WhereClause;
  operationType?: WhereClause;
}