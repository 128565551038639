import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiResponse, RequestConfig, ServerType } from '../types';
import getServerConfig from './getServerConfig';

export const getPutRequest = async<T = any>(url: string, data?: unknown, config: RequestConfig = {}): Promise<AxiosResponse<ApiResponse<T>>> => {
  const {
    controller,
    server = ServerType.Go,
  } = config;
  const { domain, token } = getServerConfig(server);
  const headers: AxiosRequestConfig = {
    headers: { Authorization: token || '' },
    validateStatus: (status) => status < 500,
    signal: controller ? controller.signal : undefined,
  };
  const response = await axios.put<ApiResponse<T>>(`${domain}/${url}`, data, headers);
  const { data: dataResponse, status } = response;
  if (typeof dataResponse === 'string' && status >= 300) {
    throw new Error(dataResponse);
  }
  const { error, errors } = dataResponse;
  const receivedError = error || errors;
  if (status >= 300 && receivedError) {
    const errorMessage = Array.isArray(receivedError)
      ? receivedError.map(({ detail }) => detail).join('\n\n')
      : receivedError;
    throw new Error(errorMessage);
  }
  return response;
};

export default getPutRequest;