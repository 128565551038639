/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { Message } from '../../types';

interface ChatState {
  count: number,
  entities:Record<string, Message[]>,
  unread: number,
}
type UnreadMessagesCountArgs = {
  clientID: string | undefined,
  adcID: string | undefined,
}

const initialState: ChatState = {
  count: 0,
  entities: {},
  unread: 0,
}
// const data = () => {
//   const dispatch = useDispatch();
//     const { selected } = useAppSelector(({ clients }) => clients);
// }

export const fetchUnreadMessagesCount = createAsyncThunk(
  'getUnreadMessagesCount/fetchUnreadMessagesCount',
  async (unreadData: UnreadMessagesCountArgs) => {
    const {clientID}= unreadData;
    const {adcID} = unreadData;

    const request =  await getPostRequest(`message/operative-contact/unread/${adcID}`,
    { clientID});
    const {data} =request;
    const {data: unReadMessages} = data;
    const {unread} = unReadMessages;
    return unread;
    
  }
);

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state, action) => { // pido los mensajes la 1ra ver 
      const operativeContactId = action.payload[0].from;
      const adcId = action.payload[0].to;
      const idGeneric = [operativeContactId, adcId].sort().join('_');

      const messages = _.get(action, 'payload', []).reverse();
      const prevMessages = _.get(state.entities, idGeneric, []);

      // eslint-disable-next-line no-param-reassign
      state.entities[idGeneric] = [
        ...messages,
        ...prevMessages,
      ]
    },
    setNewMessage: (state, action) => { // enviar
      const operativeContactId = action.payload.from;
      const adcId = action.payload.to;
      const idGeneric = [operativeContactId, adcId].sort().join('_');

      const message = _.get(action, 'payload', {});
      const prevMessages = _.get(state.entities, idGeneric, []);

      // const userId = localStorage.getItem('userId');
      // const isReaded = operativeContactId === userId;
      // const unread = (isReaded ? 0 : state.unread + 1);
      state.entities[idGeneric] = [
        ...prevMessages,
        message
      ]
      // state.unread = unread;
      setTimeout(() => {
        const chatScroll = document.getElementById('chat-scroll');
        if (chatScroll) {
          chatScroll.scrollTo(0, chatScroll.scrollHeight);
        }
      }, 100);

    }
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchUnreadMessagesCount.fulfilled, (state, action: PayloadAction< number> ) => {
        const {payload} = action;
        state.unread = payload;
      });
  },
});

export const { setMessages, setNewMessage } = chatSlice.actions;

export default chatSlice.reducer;