import { useEffect, useRef } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';

import _ from 'lodash';

import { useAppDispatch } from '../..';
import {
  createClientPendingTask,
  deleteClientPendingTask,
  fetchClientPendingTasksNoRedux,
  fetchPeriods,
  showMessage,
  updateClientPendingTask,
} from '../../../store/slices';
import {
  Cell,
  Change,
  ClientPendingTask,
  ClientPendingTaskTitle,
  ClientPendingTaskType,
  Employee,
  EntitiesState,
  HandleChanges,
  Option,
} from '../../../types';
import { useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';
import config from './config';

type UseReentryEmployeesDatasheet = {
  isLoading: boolean,
  hasData: boolean,
  hasPendingChanges: boolean,
  count: number,
  rows: EntitiesState,
  changes: EntitiesState,
  grid: Cell[][],
  handleChanges: HandleChanges,
  ValueRenderer: ReactDataSheet.ValueRenderer<any, any>,
  cleanDataSheetLocalStorage: () => void,
  entities: EntitiesState,
  cleanChanges: () => void,
};

const useReentryEmployeesDatasheet = (payrollId: string | undefined): UseReentryEmployeesDatasheet => {
  const {
    entities: employeesMap,
    isLoading,
    count,
  } = useAppSelector(({ employees }) => employees);
  const payrollsTypesMap = useAppSelector(
    ({ payrollsTypes }) => payrollsTypes.entities
  );
  const { periodsPerPayrollTypes } = useAppSelector(({ periods }) => periods);
  const {
    hasData,
    isCalculating,
    grid,
    getCell,
    rows,
    changes,
    handleChanges,
    ValueRenderer,
    updateColumnAtributes,
    updateCellAtributes,
    cleanDataSheetLocalStorage,
    cleanChanges,
    entities
  } = useDataSheet(columns, employeesMap, config);
  const hasPendingChanges = Object.keys(changes || {}).length > 0;

  const handleReentryEmployeesChanges: HandleChanges = (
    edits: Change[]
  ): void => {
    const validatedChanges: Change[] = [];
    edits.forEach((change) => {
      const { col, row, value } = change;
      const cell = change.cell || getCell(row, col);
      const { field } = cell;
      const validatedChange: Change = { ...change };
      if (field === 'choosed') {
        updateCellAtributes(row, 'payrollTypeId', { isEnabled: !!value });
        updateCellAtributes(row, 'reentryDate', { isEnabled: !!value });
        validatedChanges.push({
          row,
          col: 'payrollTypeId',
          value: value ? payrollId : '',
          cell: null,
        });
        validatedChanges.push({
          row,
          col: 'reentryDate',
          value: value ? new Date() : '',
          cell: null,
        });
      }
      validatedChanges.push(validatedChange);
    });
    handleChanges(validatedChanges);
  };

  useEffect(() => {
    const options: Option[] = Object.values(payrollsTypesMap).map(
      ({ id, name }) => ({ value: id, label: name })
    );
    updateColumnAtributes('payrollTypeId', { options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollsTypesMap]);

  const employees = useAppSelector((state) => state.employees);

  const selectedClientId = useAppSelector(({ clients }) => clients.selected);
  const changesAlertPublished = useRef(false);
  const prevTasksDeleted = useRef(false);
  const changesSnapshot = useRef({} as EntitiesState);
  const employeesSnapshot = useRef<Record<string, Employee>>({});
  const dispatch = useAppDispatch();

  const handleWebsiteUnload = async (changesParam: EntitiesState) => {
    // console.log("[D] Started Unload Process");
    if (changesParam) {
      if (Object.values(changesParam).length > 0) {

        const data = await fetchClientPendingTasksNoRedux({
          clientId: {
            eq: selectedClientId,
          },
          payrollTypeId: {
            eq: payrollId,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_REACTIVATION
          },
        })

        Object.values(changesParam).forEach((change) => {
          const { id } = change;

          const prevTask = data.find((task: ClientPendingTask) => task.employeeId === id);

          if (prevTask) {
            // console.log(`[D] Unload Process: Already Exists Pending Task [${i + 1}/${Object.values(changesParam).length}]`);
            dispatch(updateClientPendingTask(prevTask))
          } else {
            const employee = employeesSnapshot.current[id];

            dispatch(createClientPendingTask({
              clientId: selectedClientId,
              payrollTypeId: payrollId,
              employeeId: id,
              type: ClientPendingTaskType.CPTT_EMPLOYEES_REACTIVATION,
              employeeName: `${employee?.firstLastName} ${employee?.secondLastName} ${employee?.name}`,
            }))
            // console.log(`[D] Unload Process: Created Pending Task [${i + 1}/${Object.values(changesParam).length}]`);

          }

        })
      } else {
        // console.log("[D] Finished Unload Process: Nothing happened [1]");
      }
    } else {
      // console.log("[D] Finished Unload Process: Nothing happened [0]");
    }
  }

  const warnAgainstWebsiteUnload = async (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = '';
  }

  const handleDeleteAllPendingTasks = async () => {
    dispatch(deleteClientPendingTask({
      clientId: {
        eq: selectedClientId,
      },
      title: {
        eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_REACTIVATION
      },
      payrollTypeId: {
        eq: payrollId
      }
    }))
  }

  useEffect(() => {
    employeesSnapshot.current = employees.entities;
  }, [employees.entities]);

  // Unloading website
  useEffect(() => {
    changesSnapshot.current = changes;
    if (changes) {
      if (Object.values(changes).length > 0 && !changesAlertPublished.current) {
        prevTasksDeleted.current = false;
        // console.log("[D] Activated Unload Listener");
        window.addEventListener('beforeunload', warnAgainstWebsiteUnload)
        changesAlertPublished.current = true
      } else if (Object.values(changes).length === 0) {
        if (changesAlertPublished.current) {
          // console.log("[D] Deactivated Unload Listener");
          window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
        }
        changesAlertPublished.current = false
      }

      if (Object.values(changes).length === 0 && !prevTasksDeleted.current) {
        // console.log("[D] No changes detected, deleting pending tasks (if apply)");
        handleDeleteAllPendingTasks();
        prevTasksDeleted.current = true;
      }
    }

    return () => {
      window.removeEventListener('beforeunload', warnAgainstWebsiteUnload)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))
    return () => {
      window.removeEventListener('beforeunload', () => (handleWebsiteUnload(changesSnapshot.current)))

      handleWebsiteUnload(changesSnapshot.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    const messages: string[] = [];
    Object.values(changes).forEach((entity) => {
      const employee = _.get(employeesMap, entity.id);
      const payrollTypeId = _.get(entity, 'payrollTypeId', '');
      const reentryDateStr = _.get(entity, 'reentryDate', '');
      if (!payrollTypeId || !reentryDateStr) {
        return;
      }
      const periods = _.get(periodsPerPayrollTypes, payrollTypeId, []);
      if (periods.length === 0) {
        return;
      }
      const reentryDate = new Date(reentryDateStr);
      const isInClosedPeriod = periods.some((period) => {
        const { isClosed } = period;
        if (!isClosed) {
          return false;
        }
        const startDate = new Date(period.start || '');
        const endDate = new Date(period.end || '');
        return startDate < reentryDate && reentryDate < endDate;
      });
      if (isInClosedPeriod) {
        messages.push(`El empleado ${employee.name} ${employee.firstLastName} ${employee.secondLastName} esta siendo reingresado a una nómina con periodo calculado y cerrado`);
      }
    });
    if (messages.length > 0) {
      dispatch(showMessage({
        type: 'warning',
        message: messages,
      }));
    }
  }, [changes]);

  useEffectOnce(() => {
    dispatch(fetchPeriods({ client: selectedClientId }));
  });

  return {
    isLoading: isLoading || isCalculating,
    // isSaving,
    // isFetching,
    hasData,
    hasPendingChanges,
    count,
    rows,
    changes,
    grid,
    handleChanges: handleReentryEmployeesChanges,
    // handleSave,
    ValueRenderer,
    cleanDataSheetLocalStorage,
    entities,
    cleanChanges
  };
};

export default useReentryEmployeesDatasheet;
