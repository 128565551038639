/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import {
  CFDIConcept,
  EnumFrequencyType,
  EnumFrequencyTypeFilter,
  Option,
  TaxPaymentMethod,
  TaxPaymentMethodType,
  TaxRegime,
} from '../../types';

export interface OptionsCatalogState {
  taxRegimes: {
    selected: Option | null;
    options: Option[];
    isLoading: boolean;
  },
  CFDIConcepts: {
    selected: Option | null;
    options: Option[];
    isLoading: boolean;
  },
  paymentMethods: {
    selected: Option | null;
    options: Option[];
    isLoading: boolean;
  },
  paymentMethodTypes: {
    selected: Option | null;
    options: Option[];
    isLoading: boolean;
  },
  frequenciesTypes: {
    selected: Option | null;
    options: Option[];
    isLoading: boolean;
  },
};

const initialState: OptionsCatalogState = {
  taxRegimes: {
    selected: null,
    options: [],
    isLoading: false,
  },
  CFDIConcepts: {
    selected: null,
    options: [],
    isLoading: false,
  },
  paymentMethods: {
    selected: null,
    options: [],
    isLoading: false,
  },
  paymentMethodTypes: {
    selected: null,
    options: [],
    isLoading: false,
  },
  frequenciesTypes: {
    selected: null,
    options: [],
    isLoading: false,
  },
};

export const fetchTaxRegimesCatalog = createAsyncThunk(
  'getTaxRegimesCatalog/fetchTaxRegimesCatalog',
  async () => {
    const params = getGraphQLParameters('GetTaxRegimesCatalog', {});
    const response = await getPostRequest('', params);
    return _.get(response, 'data.data.catalogueTaxRegimes.data', []);
  },
);

export const fetchCFDIConceptsCatalog = createAsyncThunk(
  'getCFDIConceptsCatalog/fetchCFDIConceptsCatalog',
  async () => {
    const params = getGraphQLParameters('GetCFDIConceptsCatalog', {});
    const response = await getPostRequest('', params);
    return _.get(response, 'data.data.catalogueCFDIConcepts.data', []);
  },
);

export const fetchPaymentMethodsCatalog = createAsyncThunk(
  'getPaymentMethodsCatalog/fetchPaymentMethodsCatalog',
  async () => {
    const params = getGraphQLParameters('GetPaymentMethodsCatalog', {});
    const response = await getPostRequest('', params);
    return _.get(response, 'data.data.cataloguePayMethods.data', []);
  },
);

export const fetchPaymentMethodTypesCatalog = createAsyncThunk(
  'getPaymentMethodTypesCatalog/fetchPaymentMethodTypesCatalog',
  async () => {
    const params = getGraphQLParameters('GetPaymentMethodTypesCatalog', {});
    const response = await getPostRequest('', params);
    return _.get(response, 'data.data.cataloguePaymentMethodTypes.data', []);
  },
);

export const fetchFrequenciesTypesCatalog = createAsyncThunk(
  'getFrequenciesTypesCatalog/fetchFrequenciesTypesCatalog',
  async (filters?: EnumFrequencyTypeFilter) => {
    const params = getGraphQLParameters('enumFrequencyType', {});
    const response = await getPostRequest<{ enumFrequencyType: EnumFrequencyType[] }>('', params);
    const allFrequencies = response.data.data.enumFrequencyType;// _.get(response, 'data.data.enumFrequencyType', []);
    let filteredFrequencies = allFrequencies;
    if (filters?.indexes?.length) {
      filteredFrequencies = allFrequencies.filter((_freq, index) => filters.indexes?.includes(index + 1) );
    }

    return filteredFrequencies
  },
);

export const optionsCatalogSlice = createSlice({
  name: 'optionsCatalog',
  initialState,
  reducers: {
    cleanOptionsCatalog: (state) => {
      state.taxRegimes.options = [];
      state.taxRegimes.isLoading = false;
      state.taxRegimes.selected = null;
      state.CFDIConcepts.options = [];
      state.CFDIConcepts.isLoading = false;
      state.CFDIConcepts.selected = null;
      state.paymentMethods.options = [];
      state.paymentMethods.isLoading = false;
      state.paymentMethods.selected = null;
      state.paymentMethodTypes.options = [];
      state.paymentMethodTypes.isLoading = false;
      state.paymentMethodTypes.selected = null;
      state.frequenciesTypes.options = [];
      state.frequenciesTypes.isLoading = false;
      state.frequenciesTypes.selected = null;
    },
    setSelectedTaxRegimen: (state, action: PayloadAction<Option | null>) => {
      const { payload } = action;
      state.taxRegimes.selected = payload;
    },
    setSelectedCFDIConcept: (state, action: PayloadAction<Option | null>) => {
      const { payload } = action;
      state.CFDIConcepts.selected = payload;
    },
    setSelectedTaxPaymentMethod: (state, action: PayloadAction<Option | null>) => {
      const { payload } = action;
      state.paymentMethods.selected = payload;
    },
    setSelectedTaxPaymentMethodType: (state, action: PayloadAction<Option | null>) => {
      const { payload } = action;
      state.paymentMethodTypes.selected = payload;
    },
    setSelectedFrequencyType: (state, action: PayloadAction<Option | null>) => {
      const { payload } = action;
      state.frequenciesTypes.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxRegimesCatalog.pending, (state) => {
        state.taxRegimes.isLoading = true;
      })
      .addCase(fetchTaxRegimesCatalog.fulfilled, (state, action: PayloadAction<TaxRegime[]>) => {
        state.taxRegimes.isLoading = false;
        const { payload } = action;
        if (payload) {
          const options: Option[] = payload.map(({ id, cRegimenFiscal, description }) => ({
            id,
            value: cRegimenFiscal,
            label: `(${cRegimenFiscal}) ${description}`,
          }));

          state.taxRegimes.options = options;
          state.taxRegimes.selected = null;
        }
      })
      .addCase(fetchTaxRegimesCatalog.rejected, (state) => {
        state.taxRegimes.isLoading = false;
      });

    builder
      .addCase(fetchCFDIConceptsCatalog.pending, (state) => {
        state.CFDIConcepts.isLoading = true;
      })
      .addCase(fetchCFDIConceptsCatalog.fulfilled, (state, action: PayloadAction<CFDIConcept[]>) => {
        state.CFDIConcepts.isLoading = false;
        const { payload } = action;
        if (payload) {
          const options: Option[] = payload.map(({ id, cUsoCFDI, description }) => ({
            id,
            value: cUsoCFDI,
            label: `(${cUsoCFDI}) ${description}`,
          }));

          state.CFDIConcepts.options = options;
          state.CFDIConcepts.selected = null;
        }
      })
      .addCase(fetchCFDIConceptsCatalog.rejected, (state) => {
        state.CFDIConcepts.isLoading = false;
      });

    builder
      .addCase(fetchPaymentMethodsCatalog.pending, (state) => {
        state.paymentMethods.isLoading = true;
      })
      .addCase(fetchPaymentMethodsCatalog.fulfilled, (state, action: PayloadAction<TaxPaymentMethod[]>) => {
        state.paymentMethods.isLoading = false;
        const { payload } = action;
        if (payload) {
          const options: Option[] = payload.map(({ id, metodoPago, description }) => ({
            id,
            value: metodoPago,
            label: `(${metodoPago}) ${description}`,
          }));

          state.paymentMethods.options = options;
          state.paymentMethods.selected = null;
        }
      })
      .addCase(fetchPaymentMethodsCatalog.rejected, (state) => {
        state.paymentMethods.isLoading = false;
      });

    builder
      .addCase(fetchPaymentMethodTypesCatalog.pending, (state) => {
        state.paymentMethodTypes.isLoading = true;
      })
      .addCase(fetchPaymentMethodTypesCatalog.fulfilled, (state, action: PayloadAction<TaxPaymentMethodType[]>) => {
        state.paymentMethodTypes.isLoading = false;
        const { payload } = action;
        if (payload) {
          const options: Option[] = payload.map(({ id, formaPago, name, billingId }) => ({
            id,
            value: formaPago,
            label: `(${formaPago}) ${name}`,
            billingId,
          }));

          state.paymentMethodTypes.options = options;
          state.paymentMethodTypes.selected = null;
        }
      })
      .addCase(fetchPaymentMethodTypesCatalog.rejected, (state) => {
        state.paymentMethodTypes.isLoading = false;
      });

    builder
      .addCase(fetchFrequenciesTypesCatalog.pending, (state) => {
        state.frequenciesTypes.isLoading = true;
      })
      .addCase(fetchFrequenciesTypesCatalog.fulfilled, (state, action: PayloadAction<EnumFrequencyType[]>) => {
        state.frequenciesTypes.isLoading = false;
        const { payload } = action;
        if (payload) {
          const options: Option[] = payload.map(({ value, displayValue }) => ({
            value,
            label: displayValue,
          }));

          state.frequenciesTypes.options = options;
          state.frequenciesTypes.selected = null;
        }
      })
      .addCase(fetchFrequenciesTypesCatalog.rejected, (state) => {
        state.frequenciesTypes.isLoading = false;
      });
  },
});

export const {
  cleanOptionsCatalog,
  setSelectedTaxRegimen,
  setSelectedCFDIConcept,
  setSelectedTaxPaymentMethod,
  setSelectedTaxPaymentMethodType,
  setSelectedFrequencyType,
} = optionsCatalogSlice.actions;

export default optionsCatalogSlice.reducer;
