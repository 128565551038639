/* eslint-disable @typescript-eslint/ban-ts-comment */
import _ from 'lodash';

import { getGraphQLParameters, getPostRequest } from '../../helpers';
import { Operations } from '../../helpers/getGraphQLParameters';
import { Pagination } from '../../types/request';
import ServiceError from '../ServiceError';
import { PayrollSchemas } from './constants';
import { PPPSPrepayroll, SFPrepayroll } from './types';

// TODO: Add types.

const formatEmployees = (
  fetchedEmployees: any[],
  selected: boolean,
  extra: any = {}
): any[] => {
  const employees = fetchedEmployees.map(
    // Exclude unrelevant properties.
    ({ paymentMethod, employee: posibleEmployee, ...props }: any) => {
      // posibleEmployee comes from getEmployeesExistingPrepayroll method
      // and not from getEmployeesNewPrepayroll method.
      const employee = posibleEmployee ?? props;

      // TODO: Get only relevant properties.
      const mergedEmployee = {
        ...props,
        ...employee,
        ...extra,
        paymentMethod: paymentMethod ? paymentMethod.id : '',
      };

      if (mergedEmployee.amount === undefined)
        mergedEmployee.amount = mergedEmployee.netSalary;

      if (mergedEmployee.workedDays === undefined)
        mergedEmployee.workedDays = mergedEmployee.frecuency;

      const bankAccountsByBank = _.groupBy(mergedEmployee.bankAccounts, 'bank');

      const banks = Object.keys(bankAccountsByBank).map((bank) => ({
        value: bank,
        label: bank,
      }));

      return {
        ...mergedEmployee,
        banks,
        bankAccountsByBank,
        selected,
      };
    }
  );

  return employees;
};

class PeriodService {
  /**
   * Get employees.
   * @returns
   */
  async getEmployees({
    payrollPeriod,
    taxSchema,
    workedDays,
    type
  }: {
    payrollPeriod: string;
    pagination?: Pagination;
    taxSchema?: string;
    workedDays: number;
    type?: string;
  }) {
    // !! Kind of hacky.
    const pagination = {
      page: 1,
      limit: 1000,
    };

    let savedEmployees: any = {
      count: 0,
      entities: [],
      calculated: false,
    };

    if (taxSchema)
      savedEmployees = await this.getEmployeesExistingPrepayroll({
        payrollPeriod,
        pagination,
        taxSchema,
        type
      });

    const newEmployees = await this.getEmployeesNewPayroll({
      payrollPeriod,
      pagination,
      selected: savedEmployees.count === 0,
      workedDays,
    });

    const newEmployeesById = _.keyBy(newEmployees.entities, 'id');
    const savedEmployeesById = _.keyBy(savedEmployees.entities, 'id');

    const entitiesById = {
      ...newEmployeesById,
      ...savedEmployeesById,
    };

    const entities = Object.values(entitiesById).sort((a: any, b: any) =>
      a.firstLastName.localeCompare(b.firstLastName)
    );

    const employees = {
      count: entities.length,
      entities,
      pagination,
      calculated: savedEmployees.calculated,
    };

    return employees;
  }

  /**
   * Get employees in existing prepayroll.
   * Used when editing a prepayroll.
   * @returns
   */
  private async getEmployeesExistingPrepayroll({
    payrollPeriod,
    pagination,
    taxSchema,
    type
  }: {
    payrollPeriod: string;
    taxSchema: string;
    pagination: Pagination;
    type?: string;
  }) {
    let query = 'sfprepayrolls';
    switch (taxSchema) {
      case PayrollSchemas.PPPS:
      case PayrollSchemas.NOM035:
        if (type === 'Extraordinario')
          query = 'extraordinaryPayrolls';
        else query = 'pppsPayrolls';
        break;
      default:
        break;
    }

    // @ts-ignore
    const parameters = getGraphQLParameters(query, {
      payrollPeriod,
      pagination,
    });

    const { data } = await getPostRequest('', parameters);

    const prepayrolls = data.data[query];

    const employees = {
      count: prepayrolls ? prepayrolls.count : 0,
      entities: prepayrolls ? prepayrolls.data : [],
      pagination,
      calculated: false,
    };

    employees.entities = formatEmployees(employees.entities, true);
    employees.calculated = Boolean(
      employees.entities.find((employee: any) => employee.calculated)
    );

    return employees;
  }

  /**
   * Get employees filtered by payroll period.
   * Used when creating a prepayroll.
   * @returns
   */
  private async getEmployeesNewPayroll({
    payrollPeriod,
    pagination,
    selected,
    workedDays,
  }: {
    payrollPeriod: string;
    pagination: Pagination;
    selected: boolean;
    workedDays: number;
  }) {
    const parameters = getGraphQLParameters('payrollPeriodEmployees', {
      payrollPeriod,
      pagination,
    });

    const { data } = await getPostRequest('', parameters);

    const fetchedEmployees = data.data.employees;

    const employees = {
      count: fetchedEmployees ? fetchedEmployees.count : 0,
      entities: fetchedEmployees ? fetchedEmployees.entities : [],
      pagination,
    };

    employees.entities = formatEmployees(employees.entities, selected, {
      workedDays,
    });

    return employees;
  }

  /**
   * Get the payroll period.
   * @param id
   * @returns
   */
  async get(id: string) {
    const parameters = getGraphQLParameters('payrollPeriod', {
      id,
    });
    const response = await getPostRequest('', parameters);

    const { payrollPeriod } = response.data.data;

    if (!payrollPeriod) throw new Error('Periodo no encontrado.');

    return {
      ...payrollPeriod,
      frequency: payrollPeriod.frequency.name.slice(0, 3).toUpperCase(),
      workedDays: payrollPeriod.frequency.workDays,
    };
  }

  /**
   * Get payment methods formatted as options.
   * @returns
   */
  async getPaymentMethods() {
    const response = await getPostRequest('v2/paymentmethod/all');

    const paymentMethods = response.data.data;

    if (!paymentMethods) throw new Error('Metodos de pago no encontrados.');

    return paymentMethods.map(({ id, name }: any) => ({
      value: id,
      label: name,
    }));
  }

  /**
   * Save the prepayroll.
   */
  async savePrepayroll({
    payrollPeriod,
    employees,
    taxSchema,
    type,
  }: {
    payrollPeriod: string;
    employees: Array<any>;
    taxSchema: string;
    type: string,
  }) {
    switch (taxSchema) {
      case PayrollSchemas.PPPS:
      case PayrollSchemas.NOM035:
        return this.savePPPSPrepayroll({ payrollPeriod, employees, type });
      default:
        return this.saveSFPrepayroll({ payrollPeriod, employees });
    }
  }

  private async saveSFPrepayroll({
    payrollPeriod,
    employees,
  }: {
    payrollPeriod: string;
    employees: any[];
  }) {
    const prepayrolls: SFPrepayroll[] = [];

    employees.forEach((employee: any) => {
      let workedDays = parseInt(employee.workedDays, 10);
      if (Number.isNaN(workedDays))
        workedDays = 0;

      let netSalary = parseFloat(employee.netSalary);
      if (Number.isNaN(netSalary))
        netSalary = 0;

      const prepayroll: SFPrepayroll = {
        paymentMethod: employee.paymentMethod,
        netSalary,
        vacationBonus: 0,
        bonus: 0,
        awards: 0,
        diningRoom: 0,
        disabilityDays: 0,
        ptu: 0,
        savingFund: 0,
        pantryVouchers: 0,
        workedDays,
        doubleOvertime: 0,
        tripleOvertime: 0,
        alimony: 0,
        faults: 0,
        sundaysWorked: 0,
        vacation: 0,
        holidayWorked: 0,
        calculoFiscal: false,
        employeeBankAccount: employee.bankAccount,
        employee: employee.id,
      };

      prepayrolls.push(prepayroll);
    });

    const parameters = getGraphQLParameters('sfprepayrollAddMany', {
      payrollPeriod,
      prepayroll: prepayrolls,
    });

    const { data } = await getPostRequest('', parameters);

    if (data.errors)
      throw new ServiceError(
        'Han ocurrido errores al guardar la prenomina.',
        data.errors
      );

    if (!data.data) throw new Error('Error al guardar la prenomina.');
  }

  private async savePPPSPrepayroll({
    payrollPeriod,
    employees,
    type,
  }: {
    payrollPeriod: string;
    employees: any[];
    type: string,
  }) {
    const params = getGraphQLParameters('PayrollPeriodEdit', {
      id: payrollPeriod,
      isClosed: false,
    });
    await getPostRequest('', params);
    const markAsFilledParams = getGraphQLParameters('PayrollPeriodMarkAsFilled', {
      id: payrollPeriod,
    });
    await getPostRequest('', markAsFilledParams);

    const prepayrolls: PPPSPrepayroll[] = [];

    employees.forEach((employee: any) => {
      let amount = parseFloat(employee.amount);
      if (Number.isNaN(amount))
        amount = 0;

      let workedDays = parseInt(employee.workedDays, 10);
      if (Number.isNaN(workedDays))
        workedDays = 0;

      const employeePayroll: PPPSPrepayroll = {
        amount,
        paymentMethod: employee.paymentMethod,
        workedDays: type === 'Extraordinario' ? undefined : workedDays,
        employeeBankAccount: employee.bankAccount,
        employee: employee.id,
      };

      prepayrolls.push(employeePayroll);
    });

    const operationName: Operations = type === 'Extraordinario' ? 'extraordinaryPayrollAddMany' : 'pppsPayrollAddMany';

    // Se agrega la nomina
    const parameters = getGraphQLParameters(operationName, {
      payrollPeriod,
      payroll: prepayrolls,
      prepayrolls,
    });

    const { data } = await getPostRequest('', parameters);

    if (data.errors)
      throw new ServiceError(
        'Han ocurrido errores al guardar la prenomina.',
        data.errors
      );

    if (!data.data) throw new Error('Error al guardar la prenomina.');

    const operationName2: Operations = type === 'Extraordinario' ? 'AddCalculateExtraordinaryPayroll' : 'AddCalculatePPPSPayroll';

    const parameters2 = getGraphQLParameters(operationName2, {
      payrollPeriod,
    });
    // Se manda a calcular la nomina
    await getPostRequest('', parameters2);

    const params2 = getGraphQLParameters('PayrollPeriodEdit', {
      id: payrollPeriod,
      payrollStatus: 'authorized',
    });
    await getPostRequest('', params2);
  }
}

const periodService = new PeriodService();

export default periodService;
