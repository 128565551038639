import JSZip from 'jszip';
import { save } from 'save-file';

import { getGetRequest } from '../helpers'
import myGet from '../helpers/myGet';

// eslint-disable-next-line no-async-promise-executor
const downloadZippedFiles = (arrOfDocs = [], fileName = 'comprobante') => new Promise(async (resolve, reject) => {
  try {
    const downloadResponses = await Promise.all(
      arrOfDocs.map((e) => getGetRequest(`upload/${myGet(e, 'id', '')}`)),
    );

    let downloadURLs = {};
    downloadResponses.forEach(async (response) => {
      const id = myGet(response, 'config.url', '').split('/');
      downloadURLs = { ...downloadURLs, [id[id.length - 1]]: myGet(response, 'data.request.responseURL', '') };
    });

    // // console.log(downloadURLs);

    const blobResponses = await Promise.all(
      Object.keys(downloadURLs).map(async (key) => {
        const link = downloadURLs[key];
        if (link !== '') {
          return window.fetch(link);
        }

        return null;
      }),
    );

    // // console.log(blobResponses);

    let blobs = {};
    // eslint-disable-next-line no-restricted-syntax
    for await (const elem of blobResponses) {
      const url = myGet(elem, 'url', '');
      // eslint-disable-next-line no-loop-func
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      const id = Object.keys(downloadURLs).find((z) => {
        const tmpURL = downloadURLs[z];

        if (tmpURL === url) {
          return true;
        }

        return false;
      });

      const blob = await elem.blob();
      blobs = { ...blobs, [id]: blob };
    }

    if (Object.values(blobs).length === 0) {
      resolve();
    }

    const zip = new JSZip();
    const folder = zip.folder(fileName);

    Object.keys(blobs).forEach((id) => {
      const blob = blobs[id];

      const doc = arrOfDocs.find((e) => myGet(e, 'id', '') === id);

      folder.file(myGet(doc, 'name', 'unknown_file'), blob, { binary: true });
    });

    const blob = await zip.generateAsync({ type: 'blob' });
    save(blob, `${fileName}.zip`);
    // window.open(`data:application/zip;base64,${base64}`, '_blank');
    resolve('Success')
  } catch ({ message }) {
    reject(message)
  }
});

export default downloadZippedFiles;
