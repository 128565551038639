import { FC } from 'react';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import { useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ITheme } from './index';

interface Props {
  children: JSX.Element,
}

const PageContainer: FC<Props> = ({ children }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const status = useSelector(({ session }: RootState) => session.status);
  const location = useLocation()

  const isMobile = useMediaQuery((theme: ITheme) => theme.breakpoints.down('sm'))

  const selectedClientId = useAppSelector((state) => state.clients.selected)
  const fullClients = useAppSelector((state) => state.fullClients.entities)
  const selectedClient = fullClients[selectedClientId]
  const globalPermissions = selectedClient?.globalPermissions
  const { pathname } = useLocation();
  const isLogin = pathname === '/login'

  return (
    <Grid
      container
    >
      <Grid
        item
        maxHeight='100%'
        xs={12}
      >
        <Container maxWidth='xl' style={{ maxHeight: '100%' }}>
          <Box
            display='flex'
            position='relative'
            style={{
              background: 'white', minHeight: '80vh', padding: '20px 40px 40px 40px', borderRadius: '10px',
              ...(/\/period\//.test(location.pathname) ? { height: isMobile ? '83vh' : '80vh' } : {})
            }}
            zIndex={1}
          >
            <div
              style={{
                width: '100%',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {!globalPermissions && !isLogin && (
                <Box style={{ width: '100%', height: '100%', display: 'grid', placeContent: 'center' }}>
                  <CircularProgress style={{ margin: 'auto' }} />
                  <Typography style={{ margin: 'auto' }}>Cargando...</Typography>
                </Box>
              )}
              {isLogin && children}
              {globalPermissions && !globalPermissions.maintenanceMode && !isLogin && children}
              {globalPermissions && globalPermissions.maintenanceMode && !isLogin && (
                <Box style={{ width: '100%', height: '100%', display: 'grid', placeContent: 'center' }}>
                  <Typography style={{ margin: 'auto', fontWeight: 400, fontSize: '1.5rem' }}>¡La plataforma se encuentra en mantenimiento!</Typography>
                  <Typography style={{ margin: 'auto', fontWeight: 400, fontSize: '1.5rem', marginBottom: 20 }}>Lamentamos el inconveniente.</Typography>
                  <Typography style={{ margin: 'auto', fontStyle: 'italic' }}>Ponte en contacto con tu asesor para recibir mas información</Typography>
                </Box>
              )}
            </div>
          </Box>
        </Container>
      </Grid>
    </Grid >
  );
};

export default PageContainer;
