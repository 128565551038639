import Select, { GroupBase, Props, StylesConfig } from 'react-select';

import { v4 as uuid } from 'uuid';

// import Control from './Control';
import getStyles from './getStyles';
// import IndicatorsContainer from './IndicatorsContainer';
import Input from './Input';
import Placeholder from './Placeholder';

const getDefualtNoOptionsMessage = (): string => 'Sin opciones';

const CustomSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> (props: Props<Option, IsMulti, Group>): JSX.Element => {
  const { styles: defaultStyles } = props;
  const styles: StylesConfig<Option, IsMulti, Group> = getStyles<Option, IsMulti, Group>(defaultStyles);
  return (
    <Select
      {...props}
      components={{
        // Control,
        // IndicatorsContainer,
        Input,
        Placeholder,
      }}
      styles={styles}
    />
  );
}

CustomSelect.defaultProps = {
  helperText: 'Seleccione una opción',
  error: false,
  id: uuid(),
  inputId: uuid(),
  isClearable: false,
  isDisabled: false,
  isLoading: false,
  // isRequired: false,
  label: 'Seleccionar',
  menuPlacement: 'auto',
  menuPortalTarget: undefined,
  menuPosition: undefined,
  name: undefined,
  noOptionsMessage: getDefualtNoOptionsMessage,
  onChange: undefined,
  options: undefined,
  placeholder: 'Seleccionar una opción',
  styles: {},
  value: undefined,
};

export default CustomSelect;
