/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { getV2APIGetRequest } from '../../helpers/getGetRequest';
import { PayrollReceipt, PayrollReceiptCancelOutput, PayrollReceiptFilter, PayrollReceiptStampOutput } from '../../types';

interface Operation<T> {
  isLoading: boolean;
  hasFinished: boolean;
  opError: number;
  opSuccess: number;
  Responses: T[];
  fatalError: boolean;
}

export interface PayrollReceiptsState {
  count: number;
  entities: Record<string, PayrollReceipt>;
  isLoading: boolean;
  selected: string;
  operationStamp: Operation<PayrollReceiptStampOutput> | null;
  operationCancel: Operation<PayrollReceiptCancelOutput> | null;
}

const initialState: PayrollReceiptsState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: '',
  operationStamp: null,
  operationCancel: null,
};

export const fetchPayrollReceipts = createAsyncThunk(
  'getPayrollReceipts/fetchPayrollReceipts',
  async (filters?: PayrollReceiptFilter) => {
    const { data: dataResponse } = await getPostRequest(
      'v2/payrollreceipt/all',
      filters
    );
    const { data } = dataResponse;
    return data || [];
  }
);

export const createPayrollReceipts = createAsyncThunk(
  'payrollReceipts/createFromDispersions',
  async (dispersionsIds: string[]) => {
    const responses = await Promise.allSettled(dispersionsIds.map((id) => getV2APIGetRequest<PayrollReceiptStampOutput>(`v2/stamping/stamp/${id}`)))

    const operations: PayrollReceiptStampOutput[] = [];

    responses.forEach((op) => {
      if (op.status === 'fulfilled') {
        operations.push(op.value.data.data);
      }
    })

    return operations;
  }
);

export const cancelPayrollReceipts = createAsyncThunk(
  'payrollReceipts/cancelFromDispersions',
  async (dispersionsIds: string[]) => {

    const responses = await Promise.allSettled(dispersionsIds.map((id) => getV2APIGetRequest<PayrollReceiptCancelOutput>(`v2/stamping/cancel/${id}`)))

    const operations: PayrollReceiptCancelOutput[] = [];

    responses.forEach((op) => {
      if (op.status === 'fulfilled') {
        operations.push(op.value.data.data);
      }
    })

    return operations;
  }
);

export const parollreceiptsSlice = createSlice({
  name: 'parollreceipts',
  initialState,
  reducers: {
    cleanPayrollReceipts: (state) => {
      state.count = 0;
      state.entities = {};
      state.selected = '';
    },
    cleanPayrollReceiptsOpStamp: (state) => {
      state.operationStamp = null;
    },
    cleanPayrollReceiptsOpCancel: (state) => {
      state.operationCancel = null;
    },
    setSelectedPayrollReceipt: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayrollReceipts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchPayrollReceipts.fulfilled,
        (state, action: PayloadAction<PayrollReceipt[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = _.keyBy(payload, 'id');
          if (
            state.selected !== '' &&
            state.entities[state.selected] === undefined
          ) {
            state.selected = '';
          }
        }
      )
      .addCase(fetchPayrollReceipts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(createPayrollReceipts.pending, (state) => {
        state.operationStamp = {
          isLoading: true,
          hasFinished: false,
        } as Operation<PayrollReceiptStampOutput>;
      })
      .addCase(
        createPayrollReceipts.fulfilled,
        (state, action: PayloadAction<PayrollReceiptStampOutput[]>) => {
          const { payload } = action;

          let errors = 0;
          let successes = 0;

          payload.forEach((op) => {

            const naturalLangParsedError: Record<string, string> = {};

            if (op.errors) {
              op.errors.forEach((error) => {
                if (/RFC/i.test(error)) {
                  naturalLangParsedError.RFC = 'El empleado no cuenta con un RFC validó, o no está registrado en el SAT';
                } else if (/CURP/i.test(error)) {
                  naturalLangParsedError.CURP = 'El empleado no cuenta con un CURP validó, o no está registrado en el SAT';
                }
              });
            }

            if ((op?.errors || []).length > 0 && Object.values(naturalLangParsedError).length === 0) {
              op.prevErrors = [...(op.errors || [])]
              op.errors = ["Ha ocurrido un error desconocido! Por favor, contacta a tu ayudante."]
              console.log("[STAMP][RESPONSE-ERRORS]", op);
            } else {
              op.prevErrors = [...(op.errors || [])]
              op.errors = Object.values(naturalLangParsedError);
            }

            if (Object.values(naturalLangParsedError).length > 0) {
              errors += 1
            } else {
              successes += 1
            }
          })

          state.operationStamp = {
            ...state.operationStamp,
            isLoading: false,
            hasFinished: true,
            Responses: payload,
            fatalError: false,
            opError: errors,
            opSuccess: successes,
          }
        }
      )
      .addCase(createPayrollReceipts.rejected, (state) => {
        state.operationStamp = {
          isLoading: false,
          hasFinished: false,
          fatalError: true,
        } as Operation<PayrollReceiptStampOutput>;
      });

    builder
      .addCase(cancelPayrollReceipts.pending, (state) => {
        state.operationCancel = {
          isLoading: true,
          hasFinished: false,
        } as Operation<PayrollReceiptCancelOutput>;
      })
      .addCase(
        cancelPayrollReceipts.fulfilled,
        (state, action: PayloadAction<PayrollReceiptCancelOutput[]>) => {
          const { payload } = action;

          let errors = 0;
          let successes = 0;

          payload.forEach((op) => {

            const naturalLangParsedError: Record<string, string> = {};

            if (op.errors) {
              op.errors.forEach((error) => {
                if (/RFC/i.test(error)) {
                  naturalLangParsedError.RFC = 'El empleado no cuenta con un RFC validó, o no está registrado en el SAT';
                } else if (/CURP/i.test(error)) {
                  naturalLangParsedError.CURP = 'El empleado no cuenta con un CURP validó, o no está registrado en el SAT';
                }
              });
            }

            if ((op?.errors || []).length > 0 && Object.values(naturalLangParsedError).length === 0) {
              op.prevErrors = [...(op.errors || [])]
              op.errors = ["Ha ocurrido un error desconocido! Por favor, contacta a tu ayudante."]
              console.log("[STAMP][RESPONSE-ERRORS]", op);
            } else {
              op.prevErrors = [...(op.errors || [])]
              op.errors = Object.values(naturalLangParsedError);
            }

            if ((op.errors || []).length > 0) {
              errors += 1
            } else {
              successes += 1
            }
          })

          state.operationCancel = {
            ...state.operationCancel,
            isLoading: false,
            hasFinished: true,
            Responses: payload,
            fatalError: false,
            opError: errors,
            opSuccess: successes,
          }
        }
      )
      .addCase(cancelPayrollReceipts.rejected, (state) => {
        state.operationCancel = {
          isLoading: false,
          hasFinished: false,
          fatalError: true,
        } as Operation<PayrollReceiptCancelOutput>;
      });
  },
});

export const { cleanPayrollReceipts, setSelectedPayrollReceipt, cleanPayrollReceiptsOpCancel, cleanPayrollReceiptsOpStamp } = parollreceiptsSlice.actions;

export default parollreceiptsSlice.reducer;
