import { FC, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  AccountBalance,
  AddCircleOutline,
  BadgeOutlined,
  CalendarToday,
  ChatOutlined,
  // ManageAccounts,
  RemoveCircleOutline,
  SyncAltOutlined,
} from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';

import { HelmetRenderer, SquareButton } from '../../components';
import { Shelfs } from '../../containers';
import myGet from '../../helpers/myGet';
import { useAppDispatch, useAppSelector, useSelectedClient } from '../../hooks';
import { fetchClientPendingTasks, fetchClients, promisedCalculateClientPendingTasksByClient } from '../../store/slices';
import NotificationList from './NotificationList';

const Home: FC<{ path: string }> = ({ path }): JSX.Element => {
  const dispatch = useAppDispatch();

  const { id: clientId } = useSelectedClient();

  useEffectOnce(() => {
    dispatch(fetchClients());
  });

  const clientsPendingTasksLoaded = useRef(false);
  const clientsMap = useAppSelector(({ clients }) => (clients.entities))

  const fullClients = useAppSelector((state) => state.fullClients.entities)
  const [loadingPendingTasks, setloadingPendingTasks] = useState(false)
  const selectedClient = fullClients[clientId]
  const globalPermissions = selectedClient?.globalPermissions

  useEffect(() => {

    const fetchPendingTasks = async () => {
      if (clientsMap && !clientsPendingTasksLoaded.current && !loadingPendingTasks) {
        if (Object.keys(clientsMap).length > 0) {
          setloadingPendingTasks(true);

          await Promise.allSettled(Object.keys(clientsMap).map((indexedClientId) => promisedCalculateClientPendingTasksByClient(indexedClientId)))

          dispatch(fetchClientPendingTasks({
            clientId: {
              inString: Object.keys(clientsMap)
            }
          }))
          clientsPendingTasksLoaded.current = true;
        }
      }
    }

    fetchPendingTasks()

    return () => {
      clientsPendingTasksLoaded.current = false;
    }
  }, [clientsMap, dispatch, loadingPendingTasks]);

  return (
    <>
      <HelmetRenderer path={path} />
      <Grid container spacing={2} style={{ height: '100%', maxHeight: '100%' }}>
        <Grid item md={9} xs={12}>
          <Shelfs itemsPerRow={3}>

            {myGet(globalPermissions, 'myEmployeesModule.enabled', false) ? (
              <SquareButton
                icon={<BadgeOutlined style={{ fontSize: '3rem' }} />}
                style={{ minHeight: '20vh' }}
                title="Mis empleados"
                to={`/my-employees/${clientId}`}
              />
            ) : null}

            {myGet(globalPermissions, 'employeeEntryModule.enabled', false) ? (
              <SquareButton
                icon={<AddCircleOutline style={{ fontSize: '3rem' }} />}
                style={{ minHeight: '20vh' }}
                title="Alta de empleados"
                to={`/add-employees/${clientId}`}
              />
            ) : null}

            {myGet(globalPermissions, 'employeeDismissModule.enabled', false) ? (
              <SquareButton
                icon={<RemoveCircleOutline style={{ fontSize: '3rem' }} />}
                style={{ minHeight: '20vh' }}
                title="Desactivar empleados"
                to={`/disable-employees/${clientId}`}
              />
            ) : null}

            {myGet(globalPermissions, 'employeeReentryModule.enabled', false) ? (
              <SquareButton
                icon={<SyncAltOutlined style={{ fontSize: '3rem' }} />}
                style={{ minHeight: '20vh' }}
                title="Reactivar empleados"
                to={`/reentry-employees/${clientId}`}
              />
            ) : null}

            {myGet(globalPermissions, 'bankAccountsModule.enabled', false) ? (
              <SquareButton
                icon={<AccountBalance style={{ fontSize: '3rem' }} />}
                style={{ minHeight: '20vh' }}
                title="Cuentas de banco"
                to={`/bank-accounts/${clientId}`}
              />
            ) : null}

            {myGet(globalPermissions, 'periodsModule.enabled', false) ? (
              <SquareButton
                icon={<CalendarToday style={{ fontSize: '3rem' }} />}
                style={{ background: '#ffaa6b', minHeight: '20vh' }}
                title="Periodos y archivos"
                to={`/periods/${clientId}`}
              />
            ) : null}

            <SquareButton
              icon={<ChatOutlined style={{ fontSize: '3rem' }} />}
              style={{ minHeight: '20vh' }}
              title='Ayuda y Soporte'
              to='/support'
            />
          </Shelfs>
        </Grid>
        <Grid item md={3} xs={12}>
          <NotificationList />
        </Grid>
      </Grid >
    </>
  );
};

export default Home;
