import { Column } from '../../../types';

const columns: Column[] = [
  {
    field: 'choosed',
    title: '',
    type: 'check',
    isEnabled: true,
    defaultValue: false,
  },
  {
    field: 'key',
    title: 'Clave',
    pathValue: 'key',
  },
  {
    field: 'fullName',
    title: 'Nombre completo',
    pathValue: ['name', 'firstLastName', 'secondLastName'],
    separator: ' ',
    width: '400px',
  },
  {
    field: 'entryDate',
    title: 'Fecha de ingreso',
    type: 'date',
    pathValue: 'dateOfFirstEntry',
  },
  {
    field: 'ternureDate',
    title: 'Fecha de baja',
    type: 'date',
    pathValue: 'dismissDate',
  },
  {
    field: 'payrollTypeId',
    title: 'Nóminas',
    type: 'select',
  },
  {
    field: 'reentryDate',
    title: 'Fecha de reingreso',
    type: 'date',
  },
];

export default columns;
