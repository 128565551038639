/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getGetRequest } from '../../helpers';
import { Client } from '../../types';

export interface ClientsState {
  count: number;
  entities: Record<string, Client>;
  isLoading: boolean;
  selected: string;
}

const initialState: ClientsState = {
  count: 0,
  entities: {},
  isLoading: false,
  selected: localStorage.getItem('selected_client') || '',
};

export const fetchClients = createAsyncThunk(
  'getClients/fetchClients',
  async () => {
    const { data: dataResponse } = await getGetRequest(
      'operationalcontact/get-clients'
    );
    const { data } = dataResponse;
    return data;
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    cleanClients: (state) => {
      state.count = 0;
      state.entities = {};
    },
    setSelectedClient: (state, action: PayloadAction<string | undefined>) => {
      const { payload } = action;
      state.selected = payload || '';
      localStorage.setItem('selected_client', state.selected);
      // const client = payload === undefined ? undefined : state.entities[payload];
      // if (client !== undefined && payload !== undefined) {
      //   state.selected = payload;
      //   localStorage.setItem('selected_client', payload);
      //   return;
      // }
      // state.selected = '';
      // localStorage.setItem('selected_client', '');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchClients.fulfilled,
        (state, action: PayloadAction<Client[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
          // if (state.selected === '' || state.entities[state.selected] === undefined) {
          //   state.selected = payload[0].id;
          //   localStorage.setItem('selected_client', payload[0].id);
          // }
        }
      )
      .addCase(fetchClients.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanClients, setSelectedClient } = clientsSlice.actions;

export default clientsSlice.reducer;
