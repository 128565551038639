import { MutableRefObject, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import * as chrono from 'chrono-node';
import _ from 'lodash';

import {
  cleanMoneyValue,
  findOption,
  myOmitByRecursively,
  normalizeString,
} from '../../helpers'
import {
  Cell,
  CellUpdate,
  Change,
  EntitiesState,
  FormatedChange,
  HandleChanges,
  ObjectMap,
  Option,
} from '../../types';
import useStateLocalStorage from '../useStateLocalStorage';

type UseDataSheetChanges = {
  changedGrid: Cell[][];
  changes: EntitiesState;
  handleChanges: HandleChanges;
  cleanChanges: () => void;
  cleanCellChange: (rowId: string, colId: string) => void;
  cleanRowChanges: (rowId: string) => void;
  cleanMultipleRowChanges: (rowIds: string[]) => void;
  cleanNonRenderedChanges: () => void;
  removeChangesStorage: () => void;
  slideChangesUp: () => void;
};

const useDataSheetChanges = (
  rowsGrid: Cell[][],
  rowsMapRef: MutableRefObject<ObjectMap>,
  colsMapRef: MutableRefObject<ObjectMap>,
  updateCellAtributes: (
    row: string,
    field: string,
    nextAtributtes: CellUpdate
  ) => void,
  cleanCellUpdate: (rowId: string, colId: string, attributeKey: string) => void,
  cleanCellUpdates: (rowId: string, colId: string) => void,
  swapUpdatesBetweenRows: (swaps: {from: string, to: string}[]) => void,
  discardNonRenderer?: boolean,
  localStorageId?: string,
): UseDataSheetChanges => {
  const { pathname } = useLocation();
  const [
    changes = {},
    setChanges,
    removeChangesStorage,
  ] = useStateLocalStorage<EntitiesState>(`useDataSheetChanges_changes${pathname}${localStorageId}`, {});

  const changedGrid = useMemo(() => {
    const nextChangedGrid = rowsGrid.map((rowGrid) => [...rowGrid]);

    Object.values(changes).forEach((entity) => {
      const { id: entityId, ...entityChanges } = entity;
      Object.keys(entityChanges).forEach((field) => {
        const rowIndex = rowsMapRef.current[entityId];
        const colIndex = colsMapRef.current[field];
        if (rowIndex === undefined || colIndex === undefined) return;
        const className = _.get(nextChangedGrid, `${rowIndex}.${colIndex}.className`) || 'edited';
        nextChangedGrid[rowIndex][colIndex] = {
          ...nextChangedGrid[rowIndex][colIndex],
          value: entityChanges[field],
          className,
        };
      });
    });

    return nextChangedGrid;
  }, [rowsGrid, changes, rowsMapRef, colsMapRef]);

  const handleChanges = (edits: Change[]) => {
    // if (DataSheetRef.current?._cache?.clearAll) {
    //   DataSheetRef.current._cache.clearAll();
    // }
    // const clearCacheMeasurerCell = DataSheetRef.current?._cache?.clear;
    // Formating changes
    const filteredChanges = edits.filter(({ value, cell, row, col }) => {
      const rowIndex = typeof row === 'string' ? rowsMapRef.current[row] : row;
      if (rowIndex === undefined) return false;
      const colIndex = typeof col === 'string' ? colsMapRef.current[col] : col;
      if (colIndex === undefined) return false;
      return value !== undefined && value !== null && cell?.type !== 'button'
    });
    const cellsUpdatesMap: Record<string, CellUpdate> = {};
    const formatedChanges: FormatedChange[] = filteredChanges
      .map((change) => {
        const {
          value, row, col
        } = change;
        const rowIndex = typeof row === 'string' ? rowsMapRef.current[row] : row;
        const colIndex = typeof col === 'string' ? colsMapRef.current[col] : col;
        const cell: Cell = {
          ...changedGrid[rowIndex][colIndex],
          ...change.cell,
          onClick: undefined,
        };
        if (_.keys(change.cell).length < 5) {
          cellsUpdatesMap[change.cell?.id || ''] = change.cell || {};
        }
        const formatedChange: FormatedChange = {
          cell,
          row: rowIndex,
          col: colIndex,
          value,
        };
        // if (clearCacheMeasurerCell) {
        //   clearCacheMeasurerCell(rowIndex + 1, colIndex);
        // }
        const { type } = cell;
        if (type === 'money') {
          formatedChange.value = cleanMoneyValue(value);
        }
        if (type === 'select') {
          const { options = [] } = cell;
          const option: Option = typeof value === 'object' && !Array.isArray(value) && value !== null
            ? value as Option
            : findOption(`${value}`, options);
          const { value: optionValue, label } = option;
          // if (label.length > 0) {
          //   updateCellAtributes(id, field, { label });
          // } else {
          //   cleanCellUpdate(id, field, 'label');
          // }
          formatedChange.cell.label = label;
          formatedChange.value = optionValue;
        }
        if ((type === 'boolean' || type === 'check') && typeof value !== 'boolean') {
          formatedChange.value = normalizeString('no') !== normalizeString(`${value}`);
        }
        if (type === 'date' && typeof value === 'string') {
          formatedChange.value = chrono.es.parseDate(value);
        }
        if (type === 'file' && typeof value === 'string') {
          formatedChange.value = '';
        }
        return formatedChange;
      });

    setChanges((prevChanges) => {
      let nextChanges: EntitiesState = { ...prevChanges };
      if (discardNonRenderer) {
        const fields = Object.values(rowsMapRef.current);
        nextChanges = _.pick(prevChanges, fields) as EntitiesState;
      }

      formatedChanges.forEach(({ value, cell }) => {
        const { initialValue, field, id: entityId, className, cleanOnChange, cleanValuesOnChange } = cell;

        cleanOnChange?.forEach((fieldToClean) => {
          if (nextChanges[entityId]) {
            delete nextChanges[entityId][fieldToClean];
          }
          cleanCellUpdates(entityId, fieldToClean);
        });

        cleanValuesOnChange?.forEach((fieldToClean) => {
          if (nextChanges[entityId]) {
            delete nextChanges[entityId][fieldToClean];
          }
        });

        if (cellsUpdatesMap[cell.id]) {
          if (className === 'error') {
            updateCellAtributes(entityId, field, { className });
          }
        } else {
          cleanCellUpdate(entityId, field, 'className');
        }
        nextChanges[entityId] = {
          ...(nextChanges[entityId] && nextChanges[entityId]),
          id: entityId,
          [field]: value === initialValue ? '' : value,
        };
      });
      const cleanedNullableValuesChanges = myOmitByRecursively(nextChanges, (val) => {
        if (typeof val === 'string' && val.trim() === '') return true;
        return _.isNil(val);
      }) as EntitiesState;
      const cleanedEmptyChanges = { ...cleanedNullableValuesChanges };
      Object.keys(cleanedEmptyChanges).forEach((entityId) => {
        if (Object.values(cleanedEmptyChanges[entityId]).length <= 1) {
          delete cleanedEmptyChanges[entityId];
        }
      });
      return cleanedEmptyChanges;
    });
  };

  const cleanChanges = useCallback(() => setChanges({}), [setChanges]);

  const cleanCellChange = useCallback((rowId: string, colId: string) => {
    setChanges((prevChanges) => {
      const nextChanges = { ...prevChanges };

      if (nextChanges[rowId]) {
        delete nextChanges[rowId][colId];
      }

      return nextChanges;
    });
  }, [setChanges]);

  const cleanRowChanges = useCallback((rowId: string) => {
    setChanges((prevChanges) => {
      const nextChanges = { ...prevChanges };

      delete nextChanges[rowId];

      return nextChanges;
    });
  }, [setChanges])

  const cleanMultipleRowChanges = useCallback((rows: string[]) => {
    setChanges((prevChanges) => {
      const nextChanges = { ...prevChanges };

      rows.forEach((rowId) => {
        delete nextChanges[rowId];
      });

      return nextChanges;
    });
  }, [setChanges]);

  const cleanNonRenderedChanges = useCallback(() => {
    setChanges((prevChanges) => {
      const fields = Object.values(rowsMapRef.current);
      const nextChanges: EntitiesState = _.pick(prevChanges, fields) as EntitiesState;
      return nextChanges;
    });
  }, [rowsMapRef, setChanges]);

  // const spliceRowChanges = useCallback((start: GridKey) => {}, []);
  // const spliceManyRowChanges = useCallback((start: GridKey[]) => {}, []);
  const slideChangesUp = useCallback(() => {
    setChanges((prevChanges = {}) => {
      const nextChanges:EntitiesState = {};
      const updatesToSwap: {from: string, to: string}[] = [];

      Object.values(prevChanges).forEach(({ id, ...entityChanges }, index) => {
        const targetId = rowsMapRef.current[index] || id;
        nextChanges[targetId] = { id: targetId, ...entityChanges };
        if (targetId !== id) {
          updatesToSwap.push({ from: id, to: targetId });
        }
      });

      if (updatesToSwap.length > 0) {
        swapUpdatesBetweenRows(updatesToSwap);
      }

      return nextChanges;
    });
  }, [rowsMapRef, setChanges, swapUpdatesBetweenRows]);

  return {
    changedGrid,
    changes,
    handleChanges,
    cleanChanges,
    removeChangesStorage,
    cleanCellChange,
    cleanRowChanges,
    cleanMultipleRowChanges,
    cleanNonRenderedChanges,
    slideChangesUp,
  };
};

export default useDataSheetChanges;
