/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { Check, Close } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';

import Base from './Base';
import { StepProps } from './types';

function Status({
  title,
  messages,
  failed,
}: {
  title: string;
  messages?: string[];
  failed?: boolean;
}) {
  const { spacing } = useTheme();

  let icon = <Check color="success" />;
  if (failed) icon = <Close color="error" />;

  const textColor = failed ? 'error' : 'green';

  return (
    <div
      style={{
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
        marginBottom: spacing(1),
        display: 'flex',
      }}
    >
      {icon}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: spacing(1),
        }}
      >
        <Typography color={textColor}>{title}</Typography>
        {messages instanceof Array &&
          messages.map((message, index) => (
            <Typography
              color={textColor}
              key={index.toString()}
              variant="caption"
            >
              {message}
            </Typography>
          ))}
      </div>
    </div>
  );
}

function Errors({ error, message }: { error: any; message: string }) {
  let messages: string[] = [];

  if (error instanceof Array) {
    messages = error.map((e) => e.message);

    return <Status failed messages={messages} title={message} />;
  }

  if (typeof error === 'object') {
    const { errors } = error;

    if (errors instanceof Array) messages = errors.map((e) => e.message);

    return (
      <Status failed messages={messages} title={error.message || message} />
    );
  }

  return null;
}

export interface SummaryProps extends StepProps {
  errors: any;
  successMessage: string;
  errorMessage: string;
}

function Summary({
  errors,
  title,
  successMessage,
  errorMessage,
}: SummaryProps) {
  return (
    <Base title={title}>
      <List disablePadding>
        {errors ? (
          <Errors error={errors} message={errorMessage} />
        ) : (
          <Status title={successMessage} />
        )}
      </List>
    </Base>
  );
}

export default Summary;
