/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { getPostRequest } from '../../helpers';
import { State, StateFilter } from '../../types';

export interface StatesState {
  count: number;
  entities: Record<string, State>;
  isLoading: boolean;
}

const initialState: StatesState = {
  count: 0,
  entities: {},
  isLoading: false,
};

export const fetchStates = createAsyncThunk(
  'getStates/fetchStates',
  async (filters: StateFilter) => {
    const { data: dataResponse, status } = await getPostRequest(
      'v2/state/all',
      filters
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    cleanStates: (state) => {
      state.count = 0;
      state.entities = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchStates.fulfilled,
        (state, action: PayloadAction<State[]>) => {
          const { payload } = action;
          state.isLoading = false;
          if (payload) {
            state.count = payload.length;
            state.entities = _.keyBy(payload, 'id');
          }
        }
      )
      .addCase(fetchStates.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanStates } = statesSlice.actions;

export default statesSlice.reducer;
