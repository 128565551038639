import { useCallback } from 'react';
import { useToggle } from 'react-use';

import { getPostRequest } from 'helpers';
import _ from 'lodash';
import { deleteClientPendingTask, showMessage } from 'store/slices';
import { ClientPendingTaskTitle, Entity } from 'types';

import { useAppDispatch, useAppSelector } from './redux';

const generateEmployeesIdParameters = {
  operationName: 'GenerateEmployeesID',
  query: 'mutation GenerateEmployeesID {  generateEmployeeID}',
  variables: {},
};

const operationName = 'banck';

const query = 'mutation banck($input: BankAccountInput) {  bankAccountAdd(input: $input) {    id    __typename  }}';

type Config = {
  societyBranch?: string;
  payrollType?: string;
  contractType?: string;
  employees: Record<string, Entity>;
  maxAmount?: number;
};

const useSaveEmployees = ({
  employees,
  contractType,
  societyBranch,
  payrollType,
  maxAmount,
}: Config): [isSaving: boolean, onSave: () => Promise<boolean>] => {
  const [isSaving, toggleIsSaving] = useToggle(false);
  const dispatch = useAppDispatch();

  const client = useAppSelector(({ clients }) => clients.selected);

  const onSave = useCallback(async (): Promise<boolean> => {
    if (!societyBranch) {
      dispatch(showMessage({
        message: 'Debe seleccionar una sucursal',
        type: 'error',
      }));
      return false;
    }
    if (!payrollType) {
      dispatch(showMessage({
        message: 'Debe seleccionar un tipo de nómina',
        type: 'error',
      }));
      return false;
    }
    if (!contractType) {
      dispatch(showMessage({
        message: 'Debe seleccionar un tipo de contrato',
        type: 'error',
      }));
      return false;
    }
    toggleIsSaving(true);
    const employeesArr = _.values(employees);
    const allInputs = employeesArr.map((employee) => ({
      active: true,
      civilStatus: _.get(employee, 'civilStatus'),
      client,
      contractTypes: [{
        contractType,
        expiresOn: 0,
        isActive: true,
      }],
      curp: _.get(employee, 'curp'),
      customKey: _.get(employee, 'clientNumberEmployee'),
      dateOfFirstEntry: _.get(employee, 'firstEntryDate'),
      daysType: _.get(employee, 'workdayType', ''),
      department: _.get(employee, 'department'),
      email: _.get(employee, 'email'),
      firstLastName: _.get(employee, 'firstLastName'),
      imss: _.get(employee, 'imss'),
      incorporation: _.get(employee, 'entryDate'),
      job: _.get(employee, 'job'),
      jobInformation: {
        address: {
          municipality: _.get(employee, 'municipalityWhereWork'),
          state: _.get(employee, 'stateWhereWork'),
          street: _.get(employee, 'addressWhereWork'),
          zipCode: _.get(employee, 'cpWhereWork'),
        },
      },
      name: _.get(employee, 'name'),
      nationality: _.get(employee, 'nacionality'),
      paymentMethod: _.get(employee, 'paymentMethod'),
      payrollBranch: societyBranch,
      payrollType,
      personalInformation: {
        address: {
          colony: _.get(employee, 'colony'),
          country: _.get(employee, 'country'),
          interiorNumber: _.get(employee, 'insideNumber'),
          municipality: _.get(employee, 'municipality'),
          number: _.get(employee, 'outsideNumber'),
          state: _.get(employee, 'state'),
          street: _.get(employee, 'address'),
          zipCode: _.get(employee, 'cp'),
        },
      },
      rfc: _.get(employee, 'rfc'),
      secondLastName: _.get(employee, 'secondLastName'),
      tenureDate: _.get(employee, 'antiqueDate'),
      type: _.get(employee, 'employeeType'),
    }));

    const inputs = maxAmount === undefined ? allInputs : allInputs
      .filter((_input, index) => index < maxAmount);

    const employeesMaped = _.keyBy(employeesArr, ({ curp, imss, rfc }) => `${curp}${imss}${rfc}`);

    try {
      const addEmployeesResponse = await getPostRequest('v2/employee/add', { inputs });
      const employeesData: Record<string, unknown>[] = _.get(addEmployeesResponse, 'data.data.data', []);
      const employeesErrors: string[] = _.get(addEmployeesResponse, 'data.data.errors', []) || [];
      if (employeesErrors.length > 0) {
        dispatch(showMessage({
          message: employeesErrors,
          type: 'error',
        }));
        toggleIsSaving(false);
  
        dispatch(deleteClientPendingTask({
          clientId: {
            eq: client,
          },
          title: {
            eq: ClientPendingTaskTitle.CPTTi_EMPLOYEES_REGISTRATION
          },
        }));
        return false;
      }

      const bankAccountPromises = employeesData
        .map((employeeData) => {
          const id = _.get(employeeData, 'id', '');
          const curp = _.get(employeeData, 'curp', '');
          const imss = _.get(employeeData, 'imss', '');
          const rfc = _.get(employeeData, 'rfc', '');
          const employee = _.get(employeesMaped, `${curp}${imss}${rfc}`, null);
          if (!employee) return null;
  
          const bankParameters = {
            operationName, query,
            variables: {
              input: {
                accountNumber: _.get(employee, `bankAccountAccountNumber`),
                bank: _.get(employee, `bank`),
                branchNumber: _.get(employee, `bankAccountBranchNumber`),
                cardDefault: true,
                CLABE: _.get(employee, `clabe`),
                description: _.get(employee, `bank`),
                employee: id,
              },
            },
          };
          const {
            variables: {
              input: {
                accountNumber, bank, branchNumber, CLABE,
              },
            },
          } = bankParameters;
          if (accountNumber || bank || branchNumber || CLABE) {
            return getPostRequest('', bankParameters);
          }
          return null;
        });
      await Promise.all(bankAccountPromises);
      await getPostRequest('', generateEmployeesIdParameters);
      dispatch(showMessage({
        message: 'Los empleados fueron guardados correctamente',
        type: 'success',
      }));
    } catch (error) {
      return false;
    }
    toggleIsSaving(false);
    return true;
  }, [client, contractType, dispatch, employees, maxAmount, payrollType, societyBranch, toggleIsSaving]);

  return [isSaving, onSave];
};

export default useSaveEmployees;
