/* eslint-disable react/no-array-index-key */
import { Typography, useTheme } from '@mui/material';

export interface BaseProps {
  children: React.ReactNode;
  actions?: React.ReactNode[];
  contentContainerStyle?: React.CSSProperties;
  title:
  | string
  | {
    primary: string;
    secondary?: string;
  };
}

export default function Base({
  children,
  title,
  actions = [],
  contentContainerStyle = {},
}: BaseProps) {
  const { spacing } = useTheme();

  const showActions = actions.length > 0;

  const primary = typeof title === 'string' ? title : title.primary;
  const secondary = typeof title === 'string' ? undefined : title.secondary;

  return (
    <div>
      <div
        style={{
          padding: spacing(3),
          paddingBottom: 0,
        }}
      >
        <Typography fontWeight="bold" variant="h5">
          {primary}
        </Typography>
        {secondary ? <Typography variant="h6">{secondary}</Typography> : null}
      </div>
      <div
        style={{
          paddingTop: spacing(3),
          paddingBottom: spacing(3),
          maxHeight: 500,
          overflowY: 'auto',
          ...contentContainerStyle,
        }}
      >
        {children}
      </div>
      {showActions ? (
        <div
          style={{
            padding: spacing(3),
            paddingTop: 0,
            display: 'flex',
            flexWrap: 'wrap',
            gap: spacing(2),
          }}
        >
          {actions.map((action, index) => (
            <div key={index.toString()} style={{ flex: 1 }}>
              {action}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

Base.defaultProps = {
  actions: [],
  contentContainerStyle: {},
}

