import { PayrollScheme } from './periods';
import { Pagination, TimeRank, WhereClause } from './request';

export interface PayrollType {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  Client: string;
  Company: string;
  DeletedAt: null;
  isSuperPayroll: boolean;
  superPayroll: null | string;
  name: string;
  RRule: string;
  taxSchema: TaxSchema;
  PeriodCalculation: string;
  ISRCalculation: boolean;
  ISRExents: string;
  AutomaticWage: boolean;
  PayrollSchemaType: string | null;
  holidays: string;
  HolidayBonus: number;
  HolidayBonusDays: number;
  MandatoryPerceptionModifiers: null;
  OptionalPerceptionModifiers: null;
  frequency: string;
  frequencyType: number;
  payrollDeliveryDay: boolean;
  isInterbank: boolean;
  isHighDirection: boolean;
  key: number;
  dispersingCompany: string;
  dispersingCompanyNOM035: string;
  billingCompany: string;
  customerDepositBankAccount: string;
  dispersionBankAccounts: string[] | null;
  dispersionBankAccountsNOM035: string[] | null;
  nominista: string;
  adc: string;
  dispersador: string;
  contractStartAt: Date;
  contractEndAt: Date;
  regimeType: number;
  calculationSettings: CalculationSettings;
  cfdiConcept: string;
  payMethod: string;
  operationalContacts: OperationalContacts;
}

export interface CalculationSettings {
  imss: Commission;
  isr: Commission;
  iva: Commission;
  commission: Commission;
  isn: Commission;
  costoSocial: Commission;
}

export interface Commission {
  defaultPayer: number;
  individual: string | null;
}

export interface OperationalContacts {
  prenomina: Factura[];
  nomina: Factura[];
  facturas: Factura[];
  recibosNomina: Factura[];
}

export interface Factura {
  id: string;
  name: string;
  email: string;
  address: string;
  phoneNumber: string;
}

export enum TaxSchema {
  Asimilados = "Asimilados",
  Empty = "",
  Ppps = "PPPS",
  ServiciosEspecializados = "Servicios especializados",
  ServiciosEspecializadosMásNOM035 = "Servicios especializados más NOM035",
  Sys = "SYS",
  SysPpps = "SYS + PPPS",
  Tradicional = "Tradicional",
}

export type PayrollTypeFilters = {
  wcId?: WhereClause;
  wcCompanyId?: WhereClause;
  wcClientId?: WhereClause;
  wcFrequencyId?: WhereClause;
  wcKey?: WhereClause;
  wcName?: WhereClause;
  wcSchema?: WhereClause;
  wcRegimeType?: WhereClause;
  wcFrequencyType?: WhereClause;
  wcADCId?: WhereClause;
  wcNoministaId?: WhereClause;
  wcDispersadorId?: WhereClause;
  wcResponsableId?: WhereClause;
  wcSuperPayrollId?: WhereClause;
  wcDispersingCompanyId?: WhereClause;
  fetchChildPayrolls?: boolean;
  id?: string;
  client?: string;
  payrollSchemaType?: string;
  societyID?: string;
  society?: string;
  frequency?: string;
  contractStartAt?: TimeRank;
  contractEndAt?: TimeRank;
  isSuperPayroll?: boolean;
  superPayroll?: string;
  isAssignedToSuperPayroll?: boolean;
  societyBranch?: string;
  accumulationMonthRank?: TimeRank;
  pagination?: Pagination;
  sort?: Map<string, any>;
};

type ID = string;

export type OperationalContact = {
  id: ID,
  name: string,
  email: string,
  address: string,
  phoneNumber: string,
};

export type PayrollTypeAddVariables = {
  client: ID,
  operationalContacts: {
    nomina: OperationalContact[],
    prenomina: OperationalContact[],
    facturas: OperationalContact[],
    recibosNomina: OperationalContact[],
  },
  periodCalculation: number,
  holidayBonus: number,
  bonus: number,
  frequency: ID,
  isSuperPayroll: boolean,
  payrollDeliveryDay: boolean,
  cfdiConcept?: ID,
  taxSchema: PayrollScheme,
  holidays: ID,
  isHighDirection: boolean,
  isInterbank: boolean,
  customerDepositBankAccountID: ID,
  dispersingCompanyID: ID,
  dispersingCompanyNOM035: ID | null,
  billingCompanyID: ID,
  name: string,
  payMethod?: ID,
  dispersionBankAccountsIDs: ID[],
  dispersionBankAccountsNOM035: ID[],
  noministaID?: ID,
  adcID?: ID,
  dispersadorID?: ID,
  contractStartAt: Date,
  key: string,
  frequencyType?: string,
  calculationSettings: {
    imss: {
      defaultPayer: 'Cliente' | 'Empleado',
    },
    costoSocial: {
      defaultPayer: 'Cliente' | 'Empleado',
    },
    iva: {
      defaultPayer: 'Cliente' | 'Empleado',
    },
    isr: {
      defaultPayer: 'Cliente' | 'Empleado',
    },
    isn: {
      defaultPayer: 'Cliente' | 'Empleado',
    },
    commission: {
      defaultPayer: 'Cliente' | 'Empleado',
    }
  }
};
