/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPostRequest } from '../../helpers';

export interface CivilStatusState {
  count: number;
  entities: string[];
  isLoading: boolean;
}

const initialState: CivilStatusState = {
  count: 0,
  entities: [],
  isLoading: false,
};

export const fetchCivilStatus = createAsyncThunk(
  'getCivilStatus/fetchCivilStatus',
  async () => {
    const { data: dataResponse, status } = await getPostRequest(
      'v2/civilstatus/all',
      null
    );
    const { data, errors } = dataResponse;
    if (status === 400 && Array.isArray(errors)) {
      throw new Error(errors.map(({ detail }) => detail).join('\n\n'));
    }
    if ((status === 201 || status === 200) && !errors) {
      return data;
    }
    return errors;
  }
);

export const civilStatusSlice = createSlice({
  name: 'civilStatus',
  initialState,
  reducers: {
    cleanCivilStatus: (state) => {
      state.count = 0;
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCivilStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchCivilStatus.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          const { payload } = action;
          state.isLoading = false;
          state.count = payload.length;
          state.entities = payload;
        }
      )
      .addCase(fetchCivilStatus.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { cleanCivilStatus } = civilStatusSlice.actions;

export default civilStatusSlice.reducer;
