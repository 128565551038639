import { EmployeesServiceErrorCodes, EmployeesServiceMessages } from '../constants';
import { FullEmployeee } from '../types';

export class EmployeesServiceError extends Error {
  employee: FullEmployeee | null = null;

  errors: EmployeesServiceError[] = [];

  constructor(
    public code: EmployeesServiceErrorCodes,
    extra: {
      message?: string;
      employee?: FullEmployeee | null;
      errors?: EmployeesServiceError[];
    } = {},
  ) {
    super(`${extra.message || EmployeesServiceMessages[code]} (Codigo: ${code}).`);
    this.name = 'DisableEmployeesError';

    if (extra.employee)
      this.employee = extra.employee;

    if (extra.errors)
      this.errors = extra.errors;
  }
}
