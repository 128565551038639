import { FC, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from '../../hooks';
import Loader from '../../pages/Loader';
import {
  closeMessage,
  fetchClients,
  fetchPayrollsTypes,
  loginWithToken,
  logout,
} from '../../store/slices';
import Header from './Header';
import PageContainer from './PageContainer';
import './index.css';

const Alert = forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    {...props}
  />
)
);

interface Props {
  children: JSX.Element,
};

export const mainTheme = createTheme({
  palette: {
    background: {
      default: '#f3f3f3',
    },
  },
  components: {
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          background: '#f5bd50',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          // fontSize: '3rem',
          // fontSizeLarge: '3rem',
          // fontSize
        },
        // fontSizeLarge: 300,
      },
    },
  },
});

export type ITheme = typeof mainTheme;

const Layout: FC<Props> = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { status } = useAppSelector(({ session }) => session);
  const clientID = useAppSelector(({ clients }) => clients.selected);
  const {
    horizontal,
    vertical,
    open,
    type,
    message,
    autoHideDuration,
  } = useAppSelector(({ snackbarMessage }) => snackbarMessage);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoading = status === 'loading';

  const handleClose = () => {
    dispatch(closeMessage());
  };

  useEffectOnce(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(loginWithToken());
    } else {
      dispatch(logout());
    }
  });

  useUpdateEffect(() => {
    if (status !== 'logedIn' && pathname !== '/login') {
      navigate('/login');
    }
  }, [status, pathname, navigate]);

  useUpdateEffect(() => {
    if (status === 'logedIn')
      dispatch(fetchClients());
  }, [status]);

  useUpdateEffect(() => {
    if (clientID !== '')
      dispatch(fetchPayrollsTypes({ client: clientID }));
  }, [clientID]);

  return (
    <ThemeProvider theme={mainTheme}>
      <div className='app__container'>
        {isLoading && (<Loader />)}
        {!isLoading && (
          <>
            <Header />
            <PageContainer>
              <>
                {children}
              </>
            </PageContainer>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              autoHideDuration={autoHideDuration}
              onClose={handleClose}
              open={open}
            >
              <Alert
                onClose={handleClose}
                severity={type}
                style={{ whiteSpace: 'break-spaces' }}
                sx={{ width: '100%' }}
              >
                {message}
              </Alert>
            </Snackbar>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};
export default Layout;
