import { FC } from 'react';
import ReactFileViewer from 'react-file-viewer';
import { useToggle } from 'react-use';

import PreviewIcon from '@mui/icons-material/Preview';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import { Cell, UploadFile } from '../../types';

type Props = {
  file: UploadFile | null,
  cell: Cell,
};

const FileViewer: FC<Props> = ({ file, cell }) : JSX.Element => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);

  if (file === null) return (<strong>Sin archivo seleccionado</strong>);

  const url = file.presignedURL.includes('?') ? file.presignedURL.split('?')[0] : file.presignedURL;
  const type = url.split('.').pop();
  let iconColor: | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning' = 'inherit';
  if (cell.className === 'edited') {
    iconColor = 'success';
  }
  if (cell.className === 'error') {
    iconColor = 'error';
  }
  if (cell.className === '') {
    iconColor = 'info'
  }

  return (
    <>
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <IconButton onClick={toggleIsModalOpen}>
          <PreviewIcon color={iconColor} fontSize='medium' />
        </IconButton>
      </div>
      <Dialog
        fullWidth
        maxWidth='xl'
        onClose={toggleIsModalOpen}
        open={isModalOpen}
      >
        <DialogTitle>Evidencias</DialogTitle>
        <DialogContent>
          <ReactFileViewer
            // errorComponent={CustomErrorComponent}
            filePath={url}
            fileType={type}
            // onError={this.onError}
          />
        </DialogContent>
        <DialogActions><Button onClick={toggleIsModalOpen}>Cerrar</Button></DialogActions>
      </Dialog>
    </>
  );
};

export default FileViewer;
