/* eslint-disable prefer-destructuring */
import { useCallback } from 'react';

import { SelectedEmployees } from '../store';
import { selectEmployees, setEmployeesLoading } from '../store/slices';
import {
  fetchPeriod,
  fetchPeriodEmployees,
  savePrepayroll,
} from '../store/thunks';
import { PayrollPeriodType, PayrollScheme } from '../types';
import { Pagination } from '../types/request';
import { useAppDispatch, useAppSelector } from './redux';

export default function usePeriod() {
  const slice = useAppSelector((state) => state.period);
  const { employees, period } = slice;
  const selectedEmployeesById = employees.selected;
  const selectedEmployeesEmpty =
    Object.keys(selectedEmployeesById).length === 0;

  const dispatch = useAppDispatch();

  const handleFetchPeriod = useCallback(
    async (params: {
      payrollPeriod: string;
      employeePagination?: Pagination;
      fetchEmployees?: boolean;
      type?: string;
    }) => dispatch(fetchPeriod(params)),
    [dispatch]
  );

  const handleFetchPeriodEmployees = useCallback(
    async (params: {
      payrollPeriod: string;
      pagination: Pagination;
      taxSchema?: string;
      workedDays: number;
      type?: string;
    }) => dispatch(fetchPeriodEmployees(params)),
    [dispatch]
  );

  const fetchCurrentEmployees = useCallback(
    () =>
      dispatch(
        fetchPeriodEmployees({
          payrollPeriod: period.id,
          taxSchema: period.payrollSchema,
          workedDays: period.workedDays,
          type: period.type,
        })
      ),
    [dispatch, period]
  );

  const handleSelectEmployees = useCallback(
    (newSelectedEmployees: SelectedEmployees) =>
      dispatch(selectEmployees(newSelectedEmployees)),
    [dispatch]
  );

  type SavePrepayrollParams = Parameters<typeof savePrepayroll>;

  const handleSavePrepayroll = useCallback(
    (...params: SavePrepayrollParams) => dispatch(savePrepayroll(...params)),
    [dispatch]
  );

  const saveCurrentPrepayroll = useCallback((selectedEmployees: any[]) => {
    const payrollPeriod = period.id;
    const taxSchema = period.payrollSchema;
    const type = period.type;

    return handleSavePrepayroll({
      payrollPeriod,
      employees: selectedEmployees,
      taxSchema: taxSchema as PayrollScheme,
      type: type as PayrollPeriodType,
    });
  }, [handleSavePrepayroll, period.id, period.payrollSchema, period.type]);

  const handleSetEmployeesLoading = useCallback(
    (loading: boolean) => dispatch(setEmployeesLoading(loading)),
    [dispatch]
  );

  const removeAllSelectedEmployees = useCallback(() => {
    handleSelectEmployees({});
  }, [handleSelectEmployees]);

  return {
    ...slice,
    taxSchema: period.payrollSchema,
    selectedEmployeesById,
    selectedEmployeesEmpty,
    fetchPeriod: handleFetchPeriod,
    fetchPeriodEmployees: handleFetchPeriodEmployees,
    fetchCurrentEmployees,
    selectEmployees: handleSelectEmployees,
    savePrepayroll: handleSavePrepayroll,
    saveCurrentPrepayroll,
    setEmployeesLoading: handleSetEmployeesLoading,
    removeAllSelectedEmployees,
    periodCalculated: period.calculated,
  };
}
