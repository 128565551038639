import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@mui/material';

import { HelmetRenderer } from '../components';

interface Props {
  title?: string;
  path?: string;
}

const NotFound: FC<Props> = ({ title, path }): JSX.Element => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <HelmetRenderer path={path || ''} />
    <div
      style={{
        textAlign: 'center',
      }}
    >
      <Typography
        style={{
          marginBottom: '2rem',
        }}
        variant="h4"
      >
        {title}
      </Typography>
      <Link to="/">
        <Button
          color="info"
          fullWidth
          size="large"
          style={{
            width: '10rem',
            padding: '10px',
          }}
          variant="contained"
        >
          Inicio
        </Button>
      </Link>
    </div>
  </div>
);

NotFound.defaultProps = {
  title: 'Página no encontrada',
  path: '*',
};

export default NotFound;
