import { useEffect, useState } from 'react';

import _ from 'lodash';

import { PayrollCalculationBilling } from '../../../types';
import { useAppSelector } from '../../redux';
import useDataSheet from '../../useDataSheet';
import columns from './columns';

const useCalculationPayrollBillingDatasheet = () => {
  const { billing, isBillingLoading, } = useAppSelector(({ payrollCalculation }) => payrollCalculation);
  const client = useAppSelector(({ clients }) => {
    const { selected, entities } = clients;
    return _.get(entities, selected, null);
  });

  const [data, setData] = useState<PayrollCalculationBilling[]>([]);

  const {
    grid,
    changes,
    handleChanges,
    entities: employees,
    updateColumnAtributes,
    ValueRenderer,
    validate,
  } = useDataSheet(columns, data, {
    defaultIsEnable: false,
    transpose: true,
    id: 'billing',
  });

  useEffect(() => {
    if (billing !== null) {
      setData([billing]);
    } else {
      setData([]);
    }
  }, [billing]);

  useEffect(() => {
    const clientName: string = _.get(client, 'businessName', '');
    updateColumnAtributes('client', {
      defaultValue: clientName,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return {
    grid,
    changes,
    handleChanges,
    ValueRenderer,
    employees,
    validate,
    isLoading: isBillingLoading,
  };
};

export default useCalculationPayrollBillingDatasheet