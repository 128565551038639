export const bankAccountAdd = `
mutation bankAccountAdd($input: BankAccountInput) {
  bankAccountAdd(input: $input) {
    accountName
    accountNumber
    bank
    branchNumber
    CLABE
    description
    id
    informationNumber
  }
}`

export const bankAccountEdit = `
mutation bankAccountEdit($input: BankAccountInput, $search: BankAccountSearch) {
  bankAccountEdit(input: $input, search: $search) {
    accountName
    accountNumber
    bank
    branchNumber
    description
    CLABE
    id
    informationNumber
  }
}`;
