import { MutableRefObject, useMemo } from 'react';

import _ from 'lodash';
import { EntitiesState, Header } from 'types';

const useDataSheetRequired = (header: Header[], entitiesMapRef: MutableRefObject<EntitiesState>) => {
  const requiredHeaders: Header[] = useMemo(() => header.filter(({ isRequired }) => isRequired), [header]);
  const requiredMissingValues: EntitiesState = {};
  
  Object.values(entitiesMapRef.current).forEach((entity) => {
    requiredHeaders.forEach(({ field, title }) => {
      const value = _.get(entity, field, '');
      if (!value) {
        _.set(requiredMissingValues, `${entity.id}.id`, entity.id);
        _.set(requiredMissingValues, `${entity.id}.${field}`, title);
      }
    });
  });

  return {
    requiredMissingValues,
  };
};

export default useDataSheetRequired;
