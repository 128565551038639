import { CSSProperties, FC } from 'react';

import {
  Search
} from '@mui/icons-material';
import {
  Input,
  Paper,
} from '@mui/material';

import { usePlaceholders } from '../hooks';

interface Props {
  value: string,
  onChange: (val: string) => void,
  placeholder: string,
  placeholders?: string[],
  placeholderDelay?: number,
  containerStyle?: CSSProperties,
  paperStyle?: CSSProperties,
  iconStyle?: CSSProperties,
};

const defaultContainerStyle = { margin: '20px' };

const SearchInput: FC<Props> = ({
  value,
  onChange,
  placeholder,
  placeholders,
  placeholderDelay,
  containerStyle,
  paperStyle,
  iconStyle
}) => {
  const delay: number | null | undefined = Number(placeholders?.length) > 0 ? placeholderDelay : null;
  const dinamicPlaceholder = usePlaceholders(placeholders, placeholder, delay);
  return (
    <div style={containerStyle}>
      <Paper
        elevation={1}
        style={{
          width: '100%',
          overflowX: 'auto',
          display: 'flex',
          alignItems: 'center',
          padding: '10px 20px',
          ...paperStyle
        }}
      >
        <Search
          color='action'
          style={{
            marginRight: '8px',
            ...iconStyle
          }}
        />
        <Input
          className='flex flex-1'
          disableUnderline
          fullWidth
          inputProps={{
            'aria-label': 'Search',
          }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          placeholder={dinamicPlaceholder}
          value={value}
        />
      </Paper>
    </div>
  );
};

SearchInput.defaultProps = {
  placeholders: [],
  placeholderDelay: 100,
  containerStyle: defaultContainerStyle,
  paperStyle: {},
  iconStyle: {},
}

export default SearchInput;
