import * as bankAccounts from './bankAccounts';
import * as dismissalTypes from './dismissalTypes';
import * as employees from './employees';
import * as extraordinaryPayrolls from './extraordinaryPayrolls';
import * as holidays from './holidays';
import * as optionsCatalog from './optionsCatalog';
import * as payrollPeriod from './payrollPeriod';
import * as payrollPeriodEmployees from './payrollPeriodEmployees';
import * as payrolls from './payrolls';
import * as pppsPayrolls from './pppsPayrolls';
import * as reports from './reports';
import * as sfprepayrolls from './sfprepayrolls';

const queries = {
  ...bankAccounts,
  ...dismissalTypes,
  ...employees,
  ...extraordinaryPayrolls,
  ...holidays,
  ...optionsCatalog,
  ...payrollPeriod,
  ...payrollPeriodEmployees,
  ...payrolls,
  ...pppsPayrolls,
  ...reports,
  ...sfprepayrolls,
};

export default queries;
