export type BankAccountDraft = {
  accountNumber: string;
  adcEmail: string;
  branchNumber: string;
  CLABE: string;
  clientFullname: string;
  clientId: string;
  documentId: string;
  employeeFullname: string;
  employeeId: string;
  id: string;
  status: BankAccountDraftStatuses;
};

export type BankAccountDraftUpdate = {
  accountNumber?: string;
  adcEmail?: string;
  branchNumber?: string;
  CLABE?: string;
  clientFullname?: string;
  clientId?: string;
  documentId?: string;
  employeeFullname?: string;
  employeeId?: string;
  id: string;
  status?: BankAccountDraftStatuses;
};

export enum BankAccountDraftStatuses {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending',
  Invalidated = 'invalidated',
};

export type BankAccountDraftFilter = {
  page: number;
  limit: number;
  accountNumber?: string;
  adcEmail?: string;
  branchNumber?: string;
  CLABE?: string;
  clientFullname?: string;
  clientId?: string;
  documentId?: string;
  employeeFullname?: string;
  employeeId?: string;
  status?: BankAccountDraftStatuses;
};

