import { useCallback, useMemo } from 'react';
import {
  GroupBase,
  InputProps,
} from 'react-select';
import { useToggle } from 'react-use';

import { TextField } from '@mui/material';

const Input = <
Option,
IsMulti extends boolean = false,
Group extends GroupBase<Option> = GroupBase<Option>
> (props: InputProps<Option, IsMulti, Group>): JSX.Element => {
  const {
    selectProps,
    innerRef,
    value,
    onChange,
    onFocus,
    onBlur,
    getValue,
    id,
    name,
  } = props;
  const {
    error,
    label,
    helperText,
    required,
  } = selectProps;
  const currentSelectedValues = getValue();

  const [isFocused, toggleIsFocused] = useToggle(false);

  const placeholder = useMemo(() => {
    if (currentSelectedValues.length > 0 || value !== '') return '';
    return selectProps.placeholder as string;
  }, [currentSelectedValues.length, selectProps.placeholder, value]);

  const shrink = useMemo(() => {
    if (value !== '') return true;
    if (currentSelectedValues.length > 0) return true;
    if (isFocused && placeholder.length > 0) return true;
    return false;
  }, [currentSelectedValues.length, isFocused, placeholder.length, value]);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    if (onFocus) {
      onFocus(event);
      toggleIsFocused(true);
    }
  }, [onFocus, toggleIsFocused]);

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    if (onBlur) {
      onBlur(event);
      toggleIsFocused(false);
    }
  }, [onBlur, toggleIsFocused]);

  return (
    <TextField
      error={error}
      FormHelperTextProps={{ style: { position: 'absolute', top: '100%' } }}
      helperText={error && helperText}
      id={id}
      InputLabelProps={{
        shrink,
      }}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      onFocus={handleFocus}
      placeholder={placeholder}
      ref={innerRef}
      required={required}
      size='small'
      value={value}
    />
  );
};

export default Input;
