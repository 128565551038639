import { MutableRefObject } from 'react';

// import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import {
  Cell,
  CellPosition,
  CellType,
  CellUpdate,
  CellUpdatesState,
  defaultValues,
  EntitiesState,
  Entity,
  GetInitMetadata,
  GridKey,
  HandleChanges,
  Header,
  Metadata,
  ObjectMap,
  OnClickCell,
  Option,
  Value,
} from '../types/datasheet';
import normalizeString from './normalizeString';

export const getEntitiesArray = (entities: Entity[] | Record<string, Entity> | number): Entity[] => {
  if (Array.isArray(entities)) return entities;
  if (typeof entities === 'object') return Object.values(entities);
  if (Number.isInteger(entities)) {
    return Array.from(new Array(entities), (_entities, rowIndex) => ({ id: `${rowIndex}` }));
  }
  return [];
};

export const findOption = (search: string, options: Array<Option> = []): Option => {
  const normalizedSearch = normalizeString(search);
  const option = options.find(({ value, label }) => {
    const normalizedValue = normalizeString(value);
    const normalizedLabel = normalizeString(label);
    return normalizedSearch === normalizedValue || normalizedSearch === normalizedLabel;
  });
  if (option !== undefined) return option;
  return { value: '', label: '' };
};

export const getDefaultValue = (cell: Header) : Value => {
  const { type, defaultValue, defaultLabel } = cell;
  if (type === 'select') {
    return {
      value: (defaultValue || defaultLabel) as string,
      label: (defaultLabel || defaultValue) as string,
    };
  }
  return defaultValue || defaultValues[type];
};

export const getNonValue = (type: CellType): Value => {
  if (type === 'check' || type === 'boolean') return false;
  if (type === 'date' || type === 'file') return null;
  if (type === 'select') return { label: '', value: '', };
  if (type === 'money') return 0;
  return '';
};

export const getRange = (start: number, end: number): number[] => {
  const array: number[] = [];
  const inc = end - start > 0;
  for (let i = start; inc ? i <= end : i >= end; inc ? i += 1 : i -= 1) {
    if (inc) {
      array.push(i);
    } else {
      array.unshift(i);
    }
  }
  return array;
};

export const getPositionsRange =
(start: CellPosition, end: CellPosition): CellPosition[] => getRange(start.i, end.i)
  .flatMap((i) => getRange(start.j, end.j).map((j) => ({ i, j })));
// getRange(start.i, end.i)
//   .map((i) => getRange(start.j, end.j)
//     .map((j) => ({ i, j })))
//   .flat();

export const getObjectMap = (objects: Record<string, unknown>[], keyPath: string): ObjectMap => {
  const objectsMap: ObjectMap = {};
  objects.forEach((object, index) => {
    const key: string = _.get(object, keyPath, '') as string;
    objectsMap[key] = index;
    objectsMap[index] = key;
  });
  return objectsMap;
};

export const getValue = (entity: Entity, header: Header, metadata?: Metadata): Value => {
  const { pathValue, separator, isEnabled, defaultValue = '', type } = header;
  const defaultVal = defaultValue || (isEnabled ? getDefaultValue(header) : getNonValue(type));

  if (header.type === 'select') {
    if (typeof pathValue === 'function') {
      return defaultVal
    };
    const path = Array.isArray(pathValue)
      ? pathValue.map((p) => _.get(entity, p, defaultValue)).join(separator)
      : pathValue;
    const options: Option[] = header?.options || [];
    const search = _.get(entity, path || '', defaultValue);
    return findOption(search, options);
  }

  if (Array.isArray(pathValue)) {
    const values = pathValue.map((path) => {
      if (header.type === 'money') {
        return _.get(entity, path, 0);
      }
      return _.get(entity, path, '');
    });

    if (values.length === 0) return defaultVal;

    if (header.type === 'money') {
      return values.reduce((acc, current) => acc + current);
    }

    return values.join(separator);
  }

  if (typeof pathValue === 'string') {
    return _.get(entity, pathValue, defaultVal) || defaultVal;
  }

  if (typeof pathValue === 'function') {
    return pathValue(entity, metadata);
  }

  return defaultVal;
};

export const getRowsGrid = (
  header: Header[],
  entities: Entity[],
  entitiesMapRef: MutableRefObject<EntitiesState>,
  handleChangesRef: MutableRefObject<HandleChanges | undefined>,
  updateCellAtributesRef: MutableRefObject<((row: GridKey, col: GridKey, newAttributes: CellUpdate) => void) | undefined>,
  metadata?: Metadata,
  defaultGetInitMetadata?: GetInitMetadata,
  updatedCells?: CellUpdatesState,
  extraUpdatedCells?: CellUpdatesState
): Cell[][] => {
  const grid = entities.map<Cell[]>((entity, rowIndex) => {
    const { id } = entity;
    const rowGrid = header.map<Cell>((column, colIndex) => {
      const {
        field,
        isEnabled,
        isRequired,
        options,
        title,
        type,
        width,
        getInitMetadata = defaultGetInitMetadata,
        validation,
        overflow,
        handleClick,
        buttonComponentPath,
        cleanOnChange,
        cleanValuesOnChange,
        messageBeforeValidation,
        forceRevalidation,
        minDateValue,
        maxDateValue,
        defaultLabel,
      } = column;
      const value = getValue(entity, column, metadata);
      const cell: Cell = {
        className: '',
        colIndex,
        field,
        id,
        initialValue: value,
        isRequired,
        options,
        readOnly: !isEnabled,
        rowIndex,
        title,
        type,
        value,
        width,
        isLoading: false,
        validation,
        overflow,
        entitiesMapRef,
        metadata,
        cleanOnChange,
        cleanValuesOnChange,
        buttonComponentPath,
        messageBeforeValidation,
        forceRevalidation,
        handleChangesRef,
        updateCellAtributesRef,
        minDateValue,
        maxDateValue,
        label: defaultLabel,
        ...(getInitMetadata && getInitMetadata(entity)),
        ..._.get(updatedCells, `${id}.${field}`, {}),
        ..._.get(extraUpdatedCells, `${id}.${field}`, {}),
        onClick: undefined,
      };
      if (type === 'select') {
        const option = value as Option;
        cell.label = option.label;
        cell.value = option.value;
        cell.initialValue = option.value;
      }
      const onClick: OnClickCell | undefined = _.get(extraUpdatedCells, `${id}.${field}.onClick`, handleClick) as OnClickCell | undefined;
      if (onClick) {
        cell.onClick = () => {
          onClick(entity.id, entitiesMapRef, { metadata });
        };
      }

      return cell;
    });
    return rowGrid;
  });
  return grid;
};

export const getRowsEntities = (
  entities: Entity[],
  header: Header[],
  metadata?: Metadata
): Record<string, Entity> => {
  const rows: Record<string, Entity> = {};
  entities.forEach((entity) => {
    const { id } = entity;
    rows[id] = { ...entity };
    header.forEach((column) => {
      const { field, type } = column;
      rows[id][field] = getValue(entity, column, metadata);
      if (type === 'select' && column.pathValue === undefined) {
        const option = rows[id][field] as Option;
        rows[id][field] = option.value;
      }
    });
  });
  return rows;
};
