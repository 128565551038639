import _ from 'lodash';

const defaultValidation = (value: unknown): boolean => {
  if (typeof value === 'string') {
    return value.trim() === '';
  }
  return _.isNil(value);
};

const myOmitByRecursively = (value: unknown, validation = defaultValidation): unknown => {
  const cb = (v: unknown) => myOmitByRecursively(v, validation);
  if (_.isObject(value)) {
    if (_.isArray(value)) {
      return _.map(value, cb);
    }
    if (_.isDate(value)) {
      return value;
    }
    return _(value).mapValues(cb).omitBy(validation).value();
  }
  return value;
};

export default myOmitByRecursively;
