import { useMemo } from 'react';

import { Dialog } from '@mui/material';

import Authorization from './Authorization';
import Confirmation from './Confirmation';
import Summary from './Summary';
import { Step } from './types';

export interface EmployeesConfirmationDialogProps {
  open?: boolean;
  onClose: () => void;
  errors?: any;
  step: Step;
  titles: Record<
    Step,
    | string
    | {
      primary: string;
      secondary?: string;
    }
  >;
  confirming?: boolean;
  confirmDisabled?: boolean;
  onFinish: (step: Step) => void;
  selectedEmployees: any[];
  onChangeSelectedEmployees: (employees: any[]) => void;
  successMessage: string;
  errorMessage: string;
}

export default function EmployeesConfirmationDialog({
  open = false,
  onClose = () => { },
  errors = [],
  step,
  titles,
  confirming,
  confirmDisabled,
  onFinish,
  selectedEmployees,
  onChangeSelectedEmployees,
  successMessage,
  errorMessage,
}: EmployeesConfirmationDialogProps) {
  const content = useMemo(() => {
    switch (step) {
      case 'authorization':
        return (
          <Authorization
            onCancel={onClose}
            onFinish={onFinish}
            title={titles.authorization}
          />
        );
      case 'confirmation':
        return (
          <Confirmation
            confirmDisabled={confirmDisabled}
            confirming={confirming}
            onCancel={onClose}
            onChangeSelectedEmployees={onChangeSelectedEmployees}
            onFinish={onFinish}
            selectedEmployees={selectedEmployees}
            title={titles.confirmation}
          />
        );
      case 'summary':
        return (
          <Summary
            errorMessage={errorMessage}
            errors={errors}
            onCancel={onClose}
            onFinish={onFinish}
            successMessage={successMessage}
            title={titles.summary}
          />
        );
      default:
        throw new Error(`Unknown EmployeesConfirmationDialog state: ${step}`);
    }
  }, [
    step,
    onClose,
    onFinish,
    titles.authorization,
    titles.confirmation,
    titles.summary,
    confirmDisabled,
    confirming,
    onChangeSelectedEmployees,
    selectedEmployees,
    errorMessage,
    errors,
    successMessage,
  ]);

  if (!open) return null;

  return (
    <Dialog onClose={onClose} open={open}>
      {content}
    </Dialog>
  );
}

EmployeesConfirmationDialog.defaultProps = {
  open: false,
  confirming: false,
  confirmDisabled: false,
  errors: null,
}