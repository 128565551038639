import moment from 'moment-timezone';

import { Column, Employee } from '../../../types';

const getIsSecuredStatus = (employee: Employee) => {
  const isSecured =
    !employee.waitConfirmationHiring &&
    !employee.waitingConfirmationReentry &&
    employee.status === 'Empleado' &&
    employee.payrollType !== null;

  return isSecured;
};

const defaultMetadata = (entity: Employee): { [key: string]: any } => {
  let initialMetadata: { [key: string]: any } = {
    employeeID: entity.id,
  }
  const dismissedStatus = entity.status === 'Baja';

  if (dismissedStatus) {
    initialMetadata = {
      ...initialMetadata,
      readOnly: true,
      isEnabled: false,
    };
  }

  return initialMetadata
}

const columns: Column[] = [
  {
    field: 'key',
    title: 'Clave',
    pathValue: 'key',
    isEnabled: false,
  },
  {
    field: 'status',
    title: 'Estatus',
    pathValue: 'status',
    isEnabled: false,
  },
  {
    field: 'name',
    title: 'Nombre(s)',
    pathValue: 'name',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'firstLastName',
    title: 'Primer Apellido(s)',
    pathValue: 'firstLastName',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'secondLastName',
    title: 'Segundo Apellido(s)',
    pathValue: 'secondLastName',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'rfc',
    title: 'RFC',
    pathValue: 'rfc',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'curp',
    title: 'CURP',
    pathValue: 'curp',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'imss',
    title: 'NSS',
    pathValue: 'imss',
    type: 'text',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'incorporation',
    title: 'Fecha de Ingreso',
    pathValue: 'incorporation',
    type: 'date',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'dateOfFirstEntry',
    title: 'Fecha de Primer Ingreso',
    pathValue: 'dateOfFirstEntry',
    type: 'date',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'tenureDate',
    title: 'Fecha de Antigüedad',
    pathValue: 'tenureDate',
    type: 'date',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'email',
    title: 'Email',
    pathValue: 'email',
    type: 'email',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'job',
    title: 'Puesto',
    pathValue: 'job',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'department',
    title: 'Departamento',
    pathValue: 'department',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'payrollBranch',
    title: 'Sucursal de Sociedad',
    pathValue: 'payrollBranch',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'economicZone',
    title: 'Zona Fronteriza',
    pathValue: 'economicZone',
    isEnabled: false,
    type: 'text',
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {};

      if (employee.economicZone) {
        metadata = {
          ...metadata,
          value: 'Zona Fronteriza',
        };
      } else {
        metadata = {
          ...metadata,
          value: 'Zona No Fronteriza',
        };
      }

      return metadata;
    },
  },
  {
    field: 'minimumWage',
    title: 'Salario Mínimo',
    pathValue: 'minimumWage',
    type: 'money',
    isEnabled: false,
  },
  {
    field: 'dailyWage',
    title: 'Sueldo Diario',
    pathValue: 'dailyWage',
    type: 'money',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      if (employee.payrollTypeData?.taxSchema === 'PPPS') {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'variableSalaries',
    title: 'Sueldos Variables',
    pathValue: 'variableSalariesData.currentAmount',
    type: 'money',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {
        ...defaultMetadata(employee),
      };

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      if (employee.payrollTypeData?.taxSchema === 'PPPS') {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      return metadata;
    },
  },
  {
    field: 'integratedDailyWage',
    title: 'SDI',
    pathValue: 'integratedDailyWage',
    type: 'money',
    isEnabled: false,
  },
  {
    field: 'netSalary',
    title: 'Salario Neto',
    pathValue: 'netSalary',
    type: 'money',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'workType',
    title: 'Tipo de Jornada',
    pathValue: 'workType',
    type: 'text',
    isEnabled: false,
    getInitMetadata: () => {
      let metadata = {};

      metadata = {
        ...metadata,
        value: 'Semana Completa',
      };
      return metadata;
    },
  },
  {
    field: 'daysType',
    title: 'Jornada Laboral',
    pathValue: 'daysType',
    isRequired: true,
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'salaryType',
    title: 'Tipo de Salario',
    pathValue: 'salaryType',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'pensionType',
    title: 'Tipo de Pensión',
    pathValue: 'pensionType',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'contractType',
    title: 'Tipo de Contrato',
    pathValue: 'actualContractTypeId',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'civilStatus',
    title: 'Estado Civil',
    pathValue: 'civilStatus',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'gender',
    title: 'Género',
    pathValue: 'gender',
    type: 'text',
    isEnabled: false,
  },
  {
    field: 'bornDate',
    title: 'Fecha de Nacimiento',
    pathValue: 'bornDate',
    type: 'date',
    isEnabled: false,
  },
  {
    field: 'infonavit',
    title: '¿Tiene Infonavit?',
    pathValue: 'infonavit',
    type: 'boolean',
    isEnabled: false,
  },
  {
    field: 'infonacot',
    title: '¿Tiene Fonacot?',
    pathValue: 'infonacot',
    type: 'boolean',
    isEnabled: false,
  },
  {
    field: 'payrollType',
    title: 'Nómina',
    pathValue: 'payrollType',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'paymentFrequency',
    title: 'Frecuencia de Pago',
    pathValue: 'payrollFrequencyData.name',
    type: 'text',
    isEnabled: false,
  },
  {
    field: 'payrollScheme',
    title: 'Esquema de Nómina',
    pathValue: 'payrollTypeData.taxSchema',
    type: 'text',
    isEnabled: false,
  },
  {
    field: 'paymentMethod',
    title: 'Metodo de Pago',
    pathValue: 'paymentMethod',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      const metadata = {
        ...defaultMetadata(employee),
      };

      return metadata;
    },
  },
  {
    field: 'work',
    title: 'Obra',
    pathValue: 'work',
    type: 'select',
    isEnabled: true,
    getInitMetadata: (entity) => {
      const employee = entity as Employee;
      let metadata = {};

      const isSecured = getIsSecuredStatus(employee);

      if (isSecured) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      if (employee.worksByClientAndBranch?.length === 0) {
        metadata = {
          ...metadata,
          readOnly: true,
          isEnabled: false,
        };
      }

      metadata = {
        ...metadata,
        value: employee.workData?.clientWorkRecord ? `${employee.workData?.clientWorkRecord} (${employee.workData?.name})` : ''
      }

      return metadata;
    },
  },
  {
    field: 'dismissDate',
    title: 'Fecha de Baja',
    pathValue: 'dismissDate',
    type: 'date',
    isEnabled: false,
    getInitMetadata: (entity) => {
      let metadata = {};

      const employee = entity as Employee;

      const utcDate = new Date(employee.dismissDate);
      const date = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

      const formattedDate = moment(date).format('YYYY-MM-DD');

      metadata = {
        ...metadata,
        value: formattedDate === '0000-12-31' ? '' : formattedDate
      }

      return metadata;
    },
  },
];

export default columns;
